import React, { useState, useEffect, useRef } from "react";
import styled, { withTheme } from "styled-components";
import GlossaryPage from "../components/GlossaryPage";

const PageWrapper = styled.section`
  .glossary-title {
    top: 0;
  }
`;

function Glossary({ theme }) {
  return (
    <PageWrapper>
      <GlossaryPage />
    </PageWrapper>
  );
}

export default withTheme(Glossary);
