import React, { useState, useEffect, useRef } from "react";
import api from "../components/api";
import utils from "../components/utils";
import { useOktaAuth } from "@okta/okta-react";
import styled, { withTheme } from "styled-components";
import PostRow from "../components/PostRow";
import OutlineButton from "../components/OutlineButton";

const PageWrapper = styled.section`
  padding: 0 20px;

  .resource-listing {
    margin-top: 80px;

    @media only screen and (max-width: 900px) {
      margin-top: 30px;
    }
  }

  h2 {
    margin: 0;
    text-transform: uppercase;
    margin-bottom: 10px;
    color: ${({ theme }) => theme.textColor};

    @media only screen and (max-width: 900px) {
      text-align: center;
    }
  }

  .wrap {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: 40px;
    max-width: 1500px;
    max-width: 100%;
    overflow-x: hidden;
    box-sizing: border-box;

    @media only screen and (max-width: 900px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      justify-content: center;
      text-align: center;
    }

    .post-row {
      @media only screen and (max-width: 900px) {
        text-align: left;
      }
      @media only screen and (max-width: 500px) {
        align-items: center;
        text-align: center;
      }
    }
  }
`;

function getGenericThumbnail(postId) {
  // Define the directory and the range of image names
  const directory = "https://assets.mx.com/hub/images/post-images";
  const maxIndex = 21; // Maximum index of images (0 to 21)

  // Use the ID to pick an image index
  const imageIndex = postId % (maxIndex + 1); // Map to a valid image index

  // Construct the image filename
  const imageName = `${imageIndex}.jpeg`;

  // Return the full path to the image
  return `${directory}/${imageName}`;
}

function Resources({ theme }) {
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaInfo, setOktaInfo] = useState([]);
  const [resources, setResources] = useState([]);
  const [category, setCategory] = useState(false);
  const [postCount, setPostCount] = useState(0);
  const [page, setPage] = useState(1);
  const limit = 8;

  useEffect(() => {
    if (authState.isAuthenticated) {
      const accessToken = authState.accessToken.accessToken;
      setOktaInfo(authState.idToken.claims);
    }
  }, [oktaInfo]);

  useEffect(() => {
    const getResources = async () => {
      let filterParams = "filter[status][_eq]=published";
      filterParams += "&sort=title";
      filterParams += "&filter[_and][0][_and][0][manager_only][_eq]=false";
      let resourcesData = await api.collection(
        "resources",
        oktaAuth,
        filterParams
      );

      resourcesData.forEach((resource) => {
        resource.thumbnail_image = resource.thumbnail_image
          ? `https://directus.hub.mx.com/assets/${resource.thumbnail_image}`
          : getGenericThumbnail(resource.id);
      });

      setResources(resourcesData);
    };
    getResources();
  }, []);

  return (
    <>
      <PageWrapper>
        {/* <div id="filters"></div> */}
        <div className="resource-listing">
          <h2>Resources</h2>
          <div className="wrap">
            {resources &&
              resources.map((resource) => {
                let url = resource.link;
                if (!url) {
                  return;
                }
                const isExternal =
                  url.includes("http") && !url.includes("hub.mx.com");
                const convertToRelativeURL = url.includes("https://hub.mx.com");

                if (convertToRelativeURL) {
                  url = url.replace("https://hub.mx.com", "");
                }

                return (
                  <PostRow
                    external={isExternal}
                    key={resource.id}
                    postId={resource.id}
                    title={resource.title}
                    description={resource.description}
                    link={url}
                    postRowType="news_event"
                    image={resource.thumbnail_image}
                  />
                );
              })}
            <div></div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
}

export default withTheme(Resources);
