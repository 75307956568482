import { React, useEffect, useState, useRef } from "react";
import styled, { withTheme } from "styled-components";
import utils from "../components/utils";
import Dropdown from "../components/global/Dropdown";
import {
  Index,
  useHits,
  Highlight,
  Configure,
  useRefinementList,
  RefinementList,
  RangeInput,
  Snippet,
  useSearchBox,
  Pagination,
  ClearRefinements,
} from "react-instantsearch-hooks-web";
import Gap from "../components/gap";
import { set } from "lodash";

const Page = styled.section`
  color: ${({ theme }) => theme.textColor};
  background: ${({ theme }) => theme.backgroundColor};
  padding: 20px 0;
`;

const formatDate = (dateString) => {
  const date = new Date(dateString);

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
  });
};

function TheSearchResults({ searchValue, isManager }) {
  const mainIndexName = isManager ? "hub_manager_content" : "Main";
  // const [showLeavingTheHubMessage, setShowLeavingTheHubMessage] = useState(false);
  // const [leavingTheHubLink, setLeavingTheHubLink] = useState("");

  let query = searchValue;

  // const LeavingTheHubMessage = () => {
  //   return (
  //     <div id="leaving-the-hub-message">
  //       <div>
  //         <h2>You are now leaving the Hub</h2>
  //         <p>
  //           If you are not redirected{" "}
  //           <a target="_blank" rel="noreferrer" href={leavingTheHubLink}>
  //             Click here
  //           </a>
  //         </p>
  //       </div>
  //     </div>
  //   );
  // };

  const CustomRefinementDropdown = ({ attribute }) => {
    const { items, refine } = useRefinementList({ attribute });
    const [isOpen, setIsOpen] = useState(false);
    const [yearOpen, setYearOpen] = useState(false);

    const dropdownRef = useRef(null); // Ref to track the dropdown container

    // Close dropdown if clicking outside
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          dropdownRef.current &&
          !dropdownRef.current.contains(event.target)
        ) {
          setIsOpen(false);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, []);

    const refinedItems = items.filter((item) => item.isRefined);

    return (
      <div ref={dropdownRef}>
        <div id="dropdown-header">
          {/* <div className="filter-btn-year" onClick={() => yearOpen(!yearOpen)}>
            Filter by Year ▾
          </div> */}
          <div className="filter-btn" onClick={() => setIsOpen(!isOpen)}>
            Filter by Content Source ▾
          </div>
          <div id="filtered-items">
            {refinedItems.map((item) => (
              <p
                className="filtered-item"
                key={item.value}
                onClick={() => refine(item.value)}
              >
                {item.label}
              </p>
            ))}
            {/* <ClearRefinements
              translations={{ resetButtonText: "Clear filters" }}
            /> */}
          </div>

          <div id="filtered-years">
            {refinedItems.map((item) => (
              <p
                className="filtered-item"
                key={item.value}
                onClick={() => refine(item.value)}
              >
                {item.label}
              </p>
            ))}
            {/* <ClearRefinements
              translations={{ resetButtonText: "Clear filters" }}
            /> */}
          </div>
        </div>

        <ul className={isOpen ? "" : "hidden"}>
          {items.map((item) => (
            <li key={item.value}>
              <input
                type="checkbox"
                checked={item.isRefined}
                onChange={() => refine(item.value)}
              />
              <p onClick={() => refine(item.value)}>
                {item.label} <span>({item.count})</span>
              </p>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const Results = ({ query }) => {
    const { hits } = useHits();

    // 🔹 Hide results before searching
    if (!query) return null;

    return (
      <div>
        <div className="filters">
          {hits.length > 0 && (
            <DropdownContainer>
              <CustomRefinementDropdown attribute="content_source" />
            </DropdownContainer>
          )}
        </div>
        {hits.length > 0 ? (
          <>
            {/* 🔹 Show Results */}
            <div className="the-results">
              {hits.map((hit) => (
                <a
                  key={hit.objectID}
                  className="result-item"
                  href={hit.url}
                  target="_blank"
                >
                  {hit.image && (
                    <img
                      className="result-image"
                      src={hit.image}
                      alt={hit.title}
                    />
                  )}
                  <div className="content">
                    <p className="content-source">
                      {hit.content_source}
                      {" - "}
                      <span className="sub-title">
                        <b>
                          {hit.sub_title}
                          <span className="last-updated">
                            {formatDate(hit.last_updated)}
                          </span>
                        </b>
                      </span>
                    </p>

                    <h4>
                      <Highlight hit={hit} attribute="title" />
                    </h4>
                    <p>{<Snippet attribute="content" hit={hit} />}</p>
                  </div>
                </a>
              ))}
            </div>
            <Pagination showFirst={false} showLast={false} />
          </>
        ) : (
          <p>No results found.</p>
        )}
      </div>
    );
  };

  function GlossarySnippets() {
    const { hits } = useHits();
    const result = hits[0];

    if (
      result &&
      result._rankingInfo.words - 1 === result._rankingInfo.proximityDistance
    ) {
      return (
        <div className="glossary-results">
          <h2 className="section-title">Glossary</h2>
          <div className="glossary-snippet">
            <h3>{result.term}</h3>
            <p>{result.definition}</p>
          </div>
        </div>
      );
    }

    return null;
  }

  return (
    <Page>
      <SearchResultsWrap className="search-results">
        {/* {showLeavingTheHubMessage && <LeavingTheHubMessage />} */}

        <StylesWrap
          className={`${query ? `searching ${query}` : "not-searching"}`}
        >
          {/* <StylesWrap> */}
          <Configure hitsPerPage={10} clickAnalytics={true} />
          <div className={`search-container`}>
            <Index indexName="Hub Glossary">
              <Configure hitsPerPage={1} getRankingInfo={true} />
              <GlossarySnippets />
            </Index>
            <Gap size={30} />
            <SearchResultsWrap>
              <Results query={query} />
            </SearchResultsWrap>
          </div>
        </StylesWrap>
      </SearchResultsWrap>
    </Page>
  );
}

const StylesWrap = styled.div`
  width: 800px;
  max-width: 100%;
  margin: 0 auto;
  padding: -2-px 50px 20px;
  box-sizing: border-box;

  .ais-SearchBox-form button {
    display: none;
    width: 100%;
  }
  p {
    color: ${({ theme }) => theme.textColor};
  }

  h1 {
    width: fit-content;
    text-align: center;
    margin: 0 auto 30px auto;
    color: ${({ theme }) => theme.textColor};
    font-weight: 300;
    font-size: 21px;
  }

  &.no-access {
    h1 {
      font-size: 35px;
    }
    p {
      font-size: 18px;
      text-align: center;
      color: ${({ theme }) => theme.textColor};
    }
  }

  .search-header {
    text-align: center;
    #main-hub-logo {
      width: 200px;
      max-width: 100%;
    }

    input {
      height: 40px;
      width: 100%;
      max-width: 100%;
      border-radius: 25px;
      height: 33px;
      padding: 0px 20px;
      border: 1px solid rgb(187, 187, 187);
      background: ${({ theme }) => theme.backgroundColor};
      color: ${({ theme }) => theme.textColor};
    }
  }

  &.not-searching {
    .search-container {
      margin-top: 20%;
      text-align: center;
      width: 100%;
    }
  }

  .filters {
    min-height: 50px;
    h4 {
      margin-bottom: 0;
    }
    button {
      background: transparent;
      border: none;
    }
  }

  .ais-RefinementList {
    border-radius: 15px;
    z-index: 20;
    width: 100%;

    ul {
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      list-style: none;
      padding: 0 20px;
      margin: 10px 0 0 0;
      width: 100%;
    }

    li {
      color: ${({ theme }) => theme.textColor};
      margin: 0 7px;
      width: fit-content;

      label {
        align-items: center;
        display: flex;
        flex-direction: row;
      }
    }

    .ais-RefinementList-checkbox {
      margin-right: 10px;
      width: 15px;
      height: 15px;
    }
    .ais-RefinementList-labelText {
      text-transform: capitalize;
      margin-right: 5px;
    }
    .ais-RefinementList-count {
      font-size: 14px;
      font-weight: 500;
      color: ${({ theme }) => theme.blue};
    }
  }

  &:has(.ais-RefinementList-item--selected) button {
    color: ${({ theme }) => theme.blue};
  }

  &.active-dropdown {
    button {
      color: ${({ theme }) => theme.blue};
    }
    .ais-RefinementList {
      display: block;
    }
  }

  .ais-Pagination {
    text-align: center;
    margin-top: 50px;
    ul {
      list-style: none;
      display: flex;
      justify-content: center;
      align-items: center;

      @media only screen and (max-width: 400px) {
        padding: 0;
        margin-bottom: 100px;
      }

      li {
        color: ${({ theme }) => theme.textColor};

        a {
          color: ${({ theme }) => theme.textColor};
          text-decoration: none;
        }
      }
    }
    .ais-Pagination-item {
      font-size: 18px;
    }
    .ais-Pagination-item--selected {
      background: ${({ theme }) => theme.blue};
      border-radius: 50%;
      a {
        color: #fff !important;
      }
    }
    .ais-Pagination-item--firstPage {
      margin-right: 10px;
    }
    .ais-Pagination-item--previousPage {
      margin-right: 10px;
    }
    .ais-Pagination-item--nextPage {
      margin-left: 10px;
    }
    .ais-Pagination-item--lastPage {
      margin-left: 10px;
    }
    .ais-Pagination-item--page {
      width: 30px;
      height: 30px;
      padding: 5px;
      line-height: 1.7;
    }
  }

  button {
    &.filtered {
      background: ${({ theme }) => theme.blue};
      color: #fff;
    }
  }
`;

const SearchResultsWrap = styled.div`
  .the-results {
    width: 100%;
    max-width: 100%;
  }
  /* Glossary */
  .glossary-results {
    margin-top: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid ${({ theme }) => theme.divider};

    @media only screen and (max-width: 900px) {
      margin-top: 0;
      padding: 20px;
    }

    .section-title {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
    }

    h2 {
      font-size: 22px;
      font-weight: 400;
      margin: 0;
      color: ${({ theme }) => theme.textColor};
    }
    .glossary-snippet {
      width: 600px;
      max-width: 100%;
      color: ${({ theme }) => theme.textColor};

      h3 {
        font-size: 28px;
        font-weight: 500;
        margin: 20px 0 10px 0;
        color: ${({ theme }) => theme.textColor};
      }

      p {
        margin: 0;
        font-size: 14px;
        font-weight: 400;
        color: ${({ theme }) => theme.textColor};
      }
    }
  }
  .result-item {
    text-decoration: none;
    color: ${({ theme }) => theme.textColor};
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 20px 0;
    transition: all 0.2s ease;

    @media only screen and (max-width: 600px) {
      flex-direction: column;
    }

    mark.ais-Highlight-highlighted,
    .ais-Snippet-highlighted {
      color: ${({ theme }) => theme.textColor};
      background-color: transparent !important;
      border-bottom: 2px solid ${({ theme }) => theme.blue};
    }

    &:hover {
      transform: scale(0.98);
    }

    .content {
      padding-top: 10px;
      margin-left: 30px;
      width: 100%;
      max-width: 100%;

      @media only screen and (max-width: 600px) {
        margin-left: 0;
      }

      p {
        margin: 5px 0 0 0;
      }

      h4 {
        margin: 0;
        font-size: 21px;
        width: 100%;
        max-width: 100%;
      }

      .content-source {
        text-transform: uppercase;
        margin: 0 0 5px 0;
        font-weight: 700;

        & > span {
          text-transform: capitalize;
          font-weight: 300;
        }

        .sub-title {
          .last-updated {
            color: ${({ theme }) => theme.textGray};
            font-weight: 300;
            margin-left: 10px;
          }
        }
      }
    }
  }

  .result-image {
    align-self: center;
    width: 80px;
    height: 80px;
    object-fit: cover;
    border-radius: 15px;
    background: #fff;

    @media only screen and (max-width: 600px) {
      width: 100%;
      /* object-fit: contain; */
    }
  }
`;

const DropdownContainer = styled.div`
  position: relative;
  text-align: center;
  cursor: pointer;

  .ais-ClearRefinements {
    position: absolute;
    right: -50px;
    top: 5px;

    button {
      color: ${({ theme }) => theme.textColor};

      &:disabled {
        display: none;
      }
    }
  }

  #dropdown-header {
    display: flex;
    flex-direction: column;

    .filter-btn {
      padding: 5px 20px;
      background: ${({ theme }) => theme.tabsWrapper};
      border-radius: 200px;
      color: ${({ theme }) => theme.textColor};
      width: fit-content;
    }
  }

  #filtered-items {
    margin-top: 10px;
    color: ${({ theme }) => theme.blue};
    display: flex;
    flex-wrap: wrap;
    flex: 1;

    p {
      background: ${({ theme }) => theme.blue};
      margin: 5px 10px;
      color: #fff;
      padding: 10px 14px;
      line-height: 1;
      border-radius: 200px;
      font-size: 14px;
    }
  }

  ul {
    position: absolute;
    top: 20px;
    background: ${({ theme }) => theme.tabsWrapper};
    color: ${({ theme }) => theme.textColor};
    list-style: none;
    padding: 0;
    text-align: left;
    width: 100%;
    left: 0;
    max-height: 300px;
    overflow: auto;
    border-radius: 10px;
    z-index: 2;

    &.hidden {
      display: none;
    }

    li {
      padding: 10px;
      display: flex;
      align-items: center;
      p {
        line-height: 1;
        margin: 0;
      }

      &:hover {
        background: ${({ theme }) => theme.hoverBackground};
      }

      span {
        color: ${({ theme }) => theme.blue};
        margin-left: 10px;
      }

      input {
        cursor: pointer;
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
  }
`;

export default withTheme(TheSearchResults);
