import React, { useState, useEffect, useRef } from "react";
import api from "../components/api";
import utils from "../components/utils";
import { useOktaAuth } from "@okta/okta-react";
import styled, { withTheme } from "styled-components";
import PostRow from "../components/PostRow";
import OutlineButton from "../components/OutlineButton";
import Pagination from "../components/PaginationB";
import FilterToggle from "../components/FilterButton";
import Gap from "../components/gap";
import Checkbox from "../components/Checkbox";
import axios from "axios";

const PageWrapper = styled.section`
  padding: 0 20px;
  color: ${({ theme }) => theme.textColor};

  h2 {
    margin: 0;
    text-transform: uppercase;
    color: ${({ theme }) => theme.textColor};
  }

  #filters {
    margin: 10px 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .tags-listing {
    margin-top: 5px;
  }

  #tags-dropdown {
    min-width: 200px;
    max-width: 100%;
    display: inline-block;
    button {
      /* width: 100%; */
      /* max-width: 100%; */
    }
    .dropdown-list {
      margin-top: 5px;
      width: 100%;
      max-width: 100%;
      height: 400px;
      overflow: auto;
      @media only screen and (min-width: 600px) {
        min-width: 300px;
      }
    }
  }

  .category-tag {
    display: inline-block;
    background: ${({ theme }) => theme.blue};
    padding: 5px 10px;
    border-radius: 35px;
    margin: 5px;
    cursor: pointer;
    p {
      margin: 0;
      line-height: 1;
    }
  }

  #pinned-articles {
    margin-top: 80px;
    @media only screen and (max-width: 900px) {
      margin-top: 30px;
    }
    h2 {
      margin-bottom: 20px;
    }
    .wrap {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      grid-gap: 20px;
      max-width: 1500px;
      max-width: 100%;
      padding-bottom: 20px;
      min-height: 280px;

      @media only screen and (max-width: 1100px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }
  }

  #articles-listing {
    padding-top: 30px;
    min-height: 400px;
    .wrap {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-column-gap: 40px;
      max-width: 1500px;
      max-width: 100%;
      overflow-x: hidden;
      box-sizing: border-box;

      @media (min-width: 1920px) {
        margin: 0 auto;
      }
      @media only screen and (max-width: 1100px) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
      }
    }

    #listing-page-count {
      color: ${({ theme }) => theme.textGray};
      font-size: 15px;
      font-weight: 400;
      margin-left: -15px;
      position: relative;
      top: -2px;
    }
  }

  #category-selection {
    #main-cats,
    #sub-cats {
      display: flex;
      gap: 16px;
      flex-wrap: wrap;
    }
  }
`;

const PinnedArticleWrap = styled.a`
  background: ${({ theme }) => theme.hoverBackground};
  padding: 20px;
  border-radius: 15px;
  transition: transform 0.2s ease;
  box-sizing: border-box;
  text-decoration: none;

  &:hover {
    transform: scale(0.99);
  }

  img {
    border-radius: 15px;
    width: 100%;
    height: 140px;
    object-fit: cover;
    margin-bottom: 5px;
  }
  h3 {
    margin: 0;
    margin-bottom: 10px;
    font-weight: 400;
    font-size: 20px;
    line-height: 1;
    color: ${({ theme }) => theme.textColor};
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  p {
    margin: 0;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.2;
    width: 100%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${({ theme }) => theme.textColor};
  }
`;

const FilterDropdown = styled.div`
  position: relative;
  display: inline;

  svg {
    margin-left: 10px;
  }

  button {
    text-transform: uppercase;
    cursor: pointer;
    color: ${({ theme }) => theme.textColor};
  }

  .dropdown-list {
    display: none;
    background: #fff;
    background: ${({ theme }) => theme.backgroundColor};
    border-radius: 15px;
    top: 30px;
    left: 0;
    position: absolute;
    z-index: 20;
    width: 250px;

    ul {
      list-style: none;
      padding: 0 20px;
    }

    li {
      margin: 10px;
      text-transform: capitalize;

      &.active-tag {
        color: ${({ theme }) => theme.blue};
      }

      &:hover {
        color: ${({ theme }) => theme.blue};
        cursor: pointer;
      }
    }
  }

  button {
    /* color: ${({ theme }) => theme.blue}; */
  }

  &.active-dropdown {
    button {
      /* color: ${({ theme }) => theme.blue}; */
    }
    .dropdown-list {
      display: block;

      background: ${({ theme }) => theme.hoverBackground};
    }

    svg {
      transform: rotate(180deg);
    }
  }
`;

const FilterButton = styled.button`
  display: inline;
  border: none;
  background: ${({ theme }) => theme.tabsWrapper};
  border-radius: 30px;
  padding: 5px 30px;
  margin: 5px 10px;

  &:first-child {
    margin-left: 0;
  }

  &.filtered {
    color: ${({ theme }) => theme.blue};
  }
`;

const getUniques = (array, key) => {
  const uniques = [
    ...new Set(
      array
        .map((item) => item[key])
        .filter((value) => value !== null && value !== undefined)
    ),
  ];
  return uniques;
};

const getSubUniques = (array, key) => {
  const uniqueThings = [];
  if (!array) return uniqueThings;
  for (const item of array) {
    const things = item[key];
    if (!things) continue;
    for (const thing of things) {
      if (thing && !uniqueThings.includes(thing)) {
        uniqueThings.push(thing);
      }
    }
  }
  return uniqueThings;
};

const genSubCatKey = (name) => {
  //lowercase
  name = name.toLowerCase();

  //replace -and- with -
  name = name.replace(/-and-/g, "-");

  //replace multiple whitespace with a single white space
  name = name.replace(/\s+/g, " ");

  //replace - with _
  name = name.replace(/-/g, "_");

  //replace " " with _
  name = name.replace(/\s/g, "_");

  return name + "_subcategory";
};

const ArticlesListingHeading = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const prettyCat = (cat) => {
  //replace and with &
  cat = cat.replace(/and/g, "&");

  //replace HR-People with HR/People
  cat = cat.replace(/hsa-fsa/g, "hsa/fsa");

  //replace - with " "
  cat = cat.replace(/-/g, " ");

  return cat;
};

function getGenericThumbnail(postId) {
  // Define the directory and the range of image names
  const directory = "https://assets.mx.com/hub/images/post-images";
  const maxIndex = 21; // Maximum index of images (0 to 21)

  // Use the ID to pick an image index
  const imageIndex = postId % (maxIndex + 1); // Map to a valid image index

  // Construct the image filename
  const imageName = `${imageIndex}.jpeg`;

  // Return the full path to the image
  return `${directory}/${imageName}`;
}

function Library({ theme }) {
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaInfo, setOktaInfo] = useState([]);
  const [articles, setArticles] = useState([]);
  const [category, setCategory] = useState("all");
  const [tags, setTags] = useState([]);
  const [activeTag, setActiveTag] = useState(false);
  const [prettyCategory, setPrettyCategory] = useState(false);
  const [postCount, setPostCount] = useState(0);
  const [page, setPage] = useState(1);
  const [pageArr, setPageArr] = useState([1, 2, 3, 4]);
  const limit = 16;
  const [pinnedArticles, setPinnedArticles] = useState([]);
  const [activeRefinementDropdown, setActiveRefinementDropdown] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1300);

  const [categoryFilters, setCategoryFilters] = useState([]);
  const [subCategoryFilters, setSubCategoryFilters] = useState([]);
  const [selectedCategoryFilter, setSelectedCategoryFilter] = useState(null);
  const [selectedSubCategoryFilter, setSelectedSubCategoryFilter] =
    useState(null);

  const [checkedManagerFilter, setCheckedManagerFilter] = useState(
    window.location.href.includes("?manager")
  );
  const handleManagerCheckboxToggle = () => {
    setCheckedManagerFilter(!checkedManagerFilter);
  };
  const [isManager, setIsManager] = useState(false);
  async function managerCheck() {
    const token = await oktaAuth.getIdToken();
    const result = await axios.post(
      // `http://localhost:3080/api/manager-check`,
      `https://api.hub.mx.com/api/manager-check`,
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );
    setIsManager(result.data.isManager);
  }

  useEffect(() => {
    if (authState.idToken.claims) {
      managerCheck();
    }
  }, [oktaInfo]);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => {
        const ismobile = window.innerWidth < 1300;
        if (ismobile !== isMobile) {
          setIsMobile(ismobile);
        }
        if (ismobile) {
          setIsMobile(ismobile);
          setSidebarToggleState("closed");
        } else {
          setSidebarToggleState("open");
        }
      },
      false
    );
  }, [isMobile]);

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });

  useEffect(() => {
    //get all categories
    (async () => {
      let params = `limit=-1`;
      params += "&fields=category";
      params += "&filter[status][_eq]=published";

      let allArticleCategories = await api.collection(
        "articles",
        oktaAuth,
        params
      );

      const uniqueCategories = getUniques(allArticleCategories, "category");
      setCategoryFilters(uniqueCategories);
    })();
  }, []);

  useEffect(() => {
    //get all subcategories
    (async () => {
      if (!selectedCategoryFilter) return;

      const subCatKey = genSubCatKey(selectedCategoryFilter);

      let params = `limit=-1`;
      params += "&fields=" + subCatKey;

      let allArticleSubCats = await api.collection(
        "articles",
        oktaAuth,
        params
      );

      const uniqueSubCategories = getUniques(allArticleSubCats, subCatKey);
      setSubCategoryFilters(uniqueSubCategories);
    })();
  }, [selectedCategoryFilter]);

  const handleClick = (event) => {
    if (activeRefinementDropdown) {
      setActiveRefinementDropdown("");
    }
    return;
  };

  useEffect(() => {
    // Check if the user is authenticated
    if (authState.isAuthenticated) {
      const accessToken = authState.accessToken.accessToken;
      setOktaInfo(authState.idToken.claims);

      // Now you can use the accessToken as needed
      // For example, you can include it in your API requests
    }
  }, [oktaInfo]);

  // Get Posts Count and tags
  useEffect(() => {
    (async () => {
      //  Get Posts
      let filterParams = "";

      if (selectedCategoryFilter) {
        filterParams += `&filter[category][_eq]=${selectedCategoryFilter}`;
      }
      if (selectedCategoryFilter && selectedSubCategoryFilter) {
        filterParams += `&filter[${genSubCatKey(
          selectedCategoryFilter
        )}][_eq]=${selectedSubCategoryFilter}`;
      }
      filterParams += `&filter[status][_eq]=published`;
      filterParams += "&sort=-update_date";
      filterParams += "&fields=id";

      if (checkedManagerFilter) {
        filterParams += "&filter[manager_only][_eq]=true";
      }

      let unlimitedParams = filterParams + "";
      unlimitedParams += `&limit=-1`;

      let limitedParams = filterParams + "";
      limitedParams += activeTag ? `&limit=-1` : `&limit=${limit}`;
      limitedParams += activeTag ? `` : `&page=${page}`;
      limitedParams += "&fields=id";
      limitedParams += "&fields=article_tags";
      limitedParams += "&fields=article_title";
      limitedParams += "&fields=article_url";
      limitedParams += "&fields=article_content";
      limitedParams += "&fields=featured_image";
      limitedParams += "&fields=category";

      const unlimitedData = await api.collection(
        "articles",
        oktaAuth,
        unlimitedParams
      );

      let articleData = await api.collection(
        "articles",
        oktaAuth,
        limitedParams
      );

      //set filtered posts count
      setPostCount(unlimitedData.length);

      //get unique tags within filtered posts
      let uniqueTags = getSubUniques(articleData, "article_tags");
      uniqueTags.sort();
      setTags(uniqueTags);

      if (activeTag) {
        //locally filter out tag
        articleData = articleData.filter(
          (a) => a.article_tags && a.article_tags.includes(activeTag)
        );
      }

      articleData.forEach((article) => {
        article.featured_image = article.featured_image
          ? `https://directus.hub.mx.com/assets/${article.featured_image}`
          : getGenericThumbnail(article.id);
      });

      setArticles(articleData);
    })();
  }, [
    activeTag,
    page,
    selectedCategoryFilter,
    selectedSubCategoryFilter,
    checkedManagerFilter,
  ]);

  // Get Pinned Articles
  useEffect(() => {
    let componentMounted = true;
    const getPinnedArticles = async () => {
      let pinnedFilterParams = "";
      pinnedFilterParams += `&filter[status][_eq]=published`;
      pinnedFilterParams += `&filter[pinned_article][_eq]=true`;
      if (category === "all") {
      } else {
        pinnedFilterParams += `&filter[category][_eq]=${category}`;
      }
      pinnedFilterParams += "&sort=-update_date";
      pinnedFilterParams += `&limit=3`;
      let pinnedArticleData = await api.collection(
        "articles",
        oktaAuth,
        pinnedFilterParams
      );
      if (componentMounted) {
        setPinnedArticles(pinnedArticleData);
      }
    };

    if (category) {
      getPinnedArticles();
    }

    return () => {
      componentMounted = false;
    };
  }, [category]);

  const SelectArrow = () => {
    let themeMode = theme.name;
    return (
      <svg
        width="11"
        height="6"
        viewBox="0 0 11 6"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.66602 1L5.66602 5L9.66602 1"
          stroke={themeMode === "light" ? "black" : "white"}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  };

  const PinnedArticle = ({ title, description, link, image, postId }) => {
    let featuredImage =
      "https://directus.hub.mx.com/assets/b4da0650-1312-4f10-8dee-3df4a79c4659";
    if (image) {
      featuredImage = `https://directus.hub.mx.com/assets/${image}?width=600`;
    } else {
    }
    return (
      <PinnedArticleWrap href={link}>
        <img src={featuredImage} />
        <div>
          <h3>{title}</h3>
          <p>{description}</p>
        </div>
      </PinnedArticleWrap>
    );
  };

  const toggleRefinementDropdown = (dropdownID) => {
    if (activeRefinementDropdown == dropdownID) {
      setActiveRefinementDropdown("");
    } else {
      setActiveRefinementDropdown(dropdownID);
    }
  };

  const handleTagClick = (e, tag) => {
    setPage(1);

    if (tag === "all") {
      setActiveTag(false);
    } else if (activeTag === tag) {
      return;
    } else {
      setActiveTag(tag);
    }

    setActiveRefinementDropdown("");
  };

  return (
    <>
      <PageWrapper>
        <div id="pinned-articles">
          <h2>Pinned Articles</h2>
          <div className="wrap">
            {pinnedArticles.length > 0 ? (
              pinnedArticles.map((article) => {
                return (
                  <PinnedArticle
                    key={article.id}
                    postId={article.id}
                    title={article.article_title}
                    description={utils.parseHTML(article)}
                    link={`/library/${article.article_url}/`}
                    image={article.featured_image}
                  />
                );
              })
            ) : (
              <p>No Pinned Articles</p>
            )}
          </div>
        </div>

        <Gap size={40} />
        <div id="category-selection">
          <h2>CATEGORIES</h2>
          <Gap size={20} />
          <div id="main-cats">
            {categoryFilters.length > 0 &&
              categoryFilters.map((cat, index) => {
                let state = "";
                if (selectedCategoryFilter) state = "grey";
                if (selectedCategoryFilter == cat) state = "selected";
                return (
                  <FilterToggle
                    key={index}
                    onClick={() => {
                      if (selectedCategoryFilter == cat) {
                        setPage(1);
                        setSelectedCategoryFilter(null);
                        setSelectedSubCategoryFilter(null);
                        setSubCategoryFilters([]);
                        setActiveTag(false);
                      } else {
                        setPage(1);
                        setSelectedCategoryFilter(cat);
                        setSelectedSubCategoryFilter(null);
                        setActiveTag(false);
                      }
                    }}
                    state={state}
                  >
                    {prettyCat(cat)}
                  </FilterToggle>
                );
              })}
          </div>
          <Gap size={20} />
          <div id="sub-cats">
            {subCategoryFilters.length > 0 &&
              subCategoryFilters.map((subCat, index) => {
                let state = "";
                if (selectedSubCategoryFilter) state = "grey";
                if (selectedSubCategoryFilter == subCat) state = "selected";
                return (
                  <FilterToggle
                    key={index}
                    onClick={() => {
                      if (selectedSubCategoryFilter == subCat) {
                        setPage(1);
                        setSelectedSubCategoryFilter(null);
                        setActiveTag(false);
                      } else {
                        setPage(1);
                        setSelectedSubCategoryFilter(subCat);
                        setActiveTag(false);
                      }
                    }}
                    state={state}
                    subVersion
                  >
                    {prettyCat(subCat)}
                  </FilterToggle>
                );
              })}
          </div>
        </div>
        <div id="articles-listing">
          <ArticlesListingHeading>
            <h2>
              All{" "}
              {selectedCategoryFilter ? prettyCat(selectedCategoryFilter) : ""}{" "}
              Articles
            </h2>
            <div id="filters">
              {
                <FilterDropdown
                  id="tags-dropdown"
                  className={
                    activeRefinementDropdown == "tags" && "active-dropdown"
                  }
                >
                  <FilterButton
                    onClick={() => toggleRefinementDropdown("tags")}
                  >
                    {activeTag ? activeTag : "Filter by Tag"}
                    <SelectArrow />
                  </FilterButton>

                  <div className="dropdown-list">
                    <ul>
                      <li
                        className={!activeTag ? "active-tag" : ""}
                        onClick={(e) => handleTagClick(e, "all")}
                      >
                        All
                      </li>
                      {tags &&
                        tags.map((tag, index) => {
                          return (
                            // <li onClick={(e) => handleTagClick("all", e)}>All</li>
                            <li
                              key={index}
                              onClick={(e) => handleTagClick(e, tag)}
                              className={activeTag === tag ? "active-tag" : ""}
                            >
                              {tag}
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </FilterDropdown>
              }
              <Gap size={10} />
              <div>
                {page > 1 && (
                  <span id="listing-page-count">{`Page ${page}`}</span>
                )}
              </div>
              {isManager && (
                <>
                  <Gap size={20} />
                  <Checkbox
                    id={"manager-filter"}
                    text={"Manager Only"}
                    checked={checkedManagerFilter}
                    toggleChecked={handleManagerCheckboxToggle}
                  />
                </>
              )}
            </div>
          </ArticlesListingHeading>
          <div className="wrap">
            {articles.length > 0 ? (
              articles.map((article) => {
                return (
                  <PostRow
                    key={article.id}
                    postId={article.id}
                    title={article.article_title}
                    description={utils.parseHTML(article)}
                    link={`/library/${article.article_url}/`}
                    image={article.featured_image}
                    tags={article.article_tags}
                    activeTag={activeTag}
                  />
                );
              })
            ) : (
              <p>No Results</p>
            )}
          </div>
          <Gap size={20} />
          <div>
            {postCount > 16 && (
              <Pagination
                setPage={setPage}
                page={page}
                count={postCount}
                limit={limit}
              />
            )}
          </div>
        </div>
        <Gap size={40} />
      </PageWrapper>
    </>
  );
}

export default withTheme(Library);
