import axios from "axios";
import utils from "./utils";
import { useOktaAuth } from "@okta/okta-react";
const asset = (key) => {
  return "https://directus.hub.mx.com/assets/" + key;
};

if (utils.getPageParams()["local-api-hard"]) {
  utils.createCookie("hub-local", true, 60);
  console.log(
    "pulling from local api for 1 hour. Delete hub-local api to undo"
  );
}

let apiBaseUrl =
  utils.getCookie("hub-local") || utils.getPageParams()["local-api"]
    ? "http://localhost:3080"
    : "https://api.hub.mx.com";

const getUserData = async (oktaAuth) => {
  const token = await oktaAuth.getIdToken();
  const result = await axios.post(`${apiBaseUrl}/api/user-check`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

const collection = async (name, oktaAuth, params) => {
  const token = await oktaAuth.getIdToken();

  const result = await axios.get(
    `${apiBaseUrl}/api/user/directus/collections/${name}?${params || ""}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data.data;
};

const collectionRaw = async (name, oktaAuth, params) => {
  const token = await oktaAuth.getIdToken();

  const result = await axios.get(
    `${apiBaseUrl}/api/user/directus/collections/${name}?${params || ""}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return result;
};

const collectionMeta = async (name, oktaAuth, params) => {
  const token = await oktaAuth.getIdToken();
  const result = await axios.get(
    `${apiBaseUrl}/api/user/directus/collections/${name}?${params || ""}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data.meta;
};

const getWebsiteMetadata = async (url) => {
  const result = await axios.get(
    `${apiBaseUrl}/api/fetch-metadata?site=${url}`
  );
  return result.data;
};

const getEmployeeRef = async (email) => {
  const result = await axios.post(`${apiBaseUrl}/api/ref-id`, { email: email });
  return result.data;
};

const directusImage = async (imageKey, oktaAuth, params) => {
  const token = await oktaAuth.getIdToken();

  const result = await axios.get(
    `${apiBaseUrl}/api/user/directus/assets/${imageKey}?${params || ""}`,
    {
      responseType: "blob",
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  console.log(result);
  return URL.createObjectURL(result.data);
};

const directusUser = async (userId, oktaAuth, params) => {
  const token = await oktaAuth.getIdToken();
  const result = await axios.get(
    `${apiBaseUrl}/api/user/directus/users/${userId}?${params || ""}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data.data;
};

const createItem = async (name, oktaAuth, data, params) => {
  const token = await oktaAuth.getIdToken();
  const result = await axios.post(
    `${apiBaseUrl}/api/user/directus/items/${name}?${params || ""}`,
    data,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  if (result.status == 200) {
    return "success";
  }
  return "error";
};

const updateItem = async (name, id, oktaAuth, data) => {
  const token = await oktaAuth.getIdToken();
  const result = await axios.patch(
    `${apiBaseUrl}/api/user/directus/items/${name}/${id}`,
    data,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  if (result.status == 200) {
    return "success";
  }
  return "error";
};

const updateMxComDirectusItem = async (name, id, oktaAuth, data) => {
  const token = await oktaAuth.getIdToken();
  const result = await axios.patch(
    `${apiBaseUrl}/public/mx-com/directus/collections/${name}/${id}`,
    data,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  if (result.status == 200) {
    return "success";
  }
  return "error";
};

const workdayReport = async (name, token, params) => {
  const result = await axios.get(
    `${apiBaseUrl}/api/user/workday/reports/${name}?${params || ""}`,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  return result.data.Report_Entry;
};

const getRequests = async (oktaAuth) => {
  const result = await axios.get(`${apiBaseUrl}/api/requests/`, {});

  return result;
};

const getServiceNowRequests = async (oktaAuth) => {
  const token = await oktaAuth.getIdToken();
  const result = await axios.get(`${apiBaseUrl}/api/sn/`, {
    headers: {
      authorization: `Bearer ${token}`,
    },
  });

  return result.data;
};

const employeePhoto = async (oktaAuth, image) => {
  const oktaToken = await oktaAuth.getIdToken();
  const s3Token = await axios.post(
    `${apiBaseUrl}/api/user/token`,
    {},
    {
      headers: {
        authorization: `Bearer ${oktaToken}`,
      },
    }
  );
  const imageResult = await axios.get(
    `${apiBaseUrl}/api/assets/s3/us-west-2/mx-images.mx.com/employee-images/${image}.jpg?access_token=${s3Token.data.access_token}`
  );

  return imageResult.request.responseURL;
};

const awsImage = async (oktaAuth, image) => {
  const oktaToken = await oktaAuth.getIdToken();
  const s3Token = await axios.post(
    `${apiBaseUrl}/api/user/token`,
    {},
    {
      headers: {
        authorization: `Bearer ${oktaToken}`,
      },
    }
  );
  const imageResult = await axios.get(
    `${apiBaseUrl}/api/assets.mx.com/uploads/summit/2022/media-kit/${image}.jpg?access_token=${s3Token.data.access_token}`
  );

  return imageResult.request.responseURL;
};

const api = {
  asset,
  getUserData,
  collection,
  collectionMeta,
  collectionRaw,
  directusUser,
  createItem,
  updateItem,
  updateMxComDirectusItem,
  workdayReport,
  employeePhoto,
  awsImage,
  directusImage,
  getWebsiteMetadata,
  getEmployeeRef,
  getRequests,
  getServiceNowRequests,
};

export default api;
