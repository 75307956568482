const testPagesData = [
  {
    status: "published",
    content_type: "blog",
    id: 110,
    url: "https://www.mx.com/blog/the-state-of-digital-banking-a-look-at-4-surveys",
    admin_url: "https://cms.mx.com/admin/content/blog/110",
    name: "Digital Banking Trends: A Synopsis of 4 Surveys",
    publish_date: "2014-08-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 43,
    url: "https://www.mx.com/blog/tech-giants-p2p-lending-and-how-banking-will-change-forever",
    admin_url: "https://cms.mx.com/admin/content/blog/43",
    name: "Tech Giants, P2P Lending, and How Banking Will Change Forever",
    publish_date: "2014-08-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 252,
    url: "https://www.mx.com/blog/marketing-advice-for-fintech-start-ups-know-your-customer-first",
    admin_url: "https://cms.mx.com/admin/content/blog/252",
    name: "Marketing Advice for Fintech Start-ups: Know Your Customer First",
    publish_date: "2014-08-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 81,
    url: "https://www.mx.com/blog/behind-the-scenes-designing-a-banking-app-for-any-device",
    admin_url: "https://cms.mx.com/admin/content/blog/81",
    name: "Behind the Scenes: Designing a Banking App for Any Device",
    publish_date: "2014-09-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 6,
    url: "https://www.mx.com/blog/why-every-banker-should-read-brett-kings-breaking-banks-book-review",
    admin_url: "https://cms.mx.com/admin/content/blog/6",
    name: "Book Review: Why Every Banker Should Read Brett King's Breaking Banks",
    publish_date: "2014-10-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 133,
    url: "https://www.mx.com/blog/fintech-book-review-chris-skinners-digital-bank",
    admin_url: "https://cms.mx.com/admin/content/blog/133",
    name: "Fintech Book Review: Chris Skinner's Digital Bank",
    publish_date: "2014-10-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 60,
    url: "https://www.mx.com/blog/handling-the-generation-gap-in-banking-part-i",
    admin_url: "https://cms.mx.com/admin/content/blog/60",
    name: "Handling the Generation Gap in Banking: Baby Boomers",
    publish_date: "2015-01-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 512,
    url: "https://www.mx.com/news/mx-apple-watch",
    admin_url: "https://cms.mx.com/admin/content/mx_news/512",
    name: "The MX Apple Watch App, Built on Helios",
    publish_date: "2015-04-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 260,
    url: "https://www.mx.com/blog/brett-king",
    admin_url: "https://cms.mx.com/admin/content/blog/260",
    name: "Brett King Interview: Branchless Solutions, Contextual Moments, and Smarter Fintech Partnerships",
    publish_date: "2016-02-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 187,
    url: "https://www.mx.com/blog/jim-marous",
    admin_url: "https://cms.mx.com/admin/content/blog/187",
    name: "Jim Marous Interview: 2016 Banking Trends Recap",
    publish_date: "2016-03-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 150,
    url: "https://www.mx.com/blog/fintech-bank-coopetition-part2",
    admin_url: "https://cms.mx.com/admin/content/blog/150",
    name: "Banks and Fintech: A Matter of Co-Opetition — Best Integration Practices",
    publish_date: "2016-03-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 146,
    url: "https://www.mx.com/blog/personalized-advice-through-alerts",
    admin_url: "https://cms.mx.com/admin/content/blog/146",
    name: "From Status to Insight: Financial Alerts That Provide Personalized Advice Will Win the Day",
    publish_date: "2016-04-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 47,
    url: "https://www.mx.com/blog/millennial-mortgage-anxiety",
    admin_url: "https://cms.mx.com/admin/content/blog/47",
    name: "Alleviating Millennial Mortgage Anxiety (From the Perspective of a Millennial)",
    publish_date: "2016-04-04",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 220,
    url: "https://www.mx.com/blog/david-gerbino",
    admin_url: "https://cms.mx.com/admin/content/blog/220",
    name: "Fintech as a Partner vs. Fintech as a Competitor: An Interview With David Gerbino",
    publish_date: "2016-04-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 198,
    url: "https://www.mx.com/blog/millennial-banks-trust",
    admin_url: "https://cms.mx.com/admin/content/blog/198",
    name: "How Banks Can Build Trust With Millennials, From a Millennial Viewpoint",
    publish_date: "2016-04-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 152,
    url: "https://www.mx.com/blog/from-the-mouth-of-a-millennial-who-we-are-and-why-were-important-to-banks",
    admin_url: "https://cms.mx.com/admin/content/blog/152",
    name: "From the Mouth of a Millennial: Who We Are and Why We’re Important to Banks",
    publish_date: "2016-04-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 4,
    url: "https://www.mx.com/blog/mobile-banking-first-seamless-across-all-devices",
    admin_url: "https://cms.mx.com/admin/content/blog/4",
    name: "How to Build Digital Banking: Mobile First, Seamless Across All Devices",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 12,
    url: "https://www.mx.com/blog/the-battle-to-be-consumers-primary-financial-institution",
    admin_url: "https://cms.mx.com/admin/content/blog/12",
    name: "The Battle To Be Consumers' Primary Financial Institution",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 24,
    url: "https://www.mx.com/blog/banks-and-bitcoin",
    admin_url: "https://cms.mx.com/admin/content/blog/24",
    name: "Why Banks Are Investing in Bitcoin [Interview]",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 32,
    url: "https://www.mx.com/blog/moven-partners-with-moneydesktop",
    admin_url: "https://cms.mx.com/admin/content/blog/32",
    name: "Moven Partners with MoneyDesktop [Video]",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 46,
    url: "https://www.mx.com/blog/heres-why-smartphones-and-account-aggregation-are-the-future-of-omnichannel-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/46",
    name: "Here’s Why Smartphones and Account Aggregation Are the Future of Omnichannel Banking",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 58,
    url: "https://www.mx.com/blog/primary-in-name-only-how-can-small-fis-win-a-greater-share-of-wallet",
    admin_url: "https://cms.mx.com/admin/content/blog/58",
    name: "Primary In Name Only? 'Silent Churn' Is A Profit Killer For Small FIs",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 64,
    url: "https://www.mx.com/blog/the-lean-startup-fintech-and-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/64",
    name: "How Banks Can Use the Lean Startup to Build Smarter Marketing",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 72,
    url: "https://www.mx.com/blog/millennials-love-big-banks-even-when-they-say-they-dont",
    admin_url: "https://cms.mx.com/admin/content/blog/72",
    name: "Millennials Love Big Banks, Even When They Say They Don't",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 75,
    url: "https://www.mx.com/blog/pfm-mobile-wallet-and-mobile-payments",
    admin_url: "https://cms.mx.com/admin/content/blog/75",
    name: "PFM, Mobile Wallet, and Mobile Payments",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 85,
    url: "https://www.mx.com/blog/4-essential-pillars-of-successful-local-marketing-for-banks-credit-unions",
    admin_url: "https://cms.mx.com/admin/content/blog/85",
    name: "4 Pillars of Successful Local Marketing for Banks & Credit Unions",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 94,
    url: "https://www.mx.com/blog/bradley-leimer-interview-empowering-lifelong-customer-relationships-in-a-digital-world",
    admin_url: "https://cms.mx.com/admin/content/blog/94",
    name: "Bradley Leimer Interview: Empowering Lifelong Customer Relationships in a Digital World",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 114,
    url: "https://www.mx.com/blog/customer-advocacy-more-about-you-or-about-your-customers",
    admin_url: "https://cms.mx.com/admin/content/blog/114",
    name: "A Simple Formula to Develop True Customer Advocacy at Financial Institutions",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 127,
    url: "https://www.mx.com/blog/an-introduction-to-moneyhawks",
    admin_url: "https://cms.mx.com/admin/content/blog/127",
    name: "Moneyhawks: The Most Profitable Customers You Don't Know You Have",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 129,
    url: "https://www.mx.com/blog/5-design-tips-every-bank-should-use-to-outsmart-the-competition",
    admin_url: "https://cms.mx.com/admin/content/blog/129",
    name: "5 Design Tips Every Bank Should Use to Outsmart the Competition",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 132,
    url: "https://www.mx.com/blog/fintech-democratizes-consumer-data-an-interview-with-kenneth-lin-ceo-of-credit-karma",
    admin_url: "https://cms.mx.com/admin/content/blog/132",
    name: "CEO of Credit Karma on How Fintech Democratizes Data",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 136,
    url: "https://www.mx.com/blog/how-to-infuse-banking-with-fresh-thinking-an-interview-with-lani-hayward-of-umpqua-bank",
    admin_url: "https://cms.mx.com/admin/content/blog/136",
    name: "How To Infuse Banking With Fresh Thinking: An Interview with Lani Hayward of Umpqua Bank",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 142,
    url: "https://www.mx.com/blog/5-ways-financial-institutions-can-be-true-digital-advocates-for-millennials",
    admin_url: "https://cms.mx.com/admin/content/blog/142",
    name: "5 Ways Financial Institutions Can Advocate for Millennials",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 160,
    url: "https://www.mx.com/blog/how-millennials-bank",
    admin_url: "https://cms.mx.com/admin/content/blog/160",
    name: "How Millennials Bank: Nine Stats",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 167,
    url: "https://www.mx.com/blog/fintech-book-review-smarter-bank-by-ron-shevlin",
    admin_url: "https://cms.mx.com/admin/content/blog/167",
    name: "Fintech Book Review: Smarter Bank by Ron Shevlin",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 169,
    url: "https://www.mx.com/blog/mbank-money-summit",
    admin_url: "https://cms.mx.com/admin/content/blog/169",
    name: "How Digital-First mBank Is Redefining Banking",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 170,
    url: "https://www.mx.com/blog/atom-bank-interview",
    admin_url: "https://cms.mx.com/admin/content/blog/170",
    name: "Atom Bank: The New Mobile-Delivered Bank [Interview w/ Anthony Thomson]",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 171,
    url: "https://www.mx.com/blog/the-great-fintech-debate-recap-and-analysis",
    admin_url: "https://cms.mx.com/admin/content/blog/171",
    name: "Banks vs. Fintech? The Great Debate Recap and Analysis",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 172,
    url: "https://www.mx.com/blog/envestnet-acquisition-of-yodlee-produces-mixed-reactions-from-press-and-analyst-community",
    admin_url: "https://cms.mx.com/admin/content/blog/172",
    name: "Envestnet Acquisition Of Yodlee Produces Mixed Reactions From Press And Analyst Community",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 190,
    url: "https://www.mx.com/blog/banks-should-focus-on-life-and-relationships-interview-w-chris-skinner",
    admin_url: "https://cms.mx.com/admin/content/blog/190",
    name: "Banks Should Focus on Relationships — An Interview with Chris Skinner",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 196,
    url: "https://www.mx.com/blog/how-td-bank-uses-analytics-to-create-a-better-customer-experience-interview",
    admin_url: "https://cms.mx.com/admin/content/blog/196",
    name: "How TD Bank Uses Analytics to Create a Richer Customer Experience [Interview]",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 197,
    url: "https://www.mx.com/blog/5-cultural-changes-to-support-new-digital-banking-strategies",
    admin_url: "https://cms.mx.com/admin/content/blog/197",
    name: "5 Cultural Changes to Support New Digital Banking Strategies",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 199,
    url: "https://www.mx.com/blog/predictions-for-the-next-three-years-in-digital-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/199",
    name: "Forrester’s Predictions for the Future of Digital Banking",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 216,
    url: "https://www.mx.com/blog/ux-interview-with-moven-designing-to-create-a-frictionless-differentiated-services",
    admin_url: "https://cms.mx.com/admin/content/blog/216",
    name: "UX Interview With Moven: Designing to Create a Frictionless Experience",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 224,
    url: "https://www.mx.com/blog/why-traditional-pfm-is-dead",
    admin_url: "https://cms.mx.com/admin/content/blog/224",
    name: "Why Traditional PFM Is Dead",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 248,
    url: "https://www.mx.com/blog/the-future-of-geomarketing-in-financial-services-a-conversation-with-david-kaplan-managing-editor-geomarketing",
    admin_url: "https://cms.mx.com/admin/content/blog/248",
    name: "The Future of Geomarketing in Financial Services",
    publish_date: "2016-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 234,
    url: "https://www.mx.com/blog/how-fintech-is-revolutionizing-small-business-lending-an-interview-with-the-ceo-of-lendio",
    admin_url: "https://cms.mx.com/admin/content/blog/234",
    name: "How Fintech Is Revolutionizing Small Business Lending: An Interview with the CEO of Lendio",
    publish_date: "2016-04-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 141,
    url: "https://www.mx.com/blog/personalized-coaching-and-advice-the-future-of-digital-money-management",
    admin_url: "https://cms.mx.com/admin/content/blog/141",
    name: "Personalized Coaching And Advice: The Future Of Digital Money Management",
    publish_date: "2016-05-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 211,
    url: "https://www.mx.com/blog/checks-balances-and-bitcoin-the-genius-of-the-blockchain",
    admin_url: "https://cms.mx.com/admin/content/blog/211",
    name: "Checks, Balances, and Bitcoin: The Genius of the Blockchain",
    publish_date: "2016-05-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 26,
    url: "https://www.mx.com/blog/the-future-of-wearable-point-of-sale-pos-technology",
    admin_url: "https://cms.mx.com/admin/content/blog/26",
    name: "The Future of Wearable Point-of-Sale (POS) Technology",
    publish_date: "2016-05-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 56,
    url: "https://www.mx.com/blog/the-future-of-credit-scoring",
    admin_url: "https://cms.mx.com/admin/content/blog/56",
    name: "The Future of Credit Scoring: Hopeful or Not?",
    publish_date: "2016-06-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 67,
    url: "https://www.mx.com/blog/brett-king-augmented",
    admin_url: "https://cms.mx.com/admin/content/blog/67",
    name: "A Joyous Romp Through the Future: Review of Brett King’s Augmented",
    publish_date: "2016-06-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 115,
    url: "https://www.mx.com/blog/the-future-of-retail-banking-how-to-save-retail-banking-from-itself",
    admin_url: "https://cms.mx.com/admin/content/blog/115",
    name: "The Future of Retail Banking: How to Save Retail Banking From Itself",
    publish_date: "2016-06-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 69,
    url: "https://www.mx.com/blog/digital-transformation",
    admin_url: "https://cms.mx.com/admin/content/blog/69",
    name: "Why Banks Need a Digital Transformation",
    publish_date: "2016-06-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 149,
    url: "https://www.mx.com/blog/the-future-of-financial-education-6-apps-that-build-saving-and-investing-fundamentals",
    admin_url: "https://cms.mx.com/admin/content/blog/149",
    name: "The Future of Financial Education – 6 Apps That Build Saving and Investing Fundamentals",
    publish_date: "2016-06-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 86,
    url: "https://www.mx.com/blog/strategycorps",
    admin_url: "https://cms.mx.com/admin/content/blog/86",
    name: "How to Get Account Holders to Willingly Pay Fees",
    publish_date: "2016-06-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 217,
    url: "https://www.mx.com/blog/what-banks-should-do-to-increase-empathy",
    admin_url: "https://cms.mx.com/admin/content/blog/217",
    name: "What Banks Should Do to Increase Empathy: A Conversation with Ethology",
    publish_date: "2016-06-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 148,
    url: "https://www.mx.com/blog/the-underbanked-millennials-and-mobile-payments",
    admin_url: "https://cms.mx.com/admin/content/blog/148",
    name: "The Underbanked, Millennials, and Mobile Payments: Essential Fintech Reading Aug 1 - 5",
    publish_date: "2016-08-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 36,
    url: "https://www.mx.com/blog/why-are-banks-beating-credit-unions-in-mobile-banking-utilization",
    admin_url: "https://cms.mx.com/admin/content/blog/36",
    name: "Why Are Banks Beating Credit Unions In Mobile Banking Utilization?",
    publish_date: "2016-08-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 145,
    url: "https://www.mx.com/blog/retail-bank-customer-service-is-changing",
    admin_url: "https://cms.mx.com/admin/content/blog/145",
    name: "3 Ways Retail Bank Customer Service Is Changing (and How To Keep Up)",
    publish_date: "2016-08-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 144,
    url: "https://www.mx.com/blog/opti-channel",
    admin_url: "https://cms.mx.com/admin/content/blog/144",
    name: "Opti-Channel and the Future of Banking: Interview with Susan Wolfe of Mintel",
    publish_date: "2016-08-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 3,
    url: "https://www.mx.com/blog/improve-bank-cross-selling",
    admin_url: "https://cms.mx.com/admin/content/blog/3",
    name: "How To Improve Bank Cross-Selling *Without Making Up Fake Customer Accounts",
    publish_date: "2016-09-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 31,
    url: "https://www.mx.com/blog/api-hub-a-vision-for-banking-as-a-platform-baap",
    admin_url: "https://cms.mx.com/admin/content/blog/31",
    name: "API Hub – A Vision for Banking as a Platform (BaaP)",
    publish_date: "2016-10-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 215,
    url: "https://www.mx.com/blog/wells-fargo-considers-new-performance-metrics-essential-fintech-reading-oct-15-21",
    admin_url: "https://cms.mx.com/admin/content/blog/215",
    name: "Wells Fargo Considers New Performance Metrics, Venmo Grows Rapidly: Essential Fintech Reading Oct 15-21",
    publish_date: "2016-10-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 161,
    url: "https://www.mx.com/blog/api-europe-us",
    admin_url: "https://cms.mx.com/admin/content/blog/161",
    name: "U.S. Banks Must Follow Europe's Lead and Improve API Standards",
    publish_date: "2016-10-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 38,
    url: "https://www.mx.com/blog/navy-federal-named-best-overall-online-banking-leader-by-javelin-bbva-compass-citibank-suntrust-and-usaa-garner-financial-management-praise",
    admin_url: "https://cms.mx.com/admin/content/blog/38",
    name: "Navy Federal Named Best Overall Online Banking Leader By Javelin; BBVA Compass, Citibank, SunTrust and USAA Garner Financial Management Praise",
    publish_date: "2016-12-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 30,
    url: "https://www.mx.com/blog/70-comments-three-common-themes-the-cfpb-rfi-around-consumer-access-to-financial-records-draws-the-battle-lines",
    admin_url: "https://cms.mx.com/admin/content/blog/30",
    name: "CFPB RFI Around Consumer Access To Financial Records Draws The Battle Lines Between FIs And Aggregators",
    publish_date: "2017-03-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 246,
    url: "https://www.mx.com/blog/top-250-credit-unions-by-income-interest-income-and-non-interest-income",
    admin_url: "https://cms.mx.com/admin/content/blog/246",
    name: "Top 250 Credit Unions by Income (Interest Income and Non-interest Income)",
    publish_date: "2017-03-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 221,
    url: "https://www.mx.com/blog/the-5-essential-components-of-a-good-data-strategy",
    admin_url: "https://cms.mx.com/admin/content/blog/221",
    name: "Essential Components of a Brilliant Data Strategy",
    publish_date: "2017-03-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 154,
    url: "https://www.mx.com/blog/what-10-thought-leaders-in-fintech-and-banking-have-to-say-about-becoming-data-driven",
    admin_url: "https://cms.mx.com/admin/content/blog/154",
    name: "What 8 Thought Leaders in Fintech and Banking Have to Say About Becoming Data Driven",
    publish_date: "2017-03-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 222,
    url: "https://www.mx.com/blog/three-megatrends-in-banking-technology",
    admin_url: "https://cms.mx.com/admin/content/blog/222",
    name: "Three Megatrends in Banking Technology: A Discussion with Shara Evans",
    publish_date: "2017-04-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 153,
    url: "https://www.mx.com/blog/homie",
    admin_url: "https://cms.mx.com/admin/content/blog/153",
    name: "How Home Buyers Save $15,000 or More by Digitizing the Process: An Interview With the CEO of Homie",
    publish_date: "2017-04-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 95,
    url: "https://www.mx.com/blog/banking-and-the-devices-of-the-future",
    admin_url: "https://cms.mx.com/admin/content/blog/95",
    name: "Banking and the Devices of the Future",
    publish_date: "2017-05-04",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 121,
    url: "https://www.mx.com/blog/marketing-automation-hubspot-and-effective-campaigns-a-conversation-with-dan-marks",
    admin_url: "https://cms.mx.com/admin/content/blog/121",
    name: "Marketing Automation, HubSpot, and Effective Campaigns: A Conversation With Dan Marks",
    publish_date: "2017-07-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 178,
    url: "https://www.mx.com/blog/data-driven-money-management-ron-shevlin",
    admin_url: "https://cms.mx.com/admin/content/blog/178",
    name: "Data-Driven Money Management Brings Solid Revenue",
    publish_date: "2017-07-31",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 135,
    url: "https://www.mx.com/blog/behavioral-economics-nancy-harhut",
    admin_url: "https://cms.mx.com/admin/content/blog/135",
    name: "Behavioral Economics Is the Key to User Engagement: An Interview With Nancy Harhut",
    publish_date: "2017-08-03",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 89,
    url: "https://www.mx.com/blog/top-fintech-firms-stripe",
    admin_url: "https://cms.mx.com/admin/content/blog/89",
    name: "Top 50 Fintechs Grow 169% in 5 Years, Bank Branches Plummet: Essential Banking News",
    publish_date: "2017-08-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 203,
    url: "https://www.mx.com/blog/checkfree-founder-pete-kight",
    admin_url: "https://cms.mx.com/admin/content/blog/203",
    name: "Is the Banking Industry Is Walking Into a Death Trap?",
    publish_date: "2017-08-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 20,
    url: "https://www.mx.com/blog/tech-giants-vs-banks-essential-banking-news",
    admin_url: "https://cms.mx.com/admin/content/blog/20",
    name: "Tech Giants vs Banks, Devices, and the Future of Banking, FinovateFall: Essential Banking News",
    publish_date: "2017-09-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 241,
    url: "https://www.mx.com/blog/3-social-media-trends-that-should-be-more-widely-adopted-in-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/241",
    name: "3 Social Media Trends that Should Be More Widely Adopted in Banking",
    publish_date: "2017-11-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 191,
    url: "https://www.mx.com/blog/convincing-millennials-to-switch-banks-what-they-need-from-you",
    admin_url: "https://cms.mx.com/admin/content/blog/191",
    name: "Convincing Millennials to Switch Banks: What They Need from You",
    publish_date: "2017-12-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 237,
    url: "https://www.mx.com/blog/boost-your-startups-growth-with-one-of-these-7-fintech-accelerators",
    admin_url: "https://cms.mx.com/admin/content/blog/237",
    name: "7 Fintech Accelerators that Will Boost Your Startup's Growth",
    publish_date: "2017-12-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 238,
    url: "https://www.mx.com/blog/2018-banking-trends-what-you-should-expect-to-see",
    admin_url: "https://cms.mx.com/admin/content/blog/238",
    name: "2018 Banking Trends: What You Should Expect to See",
    publish_date: "2017-12-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 247,
    url: "https://www.mx.com/blog/better-mobile-banking-millennials",
    admin_url: "https://cms.mx.com/admin/content/blog/247",
    name: "3 Words to Win Millennials: Better. Mobile. Banking.",
    publish_date: "2018-01-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 98,
    url: "https://www.mx.com/blog/tech-giants-in-financial-services-use-of-ai-in-banking-a-novel-approach-to-auto-lending-essential-banking-news",
    admin_url: "https://cms.mx.com/admin/content/blog/98",
    name: "Tech Giants in Financial Services, Use of AI in Banking, a Novel Approach to Auto Lending: Essential Banking News",
    publish_date: "2018-01-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 122,
    url: "https://www.mx.com/blog/3-crucial-banking-challenges-cfpb-legal-battle-isnt-over-essential-banking-news",
    admin_url: "https://cms.mx.com/admin/content/blog/122",
    name: "3 Crucial Banking Challenges; CFPB Legal Battle Isn’t Over; Wells Fargo Partners With Fitbit: Essential Banking News",
    publish_date: "2018-01-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 205,
    url: "https://www.mx.com/blog/yahoo-finance-releases-savings-app-occ-cautions-banks-essential-banking-news",
    admin_url: "https://cms.mx.com/admin/content/blog/205",
    name: "Yahoo Finance Releases Savings App, OCC Cautions Banks, Citi To Shape Digital Banking: Essential Banking News",
    publish_date: "2018-01-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 119,
    url: "https://www.mx.com/blog/chatbots-2018",
    admin_url: "https://cms.mx.com/admin/content/blog/119",
    name: "How Banks Are Embracing and Benefiting From Chatbots in 2018",
    publish_date: "2018-01-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 131,
    url: "https://www.mx.com/blog/rocket-mortgage-meets-amazon-coast-capital-partners-with-mx-essential-banking-news",
    admin_url: "https://cms.mx.com/admin/content/blog/131",
    name: "Rocket Mortgage Meets Amazon, Coast Capital Partners With MX, Banking and the Digital Era: Essential Banking News",
    publish_date: "2018-01-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 163,
    url: "https://www.mx.com/blog/afcus-receives-rave-mobile-reviews-2018-feeling-like-2006-essential-banking-news",
    admin_url: "https://cms.mx.com/admin/content/blog/163",
    name: "AFCU Receives Rave Mobile Reviews, 2018 Feeling Like 2006, Wells Fargo Discloses Gender Pay Gap: Essential Banking News",
    publish_date: "2018-02-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 261,
    url: "https://www.mx.com/blog/why-digital-banking-must-be-built-on-clean-data",
    admin_url: "https://cms.mx.com/admin/content/blog/261",
    name: "Why Digital Banking Must Be Built on Clean Data",
    publish_date: "2018-02-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 23,
    url: "https://www.mx.com/blog/banking-consumers-expectations-digital-banking-and-clean-data-fintechs-untapped-market-essential-banking-news",
    admin_url: "https://cms.mx.com/admin/content/blog/23",
    name: "Banking Consumer’s Expectations, Digital Banking and Clean Data, Fintech’s Untapped Market: Essential Banking News",
    publish_date: "2018-02-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 236,
    url: "https://www.mx.com/blog/overseas-money-transfer-fintech",
    admin_url: "https://cms.mx.com/admin/content/blog/236",
    name: "Is the Overseas Money Transfer Industry Going the Fintech Way?",
    publish_date: "2018-02-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 188,
    url: "https://www.mx.com/blog/banks-need-to-use-data-more-effectively",
    admin_url: "https://cms.mx.com/admin/content/blog/188",
    name: "Banks Need to Use Data More Effectively. Here's How to Start.",
    publish_date: "2018-02-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 99,
    url: "https://www.mx.com/blog/fintech-festival",
    admin_url: "https://cms.mx.com/admin/content/blog/99",
    name: "The Biggest Thing Banks Should Be Doing: Surveys From the Latest Fintech Festival",
    publish_date: "2018-02-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 162,
    url: "https://www.mx.com/blog/use-data-to-empower-true-financial-strength",
    admin_url: "https://cms.mx.com/admin/content/blog/162",
    name: "Use Data + AI to Empower True Financial Strength",
    publish_date: "2018-02-28",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 250,
    url: "https://www.mx.com/blog/revenue-models-data",
    admin_url: "https://cms.mx.com/admin/content/blog/250",
    name: "Banks Must Change Their Revenue Model to Survive: A Focused Look at Consumers and Data",
    publish_date: "2018-03-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 87,
    url: "https://www.mx.com/blog/clean-categorize-and-classify-3-steps-to-making-financial-transaction-data-more-useful",
    admin_url: "https://cms.mx.com/admin/content/blog/87",
    name: "Clean, Categorize, and Classify: 3 Steps to Making Financial Transaction Data More Useful",
    publish_date: "2018-03-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 18,
    url: "https://www.mx.com/blog/banking-in-1914-vs-2014-nothing-is-different-but-everything-has-changed",
    admin_url: "https://cms.mx.com/admin/content/blog/18",
    name: "Banking 100 Years Ago Versus Banking Now",
    publish_date: "2018-03-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 227,
    url: "https://www.mx.com/blog/the-top-5-consumer-wants-in-banking-and-a-checklist-to-meet-them",
    admin_url: "https://cms.mx.com/admin/content/blog/227",
    name: "The Biggest Challenge Facing Banks (And a Checklist to Overcome It)",
    publish_date: "2018-03-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 213,
    url: "https://www.mx.com/blog/tax-returns",
    admin_url: "https://cms.mx.com/admin/content/blog/213",
    name: "How Banks and Credit Unions Can Best Help Account Holders During Tax Season",
    publish_date: "2018-03-28",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 106,
    url: "https://www.mx.com/blog/roi-fintech-data",
    admin_url: "https://cms.mx.com/admin/content/blog/106",
    name: "How to Help Your Organization Understand the ROI of Fintech Data",
    publish_date: "2018-04-04",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 50,
    url: "https://www.mx.com/blog/roi-big-data",
    admin_url: "https://cms.mx.com/admin/content/blog/50",
    name: "Realizing the Power and ROI of Big Data",
    publish_date: "2018-04-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 29,
    url: "https://www.mx.com/blog/data-delusions-in-fintech",
    admin_url: "https://cms.mx.com/admin/content/blog/29",
    name: "Data Delusions in Fintech: Common Misconceptions Costing Bankers Money",
    publish_date: "2018-04-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 134,
    url: "https://www.mx.com/blog/lessons-to-teach-your-kids-for-financial-literacy-month",
    admin_url: "https://cms.mx.com/admin/content/blog/134",
    name: "Lessons to Teach Your Kids for Financial Literacy Month",
    publish_date: "2018-04-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 207,
    url: "https://www.mx.com/blog/how-bank-customers-benefit-from-artificial-intelligence-ai",
    admin_url: "https://cms.mx.com/admin/content/blog/207",
    name: "How Bank Customers Benefit From Artificial Intelligence (AI)",
    publish_date: "2018-05-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 105,
    url: "https://www.mx.com/blog/accurate-alerts-start-with-clean-data",
    admin_url: "https://cms.mx.com/admin/content/blog/105",
    name: "Accurate Alerts Start with Clean Data",
    publish_date: "2018-05-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 186,
    url: "https://www.mx.com/blog/clear-transactions-start-with-clean-data",
    admin_url: "https://cms.mx.com/admin/content/blog/186",
    name: "Clear Transactions Start with Clean Data",
    publish_date: "2018-05-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 97,
    url: "https://www.mx.com/blog/effective-marketing-starts-with-clean-data",
    admin_url: "https://cms.mx.com/admin/content/blog/97",
    name: "Effective Marketing Starts with Clean Data",
    publish_date: "2018-05-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 212,
    url: "https://www.mx.com/blog/financial-wellness-starts-with-clean-data",
    admin_url: "https://cms.mx.com/admin/content/blog/212",
    name: "Financial Wellness Starts with Clean Data",
    publish_date: "2018-05-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 206,
    url: "https://www.mx.com/blog/intelligent-chatbots-start-with-clean-data",
    admin_url: "https://cms.mx.com/admin/content/blog/206",
    name: "Intelligent Chatbots Start with Clean Data",
    publish_date: "2018-05-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 10,
    url: "https://www.mx.com/blog/how-mx-has-helped-mercantile-bank-of-michigan",
    admin_url: "https://cms.mx.com/admin/content/blog/10",
    name: "How MX is Helping Mercantile Bank of Michigan",
    publish_date: "2018-05-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 137,
    url: "https://www.mx.com/blog/the-future-of-banking-the-devices-and-tools-changing-the-industry",
    admin_url: "https://cms.mx.com/admin/content/blog/137",
    name: "The Future of Banking: The Devices and Tools Changing the Industry",
    publish_date: "2018-05-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 415,
    url: "https://www.mx.com/blog/potential-gains-from-ai-in-finances-approach-one-trillion-dollars",
    admin_url: "https://cms.mx.com/admin/content/blog/415",
    name: "Potential Gains fFom AI in Finances Approach One Trillion Dollars",
    publish_date: "2018-06-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 57,
    url: "https://www.mx.com/blog/state-of-the-banking-industry-at-the-second-half-of-2018",
    admin_url: "https://cms.mx.com/admin/content/blog/57",
    name: "State of the Banking Industry at the Second Half of 2018",
    publish_date: "2018-06-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 175,
    url: "https://www.mx.com/blog/is-your-bank-ready-for-gdpr-its-coming-may-25",
    admin_url: "https://cms.mx.com/admin/content/blog/175",
    name: "Is Your Bank Ready for GDPR? It's Coming May 25",
    publish_date: "2018-06-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 201,
    url: "https://www.mx.com/blog/10-steps-to-ease-your-financial-stress",
    admin_url: "https://cms.mx.com/admin/content/blog/201",
    name: "10 Steps for Consumers to Ease Financial Stress",
    publish_date: "2018-06-19",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 11,
    url: "https://www.mx.com/blog/how-next-gen-banks-and-the-rising-generation-will-impact-you",
    admin_url: "https://cms.mx.com/admin/content/blog/11",
    name: "How Next-Gen Banks and the Rising Generation Will Impact You",
    publish_date: "2018-07-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 111,
    url: "https://www.mx.com/blog/james-dotter-shares-realizing-the-roi-of-big-data-and-ai-in-banking-the-foundational-steps",
    admin_url: "https://cms.mx.com/admin/content/blog/111",
    name: "Realizing the ROI of Big Data and AI in Banking",
    publish_date: "2018-07-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 130,
    url: "https://www.mx.com/blog/financial-concerns-no-1-among-americans-today",
    admin_url: "https://cms.mx.com/admin/content/blog/130",
    name: "Financial Concerns No. 1 Among Americans Today",
    publish_date: "2018-07-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 59,
    url: "https://www.mx.com/blog/how-to-read-the-occ-fintech-charter",
    admin_url: "https://cms.mx.com/admin/content/blog/59",
    name: "How to Read the OCC Fintech Charter",
    publish_date: "2018-07-31",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 13,
    url: "https://www.mx.com/blog/keeping-it-clean-the-power-of-clean-data",
    admin_url: "https://cms.mx.com/admin/content/blog/13",
    name: "Keeping It Clean: The Power of Clean Data",
    publish_date: "2018-08-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 63,
    url: "https://www.mx.com/blog/essential-fintech-news-august",
    admin_url: "https://cms.mx.com/admin/content/blog/63",
    name: "Essential Fintech News - August 2018",
    publish_date: "2018-08-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 113,
    url: "https://www.mx.com/blog/essential-fintech-news-september",
    admin_url: "https://cms.mx.com/admin/content/blog/113",
    name: "Essential Fintech News - September 2018",
    publish_date: "2018-09-19",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 2,
    url: "https://www.mx.com/blog/essential-fintech-news-october",
    admin_url: "https://cms.mx.com/admin/content/blog/2",
    name: "Essential Fintech News - October 2018",
    publish_date: "2018-10-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 426,
    url: "https://www.mx.com/blog/thrift-to-credit",
    admin_url: "https://cms.mx.com/admin/content/blog/426",
    name: "A Nation's Journey From Thrift to Credit",
    publish_date: "2018-11-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 257,
    url: "https://www.mx.com/blog/essential-fintech-news-october-0",
    admin_url: "https://cms.mx.com/admin/content/blog/257",
    name: "Essential Fintech News - November 2018",
    publish_date: "2018-11-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 416,
    url: "https://www.mx.com/blog/open-banking-coming-to-the-us",
    admin_url: "https://cms.mx.com/admin/content/blog/416",
    name: "Open Banking is Coming to the U.S.",
    publish_date: "2018-12-03",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 109,
    url: "https://www.mx.com/blog/essential-fintech-news-october-0-0",
    admin_url: "https://cms.mx.com/admin/content/blog/109",
    name: "Essential Fintech News - December 2018",
    publish_date: "2018-12-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 425,
    url: "https://www.mx.com/blog/compete-to-win-mobile-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/425",
    name: "Compete to Win with Mobile Banking",
    publish_date: "2018-12-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 422,
    url: "https://www.mx.com/blog/the-social-cost-of-financial-stress",
    admin_url: "https://cms.mx.com/admin/content/blog/422",
    name: "The Social Cost of Financial Stress",
    publish_date: "2019-01-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 420,
    url: "https://www.mx.com/blog/how-biometrics-are-impacting-the-financial-industry",
    admin_url: "https://cms.mx.com/admin/content/blog/420",
    name: "How Biometrics are Impacting the Financial Industry",
    publish_date: "2019-01-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 44,
    url: "https://www.mx.com/blog/essential-fintech-news-january",
    admin_url: "https://cms.mx.com/admin/content/blog/44",
    name: "Essential Fintech News - January 2019",
    publish_date: "2019-01-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 424,
    url: "https://www.mx.com/blog/true-cost-being-poor-in-america",
    admin_url: "https://cms.mx.com/admin/content/blog/424",
    name: "The True Cost of Being Poor in America",
    publish_date: "2019-01-28",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 423,
    url: "https://www.mx.com/blog/student-loan-crisis-in-america",
    admin_url: "https://cms.mx.com/admin/content/blog/423",
    name: "The Student Loan Crisis In America",
    publish_date: "2019-02-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 421,
    url: "https://www.mx.com/blog/household-debt-q1-2019",
    admin_url: "https://cms.mx.com/admin/content/blog/421",
    name: "Household Debt, Q1 2019",
    publish_date: "2019-02-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 419,
    url: "https://www.mx.com/blog/millennials-and-finances",
    admin_url: "https://cms.mx.com/admin/content/blog/419",
    name: "Has the American Dream Turned into a Nightmare for Millennials?",
    publish_date: "2019-02-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 33,
    url: "https://www.mx.com/blog/essential-fintech-news-february",
    admin_url: "https://cms.mx.com/admin/content/blog/33",
    name: "Essential Fintech News - February 2019",
    publish_date: "2019-02-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 27,
    url: "https://www.mx.com/blog/essential-fintech-news-march",
    admin_url: "https://cms.mx.com/admin/content/blog/27",
    name: "Essential Fintech News - March 2019",
    publish_date: "2019-03-28",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 413,
    url: "https://www.mx.com/blog/financial-literacy",
    admin_url: "https://cms.mx.com/admin/content/blog/413",
    name: "Americans Are Scared of Their Accounts",
    publish_date: "2019-04-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 414,
    url: "https://www.mx.com/blog/payday-loans",
    admin_url: "https://cms.mx.com/admin/content/blog/414",
    name: "Financial Institutions Are the Positive Alternative to Payday Loans",
    publish_date: "2019-04-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 409,
    url: "https://www.mx.com/blog/social-impact-on-financial-wellness",
    admin_url: "https://cms.mx.com/admin/content/blog/409",
    name: "The Social Impact on Financial Wellness",
    publish_date: "2019-04-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 9,
    url: "https://www.mx.com/blog/essential-fintech-news-april",
    admin_url: "https://cms.mx.com/admin/content/blog/9",
    name: "Essential Fintech News - April 2019",
    publish_date: "2019-04-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 408,
    url: "https://www.mx.com/blog/is-big-tech-the-future-of-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/408",
    name: "Is Big Tech the Future of Banking?",
    publish_date: "2019-04-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 244,
    url: "https://www.mx.com/blog/challenges-facing-banks",
    admin_url: "https://cms.mx.com/admin/content/blog/244",
    name: "3 Crucial Challenges Facing Banks in 2019",
    publish_date: "2019-05-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 138,
    url: "https://www.mx.com/blog/essential-fintech-news-may",
    admin_url: "https://cms.mx.com/admin/content/blog/138",
    name: "Essential Fintech News - May 2019",
    publish_date: "2019-05-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 417,
    url: "https://www.mx.com/blog/mobile-is-changing-how-people-interact",
    admin_url: "https://cms.mx.com/admin/content/blog/417",
    name: "Mobile is Changing How People Interact",
    publish_date: "2019-06-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 103,
    url: "https://www.mx.com/blog/essential-fintech-news-may-0",
    admin_url: "https://cms.mx.com/admin/content/blog/103",
    name: "Essential Fintech News - June 2019",
    publish_date: "2019-06-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 407,
    url: "https://www.mx.com/blog/changing-face-of-wealth",
    admin_url: "https://cms.mx.com/admin/content/blog/407",
    name: "The Changing Face of Wealth",
    publish_date: "2019-07-19",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 406,
    url: "https://www.mx.com/blog/gender-inequalities-of-aging",
    admin_url: "https://cms.mx.com/admin/content/blog/406",
    name: "The Gender Inequalities of Aging",
    publish_date: "2019-08-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 404,
    url: "https://www.mx.com/blog/household-debt-2019-q3",
    admin_url: "https://cms.mx.com/admin/content/blog/404",
    name: "Household Debt 2019 Q3",
    publish_date: "2019-08-19",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 412,
    url: "https://www.mx.com/blog/digital-is-taking-over",
    admin_url: "https://cms.mx.com/admin/content/blog/412",
    name: "Cash Is Losing Relevance as Digital Gains Traction",
    publish_date: "2019-08-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 223,
    url: "https://www.mx.com/blog/essential-fintech-news-august-1",
    admin_url: "https://cms.mx.com/admin/content/blog/223",
    name: "Essential Fintech News - August 2019",
    publish_date: "2019-08-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 402,
    url: "https://www.mx.com/blog/growing-pains-of-fintechs",
    admin_url: "https://cms.mx.com/admin/content/blog/402",
    name: "Growing Pains of Fintechs in the Financial Industry",
    publish_date: "2019-09-04",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 411,
    url: "https://www.mx.com/blog/financial-literacy-affects-everyone",
    admin_url: "https://cms.mx.com/admin/content/blog/411",
    name: "Financial Literacy Affects Everyone",
    publish_date: "2019-09-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 90,
    url: "https://www.mx.com/blog/digital-banking-week-fintech-experts",
    admin_url: "https://cms.mx.com/admin/content/blog/90",
    name: "Highlights from Digital Banking Week 2019: The Best of 24 Fintech and Banking Experts",
    publish_date: "2019-09-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 39,
    url: "https://www.mx.com/blog/essential-fintech-news-august-1-1",
    admin_url: "https://cms.mx.com/admin/content/blog/39",
    name: "Essential Fintech News - September 2019",
    publish_date: "2019-09-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 405,
    url: "https://www.mx.com/blog/5-ways-technology-can-help-financial-institutions-create-inclusivity",
    admin_url: "https://cms.mx.com/admin/content/blog/405",
    name: "5 Ways Technology Can Help Financial Institutions Create Inclusivity",
    publish_date: "2019-10-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 19,
    url: "https://www.mx.com/blog/how-to-build-a-4-billion-fintech-company-the-story-of-pete-kight-founder-of-checkfree/",
    admin_url: "https://cms.mx.com/admin/content/blog/19",
    name: "How to Build a $4.4 Billion Fintech Company: The Story of Pete Kight, Founder of CheckFree",
    publish_date: "2019-10-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 403,
    url: "https://www.mx.com/blog/powering-customer-financial-well-being-through-ai",
    admin_url: "https://cms.mx.com/admin/content/blog/403",
    name: "Powering Customer Financial Well-Being Through Conversational AI",
    publish_date: "2019-11-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 410,
    url: "https://www.mx.com/blog/data-breaches-are-on-the-rise",
    admin_url: "https://cms.mx.com/admin/content/blog/410",
    name: "Data Breaches Are on the Rise",
    publish_date: "2019-11-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 180,
    url: "https://www.mx.com/blog/how-to-transform-to-digital-banking-an-ultimate-guide-with-new-research",
    admin_url: "https://cms.mx.com/admin/content/blog/180",
    name: "How to Transform to Digital Banking: An Ultimate Guide With New Research",
    publish_date: "2019-11-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 92,
    url: "https://www.mx.com/blog/data-breaches-and-perceived-fraud-both-will-cost-you",
    admin_url: "https://cms.mx.com/admin/content/blog/92",
    name: "Data Breaches and Perceived Fraud: Both Will Cost You",
    publish_date: "2019-12-04",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 156,
    url: "https://www.mx.com/blog/5-ways-to-win-the-future-of-banking/",
    admin_url: "https://cms.mx.com/admin/content/blog/156",
    name: "5 Ways to Win the Future of Banking",
    publish_date: "2020-01-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 65,
    url: "https://www.mx.com/blog/reasons-its-urgent-to-create-a-culture-of-data",
    admin_url: "https://cms.mx.com/admin/content/blog/65",
    name: "5 Reasons It’s Urgent to Create a Culture of Data",
    publish_date: "2020-02-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 401,
    url: "https://www.mx.com/blog/household-debt-2020-q1",
    admin_url: "https://cms.mx.com/admin/content/blog/401",
    name: "Household Debt Reaches $14.15 trillion",
    publish_date: "2020-02-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 165,
    url: "https://www.mx.com/blog/takeaways-from-the-mx-fintech-festival",
    admin_url: "https://cms.mx.com/admin/content/blog/165",
    name: "10 Takeaways from the MX FinTech Festival 2020 to Help You Lead Digital Integration",
    publish_date: "2020-03-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 123,
    url: "https://www.mx.com/blog/guidelines-to-building-open-banking-at-your-institution",
    admin_url: "https://cms.mx.com/admin/content/blog/123",
    name: "3 Guidelines to Building Open Banking at Your Institution",
    publish_date: "2020-03-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 395,
    url: "https://www.mx.com/blog/the-occ",
    admin_url: "https://cms.mx.com/admin/content/blog/395",
    name: "Understanding the OCC's Approach to Third-Party Risk Management ",
    publish_date: "2020-03-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 53,
    url: "https://www.mx.com/blog/what-coronavirus-means-for-digital-initiatives-in-financial-services",
    admin_url: "https://cms.mx.com/admin/content/blog/53",
    name: "What Coronavirus Means for Digital Initiatives in Financial Services",
    publish_date: "2020-03-19",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 195,
    url: "https://www.mx.com/blog/banking-transformation-week-virtual-conference-day-highlights",
    admin_url: "https://cms.mx.com/admin/content/blog/195",
    name: "Banking Transformation Week: Day 1 Highlights",
    publish_date: "2020-03-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 225,
    url: "https://www.mx.com/blog/banking-transformation-week-virtual-conference-day-2-highlights",
    admin_url: "https://cms.mx.com/admin/content/blog/225",
    name: "Banking Transformation Week: Day 2 Highlights",
    publish_date: "2020-03-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 219,
    url: "https://www.mx.com/blog/banking-transformation-week-recap",
    admin_url: "https://cms.mx.com/admin/content/blog/219",
    name: "Banking Transformation Week | Day 3 Recap",
    publish_date: "2020-03-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 242,
    url: "https://www.mx.com/blog/questions-to-ask-when-looking-for-a-financial-aggregator",
    admin_url: "https://cms.mx.com/admin/content/blog/242",
    name: "8 Questions to Ask When Looking For a Financial Aggregator",
    publish_date: "2020-03-31",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 189,
    url: "https://www.mx.com/blog/leading-from-the-front-establishing-consumer-confidence",
    admin_url: "https://cms.mx.com/admin/content/blog/189",
    name: "Leading From the Front: Establishing Consumer Confidence Amidst Uncertainty [A Conversation With Ryan Caldwell and James Dotter]",
    publish_date: "2020-04-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 399,
    url: "https://www.mx.com/blog/mx-budget-bracket",
    admin_url: "https://cms.mx.com/admin/content/blog/399",
    name: "MX Budget Bracket 2020",
    publish_date: "2020-04-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 182,
    url: "https://www.mx.com/blog/smb-experts-how-to-best-help-your-small-business-customers-right-now",
    admin_url: "https://cms.mx.com/admin/content/blog/182",
    name: "SMB Experts: How to Best Help Your Small Business Customers Right Now",
    publish_date: "2020-04-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 202,
    url: "https://www.mx.com/blog/banking-panel-how-to-best-help-your-retail-customers-right-now",
    admin_url: "https://cms.mx.com/admin/content/blog/202",
    name: "Banking Panel: How to Best Help Your Retail Customers Right Now",
    publish_date: "2020-04-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 164,
    url: "https://www.mx.com/blog/customer-lifetime-value-in-banking-hinges-on-advocacy-and-engagement",
    admin_url: "https://cms.mx.com/admin/content/blog/164",
    name: "Customer Lifetime Value in Banking Hinges on Advocacy and Engagement",
    publish_date: "2020-04-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 107,
    url: "https://www.mx.com/blog/rise-shine-breakfast-advocacy-a-roundtable-on-banking-and-coronavirus",
    admin_url: "https://cms.mx.com/admin/content/blog/107",
    name: "Rise & Shine, Breakfast & Advocacy: A Roundtable on Banking and Coronavirus",
    publish_date: "2020-04-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 200,
    url: "https://www.mx.com/blog/why-banks-should-be-more-like-chick-fil-a",
    admin_url: "https://cms.mx.com/admin/content/blog/200",
    name: "Why Banks Should Be More Like Chick-fil-A",
    publish_date: "2020-04-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 249,
    url: "https://www.mx.com/blog/usher-in-the-future-of-banking-with-a-great-data-strategy",
    admin_url: "https://cms.mx.com/admin/content/blog/249",
    name: "Usher in the Future of Banking with a Great Data Strategy: A Conversation With Theo Lau and Jill Castilla",
    publish_date: "2020-04-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 259,
    url: "https://www.mx.com/blog/16-businesses-stepping-up-and-leaning-in-amid-covid",
    admin_url: "https://cms.mx.com/admin/content/blog/259",
    name: "Businesses are Stepping Up and Leaning In Amid COVID-19",
    publish_date: "2020-04-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 73,
    url: "https://www.mx.com/blog/the-big-debates-that-will-shape-banking-this-decade-ron-shevlin",
    admin_url: "https://cms.mx.com/admin/content/blog/73",
    name: "The Big Debates That Will Shape Banking This Decade: Ron Shevlin",
    publish_date: "2020-04-28",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 76,
    url: "https://www.mx.com/blog/frontline-tips-for-banking-during-coronavirus-from-a-roundtable-of-experts",
    admin_url: "https://cms.mx.com/admin/content/blog/76",
    name: "5 Frontline Tips for Banking During Coronavirus From a Roundtable of Experts",
    publish_date: "2020-04-28",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 102,
    url: "https://www.mx.com/blog/the-future-of-regional-banking-an-interview-with-brent-beardall-ceo-at-wafd",
    admin_url: "https://cms.mx.com/admin/content/blog/102",
    name: "The Future of Regional Banking: An Interview with Brent Beardall, CEO at WaFd",
    publish_date: "2020-05-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 208,
    url: "https://www.mx.com/blog/3-ways-the-new-occ-data-guidelines-empower-financial-strength",
    admin_url: "https://cms.mx.com/admin/content/blog/208",
    name: "3 Ways the New OCC Data Guidelines Empower Financial Strength",
    publish_date: "2020-05-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 226,
    url: "https://www.mx.com/blog/steps-banks-can-take-to-use-data-for-good",
    admin_url: "https://cms.mx.com/admin/content/blog/226",
    name: "6 Steps Banks Can Take to Use Data for Good",
    publish_date: "2020-05-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 192,
    url: "https://www.mx.com/blog/essential-lessons-from-the-ppp-loan-request-effort",
    admin_url: "https://cms.mx.com/admin/content/blog/192",
    name: "3 Essential Lessons From the PPP Loan Request Effort",
    publish_date: "2020-05-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 397,
    url: "https://www.mx.com/blog/whats-changed-consumer-spending-and-saving-amid-covid",
    admin_url: "https://cms.mx.com/admin/content/blog/397",
    name: "Consumer Spending and Saving Amid COVID-19: What’s Changed?",
    publish_date: "2020-05-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 418,
    url: "https://www.mx.com/blog/impact-of-financial-stress-on-america",
    admin_url: "https://cms.mx.com/admin/content/blog/418",
    name: "The Impact of Financial Stress on America",
    publish_date: "2020-05-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 41,
    url: "https://www.mx.com/blog/how-banks-can-be-heroes-in-this-crisis-from-intermediary-to-advocate",
    admin_url: "https://cms.mx.com/admin/content/blog/41",
    name: "How Banks Can Be Heroes in This Crisis: From Intermediary To Advocate",
    publish_date: "2020-05-19",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 21,
    url: "https://www.mx.com/blog/disruptive-innovation-in-banking-lessons-from-the-former-cio-at-nasa",
    admin_url: "https://cms.mx.com/admin/content/blog/21",
    name: "Disruptive Innovation in Banking: Lessons From the Former CIO at NASA",
    publish_date: "2020-05-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 126,
    url: "https://www.mx.com/blog/user-experience-at-banks-what-consumers-want-according-to-data",
    admin_url: "https://cms.mx.com/admin/content/blog/126",
    name: "User Experience at Banks: What Consumers Want",
    publish_date: "2020-05-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 128,
    url: "https://www.mx.com/blog/3-ways-to-market-to-everyone-on-an-individual-level-with-audience-segmentation",
    admin_url: "https://cms.mx.com/admin/content/blog/128",
    name: "3 Ways to Market to Everyone on an Individual Level with Audience Segmentation",
    publish_date: "2020-05-28",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 100,
    url: "https://www.mx.com/blog/why-financial-advocacy-in-banking-is-imperative-based-on-consumer-data",
    admin_url: "https://cms.mx.com/admin/content/blog/100",
    name: "Why Financial Advocacy In Banking Is Imperative, Based on Consumer Data",
    publish_date: "2020-06-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 84,
    url: "https://www.mx.com/blog/6-steps-credit-unions-can-take-to-help-members-secure-financial-freedom",
    admin_url: "https://cms.mx.com/admin/content/blog/84",
    name: "6 Steps Credit Unions Can Take to Help Members Secure Financial Freedom",
    publish_date: "2020-06-04",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 396,
    url: "https://www.mx.com/blog/financial-stress-in-the-military-depends-on-readiness",
    admin_url: "https://cms.mx.com/admin/content/blog/396",
    name: "Financial Stress in the Military: Mission-Critical Success Depends on Financial Readiness",
    publish_date: "2020-06-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 71,
    url: "https://www.mx.com/blog/breaking-down-silos-creating-a-sustainable-internal-data-culture",
    admin_url: "https://cms.mx.com/admin/content/blog/71",
    name: "Breaking Down Silos: Creating a Sustainable Internal Data Culture by John Derrico, VP of Data Strategy at Mastercard",
    publish_date: "2020-06-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 159,
    url: "https://www.mx.com/blog/what-people-want-from-fintech-and-banking-in-interview",
    admin_url: "https://cms.mx.com/admin/content/blog/159",
    name: "What People Want From Fintech and Banking in 2020",
    publish_date: "2020-06-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 398,
    url: "https://www.mx.com/blog/financial-behavior-spending-amid-covid",
    admin_url: "https://cms.mx.com/admin/content/blog/398",
    name: "Financial Behavior and Spending Amid COVID-19",
    publish_date: "2020-06-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 14,
    url: "https://www.mx.com/blog/bold-predictions-for-the-new-world-of-banking-and-fintech",
    admin_url: "https://cms.mx.com/admin/content/blog/14",
    name: "6 Bold Predictions for the New World of Banking and Fintech",
    publish_date: "2020-06-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 140,
    url: "https://www.mx.com/blog/the-future-of-fintech-fueling-financial-recovery-through-innovation",
    admin_url: "https://cms.mx.com/admin/content/blog/140",
    name: "The Future of Fintech: Fueling Financial Recovery Through Innovation",
    publish_date: "2020-06-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 147,
    url: "https://www.mx.com/blog/humanizing-money-how-will-change-the-money-experience-for-good",
    admin_url: "https://cms.mx.com/admin/content/blog/147",
    name: "Humanizing Money: How 2020 Will Change the Money Experience — For Good",
    publish_date: "2020-06-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 28,
    url: "https://www.mx.com/blog/why-reskilling-at-banks-matters-more-than-ever",
    admin_url: "https://cms.mx.com/admin/content/blog/28",
    name: "Why Reskilling at Banks Matters More Than Ever",
    publish_date: "2020-06-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 37,
    url: "https://www.mx.com/blog/value-added-banking-a-win-win-for-all",
    admin_url: "https://cms.mx.com/admin/content/blog/37",
    name: "Value-Added Banking: A Win-Win for All",
    publish_date: "2020-07-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 51,
    url: "https://www.mx.com/blog/bank-ceos-give-essential-advice",
    admin_url: "https://cms.mx.com/admin/content/blog/51",
    name: "3 Bank CEOs Give Essential Advice for 2020",
    publish_date: "2020-07-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 393,
    url: "https://www.mx.com/blog/fintech-landscape-amid-covid-19",
    admin_url: "https://cms.mx.com/admin/content/blog/393",
    name: "COVID-19’s Impact on the Fintech Landscape",
    publish_date: "2020-07-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 49,
    url: "https://www.mx.com/blog/digital-transformation-from-luxury-to-necessity",
    admin_url: "https://cms.mx.com/admin/content/blog/49",
    name: "Digital Transformation: From Luxury to Necessity",
    publish_date: "2020-07-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 194,
    url: "https://www.mx.com/blog/what-people-want-from-the-bank-of-the-future",
    admin_url: "https://cms.mx.com/admin/content/blog/194",
    name: "What People Want From the Bank of the Future",
    publish_date: "2020-07-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 93,
    url: "https://www.mx.com/blog/banking-transformation-made-simple-the-best-tips-from-experts",
    admin_url: "https://cms.mx.com/admin/content/blog/93",
    name: "Banking Transformation Made Simple: The Best Tips From 7 Experts",
    publish_date: "2020-07-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 231,
    url: "https://www.mx.com/blog/customer-service-in-banking-requires-seeing-the-whole-person",
    admin_url: "https://cms.mx.com/admin/content/blog/231",
    name: "Customer Service in Banking Requires Seeing the Whole Person",
    publish_date: "2020-07-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 108,
    url: "https://www.mx.com/blog/heres-what-bank-ceos-must-do-in",
    admin_url: "https://cms.mx.com/admin/content/blog/108",
    name: "Here's What Bank and Fintech CEOs Should Do in 2020",
    publish_date: "2020-07-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 184,
    url: "https://www.mx.com/blog/financial-advocacy-how-fintechs-can-take-an-active-role",
    admin_url: "https://cms.mx.com/admin/content/blog/184",
    name: "Financial Advocacy: How Fintechs Can Take an Active Role",
    publish_date: "2020-07-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 240,
    url: "https://www.mx.com/blog/top-fast-food-restaurants-during-covid",
    admin_url: "https://cms.mx.com/admin/content/blog/240",
    name: "Top Fast Food Restaurants During COVID-19",
    publish_date: "2020-07-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 52,
    url: "https://www.mx.com/blog/accelerate-digital-innovation-at-your-financial-institution",
    admin_url: "https://cms.mx.com/admin/content/blog/52",
    name: "Path to Data Access: Accelerate Digital Innovation at your Financial Institution",
    publish_date: "2020-08-03",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 77,
    url: "https://www.mx.com/blog/screen-scraping-vs-bank-apis-whats-the-difference",
    admin_url: "https://cms.mx.com/admin/content/blog/77",
    name: "Screen Scraping Vs. Bank APIs: What’s the Difference?",
    publish_date: "2020-08-04",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 82,
    url: "https://www.mx.com/blog/an-intro-to-fintech-with-examples",
    admin_url: "https://cms.mx.com/admin/content/blog/82",
    name: "Fintech Defined: Examples, Use Cases, and Benefits",
    publish_date: "2020-08-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 104,
    url: "https://www.mx.com/blog/of-consumers-value-financial-account-aggregation-heres-why",
    admin_url: "https://cms.mx.com/admin/content/blog/104",
    name: "91% of Consumers Value Financial Account Aggregation. Here’s Why.",
    publish_date: "2020-08-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 185,
    url: "https://www.mx.com/blog/the-ultimate-guide-to-the-new-world-of-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/185",
    name: "Understanding the New World of Banking Post COVID-19",
    publish_date: "2020-08-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 254,
    url: "https://www.mx.com/blog/how-fintechs-can-resolve-these-5-common-security-issues",
    admin_url: "https://cms.mx.com/admin/content/blog/254",
    name: "How Fintechs Can Resolve these 5 Common Security Issues",
    publish_date: "2020-08-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 34,
    url: "https://www.mx.com/blog/atms-didnt-reduce-tellers-but-todays-tech-will",
    admin_url: "https://cms.mx.com/admin/content/blog/34",
    name: "ATMs Didn’t Reduce Tellers, But Today’s Tech Will",
    publish_date: "2020-08-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 54,
    url: "https://www.mx.com/blog/the-roi-of-fintech-is-that-youre-still-in-business-in-5-years",
    admin_url: "https://cms.mx.com/admin/content/blog/54",
    name: "The ROI of Fintech Is That You'll Survive the Next 5 Years",
    publish_date: "2020-08-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 173,
    url: "https://www.mx.com/blog/bank-data-and-ai-an-ultimate-guide",
    admin_url: "https://cms.mx.com/admin/content/blog/173",
    name: "Bank Data and AI: An Ultimate Guide",
    publish_date: "2020-08-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 25,
    url: "https://www.mx.com/blog/mx-announces-new-connectivity-and-data-products",
    admin_url: "https://cms.mx.com/admin/content/blog/25",
    name: "MX Announces New Connectivity and Data Products",
    publish_date: "2020-09-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 232,
    url: "https://www.mx.com/blog/money-experience-summit-day-1-highlights",
    admin_url: "https://cms.mx.com/admin/content/blog/232",
    name: "Money Experience Summit 2020 Day 1 Highlights",
    publish_date: "2020-09-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 17,
    url: "https://www.mx.com/blog/mx-announces-new-mobile-marketing-and-financial-guidance-products",
    admin_url: "https://cms.mx.com/admin/content/blog/17",
    name: "MX Announces New Mobile, Marketing, and Financial Guidance Products",
    publish_date: "2020-09-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 143,
    url: "https://www.mx.com/blog/money-experience-summit-day-2-highlights",
    admin_url: "https://cms.mx.com/admin/content/blog/143",
    name: "Money Experience Summit 2020: Day 2 Highlights",
    publish_date: "2020-09-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 91,
    url: "https://www.mx.com/blog/the-psychology-and-future-of-money-stephen-dubner",
    admin_url: "https://cms.mx.com/admin/content/blog/91",
    name: "The Psychology and Future of Money: Stephen Dubner",
    publish_date: "2020-09-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 62,
    url: "https://www.mx.com/blog/defining-moments-that-lead-to-greatness-gabby-douglas",
    admin_url: "https://cms.mx.com/admin/content/blog/62",
    name: "Defining Moments that Lead to Greatness: Gabby Douglas",
    publish_date: "2020-09-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 400,
    url: "https://www.mx.com/blog/improving-customer-experience-with-security-and-convenience",
    admin_url: "https://cms.mx.com/admin/content/blog/400",
    name: "Improving the Customer Experience with Security and Convenience",
    publish_date: "2020-09-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 55,
    url: "https://www.mx.com/blog/ron-shevlins-wins-and-fails-for-2020",
    admin_url: "https://cms.mx.com/admin/content/blog/55",
    name: "Ron Shevlin’s Wins & Fails for 2020",
    publish_date: "2020-09-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 70,
    url: "https://www.mx.com/blog/on-the-edge-the-art-of-high-impact-leadership",
    admin_url: "https://cms.mx.com/admin/content/blog/70",
    name: "On the Edge: The Art of High-Impact Leadership",
    publish_date: "2020-09-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 55,
    url: "https://www.mx.com/news/pacific-business-news-central-pacific-bank-releases-upgraded-banking-app-as-part-of-rise2020-investment",
    admin_url: "https://cms.mx.com/admin/content/mx_news/55",
    name: "Pacific Business News: Central Pacific Bank releases upgraded banking app as part of RISE2020 investment",
    publish_date: "2020-09-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 101,
    url: "https://www.mx.com/blog/how-can-financial-institutions-thrive-in-an-era-of-open-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/101",
    name: "How Can Financial Institutions Thrive in an Era of Open Finance?",
    publish_date: "2020-09-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 125,
    url: "https://www.mx.com/blog/want-to-offer-a-better-money-experience-start-with-better-data",
    admin_url: "https://cms.mx.com/admin/content/blog/125",
    name: "Want to Offer a Better Money Experience? Start With Better Data.",
    publish_date: "2020-10-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 166,
    url: "https://www.mx.com/blog/the-color-of-money-explaining-the-racial-wealth-gap",
    admin_url: "https://cms.mx.com/admin/content/blog/166",
    name: "The Color of Money: Explaining the Racial Wealth Gap — Mehrsa Baradaran, Professor at UCI Law",
    publish_date: "2020-10-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 394,
    url: "https://www.mx.com/blog/8-ways-banking-will-change-forever-due-to-covid",
    admin_url: "https://cms.mx.com/admin/content/blog/394",
    name: "The New Normal: 8 Ways Banking Will Change Forever Due to COVID-19",
    publish_date: "2020-10-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 66,
    url: "https://www.mx.com/blog/frank-abagnales-advice-for-banks-and-credit-unions",
    admin_url: "https://cms.mx.com/admin/content/blog/66",
    name: "Frank Abagnale’s Advice for Banks and Credit Unions",
    publish_date: "2020-10-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 158,
    url: "https://www.mx.com/blog/digital-transformation-in-banking-insights",
    admin_url: "https://cms.mx.com/admin/content/blog/158",
    name: "Digital Transformation in Banking: Insights from James Robert Lay",
    publish_date: "2020-10-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 53,
    url: "https://www.mx.com/news/bank-director-how-open-finance-fuels-the-money-experience-and-drives-growth",
    admin_url: "https://cms.mx.com/admin/content/mx_news/53",
    name: "Bank Director: How Open Finance Fuels the Money Experience and Drives Growth",
    publish_date: "2020-10-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 239,
    url: "https://www.mx.com/blog/2020-the-make-or-break-moment-for-fintech",
    admin_url: "https://cms.mx.com/admin/content/blog/239",
    name: "2020: The Make or Break Moment for Fintech",
    publish_date: "2020-10-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 49,
    url: "https://www.mx.com/news/utah-valley-businessq-mx-named-to-uv50-and-one-of-10-economic-engines-of-utah-valley",
    admin_url: "https://cms.mx.com/admin/content/mx_news/49",
    name: "Utah Valley BusinessQ: MX Named to UV50 and One of 10 Economic Engines of Utah Valley ",
    publish_date: "2020-10-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 88,
    url: "https://www.mx.com/blog/3-insights-about-how-banks-are-responding-to-covid-19",
    admin_url: "https://cms.mx.com/admin/content/blog/88",
    name: "3 Insights About How Banks Are Responding to COVID-19",
    publish_date: "2020-10-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 83,
    url: "https://www.mx.com/blog/will-banks-and-fintechs-create-the-experiences-needed-for-the-new-normal",
    admin_url: "https://cms.mx.com/admin/content/blog/83",
    name: "Will Banks and Fintechs Create the Experiences Needed for the New Normal?",
    publish_date: "2020-10-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 44,
    url: "https://www.mx.com/news/ryan-caldwell-speaks-at-money-2020",
    admin_url: "https://cms.mx.com/admin/content/mx_news/44",
    name: "Ryan Caldwell speaks at Money 20/20",
    publish_date: "2020-11-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 40,
    url: "https://www.mx.com/blog/big-data-big-opportunities-big-responsibilitie",
    admin_url: "https://cms.mx.com/admin/content/blog/40",
    name: "Big Data, Big Opportunities, Big Responsibilities: Navigating a Mutually Beneficial Path",
    publish_date: "2020-11-03",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 218,
    url: "https://www.mx.com/blog/hackers-know-your-organization-do-you-know-theirs",
    admin_url: "https://cms.mx.com/admin/content/blog/218",
    name: "Hackers Know Your Organization. Do You Know Theirs?",
    publish_date: "2020-11-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 181,
    url: "https://www.mx.com/blog/bank-api-benefits-and-examples",
    admin_url: "https://cms.mx.com/admin/content/blog/181",
    name: "Bank API Examples and Benefits",
    publish_date: "2020-11-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 116,
    url: "https://www.mx.com/blog/innovation-amidst-uncertainty-3-lessons-from-a-bank-ceo",
    admin_url: "https://cms.mx.com/admin/content/blog/116",
    name: "Innovation Amidst Uncertainty: 3 Lessons from a Bank CEO",
    publish_date: "2020-11-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 48,
    url: "https://www.mx.com/blog/introducing-the-money-experience",
    admin_url: "https://cms.mx.com/admin/content/blog/48",
    name: "Introducing the Money Experience: A Guide to the Future of Banking",
    publish_date: "2020-11-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 112,
    url: "https://www.mx.com/blog/new-money-new-problems-how-covid-19-has-accelerated-the-move-to-digital-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/112",
    name: "New Money, New Problems: How COVID-19 Has Accelerated the Move to Digital Banking",
    publish_date: "2020-11-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 177,
    url: "https://www.mx.com/blog/keep-relationships-with-customers-alive-money-experience",
    admin_url: "https://cms.mx.com/admin/content/blog/177",
    name: "How to Keep Relationships With Your Customers Alive? The Money Experience",
    publish_date: "2020-11-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 16,
    url: "https://www.mx.com/blog/2021-the-year-of-the-money-experience",
    admin_url: "https://cms.mx.com/admin/content/blog/16",
    name: "2021: The Year of the Money Experience [Original Survey Data]",
    publish_date: "2020-11-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 263,
    url: "https://www.mx.com/blog/2021-banking-data-priorities-survey-results",
    admin_url: "https://cms.mx.com/admin/content/blog/263",
    name: "2021 Banking Data Priorities: Survey Results",
    publish_date: "2020-11-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 243,
    url: "https://www.mx.com/blog/3-banking-lessons-from-industries-beyond-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/243",
    name: "3 Banking Lessons From Industries Beyond Banking",
    publish_date: "2020-12-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 8,
    url: "https://www.mx.com/news/finance-magnates-the-beginning-of-the-end-of-cash-2020-in-fintech-trends",
    admin_url: "https://cms.mx.com/admin/content/mx_news/8",
    name: "Finance Magnates: The Beginning of the End of Cash? 2020 in Fintech Trends",
    publish_date: "2020-12-03",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 168,
    url: "https://www.mx.com/blog/how-can-data-power-the-money-experience",
    admin_url: "https://cms.mx.com/admin/content/blog/168",
    name: "How Can Data Power the Money Experience?",
    publish_date: "2020-12-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 19,
    url: "https://www.mx.com/news/forbes-top-5-banking-and-fintech-trends-for-2021",
    admin_url: "https://cms.mx.com/admin/content/mx_news/19",
    name: "Forbes: Top 5 Banking And Fintech Trends For 2021",
    publish_date: "2020-12-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 120,
    url: "https://www.mx.com/blog/2021-bank-innovation-banker-consumer-survey",
    admin_url: "https://cms.mx.com/admin/content/blog/120",
    name: "2021 Bank Innovation: Banker + Consumer Survey",
    publish_date: "2020-12-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 245,
    url: "https://www.mx.com/blog/introducing-the-mx-business-data-suite",
    admin_url: "https://cms.mx.com/admin/content/blog/245",
    name: "Introducing the MX Business Data Suite",
    publish_date: "2020-12-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 183,
    url: "https://www.mx.com/blog/6-open-finance-insights-for-bankers-and-fintech-developers",
    admin_url: "https://cms.mx.com/admin/content/blog/183",
    name: "6 Open Finance Insights for Bankers and Fintech Developers",
    publish_date: "2020-12-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 61,
    url: "https://www.mx.com/blog/7-key-takeaways-from-matthew-mcconaugheys-book-greenlights",
    admin_url: "https://cms.mx.com/admin/content/blog/61",
    name: "7 Key Takeaways From Matthew McConaughey and His Book: Greenlights",
    publish_date: "2020-12-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 48,
    url: "https://www.mx.com/news/business-insider-why-the-workout-trend-may-be-here-to-stay",
    admin_url: "https://cms.mx.com/admin/content/mx_news/48",
    name: "Business Insider: Why the workout trend may be here to stay",
    publish_date: "2020-12-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 176,
    url: "https://www.mx.com/blog/vision-for-2021-banking-and-beyond-lessons-from-a-fintech-cto",
    admin_url: "https://cms.mx.com/admin/content/blog/176",
    name: "Vision for 2021 Banking and Beyond: Lessons From a Fintech CTO",
    publish_date: "2020-12-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 80,
    url: "https://www.mx.com/blog/mx-top-10-assets-of-2020-for-banks-and-fintechs",
    admin_url: "https://cms.mx.com/admin/content/blog/80",
    name: "MX Top 10 Assets of 2020 for Banks and Fintechs",
    publish_date: "2020-12-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 229,
    url: "https://www.mx.com/blog/3-insights-into-consumer-money-habits-in-2021",
    admin_url: "https://cms.mx.com/admin/content/blog/229",
    name: "3 Insights into Consumer Money Habits in 2021",
    publish_date: "2021-01-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 255,
    url: "https://www.mx.com/blog/helping-your-customers-thrive-in-2021",
    admin_url: "https://cms.mx.com/admin/content/blog/255",
    name: "Aligning Digital Strategies with Customer Expectations",
    publish_date: "2021-01-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 265,
    url: "https://www.mx.com/blog/open-banking-api-platforms-explained",
    admin_url: "https://cms.mx.com/admin/content/blog/265",
    name: "Open Banking API Platforms Explained",
    publish_date: "2021-01-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 193,
    url: "https://www.mx.com/blog/automating-the-money-experience-what-does-it-look-like",
    admin_url: "https://cms.mx.com/admin/content/blog/193",
    name: "Automating the Money Experience: What Does It Look Like?",
    publish_date: "2021-01-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 68,
    url: "https://www.mx.com/blog/3-ways-to-incorporate-data-driven-experiences-and-be-a-true-financial-advocate",
    admin_url: "https://cms.mx.com/admin/content/blog/68",
    name: "3 Ways to Incorporate Data-Driven Experiences and Be a True Financial Advocate",
    publish_date: "2021-01-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 209,
    url: "https://www.mx.com/blog/why-tpg-and-capitalg-invested-in-mx",
    admin_url: "https://cms.mx.com/admin/content/blog/209",
    name: "Why TPG and CapitalG Invested in MX",
    publish_date: "2021-01-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 151,
    url: "https://www.mx.com/blog/off-script-with-shawn-achor-6-actionable-tips-for-being-happier-at-work",
    admin_url: "https://cms.mx.com/admin/content/blog/151",
    name: "Off-Script With Shawn Achor: 6 Actionable Tips for Being Happier at Work",
    publish_date: "2021-01-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 157,
    url: "https://www.mx.com/blog/highlights-in-money-experience-from-mx-clients-mandt-bank-bbva-formfree-becu-synovus-and-lgfcu",
    admin_url: "https://cms.mx.com/admin/content/blog/157",
    name: "Highlights in Money Experience from MX Clients: M&T Bank, FormFree, BECU, Synovus, and LGFCU",
    publish_date: "2021-01-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 204,
    url: "https://www.mx.com/blog/how-mx-uses-data-to-help-you-be-an-advocate-for-your-customers",
    admin_url: "https://cms.mx.com/admin/content/blog/204",
    name: "How MX Uses Data to Help You Be an Advocate for Your Customers",
    publish_date: "2021-01-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 15,
    url: "https://www.mx.com/blog/financial-data-protection-and-security-best-practices",
    admin_url: "https://cms.mx.com/admin/content/blog/15",
    name: "Financial Data Protection and Security Best Practices",
    publish_date: "2021-01-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 230,
    url: "https://www.mx.com/blog/mx-data-enhancement-and-connectivity-become-the-one-stop-digital-solution-for-your-customers",
    admin_url: "https://cms.mx.com/admin/content/blog/230",
    name: "MX Data Enhancement and Connectivity: Become the One-Stop Digital Solution for Your Customers",
    publish_date: "2021-01-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 7,
    url: "https://www.mx.com/blog/how-mx-uses-ai-to-personalize-and-guide-the-money-experience",
    admin_url: "https://cms.mx.com/admin/content/blog/7",
    name: "How MX Uses AI to Personalize and Guide the Money Experience",
    publish_date: "2021-01-28",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 5,
    url: "https://www.mx.com/blog/6-steps-to-build-a-culture-that-uses-bank-and-fintech-data",
    admin_url: "https://cms.mx.com/admin/content/blog/5",
    name: "6 Steps to Build a Culture That Uses Bank and Fintech Data",
    publish_date: "2021-02-03",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 46,
    url: "https://www.mx.com/news/bank-automation-news-cibc-app-revamp-offers-ai-powered-financial-insights",
    admin_url: "https://cms.mx.com/admin/content/mx_news/46",
    name: "Bank Automation News: CIBC app revamp offers AI-powered financial insights",
    publish_date: "2021-02-04",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 139,
    url: "https://www.mx.com/blog/consumers-should-own-their-financial-data-mx-comment-to-the-cfpb",
    admin_url: "https://cms.mx.com/admin/content/blog/139",
    name: "Consumers Should Own Their Financial Data: MX Comment to the CFPB",
    publish_date: "2021-02-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 50,
    url: "https://www.mx.com/news/cnbc-former-goldman-co-president-jon-winkelried-and-alphabet-venture-partner-join-fintech-start-up-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/50",
    name: "CNBC: Former Goldman co-president Jon Winkelried and Alphabet venture partner join fintech start-up MX",
    publish_date: "2021-02-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 18,
    url: "https://www.mx.com/news/bbvacom-bbva-insights-adds-a-self-driving-dimension-to-online-and-mobile-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/18",
    name: "BBVA.com: BBVA Insights adds a self-driving dimension to online and mobile banking",
    publish_date: "2021-02-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 214,
    url: "https://www.mx.com/blog/to-win-the-future-of-banking-build-a-data-flywheel-today",
    admin_url: "https://cms.mx.com/admin/content/blog/214",
    name: "To Win the Future of Banking, Build a Financial Data Flywheel Today",
    publish_date: "2021-02-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 179,
    url: "https://www.mx.com/blog/promote-better-financial-health-with-new-audience-conditions",
    admin_url: "https://cms.mx.com/admin/content/blog/179",
    name: "Promote Better Financial Health With New Audience Conditions",
    publish_date: "2021-02-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 266,
    url: "https://www.mx.com/blog/enhanced-bank-transaction-data-the-underrated-key-to-digital-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/266",
    name: "Enhanced Bank Transaction Data: The Underrated Key to Digital Banking",
    publish_date: "2021-03-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 267,
    url: "https://www.mx.com/blog/2021-is-the-year-banks-must-take-digital-challengers-seriously",
    admin_url: "https://cms.mx.com/admin/content/blog/267",
    name: "2021 Is the Year Banks Must Take Digital Challengers Seriously",
    publish_date: "2021-03-04",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 268,
    url: "https://www.mx.com/blog/the-future-of-banking-is-the-bank-fintech-hybrid",
    admin_url: "https://cms.mx.com/admin/content/blog/268",
    name: "The Future of Banking Is the Bank-Fintech Hybrid",
    publish_date: "2021-03-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 269,
    url: "https://www.mx.com/blog/grit-motivation-alignment-character-angela-duckworth-mx",
    admin_url: "https://cms.mx.com/admin/content/blog/269",
    name: "Grit Is About Intrinsic Motivation, Alignment, and Character: 3 Takeaways from Angela Duckworth’s Conversation With MX",
    publish_date: "2021-03-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 15,
    url: "https://www.mx.com/news/market-watch-plenty-have-tried-to-create-a-new-silicon-valley-but-this-new-nba-owner-and-tech-founder-may-be-succeeding",
    admin_url: "https://cms.mx.com/admin/content/mx_news/15",
    name: "MarketWatch: Plenty have tried to create a new Silicon Valley",
    publish_date: "2021-03-10",
    last_modified: "2021-03-12T23:35:32.000Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 47,
    url: "https://www.mx.com/news/cf-os-on-the-move-brian-kinion-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/47",
    name: "CFO.com: CFOs On the Move: Brian Kinion, MX",
    publish_date: "2021-03-12",
    last_modified: "2021-03-12T23:35:48.000Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 270,
    url: "https://www.mx.com/blog/get-beyond-build-vs-buy-in-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/270",
    name: "Get Beyond Build vs. Buy in Banking",
    publish_date: "2021-03-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 271,
    url: "https://www.mx.com/blog/mx-bill-predictor-using-transaction-data-to-predict-future-financial-outcomes",
    admin_url: "https://cms.mx.com/admin/content/blog/271",
    name: "MX Bill Predictor: Using Transaction Data To Predict Future Financial Outcomes",
    publish_date: "2021-03-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 273,
    url: "https://www.mx.com/blog/digital-first-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/273",
    name: "A Phone in the Pocket Is Closer Than a Branch Down the Street: The Case for Becoming a Digital-First Bank",
    publish_date: "2021-03-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 274,
    url: "https://www.mx.com/blog/ultimate-guide-to-the-top-2021-bank-challenges",
    admin_url: "https://cms.mx.com/admin/content/blog/274",
    name: "The Ultimate Guide to the Top 2021 Bank Challenges",
    publish_date: "2021-03-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 275,
    url: "https://www.mx.com/blog/bank-api-integration-for-beginners",
    admin_url: "https://cms.mx.com/admin/content/blog/275",
    name: "Bank API Integration for Beginners",
    publish_date: "2021-03-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 276,
    url: "https://www.mx.com/blog/will-the-third-stimulus-check-help-americans-rebound",
    admin_url: "https://cms.mx.com/admin/content/blog/276",
    name: "Will the Third Stimulus Check Help Americans Rebound?",
    publish_date: "2021-03-31",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 277,
    url: "https://www.mx.com/blog/digital-experience-banks-top-priority",
    admin_url: "https://cms.mx.com/admin/content/blog/277",
    name: "Why Today's Top Priority —  Digital Experiences — for Banks Makes Perfect Sense",
    publish_date: "2021-04-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 278,
    url: "https://www.mx.com/blog/jamie-dimon-banks-face-extensive-competition-from-silicon-valley",
    admin_url: "https://cms.mx.com/admin/content/blog/278",
    name: "Jamie Dimon: Banks Face “Extensive Competition from Silicon Valley”",
    publish_date: "2021-04-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 279,
    url: "https://www.mx.com/blog/value-digital-adoption-engagement-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/279",
    name: "The Value of Digital Adoption and Engagement in Banking",
    publish_date: "2021-04-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 280,
    url: "https://www.mx.com/blog/10-debate-highlights-ron-shevlin-vs-jim-marous-on-2021-challenges",
    admin_url: "https://cms.mx.com/admin/content/blog/280",
    name: "10 Debate Highlights: Ron Shevlin vs. Jim Marous on 2021 Challenges",
    publish_date: "2021-04-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 281,
    url: "https://www.mx.com/blog/when-to-buy-build-or-partner-in-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/281",
    name: "When to Buy, Build, or Partner in Banking",
    publish_date: "2021-04-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 282,
    url: "https://www.mx.com/blog/3-reasons-banks-can-t-afford-to-stall-innovation-during-an-merger-and-acquisition",
    admin_url: "https://cms.mx.com/admin/content/blog/282",
    name: "3 Reasons Banks Can’t Afford to Stall Innovation During an Merger and Acquisition",
    publish_date: "2021-04-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 284,
    url: "https://www.mx.com/blog/disruptive-digital-banking-infrastructure-mx-ceo-ryan-caldwell-speaks-with-bank-of-america-securities",
    admin_url: "https://cms.mx.com/admin/content/blog/284",
    name: "Disruptive Digital Banking Infrastructure: MX CEO Ryan Caldwell Speaks with Bank of America Securities",
    publish_date: "2021-05-04",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 285,
    url: "https://www.mx.com/blog/3-key-data-and-connectivity-insights-from-the-mx-product-forum",
    admin_url: "https://cms.mx.com/admin/content/blog/285",
    name: "3 Key Data and Connectivity Insights from the MX Product Forum",
    publish_date: "2021-05-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 286,
    url: "https://www.mx.com/blog/financial-account-aggregation-a-new-approach",
    admin_url: "https://cms.mx.com/admin/content/blog/286",
    name: "Financial Account Aggregation: A New Approach",
    publish_date: "2021-05-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 287,
    url: "https://www.mx.com/blog/67-of-u-s-consumers-say-they-suffer-financial-hardship-at-least-occasionally-how-can-banks-help",
    admin_url: "https://cms.mx.com/admin/content/blog/287",
    name: "67% of U.S. Consumers Say They Suffer Financial Hardship at Least Occasionally. How Can Banks Help?",
    publish_date: "2021-05-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 288,
    url: "https://www.mx.com/blog/how-open-banking-streamlines-mortgage-origination",
    admin_url: "https://cms.mx.com/admin/content/blog/288",
    name: "How Open Banking Streamlines Mortgage Origination",
    publish_date: "2021-05-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 289,
    url: "https://www.mx.com/blog/one-question-to-help-banks-know-whether-to-build-buy-or-partner",
    admin_url: "https://cms.mx.com/admin/content/blog/289",
    name: "One Question to Help Banks Know Whether to Build, Buy, or Partner",
    publish_date: "2021-05-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 290,
    url: "https://www.mx.com/blog/how-will-open-banking-change-payments-at-financial-institutions",
    admin_url: "https://cms.mx.com/admin/content/blog/290",
    name: "How Will Open Banking Change Payments at Financial Institutions?",
    publish_date: "2021-05-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 291,
    url: "https://www.mx.com/blog/how-open-banking-and-open-finance-empower-true-financial-strength",
    admin_url: "https://cms.mx.com/admin/content/blog/291",
    name: "How Open Banking and Open Finance Empower True Financial Strength",
    publish_date: "2021-06-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 292,
    url: "https://www.mx.com/blog/life-at-mx-nicole-barkalow-understands-fi-pain-points-firsthand",
    admin_url: "https://cms.mx.com/admin/content/blog/292",
    name: "Life at MX: Nicole Barkalow Understands FI Pain Points Firsthand",
    publish_date: "2021-06-03",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 293,
    url: "https://www.mx.com/blog/10-lessons-from-form-free-around-data-partnerships-and-disruptive-lending",
    admin_url: "https://cms.mx.com/admin/content/blog/293",
    name: "10 Lessons From FormFree Around Data, Partnerships, and Disruptive Lending",
    publish_date: "2021-06-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 294,
    url: "https://www.mx.com/blog/open-banking-credit-approval-process",
    admin_url: "https://cms.mx.com/admin/content/blog/294",
    name: "How Open Banking Will Augment the Credit Approval Process",
    publish_date: "2021-06-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 295,
    url: "https://www.mx.com/blog/open-banking-lending",
    admin_url: "https://cms.mx.com/admin/content/blog/295",
    name: "Open Banking Speeds Up the Lending Process",
    publish_date: "2021-06-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 296,
    url: "https://www.mx.com/blog/open-finance-gives-banks-new-options-for-credit-cards",
    admin_url: "https://cms.mx.com/admin/content/blog/296",
    name: "Open Finance Gives Banks New Options for Credit Cards",
    publish_date: "2021-06-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 297,
    url: "https://www.mx.com/blog/biometrics-mx-citi",
    admin_url: "https://cms.mx.com/admin/content/blog/297",
    name: "New Biometrics Capability When Connecting a Citi Account Through MX",
    publish_date: "2021-06-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 298,
    url: "https://www.mx.com/blog/prevent-fraud-with-simpler-and-easily-understandable-financial-transaction",
    admin_url: "https://cms.mx.com/admin/content/blog/298",
    name: "Prevent Fraud with Simpler and Easily Understandable Financial Transactions",
    publish_date: "2021-06-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 299,
    url: "https://www.mx.com/blog/data-enhancement-financial-innovation-at-banks",
    admin_url: "https://cms.mx.com/admin/content/blog/299",
    name: "Data Enhancement Is a Precursor to Financial Innovation at Banks",
    publish_date: "2021-06-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 300,
    url: "https://www.mx.com/blog/mx-applauds-white-house-and-cfpb-efforts-to-improve-financial-data-access",
    admin_url: "https://cms.mx.com/admin/content/blog/300",
    name: "MX Applauds White House & CFPB Efforts To Improve Financial Data Access",
    publish_date: "2021-07-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 115,
    url: "https://www.mx.com/news/finextra-mx-launches-financial-insights-ap-is",
    admin_url: "https://cms.mx.com/admin/content/mx_news/115",
    name: "Finextra: MX launches financial insights APIs",
    publish_date: "2021-07-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 302,
    url: "https://www.mx.com/blog/mx-product-forum-highlights-from-data-to-action",
    admin_url: "https://cms.mx.com/admin/content/blog/302",
    name: "MX Product Forum Highlights: From Data to Action",
    publish_date: "2021-07-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 121,
    url: "https://www.mx.com/news/banking-dive-3-ways-to-keep-financial-data-fraud-from-sabotaging-digital-cx-in-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/121",
    name: "Banking Dive: 3 ways to keep financial data fraud from sabotaging digital CX in banking",
    publish_date: "2021-07-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 120,
    url: "https://www.mx.com/news/the-financial-brand-this-small-bank-believes-its-digital-brand-will-succeed-where-chase-failed",
    admin_url: "https://cms.mx.com/admin/content/mx_news/120",
    name: "The Financial Brand: This Small Bank Believes Its Digital Brand Will Succeed Where Chase Failed",
    publish_date: "2021-07-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 119,
    url: "https://www.mx.com/news/forbes-17-industry-leaders-share-pros-and-cons-of-tapping-into-open-source-code",
    admin_url: "https://cms.mx.com/admin/content/mx_news/119",
    name: "Forbes: 17 Industry Leaders Share Pros And Cons Of Tapping Into Open-Source Code",
    publish_date: "2021-08-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 303,
    url: "https://www.mx.com/blog/reducing-fraud-with-account-verification",
    admin_url: "https://cms.mx.com/admin/content/blog/303",
    name: "Reducing Fraud with Account Verification",
    publish_date: "2021-08-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 304,
    url: "https://www.mx.com/blog/7-ways-theo-epstein-used-data-leadership-and-culture-to-revolutionize-baseball",
    admin_url: "https://cms.mx.com/admin/content/blog/304",
    name: "7 Ways Theo Epstein Used Data, Leadership, and Culture to Revolutionize Baseball",
    publish_date: "2021-08-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 305,
    url: "https://www.mx.com/blog/mxaccess-open-finance-to-improve-customer-loyalty",
    admin_url: "https://cms.mx.com/admin/content/blog/305",
    name: "Accelerate Open Finance and Improve Customer Trust and Loyalty",
    publish_date: "2021-10-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 306,
    url: "https://www.mx.com/blog/experience-product-forum-2021",
    admin_url: "https://cms.mx.com/admin/content/blog/306",
    name: "Experience Product Forum 2021",
    publish_date: "2021-11-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 166,
    url: "https://www.mx.com/news/the-top-5-trends-in-fintech-and-banking-for-2022",
    admin_url: "https://cms.mx.com/admin/content/mx_news/166",
    name: "The Top 5 Trends In Fintech And Banking For 2022",
    publish_date: "2021-12-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 167,
    url: "https://www.mx.com/news/20-of-consumers-are-very-interested-in-switching-banks-for-an-open-banking-platform",
    admin_url: "https://cms.mx.com/admin/content/mx_news/167",
    name: "20% of Consumers Are Very Interested in Switching Banks for an Open Banking Platform",
    publish_date: "2021-12-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 308,
    url: "https://www.mx.com/blog/gen-z-banking-perspectives-2022-predictions",
    admin_url: "https://cms.mx.com/admin/content/blog/308",
    name: "Gen Z Banking Perspectives & 2022 Predictions",
    publish_date: "2021-12-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 309,
    url: "https://www.mx.com/blog/mx-visionaries-ted-lasso-lessons",
    admin_url: "https://cms.mx.com/admin/content/blog/309",
    name: "10 Lessons on Life, Love, and Leadership from Ted Lasso",
    publish_date: "2022-01-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 310,
    url: "https://www.mx.com/blog/mx-ceo-trends-in-open-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/310",
    name: "Trends in Open Finance: A Conversation with MX and CapitalG",
    publish_date: "2022-01-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 71,
    url: "https://www.mx.com/webinars/interim-ceo-president-shane-evans/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/71",
    name: "The Future of Open Finance",
    publish_date: "2022-01-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 81,
    url: "https://www.mx.com/webinars/interim-ceo-president-shane-evans",
    admin_url: "https://cms.mx.com/admin/content/webinars/81",
    name: "The Future of Open Finance",
    publish_date: "2022-01-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 311,
    url: "https://www.mx.com/blog/mx-carta-goldman-visionaries",
    admin_url: "https://cms.mx.com/admin/content/blog/311",
    name: "Hot Takes on Private Equity, Cryptocurrency, and Leadership from Carta and Marcus by Goldman Sachs",
    publish_date: "2022-02-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 177,
    url: "https://www.mx.com/news/american-banker-digital-bank-s-fintech-partnership-generates-new-reward-stock",
    admin_url: "https://cms.mx.com/admin/content/mx_news/177",
    name: "American Banker: Digital bank’s fintech partnership generates new reward: Stock",
    publish_date: "2022-02-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 178,
    url: "https://www.mx.com/news/sifted-open-data-what-it-is-and-what-it-means-for-fintech-and-beyond",
    admin_url: "https://cms.mx.com/admin/content/mx_news/178",
    name: "Sifted: Open data: What it is, and what it means for fintech and beyond",
    publish_date: "2022-01-28",
    last_modified: "2022-02-07T18:51:58.660Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 312,
    url: "https://www.mx.com/blog/redefine-primary-banking-relationships-in-2022",
    admin_url: "https://cms.mx.com/admin/content/blog/312",
    name: "Redefining Primary Banking Relationships in 2022 and Beyond",
    publish_date: "2022-02-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 313,
    url: "https://www.mx.com/blog/javelin-webinar-top-questions-and-answers",
    admin_url: "https://cms.mx.com/admin/content/blog/313",
    name: "The Inside Scoop from Javelin Strategy & Research on Top Questions about Gen Z, Digital Banking, and More",
    publish_date: "2022-02-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 314,
    url: "https://www.mx.com/blog/mx-2022-kickoff-just-dewitt",
    admin_url: "https://cms.mx.com/admin/content/blog/314",
    name: "2022 is the Year to Just Dewitt",
    publish_date: "2022-02-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 315,
    url: "https://www.mx.com/blog/mx-roadshow-to-open-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/315",
    name: "The Road(show) to Open Finance",
    publish_date: "2022-02-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 316,
    url: "https://www.mx.com/blog/glg-research-better-digital-experience",
    admin_url: "https://cms.mx.com/admin/content/blog/316",
    name: "The Clock is Ticking for a Better Digital Banking Experience",
    publish_date: "2022-03-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 317,
    url: "https://www.mx.com/blog/building-blocks-of-an-ipo",
    admin_url: "https://cms.mx.com/admin/content/blog/317",
    name: "Building Blocks of a Successful IPO: A Conversation with Brian Kinion, CFO of MX",
    publish_date: "2022-03-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 185,
    url: "https://www.mx.com/news/american-banker-5-banks-that-just-launched-digital-only-brands",
    admin_url: "https://cms.mx.com/admin/content/mx_news/185",
    name: "American Banker: 5 banks that just launched digital-only brands",
    publish_date: "2022-03-08",
    last_modified: "2022-03-09T20:10:42.041Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 184,
    url: "https://www.mx.com/news/built-in-chicago-meet-the-women-inspiring-chicago-tech-professionals-to-hit-new-heights",
    admin_url: "https://cms.mx.com/admin/content/mx_news/184",
    name: "Built in Chicago: Meet the Women Inspiring Chicago Tech Professionals to Hit New Heights",
    publish_date: "2022-03-07",
    last_modified: "2022-03-09T20:10:53.808Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 318,
    url: "https://www.mx.com/blog/mx-fintech-festival-open-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/318",
    name: "FinTech Festival 2022 and Open Finance",
    publish_date: "2022-03-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 319,
    url: "https://www.mx.com/blog/crypto-executive-order-open-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/319",
    name: "What the Crypto Executive Order Means for Open Finance",
    publish_date: "2022-03-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 320,
    url: "https://www.mx.com/blog/fintech-festival-simon-taylor-open-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/320",
    name: "2022 FinTech Festival: Powering the Future of Open Finance",
    publish_date: "2022-03-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 321,
    url: "https://www.mx.com/blog/rules-of-investing-in-cryptocurrency",
    admin_url: "https://cms.mx.com/admin/content/blog/321",
    name: "Investing in Cryptocurrency: Know the Rules of the Game",
    publish_date: "2022-03-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 322,
    url: "https://www.mx.com/blog/create-meaningful-connections-susan-mcpherson",
    admin_url: "https://cms.mx.com/admin/content/blog/322",
    name: "Anything but the Weather: How To Create More Meaningful Connections",
    publish_date: "2022-03-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 323,
    url: "https://www.mx.com/blog/open-finance-is-the-great-enabler-fintech-festival",
    admin_url: "https://cms.mx.com/admin/content/blog/323",
    name: "Open Finance Is the Great Enabler",
    publish_date: "2022-03-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 324,
    url: "https://www.mx.com/blog/driving-financial-inclusion-among-minorities",
    admin_url: "https://cms.mx.com/admin/content/blog/324",
    name: "Drive Financial Inclusion with a Sense of Belonging",
    publish_date: "2022-03-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 325,
    url: "https://www.mx.com/blog/passion-and-purpose-of-mx-founders-day",
    admin_url: "https://cms.mx.com/admin/content/blog/325",
    name: "Founders Day: The Passion and Purpose of MX",
    publish_date: "2022-03-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 190,
    url: "https://www.mx.com/news/bank-automation-news-fiserv-anticipates-increased-adoption-of-all-data-connect-in-wake-of-mx-deal",
    admin_url: "https://cms.mx.com/admin/content/mx_news/190",
    name: "Bank Automation News: Fiserv anticipates increased adoption of AllData Connect in wake of MX deal",
    publish_date: "2022-03-29",
    last_modified: "2022-03-30T23:07:26.814Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 326,
    url: "https://www.mx.com/blog/api-era-of-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/326",
    name: "The API Era of Finance",
    publish_date: "2022-03-31",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 52,
    url: "https://www.mx.com/news/global-fintech-series-core-banking-and-infrastructure-service-platforms-making-waves-in-the-industry",
    admin_url: "https://cms.mx.com/admin/content/mx_news/52",
    name: "Global Fintech Series: Core Banking and Infrastructure Service Platforms Making Waves in the Industry",
    publish_date: "2021-01-27",
    last_modified: "2022-04-04T22:39:27.874Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 327,
    url: "https://www.mx.com/blog/reimagining-money-experience-with-open-banking-jane-barratt",
    admin_url: "https://cms.mx.com/admin/content/blog/327",
    name: "Reimagining Money Experiences with Open Banking",
    publish_date: "2022-04-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 328,
    url: "https://www.mx.com/blog/20-questions-with-kimberly-cassady-cpo",
    admin_url: "https://cms.mx.com/admin/content/blog/328",
    name: "20 Questions with MX’s New Chief People Officer, Kimberly Cassady",
    publish_date: "2022-04-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 329,
    url: "https://www.mx.com/blog/meetings-the-challenges-of-crypto",
    admin_url: "https://cms.mx.com/admin/content/blog/329",
    name: "Meeting the Challenges of Crypto",
    publish_date: "2022-04-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 330,
    url: "https://www.mx.com/blog/better-banking-through-open-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/330",
    name: "Better Banking Through Open Finance",
    publish_date: "2022-04-19",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 331,
    url: "https://www.mx.com/blog/delivering-a-better-digital-experience",
    admin_url: "https://cms.mx.com/admin/content/blog/331",
    name: "Delivering a Better Digital Experience with Financial Apps",
    publish_date: "2022-04-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 333,
    url: "https://www.mx.com/blog/cfpb-section-1024-impact-on-financial-ecosystem",
    admin_url: "https://cms.mx.com/admin/content/blog/333",
    name: "How the CFPB’s Announcement on Section 1024 Authority Will Impact the Financial Ecosystem — and Open Finance",
    publish_date: "2022-04-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 334,
    url: "https://www.mx.com/blog/how-open-finance-fuels-innovation-and-the-democratization-of-data",
    admin_url: "https://cms.mx.com/admin/content/blog/334",
    name: "How Open Finance Fuels Innovation and the Democratization of Data",
    publish_date: "2022-05-03",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 335,
    url: "https://www.mx.com/blog/consumer-research-personalized-proactive-insights",
    admin_url: "https://cms.mx.com/admin/content/blog/335",
    name: "Consumers Want a Personalized Approach to Finances",
    publish_date: "2022-05-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 174,
    url: "https://www.mx.com/news/the-financial-brand-gen-z-s-top-priorities-when-selecting-a-financial-services-provider",
    admin_url: "https://cms.mx.com/admin/content/mx_news/174",
    name: "The Financial Brand: Gen Z’s Top Priorities When Selecting a Financial Services Provider",
    publish_date: "2022-01-20",
    last_modified: "2022-05-10T15:34:58.830Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 172,
    url: "https://www.mx.com/news/silicon-slopes-mx-names-president-shane-evans-to-its-board-and-taps-him-as-its-interim-ceo",
    admin_url: "https://cms.mx.com/admin/content/mx_news/172",
    name: "Silicon Slopes: MX Names President Shane Evans to Its Board and Taps Him as its Interim CEO",
    publish_date: "2022-01-18",
    last_modified: "2022-05-10T15:35:08.448Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 157,
    url: "https://www.mx.com/news/pymnts-80-of-consumers-have-a-third-party-financial-app-connected-to-their-bank-account",
    admin_url: "https://cms.mx.com/admin/content/mx_news/157",
    name: "PYMNTS: 80% of Consumers Have a Third-Party Financial App Connected to Their Bank Account",
    publish_date: "2021-11-09",
    last_modified: "2022-05-10T15:37:31.491Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 142,
    url: "https://www.mx.com/news/money-experience-summit-2021-mx-innovator-awards-recognize-industry-leaders",
    admin_url: "https://cms.mx.com/admin/content/mx_news/142",
    name: "Money Experience Summit 2021: MX Innovator Awards Recognize Industry Leaders",
    publish_date: "2021-09-30",
    last_modified: "2022-05-10T15:39:37.433Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 118,
    url: "https://www.mx.com/news/pymnts-data-portability-and-liability-a-game-of-three-dimensional-chess",
    admin_url: "https://cms.mx.com/admin/content/mx_news/118",
    name: "PYMNTS: Data Portability And Liability: A Game Of ‘Three Dimensional Chess’",
    publish_date: "2021-08-05",
    last_modified: "2022-05-10T15:43:10.798Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 111,
    url: "https://www.mx.com/news/what-is-open-banking-and-how-will-it-affect-you",
    admin_url: "https://cms.mx.com/admin/content/mx_news/111",
    name: "Bankrate: What is open banking, and how will it affect you?",
    publish_date: "2021-07-13",
    last_modified: "2022-05-10T15:43:46.229Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 113,
    url: "https://www.mx.com/news/crowd-fund-insider-open-banking-executives-laud-inclusion-of-easier-bank-switching-in-biden-executive-order-on-competition",
    admin_url: "https://cms.mx.com/admin/content/mx_news/113",
    name: "CrowdFund Insider: Open Banking Executives Laud Inclusion of Easier Bank Switching in Biden Executive Order on Competition",
    publish_date: "2021-07-09",
    last_modified: "2022-05-10T15:44:24.171Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 104,
    url: "https://www.mx.com/news/utah-business-how-brandon-dewitt-co-founded-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/104",
    name: "Utah Business: How Brandon Dewitt Co-founded MX",
    publish_date: "2021-06-14",
    last_modified: "2022-05-10T15:55:21.172Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 103,
    url: "https://www.mx.com/news/business-insider-mx-a-way-to-streamline-financial-data",
    admin_url: "https://cms.mx.com/admin/content/mx_news/103",
    name: "Business Insider: MX: a way to streamline financial data",
    publish_date: "2021-06-08",
    last_modified: "2022-05-10T15:55:34.607Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 101,
    url: "https://www.mx.com/news/entrepreneur-3-lessons-about-company-culture-i-learned-from-the-er",
    admin_url: "https://cms.mx.com/admin/content/mx_news/101",
    name: "Entrepreneur: 3 Lessons About Company Culture I Learned From the ER",
    publish_date: "2021-06-06",
    last_modified: "2022-05-10T15:55:53.268Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 97,
    url: "https://www.mx.com/news/benzinga-fintech-spotlight-mx-onboards-lexi-hall-to-empower-regulatory-innovation-in-fintech",
    admin_url: "https://cms.mx.com/admin/content/mx_news/97",
    name: "Benzinga: Fintech Spotlight: MX Onboards Lexi Hall To Empower Regulatory Innovation In Fintech",
    publish_date: "2021-05-21",
    last_modified: "2022-05-10T15:56:29.183Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 95,
    url: "https://www.mx.com/news/tearsheet-how-form-free-used-api-connections-to-decrease-aggregation-time-89-percent",
    admin_url: "https://cms.mx.com/admin/content/mx_news/95",
    name: "Tearsheet: ‘It’s paramount that consumers have a world-class user experience’: How FormFree used API connections to decrease aggregation time 89 percent",
    publish_date: "2021-05-20",
    last_modified: "2022-05-10T15:56:36.645Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 93,
    url: "https://www.mx.com/news/mx-welcomes-lexi-hall-as-director-of-public-policy",
    admin_url: "https://cms.mx.com/admin/content/mx_news/93",
    name: "MX Welcomes Lexi Hall as Director of Public Policy",
    publish_date: "2021-05-19",
    last_modified: "2022-05-10T15:56:54.947Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 81,
    url: "https://www.mx.com/news/ksl-peyton-manning-is-coming-to-utah-for-mx-s-in-person-tech-conference-this-fall",
    admin_url: "https://cms.mx.com/admin/content/mx_news/81",
    name: "KSL: Peyton Manning is coming to Utah for MX's in-person tech conference this fall",
    publish_date: "2021-04-01",
    last_modified: "2022-05-10T15:57:54.963Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 77,
    url: "https://www.mx.com/news/utah-business-do-we-really-want-to-be-silicon-valley-or-can-we-be-better",
    admin_url: "https://cms.mx.com/admin/content/mx_news/77",
    name: "Utah Business: Do we really want to be Silicon Valley? Or can we be better?",
    publish_date: "2021-03-26",
    last_modified: "2022-05-10T15:58:30.781Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 336,
    url: "https://www.mx.com/blog/5-stages-of-open-finance-and-unlocking-the-power-of-financial-data",
    admin_url: "https://cms.mx.com/admin/content/blog/336",
    name: "5 Stages of Open Finance: Unlocking the Power of Financial Data",
    publish_date: "2022-05-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 337,
    url: "https://www.mx.com/blog/the-importance-of-seamless-connectivity",
    admin_url: "https://cms.mx.com/admin/content/blog/337",
    name: "The Importance of Seamless Connectivity",
    publish_date: "2022-05-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 338,
    url: "https://www.mx.com/blog/beyond-open-banking-open-finance-not-just-for-finance-companies",
    admin_url: "https://cms.mx.com/admin/content/blog/338",
    name: "Beyond Open Banking: Is Open Finance just for Finance Companies?",
    publish_date: "2022-05-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 11,
    url: "https://www.mx.com/news/forbes-13-ways-tech-leaders-can-support-their-developers-side-projects",
    admin_url: "https://cms.mx.com/admin/content/mx_news/11",
    name: "Forbes: 13 Ways Tech Leaders Can Support Their Developers' Side Projects",
    publish_date: "2020-12-18",
    last_modified: "2022-05-20T20:28:01.064Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 195,
    url: "https://www.mx.com/news/new-mx-research-shows-70-of-respondents-expect-their-financial-provider-to-give-them-personalized-insights-",
    admin_url: "https://cms.mx.com/admin/content/mx_news/195",
    name: "New MX Research Shows 70% of Respondents Expect Their Financial Provider to Give Them Personalized Insights",
    publish_date: "2022-04-20",
    last_modified: "2022-05-20T21:36:29.879Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 191,
    url: "https://www.mx.com/news/qolo-partners-with-mx-to-help-fintechs-and-neobanks-scale-faster",
    admin_url: "https://cms.mx.com/admin/content/mx_news/191",
    name: "Qolo Partners with MX to Help Fintechs and Neobanks Scale Faster",
    publish_date: "2022-04-01",
    last_modified: "2022-05-20T21:36:48.044Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 186,
    url: "https://www.mx.com/news/mx-and-university-of-wisconsin-credit-union-partner-to-improve-member-data-access-to-their-favorite-apps-and-services",
    admin_url: "https://cms.mx.com/admin/content/mx_news/186",
    name: "MX and University of Wisconsin Credit Union Partner to Improve Member Data Access to Their Favorite Apps and Services",
    publish_date: "2022-03-11",
    last_modified: "2022-05-20T21:37:03.212Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 182,
    url: "https://www.mx.com/news/rize-and-mx-partner-to-provide-fintechs-and-neobanks-with-embedded-finance-for-better-data-insights-and-banking-capabilities",
    admin_url: "https://cms.mx.com/admin/content/mx_news/182",
    name: "Rize and MX Partner to Provide Fintechs and Neobanks with Embedded Finance for Better Data Insights and Banking Capabilities",
    publish_date: "2022-02-25",
    last_modified: "2022-05-20T21:37:11.217Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 147,
    url: "https://www.mx.com/news/mx-names-shawn-lindquist-chief-legal-officer",
    admin_url: "https://cms.mx.com/admin/content/mx_news/147",
    name: "MX Names Shawn Lindquist Chief Legal Officer",
    publish_date: "2021-10-13",
    last_modified: "2022-05-20T21:39:29.825Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 105,
    url: "https://www.mx.com/news/libro-credit-union-teams-with-mx-to-empower-financial-wellness-in-southwestern-ontario-",
    admin_url: "https://cms.mx.com/admin/content/mx_news/105",
    name: "Libro Credit Union teams with MX to empower financial wellness in southwestern Ontario",
    publish_date: "2021-06-16",
    last_modified: "2022-05-20T21:41:20.207Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 17,
    url: "https://www.mx.com/news/mx-names-james-dotter-as-company-s-first-chief-business-officer",
    admin_url: "https://cms.mx.com/admin/content/mx_news/17",
    name: "MX Names James Dotter As Company’s First Chief Business Officer",
    publish_date: "2021-03-04",
    last_modified: "2022-05-20T21:42:40.823Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 6,
    url: "https://www.mx.com/news/forbes-11-jobs-that-may-be-automated-in-the-next-decade",
    admin_url: "https://cms.mx.com/admin/content/mx_news/6",
    name: "Forbes: 11 Jobs That May Be Automated In The Next Decade",
    publish_date: "2021-02-23",
    last_modified: "2022-05-20T21:42:51.970Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 4,
    url: "https://www.mx.com/news/mx-partners-with-hydrogen-to-drastically-cut-development-time-for-embedded-finance",
    admin_url: "https://cms.mx.com/admin/content/mx_news/4",
    name: "MX Partners with Hydrogen to Drastically Cut Development Time for Embedded Finance",
    publish_date: "2021-01-05",
    last_modified: "2022-05-20T21:43:21.018Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 41,
    url: "https://www.mx.com/news/vystar-credit-union-chooses-mx-to-enhance-online-experience-for-members",
    admin_url: "https://cms.mx.com/admin/content/mx_news/41",
    name: "VyStar Credit Union Chooses MX to Enhance Online Experience for Members",
    publish_date: "2020-12-02",
    last_modified: "2022-05-20T21:43:27.620Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 193,
    url: "https://www.mx.com/news/mx-and-boss-insights-partner-to-accelerate-business-lending-and-insights-with-access-to-real-time-financial-data",
    admin_url: "https://cms.mx.com/admin/content/mx_news/193",
    name: "MX and Boss Insights Partner to Accelerate Business Lending and Insights with Access to Real-time Financial Data",
    publish_date: "2022-04-14",
    last_modified: "2022-05-20T21:46:23.579Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 181,
    url: "https://www.mx.com/news/deposits-com-selects-mx-to-advance-the-pace-of-financial-inclusion-in-underserved-communities",
    admin_url: "https://cms.mx.com/admin/content/mx_news/181",
    name: "Deposits.com Selects MX to Advance the Pace of Financial Inclusion in Underserved Communities",
    publish_date: "2022-02-22",
    last_modified: "2022-05-20T21:48:32.011Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 175,
    url: "https://www.mx.com/news/cadence-bank-increases-interchange-revenue-with-data-insights-from-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/175",
    name: "Cadence Bank Increases Interchange Revenue with Data Insights from MX",
    publish_date: "2022-02-02",
    last_modified: "2022-05-20T21:48:46.356Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 158,
    url: "https://www.mx.com/news/introducing-the-open-finance-data-security-standard-ofdss",
    admin_url: "https://cms.mx.com/admin/content/mx_news/158",
    name: "Introducing the Open Finance Data Security Standard (OFDSS)",
    publish_date: "2021-11-16",
    last_modified: "2022-05-20T21:49:31.088Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 151,
    url: "https://www.mx.com/news/mx-powers-a-better-smarter-more-secure-data-sharing-experience-with-m-xaccess",
    admin_url: "https://cms.mx.com/admin/content/mx_news/151",
    name: "MX Powers a Better, Smarter, More Secure Data Sharing Experience with MXaccess",
    publish_date: "2021-10-27",
    last_modified: "2022-05-20T21:49:44.293Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 149,
    url: "https://www.mx.com/news/mx-introduces-m-xdata-for-business",
    admin_url: "https://cms.mx.com/admin/content/mx_news/149",
    name: "MX Introduces MXdata for Business",
    publish_date: "2021-10-20",
    last_modified: "2022-05-20T21:49:50.354Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 141,
    url: "https://www.mx.com/news/new-m-xmobile-helps-financial-institutions-and-fintechs-deliver-personalized-mobile-banking-experiences",
    admin_url: "https://cms.mx.com/admin/content/mx_news/141",
    name: "New MXmobile Helps Financial Institutions And Fintechs Deliver Personalized Mobile Banking Experiences",
    publish_date: "2021-09-29",
    last_modified: "2022-05-20T21:50:43.960Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 140,
    url: "https://www.mx.com/news/suma-wealth-selects-mx-to-power-its-data-platform-focused-on-building-financial-wealth-in-the-latinx-community",
    admin_url: "https://cms.mx.com/admin/content/mx_news/140",
    name: "SUMA Wealth Selects MX to Power its Data Platform Focused on Building Financial Wealth in the Latinx Community",
    publish_date: "2021-09-28",
    last_modified: "2022-05-20T21:50:51.999Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 136,
    url: "https://www.mx.com/news/mx-and-mahalo-technologies-partner-to-enhance-connectivity-data-and-experience-for-credit-unions-and-their-members",
    admin_url: "https://cms.mx.com/admin/content/mx_news/136",
    name: "MX and Mahalo Technologies Partner to Enhance Connectivity, Data and Experience for Credit Unions and Their Members",
    publish_date: "2021-09-17",
    last_modified: "2022-05-20T21:51:09.176Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 134,
    url: "https://www.mx.com/news/mx-helps-orbis-pay-users-get-wages-faster",
    admin_url: "https://cms.mx.com/admin/content/mx_news/134",
    name: "MX Helps OrbisPay Users Get Wages Faster",
    publish_date: "2021-09-15",
    last_modified: "2022-05-20T21:51:15.017Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 132,
    url: "https://www.mx.com/news/mx-and-payveris-bring-faster-open-api-digital-payments-and-money-movement-to-fintechs-and-financial-institutions-",
    admin_url: "https://cms.mx.com/admin/content/mx_news/132",
    name: "MX and Payveris Bring Faster, Open-API, Digital Payments and Money Movement to Fintechs and Financial Institutions",
    publish_date: "2021-09-08",
    last_modified: "2022-05-20T21:51:20.649Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 125,
    url: "https://www.mx.com/news/mx-partners-with-goalry-to-help-people-improve-their-lives-through-their-financial-goals",
    admin_url: "https://cms.mx.com/admin/content/mx_news/125",
    name: "MX Partners With Goalry to Help People Improve their Lives through their Financial Goals",
    publish_date: "2021-08-19",
    last_modified: "2022-05-20T21:51:32.879Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 123,
    url: "https://www.mx.com/news/mx-announces-5-time-olympian-and-gold-medalist-allyson-felix-to-keynote-money-experience-summit-2021",
    admin_url: "https://cms.mx.com/admin/content/mx_news/123",
    name: "MX Announces 5-Time Olympian and Gold Medalist Allyson Felix to Keynote Money Experience Summit 2021",
    publish_date: "2021-08-17",
    last_modified: "2022-05-20T21:51:44.957Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 110,
    url: "https://www.mx.com/news/mx-launches-new-financial-insights-ap-is-to-power-financial-wellness-and-accelerate-open-finance-",
    admin_url: "https://cms.mx.com/admin/content/mx_news/110",
    name: "MX Launches New Financial Insights APIs to Power Financial Wellness and Accelerate Open Finance",
    publish_date: "2021-07-13",
    last_modified: "2022-05-20T21:52:08.502Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 99,
    url: "https://www.mx.com/news/shane-evans-named-president-of-mx-to-continue-rapid-growth-and-expansion-of-the-money-experience-category",
    admin_url: "https://cms.mx.com/admin/content/mx_news/99",
    name: "Shane Evans Named President of MX to Continue Rapid Growth and Expansion of the Money Experience Category",
    publish_date: "2021-06-02",
    last_modified: "2022-05-20T21:52:20.167Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 96,
    url: "https://www.mx.com/news/viva-first-selects-mx-to-power-its-mobile-banking-app-focused-on-building-financial-wellness-in-the-latino-community-",
    admin_url: "https://cms.mx.com/admin/content/mx_news/96",
    name: "Viva First Selects MX to Power its Mobile Banking App Focused on Building Financial Wellness in the Latino Community",
    publish_date: "2021-05-24",
    last_modified: "2022-05-20T21:52:32.404Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 75,
    url: "https://www.mx.com/news/abby-bank-launches-new-tool-powered-by-mx-to-help-members-improve-their-financial-lives",
    admin_url: "https://cms.mx.com/admin/content/mx_news/75",
    name: "AbbyBank Launches New Tool Powered by MX to Help Members Improve Their Financial Lives",
    publish_date: "2021-03-29",
    last_modified: "2022-05-20T21:52:50.745Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 24,
    url: "https://www.mx.com/news/mx-raises-300-million-in-series-c-funding",
    admin_url: "https://cms.mx.com/admin/content/mx_news/24",
    name: "MX Raises $300 Million in Series C Funding, Increases Valuation to $1.9 Billion",
    publish_date: "2021-01-13",
    last_modified: "2022-05-20T21:53:22.082Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 339,
    url: "https://www.mx.com/blog/5-reasons-to-love-open-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/339",
    name: "5 Reasons to Love Open Finance",
    publish_date: "2022-05-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 163,
    url: "https://www.mx.com/news/first-foundation-bank-launches-new-mobile-app-with-m-xmobile-simplifying-the-way-clients-get-financial-insights",
    admin_url: "https://cms.mx.com/admin/content/mx_news/163",
    name: "First Foundation Bank Launches New Mobile App with MXmobile, Simplifying the Way Clients Get Financial Insights",
    publish_date: "2021-12-06",
    last_modified: "2022-05-25T15:41:09.679Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 150,
    url: "https://www.mx.com/news/mx-and-up-swot-bring-modern-connectivity-financial-insights-analytics-and-dashboards-to-small-and-midsize-businesses",
    admin_url: "https://cms.mx.com/admin/content/mx_news/150",
    name: "MX and upSWOT Bring Modern Connectivity, Financial Insights, Analytics and Dashboards to Small and Midsize Businesses",
    publish_date: "2021-12-09",
    last_modified: "2022-05-25T15:42:30.012Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 341,
    url: "https://www.mx.com/blog/open-banking-data-aggregation-5-answers-from-cornerstone-research",
    admin_url: "https://cms.mx.com/admin/content/blog/341",
    name: "Open Banking Data Aggregation: 5 Answers from Cornerstone Research",
    publish_date: "2022-06-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 342,
    url: "https://www.mx.com/blog/how-to-choose-an-open-banking-data-partner",
    admin_url: "https://cms.mx.com/admin/content/blog/342",
    name: "How to Choose an Open Banking Data Partner",
    publish_date: "2022-06-03",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 343,
    url: "https://www.mx.com/blog/a-roadmap-for-choosing-a-data-partner",
    admin_url: "https://cms.mx.com/admin/content/blog/343",
    name: "Real-World Experiences: A Roadmap for Choosing a Data Partner",
    publish_date: "2022-06-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 344,
    url: "https://www.mx.com/blog/creating-a-fair-and-competitive-financial-environment",
    admin_url: "https://cms.mx.com/admin/content/blog/344",
    name: "Creating a Fair and Competitive Financial Environment",
    publish_date: "2022-06-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 347,
    url: "https://www.mx.com/blog/open-banking-data-agg-qs",
    admin_url: "https://cms.mx.com/admin/content/blog/347",
    name: "Answers to Your Open Banking Data Aggregation Questions",
    publish_date: "2022-06-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "demo_pages",
    id: 2,
    url: "https://www.mx.com/demo/choosing-the-right-data-aggregator",
    admin_url: "https://cms.mx.com/admin/content/demo_pages/2",
    name: "Choosing the Right Data Aggregator",
    publish_date: "2022-06-15T02:50:11.945Z",
    last_modified: "2022-06-15T16:20:20.193Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 348,
    url: "https://www.mx.com/blog/financial-innovation-open-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/348",
    name: "Driving A New Era of Financial Innovation",
    publish_date: "2022-06-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 349,
    url: "https://www.mx.com/blog/justdewitt-fintechdevcon-scholarship",
    admin_url: "https://cms.mx.com/admin/content/blog/349",
    name: "Announcing the #JustDewitt scholarship for fintech_devcon",
    publish_date: "2022-06-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 350,
    url: "https://www.mx.com/blog/a-guide-to-data-utilization",
    admin_url: "https://cms.mx.com/admin/content/blog/350",
    name: "Mind the Gap — A Guide to Data Utilization",
    publish_date: "2022-06-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 351,
    url: "https://www.mx.com/blog/mx-pride-2022",
    admin_url: "https://cms.mx.com/admin/content/blog/351",
    name: "MX Pride: We Are All MX",
    publish_date: "2022-06-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 352,
    url: "https://www.mx.com/blog/mx-observes-juneteenth",
    admin_url: "https://cms.mx.com/admin/content/blog/352",
    name: "MX Observes Juneteenth: Educate, Act, Reflect",
    publish_date: "2022-06-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 353,
    url: "https://www.mx.com/blog/enabling-financial-success-during-crisis-with-open-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/353",
    name: "Enabling Financial Success During Crisis with Open Finance",
    publish_date: "2022-07-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 354,
    url: "https://www.mx.com/blog/financial-data-the-key-to-surviving-inflation",
    admin_url: "https://cms.mx.com/admin/content/blog/354",
    name: "Is Financial Data the Key to Surviving Record Inflation?",
    publish_date: "2022-07-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 355,
    url: "https://www.mx.com/blog/fintech-and-finance-leaders-riffing-on-community-open-banking-payments-and-more",
    admin_url: "https://cms.mx.com/admin/content/blog/355",
    name: "Fintech and Finance Leaders Riffing on Community, Open Banking, Payments, and More",
    publish_date: "2022-07-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 356,
    url: "https://www.mx.com/blog/spotlight-on-success-q2-rockstar-employees",
    admin_url: "https://cms.mx.com/admin/content/blog/356",
    name: "Spotlight on Success: Q2 Rockstar Employees",
    publish_date: "2022-07-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 357,
    url: "https://www.mx.com/blog/secure-data-access-is-the-key-to-an-open-finance-ecosystem",
    admin_url: "https://cms.mx.com/admin/content/blog/357",
    name: "Secure Data Access is the Key to an Open Finance Ecosystem",
    publish_date: "2022-07-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 358,
    url: "https://www.mx.com/blog/riding-the-wave-of-financial-wellness-means-building-education-community-and-trust",
    admin_url: "https://cms.mx.com/admin/content/blog/358",
    name: "Riding the Wave of Financial Wellness Means Building Education, Community, and Trust",
    publish_date: "2022-07-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 222,
    url: "https://www.mx.com/news/mx-powers-instant-account-verifications-and-enhanced-data-for-future-pay-",
    admin_url: "https://cms.mx.com/admin/content/mx_news/222",
    name: "MX Powers Instant Account Verifications and Enhanced Data for FuturePay",
    publish_date: "2022-07-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 360,
    url: "https://www.mx.com/blog/forrester-financial-wellbeing-as-core-strategy",
    admin_url: "https://cms.mx.com/admin/content/blog/360",
    name: "Financial Well-Being: A Core Strategy for Financial Services",
    publish_date: "2022-07-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 223,
    url: "https://www.mx.com/news/sionic-and-mx-partner-to-simply-and-safely-link-us-consumer-bank-accounts-for-direct-to-merchant-payments-at-the-point-of-sale-pos",
    admin_url: "https://cms.mx.com/admin/content/mx_news/223",
    name: "Sionic and MX Partner to Simply and Safely Link US Consumer Bank Accounts for Direct-to-Merchant Payments at the Point of Sale (POS)",
    publish_date: "2022-07-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 362,
    url: "https://www.mx.com/blog/whats-next-for-mobile-banking-more-of-everything",
    admin_url: "https://cms.mx.com/admin/content/blog/362",
    name: "What’s Next in Mobile Banking? More of Everything",
    publish_date: "2022-07-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 226,
    url: "https://www.mx.com/news/guild-financial-connects-with-mx-to-help-military-community-build-personal-wealth",
    admin_url: "https://cms.mx.com/admin/content/mx_news/226",
    name: "Guild Financial Connects with MX to Help Military Community Build Personal Wealth",
    publish_date: "2022-08-11",
    last_modified: "2022-08-11T13:58:06.363Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 229,
    url: "https://www.mx.com/news/new-mx-research-shows-40-of-consumers-believe-current-financial-situation-has-changed-for-the-worse-due-to-rising-costs",
    admin_url: "https://cms.mx.com/admin/content/mx_news/229",
    name: "New MX Research Shows 40% of Consumers Believe Current Financial Situation has Changed for the Worse Due to Rising Costs",
    publish_date: "2022-08-23",
    last_modified: "2022-08-23T19:31:12.997Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 363,
    url: "https://www.mx.com/blog/the-current-state-of-consumer-finances",
    admin_url: "https://cms.mx.com/admin/content/blog/363",
    name: "The Current State of Consumer Finances",
    publish_date: "2022-08-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 364,
    url: "https://www.mx.com/blog/connect-consumers-to-financial-accounts-safely-securely",
    admin_url: "https://cms.mx.com/admin/content/blog/364",
    name: "Let Us Help You Connect Consumers to Their Financial Accounts Safely and Securely",
    publish_date: "2022-08-29",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 365,
    url: "https://www.mx.com/blog/the-ongoing-digital-banking-transformation",
    admin_url: "https://cms.mx.com/admin/content/blog/365",
    name: "The Ongoing Digital Banking Transformation",
    publish_date: "2022-08-31",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 366,
    url: "https://www.mx.com/blog/apis-an-expanding-foundation-for-digital-business",
    admin_url: "https://cms.mx.com/admin/content/blog/366",
    name: "APIs — An Expanding Foundation for Digital Business",
    publish_date: "2022-09-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 372,
    url: "https://www.mx.com/blog/open-banking-relationships",
    admin_url: "https://cms.mx.com/admin/content/blog/372",
    name: "In Banking, the Best Relationships are Open",
    publish_date: "2022-09-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 373,
    url: "https://www.mx.com/blog/outcome-based-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/373",
    name: "What is Outcome-Based Banking?",
    publish_date: "2022-09-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 367,
    url: "https://www.mx.com/blog/gen-z-money-habits-credit-card-vs-mobile-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/367",
    name: "Gen Z Money Habits — Credit Card VS. Mobile Banking",
    publish_date: "2022-09-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 368,
    url: "https://www.mx.com/blog/the-roadblocks-to-financial-health",
    admin_url: "https://cms.mx.com/admin/content/blog/368",
    name: "The Roadblocks to Financial Health",
    publish_date: "2022-09-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 369,
    url: "https://www.mx.com/blog/three-keys-to-building-consumer-trust",
    admin_url: "https://cms.mx.com/admin/content/blog/369",
    name: "Three Keys to Building Consumer Trust",
    publish_date: "2022-09-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 370,
    url: "https://www.mx.com/blog/talking-fintech-definitions-from-a-to-d",
    admin_url: "https://cms.mx.com/admin/content/blog/370",
    name: "Talking Fintech: Definitions from A to D",
    publish_date: "2022-09-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 78,
    url: "https://www.mx.com/summit/2022/",
    admin_url: "https://cms.mx.com/admin/content/webinars/78",
    name: "MXS Rewind 2022",
    publish_date: "2022-09-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 371,
    url: "https://www.mx.com/blog/money-matters-key-takeaways-from-money-experience-summit-2022",
    admin_url: "https://cms.mx.com/admin/content/blog/371",
    name: "Money Matters: Key Takeaways from Money Experience Summit 2022",
    publish_date: "2022-09-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 374,
    url: "https://www.mx.com/blog/new-cto-and-cpo-talk-financial-wellness-and-open-data",
    admin_url: "https://cms.mx.com/admin/content/blog/374",
    name: "Why MX? New CTO and Chief Product Officer Talk Financial Wellness and Open Data",
    publish_date: "2022-10-05",
    last_modified: null,
    review: true,
  },
  {
    id: 539,
    status: "published",
    date_created: "2025-02-28T22:46:11.605Z",
    path: "data-protection-terms/index.html",
    url: "https://www.mx.com/data-protection-terms/",
    last_modified: "2022-10-07",
    publish_date: "2022-05-12",
    review: true,
  },
  {
    id: 557,
    status: "published",
    date_created: "2025-02-28T22:46:13.548Z",
    path: "mx-security-addendum/index.html",
    url: "https://www.mx.com/mx-security-addendum/",
    last_modified: "2022-10-07",
    publish_date: "2022-05-12",
    review: true,
  },
  {
    id: 612,
    status: "published",
    date_created: "2025-02-28T22:46:19.395Z",
    path: "service-level-agreement/index.html",
    url: "https://www.mx.com/service-level-agreement/",
    last_modified: "2022-10-07",
    publish_date: "2022-05-12",
    review: true,
  },
  {
    id: 617,
    status: "published",
    date_created: "2025-02-28T22:46:19.897Z",
    path: "third-party-data-provider-terms/index.html",
    url: "https://www.mx.com/third-party-data-provider-terms/",
    last_modified: "2022-10-07",
    publish_date: "2022-05-12",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 375,
    url: "https://www.mx.com/blog/addressing-the-role-of-gender-in-finances",
    admin_url: "https://cms.mx.com/admin/content/blog/375",
    name: "Addressing the Role of Gender in Finances",
    publish_date: "2022-10-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 90,
    url: "https://www.mx.com/news/forbes-the-best-banks-for-improving-consumers-financial-health-and-why-this-is-a-winning-strategy",
    admin_url: "https://cms.mx.com/admin/content/mx_news/90",
    name: "Forbes: The Best Banks For Improving Consumers’ Financial Health (And Why This Is A Winning Strategy)",
    publish_date: "2021-04-26",
    last_modified: "2022-10-07T20:42:15.029Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 2,
    url: "https://www.mx.com/news/business-insider-wall-street-people-moves-of-the-week-heres-a-rundown-of-promotions-and-new-hires-at-firms-like-goldman-sachs-brevan-howard-and-citi",
    admin_url: "https://cms.mx.com/admin/content/mx_news/2",
    name: "Business Insider: Wall Street people moves of the week: Here's a rundown of promotions and new hires at firms like Goldman Sachs, Brevan Howard, and Citi",
    publish_date: "2021-02-13",
    last_modified: "2022-10-10T14:36:48.374Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 376,
    url: "https://www.mx.com/blog/talking-fintech-definitions-from-e-to-m",
    admin_url: "https://cms.mx.com/admin/content/blog/376",
    name: "Talking Fintech: Definitions from E to M",
    publish_date: "2022-10-11",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 69,
    url: "https://www.mx.com/news/bank-director-why-banks-should-focus-on-financial-strength-in-2021",
    admin_url: "https://cms.mx.com/admin/content/mx_news/69",
    name: "Bank Director: Why Banks Should Focus on Financial Strength in 2021",
    publish_date: "2021-01-05",
    last_modified: "2022-10-13T16:47:30.038Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 197,
    url: "https://www.mx.com/news/klari-vis-and-open-finance-leader-mx-partner-to-serve-financial-institutions-with-data-driven-insights",
    admin_url: "https://cms.mx.com/admin/content/mx_news/197",
    name: "KlariVis and Open Finance Leader MX Partner to Serve Financial Institutions with Data-Driven Insights",
    publish_date: "2022-04-28",
    last_modified: "2022-10-13T20:55:18.950Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 82,
    url: "https://www.mx.com/news/tearsheet-4-charts-on-consumers-increased-demand-for-digital-and-personalized-banking-solutions",
    admin_url: "https://cms.mx.com/admin/content/mx_news/82",
    name: "Tearsheet: 4 charts on consumers’ increased demand for digital and personalized banking solutions",
    publish_date: "2021-04-09",
    last_modified: "2022-10-13T20:56:53.436Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 377,
    url: "https://www.mx.com/blog/our-greatest-opportunity-is-open-data",
    admin_url: "https://cms.mx.com/admin/content/blog/377",
    name: "Our Greatest Opportunity is Open Data",
    publish_date: "2022-10-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 378,
    url: "https://www.mx.com/blog/pardon-the-finterruption-embedded-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/378",
    name: "The Death of Bank Branches and Rise of Embedded Finance",
    publish_date: "2022-10-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 379,
    url: "https://www.mx.com/blog/banks-needs-to-embrace-not-ignore-fintech-disruption",
    admin_url: "https://cms.mx.com/admin/content/blog/379",
    name: "Banks Needs to Embrace, Not Ignore Fintech Disruption",
    publish_date: "2022-10-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 380,
    url: "https://www.mx.com/blog/the-evolution-of-consumer-spending-and-saving",
    admin_url: "https://cms.mx.com/admin/content/blog/380",
    name: "The Evolution of Consumer Spending and Saving",
    publish_date: "2022-11-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 381,
    url: "https://www.mx.com/blog/understanding-the-financial-challenges-of-gig-workers",
    admin_url: "https://cms.mx.com/admin/content/blog/381",
    name: "Understanding the Financial Challenges of Gig Workers",
    publish_date: "2022-11-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 382,
    url: "https://www.mx.com/blog/next-modern-call-for-speed",
    admin_url: "https://cms.mx.com/admin/content/blog/382",
    name: "Modernization = Speed?",
    publish_date: "2022-11-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 383,
    url: "https://www.mx.com/blog/how-lenders-can-meet-borrower-expectations-in-today-s-financial-climate",
    admin_url: "https://cms.mx.com/admin/content/blog/383",
    name: "How Lenders Can Meet Borrower Expectations in Today’s Financial Climate",
    publish_date: "2022-11-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 384,
    url: "https://www.mx.com/blog/season-for-increased-fraud-risk",
    admin_url: "https://cms.mx.com/admin/content/blog/384",
    name: "Tis the Season…for Increased Fraud Risk",
    publish_date: "2022-11-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 294,
    url: "https://www.mx.com/news/brandon-dewitt-interviewed-by-american-banker/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/294",
    name: "Brandon Dewitt interviewed by American Banker",
    publish_date: "2018-07-25",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 313,
    url: "https://www.mx.com/news/bbva-compass-tells-american-banker-how-their-mobile-pfm-usage-has-shot-up-with-mx/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/313",
    name: "BBVA Compass tells American Banker how their mobile PFM usage has shot up with MX",
    publish_date: "2018-10-19",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 322,
    url: "https://www.mx.com/news/a-look-inside-mxs-flat-organizational-structure/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/322",
    name: "A look inside MX's flat organizational structure",
    publish_date: "2018-12-04",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 328,
    url: "https://www.mx.com/news/bankrate-story-spotlights-mx-digital-money-management-tools/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/328",
    name: "Bankrate story spotlights MX digital money management tools",
    publish_date: "2019-01-17",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 335,
    url: "https://www.mx.com/news/american-banker-reports-on-mx-partnership-with-personetics/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/335",
    name: "American Banker reports on MX partnership with Personetics",
    publish_date: "2019-03-11",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 336,
    url: "https://www.mx.com/news/american-banker-spotlights-mx-engineers-teaching-refugees/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/336",
    name: "American Banker spotlights MX engineers teaching refugees",
    publish_date: "2019-03-06",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 369,
    url: "https://www.mx.com/news/bankrate-features-mx-personetics-in-article/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/369",
    name: "Bankrate features MX-Personetics in article",
    publish_date: "2019-05-21",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 385,
    url: "https://www.mx.com/news/abc-4-story-features-mx-and-patriot-boot-camp/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/385",
    name: "ABC 4 story features MX and Patriot Boot Camp",
    publish_date: "2019-09-05",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 392,
    url: "https://www.mx.com/news/the-end-goal-is-to-improve-peoples-lives.-brandon-dewitt-to-american-banker/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/392",
    name: "'The end goal is to improve people's lives.' - Brandon Dewitt to American Banker",
    publish_date: "2019-09-25",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 398,
    url: "https://www.mx.com/news/brandon-dewitt-discusses-changing-the-future-of-banking-on-live-webinar-at-money20/20/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/398",
    name: "Brandon Dewitt discusses 'Changing the Future of Banking' on live webinar at Money20/20",
    publish_date: "2019-10-28",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 409,
    url: "https://www.mx.com/news/mx-study-finds-more-than-80-percent-of-u-s-consumers-use-mobile-banking-weekly",
    admin_url: "https://cms.mx.com/admin/content/mx_news/409",
    name: "MX Study Finds More Than 80 Percent of U.S. Consumers Use Mobile Banking Weekly",
    publish_date: "2019-11-20",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 411,
    url: "https://www.mx.com/news/alaska-usa-federal-credit-union-selects-mx/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/411",
    name: "Alaska USA Federal Credit Union Selects MX for its mobile banking platform ",
    publish_date: "2019-12-17",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 417,
    url: "https://www.mx.com/news/women-in-financial-services-tackling-underserved-market",
    admin_url: "https://cms.mx.com/admin/content/mx_news/417",
    name: "Quartz: Women in financial services are tackling an underserved market: themselves",
    publish_date: "2020-01-17",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 421,
    url: "https://www.mx.com/news/bankrate-out-of-control-with-your-holiday-spending-have-your-bank-app-stop-you/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/421",
    name: "Bankrate: Out of control with your holiday spending? Have your bank app stop you",
    publish_date: "2019-12-19",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 422,
    url: "https://www.mx.com/news/what-clients-crave-in-digital-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/422",
    name: "Financial Planning: What clients crave in digital banking",
    publish_date: "2020-01-21",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 423,
    url: "https://www.mx.com/news/central-pacific-partners-with-mx-to-build-better-bank",
    admin_url: "https://cms.mx.com/admin/content/mx_news/423",
    name: "Central Pacific Bank Partners with MX to Build a Better Bank",
    publish_date: "2020-01-16",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 424,
    url: "https://www.mx.com/news/mx-promotes-tech-industry-as-title-plus",
    admin_url: "https://cms.mx.com/admin/content/mx_news/424",
    name: "MX Promotes Tech Industry in Utah as Title Plus Sponsor of Silicon Slopes Summit",
    publish_date: "2020-01-23",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 425,
    url: "https://www.mx.com/news/financial-brand-consumers-views-on-future",
    admin_url: "https://cms.mx.com/admin/content/mx_news/425",
    name: "The Financial Brand: Consumers’ Surprising Views on the Future of Banking",
    publish_date: "2020-01-27",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 426,
    url: "https://www.mx.com/news/tips-to-help-tech-leaders-nail-their-goals",
    admin_url: "https://cms.mx.com/admin/content/mx_news/426",
    name: "Forbes: 14 Tips To Help Tech Leaders Nail Their Q1 Goals",
    publish_date: "2020-01-28",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 427,
    url: "https://www.mx.com/news/silicon-slopes-interview-with-ryan-caldwell",
    admin_url: "https://cms.mx.com/admin/content/mx_news/427",
    name: "Nasdaq: Silicon Slopes interview with Ryan Caldwell",
    publish_date: "2020-01-30",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 428,
    url: "https://www.mx.com/news/bank-director-virtues-of-growth",
    admin_url: "https://cms.mx.com/admin/content/mx_news/428",
    name: "Bank Director: The Virtues of Growth",
    publish_date: "2020-01-30",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 430,
    url: "https://www.mx.com/news/digital-only-banking-is-super-buzzy",
    admin_url: "https://cms.mx.com/admin/content/mx_news/430",
    name: "Business Insider: Digital-only banking is super buzzy, but consumers don't think branches are going away any time soon",
    publish_date: "2020-01-17",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 431,
    url: "https://www.mx.com/news/silicon-slopes-the-power-of-culture-be-deliberate-or-be-disrupted",
    admin_url: "https://cms.mx.com/admin/content/mx_news/431",
    name: "Silicon Slopes: The Power of Culture: Be Deliberate or Be Disrupted",
    publish_date: "2020-02-13",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 432,
    url: "https://www.mx.com/news/how-banks-credit-unions-can-turn-good-service-into-awesome-cx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/432",
    name: "The Financial Brand: How Banks & Credit Unions Can Turn Good Service Into Awesome CX",
    publish_date: "2020-02-24",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 433,
    url: "https://www.mx.com/news/independent-banker-secrets-of-community-bankfintech-partnerships",
    admin_url: "https://cms.mx.com/admin/content/mx_news/433",
    name: "Independent Banker: Secrets of 4 community bank-fintech partnerships",
    publish_date: "2020-02-10",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 434,
    url: "https://www.mx.com/news/forbes-the-next-revolution-in-personal-finance-the-financial-feed",
    admin_url: "https://cms.mx.com/admin/content/mx_news/434",
    name: "Forbes: The Next Revolution In Personal Finance: The Financial Feed",
    publish_date: "2020-03-02",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 436,
    url: "https://www.mx.com/news/mx-named-to-2020-shatter-list",
    admin_url: "https://cms.mx.com/admin/content/mx_news/436",
    name: "MX Named to 2020 Shatter List for Helping Break Glass Ceiling for Women in Technology",
    publish_date: "2020-03-05",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 440,
    url: "https://www.mx.com/news/2020-03-11-mx-hosts-first-annual-banking-transformation-week",
    admin_url: "https://cms.mx.com/admin/content/mx_news/440",
    name: "MX Hosts First Annual Banking Transformation Week",
    publish_date: "2020-03-11",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 442,
    url: "https://www.mx.com/news/2020-03-30-medium-fintech-startups-to-watch-out-for",
    admin_url: "https://cms.mx.com/admin/content/mx_news/442",
    name: "Medium: Fintech startups to watch out for",
    publish_date: "2020-03-27",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 443,
    url: "https://www.mx.com/news/protect-your-finances-from-economic-uncertainty",
    admin_url: "https://cms.mx.com/admin/content/mx_news/443",
    name: "Fast Company: 4 easy things to do now to protect your finances from economic uncertainty",
    publish_date: "2020-03-18",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 444,
    url: "https://www.mx.com/news/forbes-banks-playbook-for-getting-through-the-coronavirus-crisis",
    admin_url: "https://cms.mx.com/admin/content/mx_news/444",
    name: "Forbes: Banks’ Playbook For Getting Through The Coronavirus Crisis",
    publish_date: "2020-03-31",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 445,
    url: "https://www.mx.com/news/economic-uncertainty-drives-percent-increase-in-mobile-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/445",
    name: "Economic uncertainty drives 50 percent increase in mobile banking, according to MX findings",
    publish_date: "2020-04-02",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 446,
    url: "https://www.mx.com/news/bank-director-covid-a-makeorbreak-moment-for-customer-loyalty",
    admin_url: "https://cms.mx.com/admin/content/mx_news/446",
    name: "Bank Director: COVID-19: A Make-or-Break Moment for Customer Loyalty",
    publish_date: "2020-04-01",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 447,
    url: "https://www.mx.com/news/the-financial-brand-covid-provides-opportunity-for-digital-transformation",
    admin_url: "https://cms.mx.com/admin/content/mx_news/447",
    name: "The Financial Brand: COVID-19 Provides Opportunity For Digital Transformation",
    publish_date: "2020-04-06",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 448,
    url: "https://www.mx.com/news/2020-03-02-magic-fintech-mirror-who-is-the-fairest-one-of-all-in",
    admin_url: "https://cms.mx.com/admin/content/mx_news/448",
    name: "Magic Fintech Mirror, Who is the Fairest One of All in 2020?",
    publish_date: "2020-02-26",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 449,
    url: "https://www.mx.com/news/tearsheet-money-experience-bracket-challenge",
    admin_url: "https://cms.mx.com/admin/content/mx_news/449",
    name: "Tearsheet: Money Experience Bracket Challenge 2020",
    publish_date: "2020-03-30",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 450,
    url: "https://www.mx.com/news/fight-over-consumer-data-ownership-pits-banks-against-fintechs",
    admin_url: "https://cms.mx.com/admin/content/mx_news/450",
    name: "The Financial Brand: Fight Over Consumer Data Ownership Pits Banks Against Fintechs",
    publish_date: "2020-03-12",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 451,
    url: "https://www.mx.com/news/affinity-federal-credit-union-and-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/451",
    name: "Tearsheet: Affinity Federal Credit Union and MX",
    publish_date: "2020-04-15",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 452,
    url: "https://www.mx.com/news/cnet-coronavirus-is-making-touch-free-shopping-a-necessity",
    admin_url: "https://cms.mx.com/admin/content/mx_news/452",
    name: "CNET: Coronavirus is making touch-free shopping a necessity",
    publish_date: "2020-04-15",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 453,
    url: "https://www.mx.com/news/cheddar-lenders-have-processed-b-in-small-biz-coronavirus-relief",
    admin_url: "https://cms.mx.com/admin/content/mx_news/453",
    name: "Cheddar: Lenders Have Processed $80B in Small Biz Coronavirus Relief",
    publish_date: "2020-04-13",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 456,
    url: "https://www.mx.com/news/fintechs-help-banks-manage-deluge-of-emergency-small-business-loans",
    admin_url: "https://cms.mx.com/admin/content/mx_news/456",
    name: "American Banker: Fintechs help banks manage deluge of emergency small-business loans",
    publish_date: "2020-04-17",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 457,
    url: "https://www.mx.com/news/fintechs-take-a-regional-approach-to-smb-covid-relief",
    admin_url: "https://cms.mx.com/admin/content/mx_news/457",
    name: "PYMNTS.com: FinTechs Take A Regional Approach To SMB COVID-19 Relief",
    publish_date: "2020-04-23",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 458,
    url: "https://www.mx.com/news/global-fintech-series-interview-with-brandon-dewitt-co-founder-and-cto-at-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/458",
    name: "Global Fintech Series: Interview with Brandon Dewitt, Co-founder and CTO at MX",
    publish_date: "2020-04-27",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 459,
    url: "https://www.mx.com/news/citizens-bank-of-edmond-and-mx-launch-sba-loan",
    admin_url: "https://cms.mx.com/admin/content/mx_news/459",
    name: "Citizens Bank of Edmond and MX Launch SBA Loan Application Portal for Small Businesses",
    publish_date: "2020-04-22",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 465,
    url: "https://www.mx.com/news/ryan-caldwell-interviewed-live-on-yahoo-finance-about-the-mx-easy-sba-portal",
    admin_url: "https://cms.mx.com/admin/content/mx_news/465",
    name: "Ryan Caldwell interviewed live on Yahoo! Finance about the MX Easy SBA Portal",
    publish_date: "2020-04-26",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 466,
    url: "https://www.mx.com/news/fast-company-how-covid-could-change-our-spending-and-saving-habits-forever",
    admin_url: "https://cms.mx.com/admin/content/mx_news/466",
    name: "Fast Company: How COVID-19 could change our spending and saving habits forever",
    publish_date: "2020-05-14",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 467,
    url: "https://www.mx.com/news/they-helped-save-their-cofounder-while-building-a-company",
    admin_url: "https://cms.mx.com/admin/content/mx_news/467",
    name: "They Helped Save Their Co-Founder While Building A Company",
    publish_date: "2020-05-06",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 471,
    url: "https://www.mx.com/news/forbes-strategies-for-making-productive-use-of-consumer-data",
    admin_url: "https://cms.mx.com/admin/content/mx_news/471",
    name: "Forbes: 14 Strategies For Making Productive Use Of Consumer Data",
    publish_date: "2020-06-04",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 472,
    url: "https://www.mx.com/news/we-were-economic-first-responders",
    admin_url: "https://cms.mx.com/admin/content/mx_news/472",
    name: "ABA Banking Journal: ‘We Were Economic First Responders'",
    publish_date: "2020-06-10",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 474,
    url: "https://www.mx.com/news/bank-budgeting-apps-tell-story-of-the-pandemics-impact-on-consumers",
    admin_url: "https://cms.mx.com/admin/content/mx_news/474",
    name: "American Banker: Bank budgeting apps tell story of the pandemic’s impact on consumers",
    publish_date: "2020-06-24",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 475,
    url: "https://www.mx.com/news/utah-fintech-mx-is-convincing-financial-institutions-to-do-things-differently",
    admin_url: "https://cms.mx.com/admin/content/mx_news/475",
    name: "KSL.com: Utah fintech MX is convincing financial institutions to do things differently",
    publish_date: "2020-06-24",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 477,
    url: "https://www.mx.com/news/bank-director-five-digital-banking-initiatives-for-second-half-of-2020",
    admin_url: "https://cms.mx.com/admin/content/mx_news/477",
    name: "Bank Director: Five Digital Banking Initiatives for Second Half of 2020",
    publish_date: "2020-07-01",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 478,
    url: "https://www.mx.com/news/mx-jenny-guerra-named-one-of-utah-business-30-women-to-watch",
    admin_url: "https://cms.mx.com/admin/content/mx_news/478",
    name: "MX's Jenny Guerra, named one of the Utah Business 30 Women to Watch for 2020",
    publish_date: "2020-07-01",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 479,
    url: "https://www.mx.com/news/what-conversations-with-fintech-ceos-revealed",
    admin_url: "https://cms.mx.com/admin/content/mx_news/479",
    name: "Forbes: What Conversations With 15 FinTech CEOs Revealed",
    publish_date: "2020-07-01",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 480,
    url: "https://www.mx.com/news/mx-announces-money-experience-summit",
    admin_url: "https://cms.mx.com/admin/content/mx_news/480",
    name: "MX Announces Money Experience Summit 2020",
    publish_date: "2020-07-15",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 481,
    url: "https://www.mx.com/news/ksltv-loans-offer-incentive-to-keep-small-business-workers-employed",
    admin_url: "https://cms.mx.com/admin/content/mx_news/481",
    name: "KSL-TV: Loans Offer Incentive To Keep Small Business Workers Employed",
    publish_date: "2020-04-08",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 482,
    url: "https://www.mx.com/news/fintech-companies-making-a-difference-during-covid",
    admin_url: "https://cms.mx.com/admin/content/mx_news/482",
    name: "Nasdaq.com: 7 Fintech Companies Making a Difference During COVID-19",
    publish_date: "2020-06-26",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 484,
    url: "https://www.mx.com/news/ksl-com-how-two-utah-tech-giants-teamed-up-to-help-small-businesses-secure-ppp-loans",
    admin_url: "https://cms.mx.com/admin/content/mx_news/484",
    name: "KSL.com: How two Utah tech giants teamed up to help small businesses secure PPP loans",
    publish_date: "2020-07-23",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 485,
    url: "https://www.mx.com/news/open-banking-gets-a-big-jump-start",
    admin_url: "https://cms.mx.com/admin/content/mx_news/485",
    name: "The Financial Brand: Open Banking Gets a Big Jump Start from COVID",
    publish_date: "2020-07-27",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 486,
    url: "https://www.mx.com/news/kentuckys-favorite-fast-food-restaurants",
    admin_url: "https://cms.mx.com/admin/content/mx_news/486",
    name: "Louisville Business First: These three brands have been Kentucky's favorite fast food restaurants during Covid-19",
    publish_date: "2020-07-29",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 488,
    url: "https://www.mx.com/news/retail-hospitality-hub-even-though-its-sales-are-down-mcdonalds-has-been-the-goto-fastfood-joint",
    admin_url: "https://cms.mx.com/admin/content/mx_news/488",
    name: "Retail & Hospitality Hub: Even Though Its Sales Are Down, McDonald’s Has Been the Go-To Fast-Food Joint",
    publish_date: "2020-08-07",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 489,
    url: "https://www.mx.com/news/mx-launches-data-connectivity-api",
    admin_url: "https://cms.mx.com/admin/content/mx_news/489",
    name: "MX Launches Data Connectivity API to Power Modern Banking Experiences",
    publish_date: "2020-07-21",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 490,
    url: "https://www.mx.com/news/digital-transactions-in-the-competitive-game",
    admin_url: "https://cms.mx.com/admin/content/mx_news/490",
    name: "Digital Transactions: In the Competitive Game of Open Banking, MX Claims a Big Lead With 50,000-Plus Connections",
    publish_date: "2020-08-20",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 491,
    url: "https://www.mx.com/news/tech-trends-that-will-soon-shake-up-the-consumer-market",
    admin_url: "https://cms.mx.com/admin/content/mx_news/491",
    name: "Forbes: 16 Tech Trends That Will Soon Shake Up The Consumer Market",
    publish_date: "2020-07-12",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 492,
    url: "https://www.mx.com/news/affinity-federal-credit-union-increases-member-adoption",
    admin_url: "https://cms.mx.com/admin/content/mx_news/492",
    name: "Affinity Federal Credit Union Increases Member Adoption With Deeper Integrations With MX",
    publish_date: "2020-09-01",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 495,
    url: "https://www.mx.com/news/salesforce-blog-how-this-fintech-company-built-an-app-in-one-weekend-that-enabled-5-billion-in-small-business-loans",
    admin_url: "https://cms.mx.com/admin/content/mx_news/495",
    name: "Salesforce Blog: How This Fintech Company Built an App in One Weekend That Enabled $5 Billion in Small Business Loans",
    publish_date: "2020-08-17",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 496,
    url: "https://www.mx.com/news/mx-named-one-of-fastest-growing-companies-in-utah",
    admin_url: "https://cms.mx.com/admin/content/mx_news/496",
    name: "MX named one of fastest growing companies in Utah",
    publish_date: "2020-09-03",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 497,
    url: "https://www.mx.com/news/money-experience-summit-mx-spotlights-becu-for-improving-the-financial-health-of-its-members",
    admin_url: "https://cms.mx.com/admin/content/mx_news/497",
    name: "Money Experience Summit 2020: MX Spotlights BECU For Improving The Financial Health Of Its Members",
    publish_date: "2020-09-02",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 498,
    url: "https://www.mx.com/news/mx-named-to-2020-cb-insights-fintech",
    admin_url: "https://cms.mx.com/admin/content/mx_news/498",
    name: "MX Named to 2020 CB Insights Fintech 250",
    publish_date: "2020-09-04",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 499,
    url: "https://www.mx.com/news/forbes-where-digital-disruption-and-banks-collide",
    admin_url: "https://cms.mx.com/admin/content/mx_news/499",
    name: "Forbes: Modern Money: Where Digital Disruption And Banks Collide",
    publish_date: "2020-07-31",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 501,
    url: "https://www.mx.com/news/money-experience-summit-mx-innovator-awards-recognize-formfree-mt-bank-and-synovus",
    admin_url: "https://cms.mx.com/admin/content/mx_news/501",
    name: "Money Experience Summit 2020: MX Innovator Awards Recognize FormFree, M&T Bank, and Synovus",
    publish_date: "2020-09-03",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 502,
    url: "https://www.mx.com/news/the-financial-brand-how-financial-institutions-must-resolve-internal-squabbles-over-data",
    admin_url: "https://cms.mx.com/admin/content/mx_news/502",
    name: "The Financial Brand: How Financial Institutions Must Resolve Internal Squabbles Over Data",
    publish_date: "2020-09-07",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 503,
    url: "https://www.mx.com/news/utah-business-mx-pivoted-to-process-5-billion-in-small-business-loans",
    admin_url: "https://cms.mx.com/admin/content/mx_news/503",
    name: "Utah Business: MX pivoted to process $5 billion in small business loans",
    publish_date: "2020-09-08",
    last_modified: "2022-12-05T09:10:41.034Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 385,
    url: "https://www.mx.com/blog/stop-digital-porch-pirates",
    admin_url: "https://cms.mx.com/admin/content/blog/385",
    name: "Don’t Let Digital Porch Pirates Ruin the Holidays",
    publish_date: "2022-12-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 386,
    url: "https://www.mx.com/blog/what-s-your-why-defining-product-development-success",
    admin_url: "https://cms.mx.com/admin/content/blog/386",
    name: "What’s Your Why? Defining Product Development Success",
    publish_date: "2022-12-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 284,
    url: "https://www.mx.com/news/mx-attracts-recent-cfo-of-the-year",
    admin_url: "https://cms.mx.com/admin/content/mx_news/284",
    name: "MX Attracts Recent CFO of the Year",
    publish_date: "2015-02-15",
    last_modified: "2022-12-13T15:21:51.707Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 270,
    url: "https://www.mx.com/news/mx-further-strengthens-its-executive-leadership-with-appointment-of-former-intel-fiserv-cmo",
    admin_url: "https://cms.mx.com/admin/content/mx_news/270",
    name: "MX Further Strengthens Its Executive Leadership with Appointment of Former Intel, Fiserv CMO",
    publish_date: "2016-01-26",
    last_modified: "2022-12-13T15:47:56.167Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 271,
    url: "https://www.mx.com/news/mxannouncescashflow",
    admin_url: "https://cms.mx.com/admin/content/mx_news/271",
    name: "MX Announces New Addition to Data-driven Money Management Tool Available to Millions of Users",
    publish_date: "2016-06-21",
    last_modified: "2022-12-13T15:49:23.644Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 273,
    url: "https://www.mx.com/news/c3s6z68rxw34guwq9y9ndz4t887zu1",
    admin_url: "https://cms.mx.com/admin/content/mx_news/273",
    name: "MX Atrium API Provides Developers with Superior Aggregation and Transaction Data via the MX Platform",
    publish_date: "2016-10-18",
    last_modified: "2022-12-13T15:54:56.331Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 387,
    url: "https://www.mx.com/blog/safeguarding-consumer-data-sharing",
    admin_url: "https://cms.mx.com/admin/content/blog/387",
    name: "Checking the Lists… Safeguarding Consumer Data Sharing",
    publish_date: "2022-12-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 388,
    url: "https://www.mx.com/blog/balance-checks-prevent-holiday-overspending",
    admin_url: "https://cms.mx.com/admin/content/blog/388",
    name: "Save Your Business and Consumers from the Pitfalls of Holiday Overspending",
    publish_date: "2022-12-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 378,
    url: "https://www.mx.com/news/brandon-dewitt-named-one-of-top-25-ctos-in-the-financial-technology-report",
    admin_url: "https://cms.mx.com/admin/content/mx_news/378",
    name: "Brandon Dewitt named one of Top 25 CTOs in The Financial Technology Report",
    publish_date: "2019-08-19",
    last_modified: "2023-01-03T22:04:50.193Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 367,
    url: "https://www.mx.com/news/brandon-dewitt-quoted-in-american-banker-story-about-ma-in-the-industry",
    admin_url: "https://cms.mx.com/admin/content/mx_news/367",
    name: "Brandon Dewitt quoted in American Banker story about M&A in the industry",
    publish_date: "2019-07-09",
    last_modified: "2023-01-03T23:28:40.836Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 388,
    url: "https://www.mx.com/news/codechangers-stem-summer-camp-for-kids-coming-to-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/388",
    name: "CodeChangers STEM summer camp for kids coming to MX",
    publish_date: "2019-07-30",
    last_modified: "2023-01-03T23:36:21.031Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 295,
    url: "https://www.mx.com/news/codechangers-the-mx-sponsored-stem-summer-camp-starts-today",
    admin_url: "https://cms.mx.com/admin/content/mx_news/295",
    name: "CodeChangers, the STEM summer camp hosted by MX, starts today!",
    publish_date: "2018-08-07",
    last_modified: "2023-01-03T23:36:21.405Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 343,
    url: "https://www.mx.com/news/credit-union-journal-opinion-piece-spotlights-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/343",
    name: "MX highlighted in Credit Union Journal",
    publish_date: "2019-04-10",
    last_modified: "2023-01-03T23:36:21.817Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 455,
    url: "https://www.mx.com/news/data-is-powerful-but-not-bankings-silver-bullet-the-financial-brand-by-don-macdonald",
    admin_url: "https://cms.mx.com/admin/content/mx_news/455",
    name: "Data is Powerful, But Not Banking’s ‘Silver Bullet’ -- article in The Financial Brand by Don MacDonald",
    publish_date: "2019-01-04",
    last_modified: "2023-01-03T23:36:22.214Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 461,
    url: "https://www.mx.com/news/deseret-news-mx-technologies-clocked-its-own-nine-figured-deal-in-2019",
    admin_url: "https://cms.mx.com/admin/content/mx_news/461",
    name: "Deseret News: MX clocked its own nine-figure deal in 2019",
    publish_date: "2020-01-09",
    last_modified: "2023-01-03T23:36:22.976Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 397,
    url: "https://www.mx.com/news/digital-banking-week-has-arrived",
    admin_url: "https://cms.mx.com/admin/content/mx_news/397",
    name: "Digital Banking Week has arrived!",
    publish_date: "2019-09-16",
    last_modified: "2023-01-03T23:37:02.948Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 302,
    url: "https://www.mx.com/news/don-macdonald-cmo-shares-mx-research-with-cuinsight-for-story-big-data-and-credit-unions",
    admin_url: "https://cms.mx.com/admin/content/mx_news/302",
    name: "Don MacDonald, CMO, shares MX research with CUInsight for story 'big data and credit unions'",
    publish_date: "2018-08-24",
    last_modified: "2023-01-03T23:37:03.702Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 374,
    url: "https://www.mx.com/news/don-macdonald-quoted-in-bank-director",
    admin_url: "https://cms.mx.com/admin/content/mx_news/374",
    name: "Don MacDonald shares thoughts on ''build vs. buy'' in Bank Director",
    publish_date: "2019-07-23",
    last_modified: "2023-01-03T23:37:04.509Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 331,
    url: "https://www.mx.com/news/don-macdonald-shares-thoughts-about-the-most-important-question-in-banking-right-now-bank-director",
    admin_url: "https://cms.mx.com/admin/content/mx_news/331",
    name: "Don MacDonald shares vision for banking in 'The Most Important Question in Banking Right Now' --Bank Director",
    publish_date: "2019-02-15",
    last_modified: "2023-01-03T23:37:04.931Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 283,
    url: "https://www.mx.com/news/don-macdonald-speaks-on-the-data-age-of-banking-at-the-financial-brand-forum",
    admin_url: "https://cms.mx.com/admin/content/mx_news/283",
    name: "Don MacDonald speaks on 'The Data Age of Banking' at The Financial Brand Forum",
    publish_date: "2018-05-09",
    last_modified: "2023-01-03T23:37:05.420Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 321,
    url: "https://www.mx.com/news/dynamic-sales-leader-dave-norton-joins-mx-executive-team",
    admin_url: "https://cms.mx.com/admin/content/mx_news/321",
    name: "Dynamic Sales Leader Dave Norton Joins MX Executive Team",
    publish_date: "2018-12-03",
    last_modified: "2023-01-03T23:38:29.629Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 304,
    url: "https://www.mx.com/news/engineering-at-mx-earns-gartner-praise",
    admin_url: "https://cms.mx.com/admin/content/mx_news/304",
    name: "Engineering at MX earns Gartner praise",
    publish_date: "2018-08-29",
    last_modified: "2023-01-03T23:38:30.566Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 386,
    url: "https://www.mx.com/news/fall-2019-data-fintech-festival-a-huge-success",
    admin_url: "https://cms.mx.com/admin/content/mx_news/386",
    name: "Fall 2019 Data Fintech Festival a huge success",
    publish_date: "2019-09-13",
    last_modified: "2023-01-03T23:38:30.946Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 418,
    url: "https://www.mx.com/news/fast-company-meet-your-2020-money-goals",
    admin_url: "https://cms.mx.com/admin/content/mx_news/418",
    name: "Fast Company: 3 easy ways to meet your 2020 money goals",
    publish_date: "2020-01-10",
    last_modified: "2023-01-03T23:38:31.553Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 289,
    url: "https://www.mx.com/news/fintech-festival-coming-to-sundance-june-18-20",
    admin_url: "https://cms.mx.com/admin/content/mx_news/289",
    name: "FinTech Festival coming to Sundance, June 18-20",
    publish_date: "2018-06-11",
    last_modified: "2023-01-03T23:39:05.270Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 414,
    url: "https://www.mx.com/news/forbes-13-demoing-strategies-that-make-tech-software-compelling",
    admin_url: "https://cms.mx.com/admin/content/mx_news/414",
    name: "Forbes: 13 Demoing Strategies that Make Tech Software Compelling",
    publish_date: "2019-12-12",
    last_modified: "2023-01-03T23:39:06.454Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 389,
    url: "https://www.mx.com/news/helios-by-mx-featured-in-aba-banking-journal",
    admin_url: "https://cms.mx.com/admin/content/mx_news/389",
    name: "Helios by MX featured in ABA Banking Journal",
    publish_date: "2019-08-07",
    last_modified: "2023-01-03T23:39:08.129Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 348,
    url: "https://www.mx.com/news/hughes-federal-credit-union-selects-mx-helios-mobile-as-part-of-its-digital-transformation",
    admin_url: "https://cms.mx.com/admin/content/mx_news/348",
    name: "Hughes Federal Credit Union selects MX Helios mobile as part of its digital transformation",
    publish_date: "2019-05-03",
    last_modified: "2023-01-03T23:40:33.491Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 327,
    url: "https://www.mx.com/news/inspiring-story-of-former-refugees-turned-interns-airs-on-ksl-5-tv",
    admin_url: "https://cms.mx.com/admin/content/mx_news/327",
    name: "Inspiring story of former refugees turned MX interns airs on KSL 5 TV",
    publish_date: "2019-01-09",
    last_modified: "2023-01-03T23:40:34.047Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 408,
    url: "https://www.mx.com/news/jane-barratt-column-published-in-fast-company",
    admin_url: "https://cms.mx.com/admin/content/mx_news/408",
    name: "Fast Company: These Are The Absolute Worst Reasons to Ask For a Raise, by Jane Barratt",
    publish_date: "2019-11-18",
    last_modified: "2023-01-03T23:40:34.422Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 420,
    url: "https://www.mx.com/news/jane-barratt-coupling-social-responsibility-and-innovation-at-consumer-electronics-show",
    admin_url: "https://cms.mx.com/admin/content/mx_news/420",
    name: "CNET: MX's Jane Barratt quoted on coupling social responsibility and innovation at the annual Consumer Electronics Show",
    publish_date: "2020-01-07",
    last_modified: "2023-01-03T23:40:34.817Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 407,
    url: "https://www.mx.com/news/jane-barratt-named-to-women-in-finance-top-100-influencers-list",
    admin_url: "https://cms.mx.com/admin/content/mx_news/407",
    name: "Jane Barratt Named to Women in Finance Top 100 Influencers List",
    publish_date: "2019-11-26",
    last_modified: "2023-01-03T23:41:07.483Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 364,
    url: "https://www.mx.com/news/jane-barratt-quoted-in-reuters-story-about-burnout",
    admin_url: "https://cms.mx.com/admin/content/mx_news/364",
    name: "Jane Barratt quoted in Reuters story about burnout",
    publish_date: "2019-06-18",
    last_modified: "2023-01-03T23:41:07.847Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 393,
    url: "https://www.mx.com/news/jane-barratt-speaks-at-fearless-in-fintech",
    admin_url: "https://cms.mx.com/admin/content/mx_news/393",
    name: "Jane Barratt speaks at Fearless in Fintech",
    publish_date: "2019-07-16",
    last_modified: "2023-01-03T23:41:08.216Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 323,
    url: "https://www.mx.com/news/jane-barratt-talks-data-driven-roi-for-credit-unions",
    admin_url: "https://cms.mx.com/admin/content/mx_news/323",
    name: "Jane Barratt talks data-driven ROI for credit unions",
    publish_date: "2018-12-04",
    last_modified: "2023-01-03T23:43:14.400Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 320,
    url: "https://www.mx.com/news/jim-marous-and-don-macdonald-to-co-present-data-driven-disruption-in-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/320",
    name: "Jim Marous and Don MacDonald to co-present 'Data-driven disruption in banking'",
    publish_date: "2018-11-09",
    last_modified: "2023-01-03T23:43:14.775Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 330,
    url: "https://www.mx.com/news/kony-and-mx-team-up-to-empower-banks-and-credit-unions-to-help-consumers-gain-greater-insight-into-their-financial-health",
    admin_url: "https://cms.mx.com/admin/content/mx_news/330",
    name: "Kony and MX Team Up to Empower Banks and Credit Unions to Help Consumers Gain Greater Insight into their Financial Health",
    publish_date: "2019-01-22",
    last_modified: "2023-01-03T23:43:15.342Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 362,
    url: "https://www.mx.com/news/meridian-chooses-mx-pulse-to-deliver-ai-driven-insights-in-online-and-mobile-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/362",
    name: "Meridian chooses MX Pulse to deliver AI-driven insights in online and mobile banking",
    publish_date: "2019-05-24",
    last_modified: "2023-01-03T23:43:16.738Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 349,
    url: "https://www.mx.com/news/meridian-innovates-by-choosing-mx-for-pulse",
    admin_url: "https://cms.mx.com/admin/content/mx_news/349",
    name: "Meridian innovates by choosing MX for Pulse",
    publish_date: "2019-05-08",
    last_modified: "2023-01-03T23:43:47.781Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 338,
    url: "https://www.mx.com/news/mx-and-personetics-partner-to-bring-ai-driven-personalized-insights-and-advice-to-their-financial-institution-customers",
    admin_url: "https://cms.mx.com/admin/content/mx_news/338",
    name: "MX and Personetics partner to bring AI-driven personalized insights and advice to their financial institution customers",
    publish_date: "2019-03-21",
    last_modified: "2023-01-03T23:43:49.099Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 387,
    url: "https://www.mx.com/news/mx-brings-together-financial-industry-leaders-for-first-annual-digital-banking-week",
    admin_url: "https://cms.mx.com/admin/content/mx_news/387",
    name: "MX Brings Together Financial Industry Leaders for First Annual Digital Banking Week",
    publish_date: "2019-09-10",
    last_modified: "2023-01-03T23:53:11.114Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 315,
    url: "https://www.mx.com/news/mx-booth-makes-a-hit-at-money-2020",
    admin_url: "https://cms.mx.com/admin/content/mx_news/315",
    name: "MX makes a hit at Money20/20",
    publish_date: "2018-10-24",
    last_modified: "2023-01-03T23:53:46.761Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 309,
    url: "https://www.mx.com/news/mx-chief-customer-officer-nate-gardner-interviews-with-bbva",
    admin_url: "https://cms.mx.com/admin/content/mx_news/309",
    name: "MX Chief Customer Officer Nate Gardner interviewed by BBVA Compass",
    publish_date: "2018-09-20",
    last_modified: "2023-01-03T23:56:29.693Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 326,
    url: "https://www.mx.com/news/mx-customer-lake-michigan-credit-union-featured-in-credit-union-journal",
    admin_url: "https://cms.mx.com/admin/content/mx_news/326",
    name: "MX customer Lake Michigan Credit Union featured in Credit Union Journal",
    publish_date: "2018-12-21",
    last_modified: "2023-01-03T23:56:30.554Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 281,
    url: "https://www.mx.com/news/mx-customer-success-featured-in-american-banker",
    admin_url: "https://cms.mx.com/admin/content/mx_news/281",
    name: "MX customer success featured in American Banker",
    publish_date: "2018-05-02",
    last_modified: "2023-01-03T23:56:30.919Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 311,
    url: "https://www.mx.com/news/mx-data-empowers-ent-credit-union-members-to-improve-their-financial-lives",
    admin_url: "https://cms.mx.com/admin/content/mx_news/311",
    name: "MX data empowers Ent Credit Union members to improve their financial lives",
    publish_date: "2018-10-03",
    last_modified: "2023-01-03T23:56:31.316Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 287,
    url: "https://www.mx.com/news/mx-data-visualization-and-budgeting-solutions-featured-in-paymentssource",
    admin_url: "https://cms.mx.com/admin/content/mx_news/287",
    name: "MX  data visualization and budgeting solutions featured in PaymentsSource",
    publish_date: "2018-06-01",
    last_modified: "2023-01-03T23:56:32.109Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 300,
    url: "https://www.mx.com/news/mx-director-of-security-interviewed-in-bankrate",
    admin_url: "https://cms.mx.com/admin/content/mx_news/300",
    name: "MX Director of Information Security interviewed in Bankrate",
    publish_date: "2018-09-08",
    last_modified: "2023-01-03T23:56:32.719Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 403,
    url: "https://www.mx.com/news/mx-enabled-featured-in-pymnts",
    admin_url: "https://cms.mx.com/admin/content/mx_news/403",
    name: "MX Enabled featured in PYMNTS.com",
    publish_date: "2019-11-05",
    last_modified: "2023-01-03T23:56:33.110Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 359,
    url: "https://www.mx.com/news/mx-engineer-denna-lawrence-featured-in-bankrate-article-on-kids-and-savings",
    admin_url: "https://cms.mx.com/admin/content/mx_news/359",
    name: "MX engineer Denna Lawrence featured in Bankrate article on kids and savings",
    publish_date: "2019-05-28",
    last_modified: "2023-01-03T23:56:33.511Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 354,
    url: "https://www.mx.com/news/mx-engineer-rosie-thomas-talks-about-ruby-on-rails",
    admin_url: "https://cms.mx.com/admin/content/mx_news/354",
    name: "MX engineer Rosie Thomas talks about Ruby on Rails",
    publish_date: "2018-05-24",
    last_modified: "2023-01-03T23:59:20.592Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 340,
    url: "https://www.mx.com/news/mx-engineering-featured-on-cover-of-software-executive",
    admin_url: "https://cms.mx.com/admin/content/mx_news/340",
    name: "MX Engineering featured on cover of Software Executive",
    publish_date: "2019-04-02",
    last_modified: "2023-01-03T23:59:20.986Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 394,
    url: "https://www.mx.com/news/mx-enters-into-partnership-with-utah-women-in-sales",
    admin_url: "https://cms.mx.com/admin/content/mx_news/394",
    name: "MX partners with Utah Women in Sales ",
    publish_date: "2019-10-08",
    last_modified: "2023-01-03T23:59:21.349Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 373,
    url: "https://www.mx.com/news/mx-featured-in-benzinga",
    admin_url: "https://cms.mx.com/admin/content/mx_news/373",
    name: "MX featured in Benzinga",
    publish_date: "2019-07-18",
    last_modified: "2023-01-03T23:59:21.750Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 383,
    url: "https://www.mx.com/news/mx-featured-on-kutv",
    admin_url: "https://cms.mx.com/admin/content/mx_news/383",
    name: "MX and Patriot Boot Camp featured on KUTV 2News",
    publish_date: "2019-08-30",
    last_modified: "2023-01-03T23:59:22.610Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 299,
    url: "https://www.mx.com/news/mx-helps-bbva-compass-app-by-aggregating-customer-accounts",
    admin_url: "https://cms.mx.com/admin/content/mx_news/299",
    name: "BBVA Compass adds MX-powered financial tool to its latest mobile app",
    publish_date: "2018-08-21",
    last_modified: "2023-01-03T23:59:23.411Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 318,
    url: "https://www.mx.com/news/mx-hosting-the-first-women-tech-council-3x3-connections-event",
    admin_url: "https://cms.mx.com/admin/content/mx_news/318",
    name: "MX hosting the first Women Tech Council '3x3 Connections' event",
    publish_date: "2018-11-26",
    last_modified: "2023-01-03T23:59:23.801Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 282,
    url: "https://www.mx.com/news/mx-hosting-utah-ruby-users-group-meetup-may-8",
    admin_url: "https://cms.mx.com/admin/content/mx_news/282",
    name: "MX hosting Utah Ruby Users Group (URUG) Meetup - May 8",
    publish_date: "2018-05-07",
    last_modified: "2023-01-03T23:59:24.283Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 357,
    url: "https://www.mx.com/news/mx-hosting-women-who-code-hack-night",
    admin_url: "https://cms.mx.com/admin/content/mx_news/357",
    name: "MX hosting Women Who Code 'Hack Night'",
    publish_date: "2019-05-23",
    last_modified: "2023-01-03T23:59:24.663Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 325,
    url: "https://www.mx.com/news/mx-in-the-future-of-silicon-slopes",
    admin_url: "https://cms.mx.com/admin/content/mx_news/325",
    name: "MX in the future of Silicon Slopes",
    publish_date: "2018-12-18",
    last_modified: "2023-01-03T23:59:25.047Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 365,
    url: "https://www.mx.com/news/mx-infographic-featured-in-credit-union-times",
    admin_url: "https://cms.mx.com/admin/content/mx_news/365",
    name: "MX infographic featured in Credit Union Times",
    publish_date: "2019-07-01",
    last_modified: "2023-01-03T23:59:25.447Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 306,
    url: "https://www.mx.com/news/mx-kicks-off-fall-2018-fintech-festival",
    admin_url: "https://cms.mx.com/admin/content/mx_news/306",
    name: "MX Kicks off Fall 2018 Fintech Festival",
    publish_date: "2018-09-17",
    last_modified: "2023-01-03T23:59:26.033Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 316,
    url: "https://www.mx.com/news/mx-lands-former-nuvi-president-as-companys-first-chief-product-officer",
    admin_url: "https://cms.mx.com/admin/content/mx_news/316",
    name: "MX lands former NUVI president as company’s first chief product officer",
    publish_date: "2018-11-05",
    last_modified: "2023-01-03T23:59:26.403Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 291,
    url: "https://www.mx.com/news/mx-qa-engineer-mara-behles-dean-talks-about-learning-to-use-javascript",
    admin_url: "https://cms.mx.com/admin/content/mx_news/291",
    name: "MX QA Engineer Mara Behles Dean talks about learning to use JavaScript",
    publish_date: "2018-06-29",
    last_modified: "2023-01-04T00:02:45.290Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 347,
    url: "https://www.mx.com/news/mx-research-spotlighted-in-finovate-blog",
    admin_url: "https://cms.mx.com/admin/content/mx_news/347",
    name: "MX Research article spotlighted in Finovate Blog",
    publish_date: "2019-04-29",
    last_modified: "2023-01-04T00:02:47.459Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 377,
    url: "https://www.mx.com/news/mx-signs-four-year-sponsorship-of-patriot-boot-camp-helping-veteran-communities-build-businesses",
    admin_url: "https://cms.mx.com/admin/content/mx_news/377",
    name: "MX Signs Four-year Sponsorship of Patriot Boot Camp, Helping Veteran Communities Build Businesses",
    publish_date: "2019-08-16",
    last_modified: "2023-01-04T00:06:08.042Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 379,
    url: "https://www.mx.com/news/mx-teams-up-with-personal-financial-wellness-app-dave",
    admin_url: "https://cms.mx.com/admin/content/mx_news/379",
    name: "MX Teams Up with Personal Financial Wellness App Dave",
    publish_date: "2019-08-26",
    last_modified: "2023-01-04T00:06:08.936Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 353,
    url: "https://www.mx.com/news/mx-working-with-cfsi-to-help-advance-financial-services-innovation",
    admin_url: "https://cms.mx.com/admin/content/mx_news/353",
    name: "MX Working with CFSI to Help Advance Financial Services Innovation",
    publish_date: "2019-05-14",
    last_modified: "2023-01-04T00:06:10.759Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 391,
    url: "https://www.mx.com/news/mxers-offer-insight-in-utah-business-story-is-college-worth-the-money",
    admin_url: "https://cms.mx.com/admin/content/mx_news/391",
    name: "MX offers insight in Utah Business story 'Is College Worth the Money?'",
    publish_date: "2019-09-20",
    last_modified: "2023-01-04T00:06:11.154Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 278,
    url: "https://www.mx.com/news/mxs-ryan-moore-to-speak-at-flatmap-in-oslo-norway",
    admin_url: "https://cms.mx.com/admin/content/mx_news/278",
    name: "MX's Ryan Moore to speak at flatMap in Oslo, Norway",
    publish_date: "2018-04-30",
    last_modified: "2023-01-04T00:06:12.057Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 319,
    url: "https://www.mx.com/news/nummo-selects-mx-data-to-help-improve-financial-future-for-thousands",
    admin_url: "https://cms.mx.com/admin/content/mx_news/319",
    name: "Nummo selects MX data to help improve financial future for thousands",
    publish_date: "2018-11-27",
    last_modified: "2023-01-04T00:06:48.650Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 437,
    url: "https://www.mx.com/news/plaid-takeover-signals-fintech-wave-of-deal-making",
    admin_url: "https://cms.mx.com/admin/content/mx_news/437",
    name: "Bloomberg: Visa's Plaid Takeover Signals Wave of Fintech Deal Making",
    publish_date: "2020-01-15",
    last_modified: "2023-01-04T00:06:50.006Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 288,
    url: "https://www.mx.com/news/questis-switches-to-mx-for-faster-more-secure-connections",
    admin_url: "https://cms.mx.com/admin/content/mx_news/288",
    name: "Questis Switches to MX for Faster, More Secure Connections",
    publish_date: "2018-05-23",
    last_modified: "2023-01-04T00:06:50.762Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 382,
    url: "https://www.mx.com/news/ryan-caldwell-interviewed-live-on-yahoo-finance-about-patriot-boot-camp",
    admin_url: "https://cms.mx.com/admin/content/mx_news/382",
    name: "Ryan Caldwell interviewed live on Yahoo! Finance about Patriot Boot Camp",
    publish_date: "2019-08-28",
    last_modified: "2023-01-04T00:06:52.106Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 402,
    url: "https://www.mx.com/news/ryan-caldwell-shares-thoughts-about-patriot-boot-camp-on-hiring-america-tv",
    admin_url: "https://cms.mx.com/admin/content/mx_news/402",
    name: "Ryan Caldwell shares thoughts about Patriot Boot Camp on Hiring America TV",
    publish_date: "2019-10-09",
    last_modified: "2023-01-04T00:06:53.207Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 399,
    url: "https://www.mx.com/news/ryan-caldwell-jane-barratt-speaking-at-money2020",
    admin_url: "https://cms.mx.com/admin/content/mx_news/399",
    name: "Ryan Caldwell, Jane Barratt Speaking at Money20/20",
    publish_date: "2019-10-24",
    last_modified: "2023-01-04T00:07:31.628Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 280,
    url: "https://www.mx.com/news/ryan-caldwell-talks-about-the-future-of-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/280",
    name: "Ryan Caldwell talks about the future of MX",
    publish_date: "2018-04-24",
    last_modified: "2023-01-04T00:08:13.529Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 297,
    url: "https://www.mx.com/news/ryan-moore-mx-engineer-to-speak-at",
    admin_url: "https://cms.mx.com/admin/content/mx_news/297",
    name: "Ryan Moore, MX engineer, to speak at React Rally",
    publish_date: "2018-08-09",
    last_modified: "2023-01-04T00:08:13.906Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 412,
    url: "https://www.mx.com/news/tearsheet-financial-data-as-the-currency-of-the-modern-economy",
    admin_url: "https://cms.mx.com/admin/content/mx_news/412",
    name: "Tearsheet: Financial data as the currency of the modern economy",
    publish_date: "2019-12-04",
    last_modified: "2023-01-04T00:08:15.592Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 360,
    url: "https://www.mx.com/news/tearsheet-reposts-mx-infographic-the-mobile-revolution-in-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/360",
    name: "Tearsheet reposts MX infographic 'The Mobile Revolution in Banking'",
    publish_date: "2019-06-07",
    last_modified: "2023-01-04T00:08:16.798Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 415,
    url: "https://www.mx.com/news/techcrunch-who-will-the-winners-be-in-the-future-of-fintech",
    admin_url: "https://cms.mx.com/admin/content/mx_news/415",
    name: "TechCrunch: Who will the winners be in the future of fintech?",
    publish_date: "2019-12-23",
    last_modified: "2023-01-04T00:08:17.400Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 341,
    url: "https://www.mx.com/news/washington-federal-chooses-mx-helios-as-its-mobile-banking-app",
    admin_url: "https://cms.mx.com/admin/content/mx_news/341",
    name: "Washington Federal chooses MX Helios as its mobile banking app",
    publish_date: "2019-04-03",
    last_modified: "2023-01-04T00:13:04.169Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 303,
    url: "https://www.mx.com/news/webinar-with-mercantile-bank-of-michigan-to-address-how-ai-starts-with-clean-data",
    admin_url: "https://cms.mx.com/admin/content/mx_news/303",
    name: "Mercantile Bank of Michigan to address how AI starts with clean data",
    publish_date: "2018-07-30",
    last_modified: "2023-01-04T00:13:04.783Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 416,
    url: "https://www.mx.com/news/yahoo-finance-jane-barratt-interviewed-on-the-future-of-data-in-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/416",
    name: "Yahoo! Finance: Jane Barratt interviewed on 'The future of data in banking'",
    publish_date: "2020-01-06",
    last_modified: "2023-01-04T00:13:06.237Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 352,
    url: "https://www.mx.com/news/your-biggest-competitor-is-complacency-don-macdonald-tells-bank-director-audience",
    admin_url: "https://cms.mx.com/admin/content/mx_news/352",
    name: "'Your biggest competitor is complacency,' Don MacDonald tells Bank Director audience",
    publish_date: "2019-05-13",
    last_modified: "2023-01-04T00:13:06.656Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 380,
    url: "https://www.mx.com/news/shane-evans-joins-mx-as-chief-revenue-officer-to-further-accelerate-growth",
    admin_url: "https://cms.mx.com/admin/content/mx_news/380",
    name: "Shane Evans Joins MX as Chief Revenue Officer to Further Accelerate Growth",
    publish_date: "2019-08-27",
    last_modified: "2023-01-04T00:25:08.403Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 389,
    url: "https://www.mx.com/blog/2023-banking-fintech-predictions",
    admin_url: "https://cms.mx.com/admin/content/blog/389",
    name: "What Does 2023 Have in Store for Banks and Fintechs? ",
    publish_date: "2023-01-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 391,
    url: "https://www.mx.com/blog/data-sharing-gap-in-consumer-behavior",
    admin_url: "https://cms.mx.com/admin/content/blog/391",
    name: "Solving the Say-Do Gap in Data Sharing",
    publish_date: "2023-01-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 392,
    url: "https://www.mx.com/blog/what-ai-can-do-for-financial-services",
    admin_url: "https://cms.mx.com/admin/content/blog/392",
    name: "Consumers Want More — Can Artificial Intelligence Deliver? ",
    publish_date: "2023-01-24",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 276,
    url: "https://www.mx.com/news/barclays-mx/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/276",
    name: "Barclays' US Consumer Business Invests in Personal Financial Management Tool by MX",
    publish_date: "2017-10-18",
    last_modified: "2023-01-26T22:29:52.408Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 205,
    url: "https://www.mx.com/news/will-i-am-to-keynote-at-mx-money-experience-summit-2022",
    admin_url: "https://cms.mx.com/admin/content/mx_news/205",
    name: "will.i.am to Keynote at MX Money Experience Summit 2022",
    publish_date: "2022-06-09",
    last_modified: "2023-01-26T22:41:54.945Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 25,
    url: "https://www.mx.com/news/central-pacific-bank-goes-live-with-mx-helios-for-mobile-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/25",
    name: "Central Pacific Bank Goes Live with MX Helios for Mobile Banking",
    publish_date: "2020-09-09",
    last_modified: "2023-01-26T22:51:16.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 218,
    url: "https://www.mx.com/news/launch-lessons-the-founder-s-legacy-with-brett-allred-chief-product-officer-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/218",
    name: "The Founder's Legacy with Brett Allred, Chief Product Officer, MX",
    publish_date: "2022-07-04",
    last_modified: "2023-01-26T23:17:47.848Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 202,
    url: "https://www.mx.com/news/finovate-podcast-david-whitcomb-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/202",
    name: "Open banking is changing financial services. Are you ready?",
    publish_date: "2022-05-11",
    last_modified: "2023-01-26T23:21:04.430Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 240,
    url: "https://www.mx.com/news/fintech-what-the-heck-podcast-david-whitcomb-and-crystal-anderson-from-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/240",
    name: "A New Era of Financial Innovation",
    publish_date: "2022-08-29",
    last_modified: "2023-01-26T23:23:31.166Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 332,
    url: "https://www.mx.com/news/tearsheet-podcast-compares-the-data-aggregators",
    admin_url: "https://cms.mx.com/admin/content/mx_news/332",
    name: "What is data aggregation and why it matters for digital finance",
    publish_date: "2019-02-15",
    last_modified: "2023-01-26T23:26:17.317Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 435,
    url: "https://www.mx.com/news/2020-03-10-breaking-banks-podcast-live-from-sundance-doug-nielsen-sam-taussig",
    admin_url: "https://cms.mx.com/admin/content/mx_news/435",
    name: "Live! From Sundance",
    publish_date: "2020-03-06",
    last_modified: "2023-01-26T23:32:22.353Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 350,
    url: "https://www.mx.com/news/marketing-money-podcast-interview-with-jane-barratt-and-brett-allred",
    admin_url: "https://cms.mx.com/admin/content/mx_news/350",
    name: "Marketing Money podcast interview with Jane Barratt and Brett Allred",
    publish_date: "2019-05-06",
    last_modified: "2023-01-26T23:37:13.063Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 454,
    url: "https://www.mx.com/news/breaking-banks-live-from-sundance-andre-cherney-ceo-aspiration",
    admin_url: "https://cms.mx.com/admin/content/mx_news/454",
    name: "Live! From Sundance: Andre Cherney, CEO, Aspiration",
    publish_date: "2020-04-19",
    last_modified: "2023-01-26T23:39:32.839Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 468,
    url: "https://www.mx.com/news/banking-transformed-podcast-data-and-advanced-analytics-a-key-to-survival/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/468",
    name: "Data and Advanced Analytics: A Key to Survival",
    publish_date: "2020-05-19",
    last_modified: "2023-01-26T23:41:36.494Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 483,
    url: "https://www.mx.com/news/fs-fintech-insider-the-impact-of-coronavirus-on-us-smb-financing",
    admin_url: "https://cms.mx.com/admin/content/mx_news/483",
    name: "The impact of coronavirus on U.S. SMB Financing",
    publish_date: "2020-05-02",
    last_modified: "2023-01-26T23:46:27.171Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 441,
    url: "https://www.mx.com/news/breaking-banks-podcast-live-from-sundance-andy-hernandez-regions-bank",
    admin_url: "https://cms.mx.com/admin/content/mx_news/441",
    name: "Live! From Sundance: Andy Hernandez, Regions Bank",
    publish_date: "2020-03-22",
    last_modified: "2023-01-26T23:52:04.171Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 176,
    url: "https://www.mx.com/news/cfo-thought-leader-embracing-change-brian-kinion-cfo-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/176",
    name: "Embracing Change | Brian Kinion, CFO, MX",
    publish_date: "2022-01-31",
    last_modified: "2023-01-26T23:58:05.033Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 187,
    url: "https://www.mx.com/news/the-financial-brand-reimagining-money-experiences-in-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/187",
    name: "Reimagining Money Experiences in Banking",
    publish_date: "2022-03-15",
    last_modified: "2023-01-27T00:02:56.790Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 135,
    url: "https://www.mx.com/news/state-of-identity-podcast-episode-mx-modern-connectivity-and-open-finance",
    admin_url: "https://cms.mx.com/admin/content/mx_news/135",
    name: "MX: Modern Connectivity & Open Finance",
    publish_date: "2021-09-09",
    last_modified: "2023-01-27T00:05:32.195Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 206,
    url: "https://www.mx.com/news/state-of-identity-podcast-a-new-era-of-financial-innovation",
    admin_url: "https://cms.mx.com/admin/content/mx_news/206",
    name: "A New Era of Financial Innovation",
    publish_date: "2022-06-10",
    last_modified: "2023-01-27T00:13:28.045Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 395,
    url: "https://www.mx.com/news/jane-barratt-interviewed-on-breaking-banks",
    admin_url: "https://cms.mx.com/admin/content/mx_news/395",
    name: "Advocating for Customers",
    publish_date: "2019-10-15",
    last_modified: "2023-01-27T01:00:46.902Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 438,
    url: "https://www.mx.com/news/male-allies-in-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/438",
    name: "Podcast 'Getting out of the boys' echo chamber': Male allies in banking",
    publish_date: "2020-01-23",
    last_modified: "2023-01-27T01:04:35.014Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 470,
    url: "https://www.mx.com/news/breaking-banks-good-policy-good-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/470",
    name: "Good Policy, Good Banking",
    publish_date: "2020-06-04",
    last_modified: "2023-01-27T02:36:12.927Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 462,
    url: "https://www.mx.com/news/fs-fintech-insider-the-breakfast-show-us",
    admin_url: "https://cms.mx.com/admin/content/mx_news/462",
    name: "The Breakfast Show US",
    publish_date: "2020-05-01",
    last_modified: "2023-01-27T02:37:58.754Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 469,
    url: "https://www.mx.com/news/mx-announces-audience-builder-to-improve-marketing-conversion",
    admin_url: "https://cms.mx.com/admin/content/mx_news/469",
    name: "MX Announces Audience Builder to Improve Marketing Conversion",
    publish_date: "2020-05-21",
    last_modified: "2023-01-27T02:39:51.110Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 307,
    url: "https://www.mx.com/news/pioneer-press-release",
    admin_url: "https://cms.mx.com/admin/content/mx_news/307",
    name: "Pioneer Bank Achieves Greater Visibility into Customers Accounts with MX",
    publish_date: "2018-09-18",
    last_modified: "2023-01-27T02:52:20.860Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 277,
    url: "https://www.mx.com/news/coast-capital",
    admin_url: "https://cms.mx.com/admin/content/mx_news/277",
    name: "MX and Coast Capital empower members with the Take Charge Money Manager",
    publish_date: "2018-01-25",
    last_modified: "2023-01-27T02:52:57.589Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 296,
    url: "https://www.mx.com/news/apiture-teams-with-mx-to-launch-personal-banking-tools/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/296",
    name: "Apiture Teams with MX to Launch Personal Banking Tools",
    publish_date: "2018-07-19",
    last_modified: "2023-01-27T02:53:41.960Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 310,
    url: "https://www.mx.com/news/mx-named-to-the-entrepreneur-top-company-cultures-list",
    admin_url: "https://cms.mx.com/admin/content/mx_news/310",
    name: "MX named to the Entrepreneur 2018 Top Company Cultures List",
    publish_date: "2018-09-27",
    last_modified: "2023-01-27T02:55:23.803Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 356,
    url: "https://www.mx.com/news/ben-holzman-named-coo-of-mx/",
    admin_url: "https://cms.mx.com/admin/content/mx_news/356",
    name: "Ben Holzman named COO of MX",
    publish_date: "2018-06-13",
    last_modified: "2023-01-27T02:55:53.420Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 493,
    url: "https://www.mx.com/news/money-experience-summit-2020-accelerating-the-future-of-money",
    admin_url: "https://cms.mx.com/admin/content/mx_news/493",
    name: "Money Experience Summit 2020: Accelerating The Future Of Money",
    publish_date: "2020-08-27",
    last_modified: "2023-01-27T02:56:27.636Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 76,
    url: "https://www.mx.com/news/mx-announces-peyton-manning-to-keynote-money-experience-summit-2021-at-utah-s-snowbird-mountain-resort",
    admin_url: "https://cms.mx.com/admin/content/mx_news/76",
    name: "MX Announces Peyton Manning to Keynote Money Experience Summit 2021 at Utah’s Snowbird Mountain Resort",
    publish_date: "2021-03-31",
    last_modified: "2023-01-30T22:12:40.835Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 427,
    url: "https://www.mx.com/blog/striking-the-balance-to-meet-consumer-expectations",
    admin_url: "https://cms.mx.com/admin/content/blog/427",
    name: "Quick and Secure: Striking the Balance to Meet Consumer Expectations",
    publish_date: "2023-01-31",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 180,
    url: "https://www.mx.com/news/h-and-r-block-partners-with-mx-on-spruce-giving-consumers-transparency-about-their-spending",
    admin_url: "https://cms.mx.com/admin/content/mx_news/180",
    name: "H&R Block Partners with MX on Spruce℠ Giving Consumers Transparency About Their Spending",
    publish_date: "2022-02-17",
    last_modified: "2023-02-06T22:53:14.938Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 152,
    url: "https://www.mx.com/news/fin-ledger-mx-access-launches-offering-open-api-access-to-fix-screen-scraping",
    admin_url: "https://cms.mx.com/admin/content/mx_news/152",
    name: "MX access launches, offering open API access to fix screen scraping",
    publish_date: "2021-10-28",
    last_modified: "2023-02-08T00:35:31.266Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 153,
    url: "https://www.mx.com/news/bank-automation-news-api-based-solutions-aim-to-accelerate-broken-processes-at-f-is",
    admin_url: "https://cms.mx.com/admin/content/mx_news/153",
    name: "API-based solutions aim to accelerate ‘broken’ processes at FIs",
    publish_date: "2021-11-02",
    last_modified: "2023-02-08T00:35:31.267Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 129,
    url: "https://www.mx.com/news/american-banker-u-s-banks-give-embedded-finance-a-whirl-will-it-catch-on",
    admin_url: "https://cms.mx.com/admin/content/mx_news/129",
    name: "U.S. banks give embedded finance a whirl. Will it catch on?",
    publish_date: "2021-08-30",
    last_modified: "2023-02-08T00:35:31.417Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 138,
    url: "https://www.mx.com/news/entrepreneur-4-skills-that-business-leaders-can-learn-from-master-gardners",
    admin_url: "https://cms.mx.com/admin/content/mx_news/138",
    name: "4 Skills That Business Leaders Can Learn From Master Gardners",
    publish_date: "2021-09-21",
    last_modified: "2023-02-08T00:35:31.418Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 131,
    url: "https://www.mx.com/news/banking-dive-prioritization-is-the-new-innovation-3-ways-the-financial-industry-can-decide-on-digital-initiatives",
    admin_url: "https://cms.mx.com/admin/content/mx_news/131",
    name: "Prioritization is the new innovation: 3 ways the financial industry can decide on digital initiatives",
    publish_date: "2021-09-07",
    last_modified: "2023-02-08T00:35:32.123Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 107,
    url: "https://www.mx.com/news/entrepreneur-data-is-the-new-currency-don-t-let-it-slip-through-your-fingers",
    admin_url: "https://cms.mx.com/admin/content/mx_news/107",
    name: "Data is the New Currency. Don’t Let It Slip Through Your Fingers",
    publish_date: "2021-06-21",
    last_modified: "2023-02-08T00:35:32.237Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 144,
    url: "https://www.mx.com/news/pymnts-security-breaches-underscore-questions-on-open-banking-s-data-liability",
    admin_url: "https://cms.mx.com/admin/content/mx_news/144",
    name: "Security Breaches Underscore Questions on Open Banking’s Data Liability",
    publish_date: "2021-10-04",
    last_modified: "2023-02-08T00:35:32.258Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 108,
    url: "https://www.mx.com/news/forbes-prioritization-is-the-new-innovation-three-ways-the-financial-industry-can-decide-on-digital-initiatives",
    admin_url: "https://cms.mx.com/admin/content/mx_news/108",
    name: "Prioritization Is The New Innovation: Three Ways The Financial Industry Can Decide On Digital Initiatives",
    publish_date: "2021-07-02",
    last_modified: "2023-02-08T00:35:32.259Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 26,
    url: "https://www.mx.com/news/american-banker-banking-data-analytics-vendor-mx-raises-dollar300-million",
    admin_url: "https://cms.mx.com/admin/content/mx_news/26",
    name: "Banking data analytics vendor MX raises $300 million",
    publish_date: "2021-01-13",
    last_modified: "2023-02-08T00:35:32.403Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 83,
    url: "https://www.mx.com/news/finovate-more-than-3-3-billion-raised-by-26-alums-in-q1-of-2021",
    admin_url: "https://cms.mx.com/admin/content/mx_news/83",
    name: "More Than $3.3 Billion Raised by 26 Alums in Q1 of 2021",
    publish_date: "2021-04-05",
    last_modified: "2023-02-08T00:35:32.406Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 35,
    url: "https://www.mx.com/news/bank-automation-news-most-consumers-want-aggregation-so-why-do-only-40-of-banks-offer-it",
    admin_url: "https://cms.mx.com/admin/content/mx_news/35",
    name: "Most consumers want aggregation, so why do only 40% of banks offer it?",
    publish_date: "2021-03-11",
    last_modified: "2023-02-08T00:35:32.408Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 210,
    url: "https://www.mx.com/news/american-banker-how-a-former-adidas-exec-is-transforming-becu",
    admin_url: "https://cms.mx.com/admin/content/mx_news/210",
    name: "How a former Adidas exec is transforming BECU",
    publish_date: "2022-06-17",
    last_modified: "2023-02-08T00:35:32.428Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 33,
    url: "https://www.mx.com/news/aba-banking-journal-a-wealth-of-opportunity",
    admin_url: "https://cms.mx.com/admin/content/mx_news/33",
    name: "A Wealth of Opportunity",
    publish_date: "2021-02-01",
    last_modified: "2023-02-08T00:35:32.463Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 78,
    url: "https://www.mx.com/news/go-banking-rates-over-half-of-americans-need-1-400-stimulus-to-pay-basic-expenses",
    admin_url: "https://cms.mx.com/admin/content/mx_news/78",
    name: "Over Half of Americans Need $1,400 Stimulus to Pay Basic Expenses",
    publish_date: "2021-03-25",
    last_modified: "2023-02-08T00:35:32.470Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 72,
    url: "https://www.mx.com/news/business-insider-wall-street-people-moves-of-the-week-james-dotter-brian-kinion",
    admin_url: "https://cms.mx.com/admin/content/mx_news/72",
    name: "Wall Street people moves of the week [James Dotter, Brian Kinion]",
    publish_date: "2021-03-06",
    last_modified: "2023-02-08T00:35:32.474Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 10,
    url: "https://www.mx.com/news/fortune-term-sheet-investor-deals-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/10",
    name: "Term Sheet: Investor Deals: MX",
    publish_date: "2021-01-13",
    last_modified: "2023-02-08T00:35:32.480Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 86,
    url: "https://www.mx.com/news/forbes-12-expert-tips-to-help-small-businesses-affordably-leverage-ai-ml",
    admin_url: "https://cms.mx.com/admin/content/mx_news/86",
    name: "12 Expert Tips To Help Small Businesses Affordably Leverage AI/ML",
    publish_date: "2021-04-19",
    last_modified: "2023-02-08T00:35:32.483Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 112,
    url: "https://www.mx.com/news/3-ways-to-keep-data-fraud-from-sabotaging-digital-banking-cx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/112",
    name: "3 Ways to Keep Data Fraud From Sabotaging Digital Banking CX",
    publish_date: "2021-07-14",
    last_modified: "2023-02-08T00:35:32.487Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 32,
    url: "https://www.mx.com/news/mx-launches-bill-predictor",
    admin_url: "https://cms.mx.com/admin/content/mx_news/32",
    name: "MX launches Bill Predictor",
    publish_date: "2021-03-18",
    last_modified: "2023-02-08T00:35:32.490Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 61,
    url: "https://www.mx.com/news/the-street-peoples-united-being-bought-by-m-and-t-bank-for-7-billion",
    admin_url: "https://cms.mx.com/admin/content/mx_news/61",
    name: "People's United Being Bought by M&T Bank for $7.6 Billion",
    publish_date: "2021-02-22",
    last_modified: "2023-02-08T00:35:32.504Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 14,
    url: "https://www.mx.com/news/utah-business-maybe-its-time-for-a-more-casual-linkedin-photo",
    admin_url: "https://cms.mx.com/admin/content/mx_news/14",
    name: "Maybe it's time for a more casual LinkedIn photo",
    publish_date: "2021-01-12",
    last_modified: "2023-02-08T00:35:32.531Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 63,
    url: "https://www.mx.com/news/forbes-the-responsibility-of-the-financial-industry-during-covid-19-and-beyond",
    admin_url: "https://cms.mx.com/admin/content/mx_news/63",
    name: "The Responsibility Of The Financial Industry During Covid-19 And Beyond",
    publish_date: "2020-10-28",
    last_modified: "2023-02-08T00:35:32.536Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 66,
    url: "https://www.mx.com/news/crowdfund-insider-us-fintech-unicorn-mx-technologies-reveals-that-financial-wellness-is-now-special-focus-area-for-financial-service-providers",
    admin_url: "https://cms.mx.com/admin/content/mx_news/66",
    name: "US Fintech Unicorn MX Technologies Reveals that Financial Wellness Is Now Special Focus Area for Financial Service Providers",
    publish_date: "2021-03-01",
    last_modified: "2023-02-08T00:35:32.541Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 70,
    url: "https://www.mx.com/news/bankrate-sick-of-spreadsheets-heres-everything-you-need-to-know-about-personal-financial-management-pfm",
    admin_url: "https://cms.mx.com/admin/content/mx_news/70",
    name: "Sick of spreadsheets? Here’s everything you need to know about personal financial management (PFM)",
    publish_date: "2020-10-27",
    last_modified: "2023-02-08T00:35:32.557Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 59,
    url: "https://www.mx.com/news/cuinsights-alaska-usa-federal-credit-union-golf-invitational-raises-dollar125000",
    admin_url: "https://cms.mx.com/admin/content/mx_news/59",
    name: "Alaska USA Federal Credit Union Golf Invitational raises $125,000",
    publish_date: "2020-12-14",
    last_modified: "2023-02-08T00:35:32.569Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 12,
    url: "https://www.mx.com/news/fast-company-this-will-be-a-year-of-financial-reckoning-here-are-3-things-to-do-to-take-control",
    admin_url: "https://cms.mx.com/admin/content/mx_news/12",
    name: "This will be a year of financial reckoning: 3 ways to take control",
    publish_date: "2021-02-12",
    last_modified: "2023-02-08T00:35:32.574Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 74,
    url: "https://www.mx.com/news/entrepreneur-great-company-culture-isn-t-magic-take-these-steps-to-create-it",
    admin_url: "https://cms.mx.com/admin/content/mx_news/74",
    name: "Great Company Culture Isn't Magic -- Take These Steps To Create It",
    publish_date: "2021-03-24",
    last_modified: "2023-02-08T00:35:32.584Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 67,
    url: "https://www.mx.com/news/finledger-mandt-bank-is-prioritizing-digital-investments",
    admin_url: "https://cms.mx.com/admin/content/mx_news/67",
    name: "M&T Bank is prioritizing digital investments",
    publish_date: "2020-09-24",
    last_modified: "2023-02-08T00:35:32.588Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 27,
    url: "https://www.mx.com/news/forbes-fintechs-should-sell-financial-health-not-financial-services",
    admin_url: "https://cms.mx.com/admin/content/mx_news/27",
    name: "Fintechs Should Sell Financial Health, Not Financial Services",
    publish_date: "2020-10-01",
    last_modified: "2023-02-08T00:35:32.597Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 28,
    url: "https://www.mx.com/news/business-insider-mx-deal-spotlights-data-sharings-starring-role-in-embedded-finance",
    admin_url: "https://cms.mx.com/admin/content/mx_news/28",
    name: "MX deal spotlights data sharing's starring role in embedded finance",
    publish_date: "2021-01-08",
    last_modified: "2023-02-08T00:35:32.598Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 68,
    url: "https://www.mx.com/news/forbes-how-open-finance-will-be-the-catalyst-for-a-new-era-in-the-financial-industry",
    admin_url: "https://cms.mx.com/admin/content/mx_news/68",
    name: "How Open Finance Will Be The Catalyst For A New Era In The Financial Industry",
    publish_date: "2021-01-14",
    last_modified: "2023-02-08T00:35:32.640Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 16,
    url: "https://www.mx.com/news/the-motley-fool-mx-spotlighted",
    admin_url: "https://cms.mx.com/admin/content/mx_news/16",
    name: "The Motley Fool spotlights MX",
    publish_date: "2020-11-01",
    last_modified: "2023-02-08T00:35:32.650Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 64,
    url: "https://www.mx.com/news/forbes-how-fintech-can-help-the-financial-health-crisis",
    admin_url: "https://cms.mx.com/admin/content/mx_news/64",
    name: "How Fintech Can Help The Financial Health Crisis",
    publish_date: "2020-09-28",
    last_modified: "2023-02-08T00:35:32.654Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 65,
    url: "https://www.mx.com/news/crowdfund-insider-fintech-funding-13-dollar100-million-mega-rounds",
    admin_url: "https://cms.mx.com/admin/content/mx_news/65",
    name: "Fintech Funding: 13 $100 Million+ Mega-Rounds ",
    publish_date: "2021-01-28",
    last_modified: "2023-02-08T00:35:32.666Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 34,
    url: "https://www.mx.com/news/banking-dive-aggregator-mx-to-expand-reach-of-money-experiences-as-it-angles-toward-ipo",
    admin_url: "https://cms.mx.com/admin/content/mx_news/34",
    name: "Aggregator MX to expand reach of 'money experiences' as it angles toward IPO",
    publish_date: "2021-01-20",
    last_modified: "2023-02-08T00:35:32.678Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 30,
    url: "https://www.mx.com/news/bloomberg-financial-data-firm-mx-quadruples-its-valuation-to-dollar19-billion",
    admin_url: "https://cms.mx.com/admin/content/mx_news/30",
    name: "Financial-Data Firm MX Quadruples Its Valuation to $1.9 Billion",
    publish_date: "2021-01-13",
    last_modified: "2023-02-08T00:35:32.684Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 13,
    url: "https://www.mx.com/news/ksl-latest-unicorn-dollar300m-funding-round-gives-utah-based-fintech-company-mx-dollar19b-valuation",
    admin_url: "https://cms.mx.com/admin/content/mx_news/13",
    name: "Latest unicorn: $300M funding round gives Utah-based fintech company MX $1.9B valuation",
    publish_date: "2021-01-13",
    last_modified: "2023-02-08T00:35:32.688Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 58,
    url: "https://www.mx.com/news/american-banker-small-louisiana-bank-gives-customers-tool-to-safeguard-their-data",
    admin_url: "https://cms.mx.com/admin/content/mx_news/58",
    name: "Small Louisiana bank gives customers tool to safeguard their data",
    publish_date: "2020-09-29",
    last_modified: "2023-02-08T00:35:32.706Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 279,
    url: "https://www.mx.com/news/five-things-you-didnt-realize-about-product-design",
    admin_url: "https://cms.mx.com/admin/content/mx_news/279",
    name: "Five Things You Didn’t Realize About Product Design",
    publish_date: "2018-04-17",
    last_modified: "2023-02-08T00:35:32.712Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 23,
    url: "https://www.mx.com/news/business-insider-mandts-head-of-digital-on-how-the-bank-is-adapting-services-to-customers-needs-amid-the-pandemic",
    admin_url: "https://cms.mx.com/admin/content/mx_news/23",
    name: "Business Insider: M&T's head of digital on how the bank is adapting services to customers' needs amid the pandemic",
    publish_date: "2020-10-30",
    last_modified: "2023-02-08T00:35:32.731Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 463,
    url: "https://www.mx.com/news/politico-everyone-is-flying-totally-blind-on-the-economy-and-markets",
    admin_url: "https://cms.mx.com/admin/content/mx_news/463",
    name: "Everyone is flying totally blind on the economy and markets",
    publish_date: "2020-05-05",
    last_modified: "2023-02-08T00:35:32.739Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 337,
    url: "https://www.mx.com/news/tearsheet-features-mx-in-data-aggregation-series",
    admin_url: "https://cms.mx.com/admin/content/mx_news/337",
    name: "The future of data aggregation and the future of finance",
    publish_date: "2019-03-14",
    last_modified: "2023-02-08T00:35:32.744Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 329,
    url: "https://www.mx.com/news/mx-gets-shout-out-in-forbes",
    admin_url: "https://cms.mx.com/admin/content/mx_news/329",
    name: "The Artificial Intelligence Gap Between Megabanks, Community Banks And Credit Unions",
    publish_date: "2019-01-18",
    last_modified: "2023-02-08T00:35:32.785Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 460,
    url: "https://www.mx.com/news/utah-business-a-conversation-about-working-from-home",
    admin_url: "https://cms.mx.com/admin/content/mx_news/460",
    name: "A Conversation About Working From Home",
    publish_date: "2020-04-28",
    last_modified: "2023-02-08T00:35:32.796Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 301,
    url: "https://www.mx.com/news/origin-bank-praises-mx-personal-finance-in-american-banker",
    admin_url: "https://cms.mx.com/admin/content/mx_news/301",
    name: "Small banks need big data to maintain customer service edge",
    publish_date: "2018-08-22",
    last_modified: "2023-02-08T00:35:32.821Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 29,
    url: "https://www.mx.com/news/thinkadvisor-advicent-selects-the-mx-financial-data-platform",
    admin_url: "https://cms.mx.com/admin/content/mx_news/29",
    name: "Advicent selects the MX financial data platform",
    publish_date: "2020-09-10",
    last_modified: "2023-02-08T00:35:32.823Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 305,
    url: "https://www.mx.com/news/mx-billboards-get-shout-out-in-forbes",
    admin_url: "https://cms.mx.com/admin/content/mx_news/305",
    name: "This is How LeBron James Can Guide Your Recruitment Strategy",
    publish_date: "2018-08-27",
    last_modified: "2023-02-08T00:35:32.856Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 5,
    url: "https://www.mx.com/news/deseret-news-utahs-mx-pulls-in-dollar300m-in-fresh-funding-nears-a-dollar2b-valuation",
    admin_url: "https://cms.mx.com/admin/content/mx_news/5",
    name: "Utah’s MX pulls in $300M in fresh funding, nears a $2B valuation",
    publish_date: "2021-01-14",
    last_modified: "2023-02-08T14:47:34.920Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 126,
    url: "https://www.mx.com/news/forbes-the-great-data-debate-a-mx-point-of-view",
    admin_url: "https://cms.mx.com/admin/content/mx_news/126",
    name: "The Great Data Debate: A MX Point Of View",
    publish_date: "2021-08-17",
    last_modified: "2023-02-08T16:11:42.141Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 428,
    url: "https://www.mx.com/blog/macro-trends-impacting-banks",
    admin_url: "https://cms.mx.com/admin/content/blog/428",
    name: "Breaking Down Top Macroeconomic Trends Impacting Banks",
    publish_date: "2023-02-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 429,
    url: "https://www.mx.com/blog/macroeconomic-trends-credit-unions",
    admin_url: "https://cms.mx.com/admin/content/blog/429",
    name: "How Credit Unions Can Tackle Macroeconomic Trends to Win in 2023",
    publish_date: "2023-02-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 204,
    url: "https://www.mx.com/news/acceleration-of-open-finance-fuels-growth-of-mx-partner-network-",
    admin_url: "https://cms.mx.com/admin/content/mx_news/204",
    name: "Acceleration of Open Finance Fuels Growth of MX Partner Network",
    publish_date: "2022-06-08",
    last_modified: "2023-02-23T15:38:45.106Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 220,
    url: "https://www.mx.com/news/mx-simplifies-data-sharing-with-secure-processor-tokens",
    admin_url: "https://cms.mx.com/admin/content/mx_news/220",
    name: "MX Simplifies Data Sharing with Secure Processor Tokens",
    publish_date: "2022-07-19",
    last_modified: "2023-02-23T15:39:05.776Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 430,
    url: "https://www.mx.com/blog/macroeconomic-insurance-trends",
    admin_url: "https://cms.mx.com/admin/content/blog/430",
    name: "4 Macroeconomic Trends Impacting Insurance Companies",
    publish_date: "2023-02-27",
    last_modified: null,
    review: true,
  },
  {
    id: 608,
    status: "published",
    date_created: "2025-02-28T22:46:18.922Z",
    path: "riff/index.html",
    url: "https://www.mx.com/riff/",
    last_modified: "2023-03-01",
    publish_date: "2023-03-01",
    review: true,
  },
  {
    id: 609,
    status: "published",
    date_created: "2025-02-28T22:46:19.096Z",
    path: "riff-on-a-lift/index.html",
    url: "https://www.mx.com/riff-on-a-lift/",
    last_modified: "2023-03-01",
    publish_date: "2023-03-01",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 40,
    url: "https://www.mx.com/news/yahoo-news-fintech-focus-mx-the-state-of-banking-into-2021",
    admin_url: "https://cms.mx.com/admin/content/mx_news/40",
    name: "Fintech Focus - MX: The State of Banking in 2021",
    publish_date: "2020-12-21",
    last_modified: "2023-03-06T22:31:07.576Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 154,
    url: "https://www.mx.com/news/business-insider-us-consumers-must-trust-their-financial-institution-before-they-ll-share-their-bank-credentials",
    admin_url: "https://cms.mx.com/admin/content/mx_news/154",
    name: "US consumers must trust their FI to share their bank credentials",
    publish_date: "2021-11-04",
    last_modified: "2023-03-06T22:36:29.649Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 432,
    url: "https://www.mx.com/blog/harnessing-ai-for-fintech-innovation",
    admin_url: "https://cms.mx.com/admin/content/blog/432",
    name: "Harnessing the Power of AI for Fintech Innovation",
    publish_date: "2023-03-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 75,
    url: "https://www.mx.com/fintech-festival/2023/",
    admin_url: "https://cms.mx.com/admin/content/webinars/75",
    name: "Fintech Festival On-Demand Keynotes",
    publish_date: "2023-03-15",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 435,
    url: "https://www.mx.com/blog/leveraging-data-to-drive-growth",
    admin_url: "https://cms.mx.com/admin/content/blog/435",
    name: "Leveraging Data To Drive New Growth Opportunities",
    publish_date: "2023-03-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 42,
    url: "https://www.mx.com/news/ai-thority-mx-names-james-dotter-as-company-s-first-chief-business-officer",
    admin_url: "https://cms.mx.com/admin/content/mx_news/42",
    name: "MX Names James Dotter as Company’s First Chief Business Officer",
    publish_date: "2021-03-05",
    last_modified: "2023-03-22T21:55:38.174Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 94,
    url: "https://www.mx.com/news/the-financial-brand-fintech-bank-hybrids-are-the-future-of-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/94",
    name: "Fintech-Bank Hybrids Are the Future of Banking",
    publish_date: "2021-05-19",
    last_modified: "2023-03-22T22:04:10.965Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 51,
    url: "https://www.mx.com/news/yahoo-finance-mx-ceo-ryan-caldwell-on-raising-dollar300m-series-c-funding",
    admin_url: "https://cms.mx.com/admin/content/mx_news/51",
    name: "MX CEO Ryan Caldwell on raising $300M Series C Funding",
    publish_date: "2021-01-19",
    last_modified: "2023-03-22T22:24:01.115Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 170,
    url: "https://www.mx.com/news/cnbc-fintech-start-up-mx-names-new-ceo-as-firm-prepares-for-sale-or-ipo",
    admin_url: "https://cms.mx.com/admin/content/mx_news/170",
    name: "Fintech start-up MX names new CEO as firm prepares for sale or IPO",
    publish_date: "2022-01-12",
    last_modified: "2023-03-22T22:34:25.728Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 436,
    url: "https://www.mx.com/blog/investor-advice-for-fintechs",
    admin_url: "https://cms.mx.com/admin/content/blog/436",
    name: "Investor Advice for Fintechs",
    publish_date: "2023-03-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 37,
    url: "https://www.mx.com/news/mx-named-one-of-top-5-mobile-banking-solution-providers",
    admin_url: "https://cms.mx.com/admin/content/mx_news/37",
    name: "MX Named One Of Top 5 Mobile Banking Solution Providers",
    publish_date: "2020-12-04",
    last_modified: "2023-03-29T21:05:16.007Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 156,
    url: "https://www.mx.com/news/banking-dive-how-neobanks-are-cashing-in-on-the-gen-z-market",
    admin_url: "https://cms.mx.com/admin/content/mx_news/156",
    name: "How neobanks are cashing in on the Gen Z market",
    publish_date: "2021-11-10",
    last_modified: "2023-03-29T21:16:29.922Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 36,
    url: "https://www.mx.com/news/crowdfund-insider-bbva-usa-launches-bbva-insights-to-online-banking-and-mobile-banking-app",
    admin_url: "https://cms.mx.com/admin/content/mx_news/36",
    name: "BBVA USA Launches BBVA Insights to Online Banking & Mobile Banking App",
    publish_date: "2021-02-15",
    last_modified: "2023-03-29T21:25:23.755Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 89,
    url: "https://www.mx.com/news/nyc-fintech-women-fin-tech-female-fridays-meet-mx-chief-advocacy-officer-jane-barratt",
    admin_url: "https://cms.mx.com/admin/content/mx_news/89",
    name: "FinTech Female Fridays: Meet MX Chief Advocacy Officer, Jane Barratt",
    publish_date: "2021-04-23",
    last_modified: "2023-03-29T21:37:14.812Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 31,
    url: "https://www.mx.com/news/ny-daily-news-a-year-of-financial-reckoning",
    admin_url: "https://cms.mx.com/admin/content/mx_news/31",
    name: "A Year of Financial Reckoning",
    publish_date: "2021-02-21",
    last_modified: "2023-03-29T21:43:58.989Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 21,
    url: "https://www.mx.com/news/crunchbase-providing-a-money-experience-mx-banks-on-dollar300m-series-c",
    admin_url: "https://cms.mx.com/admin/content/mx_news/21",
    name: "Providing A Money Experience: MX Banks On $300M Series C",
    publish_date: "2021-01-12",
    last_modified: "2023-03-29T21:52:32.045Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 20,
    url: "https://www.mx.com/news/the-financial-brand-3-ways-to-use-data-to-build-an-impactful-financial-wellness-strategy",
    admin_url: "https://cms.mx.com/admin/content/mx_news/20",
    name: "3 Ways to Use Data to Build an Impactful Financial Wellness Strategy",
    publish_date: "2021-02-18",
    last_modified: "2023-03-29T22:16:34.156Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 7,
    url: "https://www.mx.com/news/reuters-fintech-startup-mx-raises-dollar300-mln-at-dollar19-bln-value",
    admin_url: "https://cms.mx.com/admin/content/mx_news/7",
    name: "Fintech startup MX raises $300 mln at $1.9 bln value",
    publish_date: "2021-01-13",
    last_modified: "2023-03-29T22:26:48.201Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 235,
    url: "https://www.mx.com/news/forbes-new-payment-service-using-same-tech-as-zelle-and-venmo-will-cut-out-visa-and-mastercard-fees",
    admin_url: "https://cms.mx.com/admin/content/mx_news/235",
    name: "New Payment Service Using Same Tech As Zelle And Venmo Will Cut Out Visa And Mastercard Fees",
    publish_date: "2022-08-26",
    last_modified: "2023-03-29T22:31:50.781Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 214,
    url: "https://www.mx.com/news/idg-connect-cio-spotlight-felix-quintana-mx-technologies",
    admin_url: "https://cms.mx.com/admin/content/mx_news/214",
    name: "CIO Spotlight: Felix Quintana, MX Technologies",
    publish_date: "2022-07-04",
    last_modified: "2023-03-29T22:41:51.206Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 437,
    url: "https://www.mx.com/blog/top-takeaways-from-riff-near-a-lift-2023",
    admin_url: "https://cms.mx.com/admin/content/blog/437",
    name: "Top Takeaways from Senior Finance and Fintech Leaders: Data, Relationships, Resiliency, and Growth",
    publish_date: "2023-03-30",
    last_modified: null,
    review: true,
  },
  {
    id: 613,
    status: "published",
    date_created: "2025-02-28T22:46:19.491Z",
    path: "signature-generator/index.html",
    url: "https://www.mx.com/signature-generator/",
    last_modified: "2023-04-05",
    publish_date: "2023-04-05",
    review: true,
  },
  {
    id: 475,
    status: "published",
    date_created: "2025-02-28T22:46:04.890Z",
    path: "analysts/q12022update/index.html",
    url: "https://www.mx.com/analysts/q12022update/",
    last_modified: "2023-04-06",
    publish_date: "2022-05-26",
    review: true,
  },
  {
    id: 476,
    status: "published",
    date_created: "2025-02-28T22:46:05.033Z",
    path: "analysts/q22022update/index.html",
    url: "https://www.mx.com/analysts/q22022update/",
    last_modified: "2023-04-06",
    publish_date: "2022-08-31",
    review: true,
  },
  {
    id: 554,
    status: "published",
    date_created: "2025-02-28T22:46:13.127Z",
    path: "minimum-terms-and-conditions/index.html",
    url: "https://www.mx.com/minimum-terms-and-conditions/",
    last_modified: "2023-04-06",
    publish_date: "2022-05-12",
    review: true,
  },
  {
    id: 594,
    status: "published",
    date_created: "2025-02-28T22:46:17.494Z",
    path: "qualtrics-survey/index.html",
    url: "https://www.mx.com/qualtrics-survey/",
    last_modified: "2023-04-06",
    publish_date: "2022-09-08",
    review: true,
  },
  {
    status: "published",
    content_type: "events",
    id: 14,
    url: "https://www.mx.com/events/executive-exchange-dinner/04242023",
    admin_url: "https://cms.mx.com/admin/content/events/14",
    name: "MX Executive Exchange Dinner in Atlanta | April 2023",
    publish_date: "2023-04-03T17:48:14.094Z",
    last_modified: "2023-04-06T13:50:17.874Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 438,
    url: "https://www.mx.com/blog/do-consumers-trust-financial-services",
    admin_url: "https://cms.mx.com/admin/content/blog/438",
    name: "Do Consumers Trust Financial Services?",
    publish_date: "2023-04-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "events",
    id: 13,
    url: "https://www.mx.com/events/dinner-networking-drinks/04182023",
    admin_url: "https://cms.mx.com/admin/content/events/13",
    name: "MX Dinner at 2023 FDX Summit",
    publish_date: "2023-03-28T20:42:58.073Z",
    last_modified: "2023-04-17T16:11:11.008Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 439,
    url: "https://www.mx.com/blog/financial-providers-can-ease-consumer-money-stress",
    admin_url: "https://cms.mx.com/admin/content/blog/439",
    name: "How Financial Providers Can Help Ease Rising Consumer Money Stress",
    publish_date: "2023-04-24",
    last_modified: null,
    review: true,
  },
  {
    id: 614,
    status: "published",
    date_created: "2025-02-28T22:46:19.593Z",
    path: "signature-generator-mxs/index.html",
    url: "https://www.mx.com/signature-generator-mxs/",
    last_modified: "2023-04-25",
    publish_date: "2023-04-25",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 4,
    url: "https://www.mx.com/products/spec-sheets/account-aggregation",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/4",
    name: "Account Aggregation",
    publish_date: "2023-02-07T16:27:17.151Z",
    last_modified: "2023-05-01T18:02:26.309Z",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 5,
    url: "https://www.mx.com/products/spec-sheets/extended-transaction-history",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/5",
    name: "Extended Transaction History",
    publish_date: "2023-02-07T16:29:20.082Z",
    last_modified: "2023-05-01T18:05:29.491Z",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 6,
    url: "https://www.mx.com/products/spec-sheets/balance-checks",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/6",
    name: "Balance Checks",
    publish_date: "2023-02-07T16:29:56.795Z",
    last_modified: "2023-05-01T18:06:09.541Z",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 8,
    url: "https://www.mx.com/products/spec-sheets/account-owner-identification",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/8",
    name: "Account Owner Identification",
    publish_date: "2023-02-07T16:31:24.182Z",
    last_modified: "2023-05-01T18:08:04.474Z",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 9,
    url: "https://www.mx.com/products/spec-sheets/microdeposits",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/9",
    name: "Microdeposits",
    publish_date: "2023-02-07T16:32:15.995Z",
    last_modified: "2023-05-01T18:10:36.824Z",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 11,
    url: "https://www.mx.com/products/spec-sheets/statements",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/11",
    name: "Statements",
    publish_date: "2023-02-07T16:51:15.663Z",
    last_modified: "2023-05-01T18:11:28.597Z",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 12,
    url: "https://www.mx.com/products/spec-sheets/data-enhancement-on-platform",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/12",
    name: "Data Enhancement On Platform ",
    publish_date: "2023-02-07T16:51:48.007Z",
    last_modified: "2023-05-01T18:12:18.790Z",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 14,
    url: "https://www.mx.com/products/spec-sheets/business-data-enhancement",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/14",
    name: "Business Data Enhancement",
    publish_date: "2023-02-07T16:53:09.995Z",
    last_modified: "2023-05-01T18:13:20.685Z",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 15,
    url: "https://www.mx.com/products/spec-sheets/pfm",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/15",
    name: "Personal Finance Management (PFM)",
    publish_date: "2023-02-07T16:53:40.306Z",
    last_modified: "2023-05-01T18:14:51.237Z",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 19,
    url: "https://www.mx.com/products/spec-sheets/customer-analytics",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/19",
    name: "Customer Analytics",
    publish_date: "2023-02-07T16:57:06.483Z",
    last_modified: "2023-05-01T18:21:29.424Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 440,
    url: "https://www.mx.com/blog/financial-institution-trends",
    admin_url: "https://cms.mx.com/admin/content/blog/440",
    name: "Current Trends for Financial Institutions",
    publish_date: "2023-05-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 441,
    url: "https://www.mx.com/blog/financial-wellness-consumer-attitudes",
    admin_url: "https://cms.mx.com/admin/content/blog/441",
    name: "What Financial Wellness Means for Consumers",
    publish_date: "2023-05-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 18,
    url: "https://www.mx.com/products/spec-sheets/data-access",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/18",
    name: "Data Access",
    publish_date: "2023-02-07T16:56:39.200Z",
    last_modified: "2023-05-11T18:15:33.951Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 442,
    url: "https://www.mx.com/blog/fintech-trends",
    admin_url: "https://cms.mx.com/admin/content/blog/442",
    name: "What Fintechs Should Watch in Q2 2023",
    publish_date: "2023-05-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 443,
    url: "https://www.mx.com/blog/using-data-for-financial-wellness",
    admin_url: "https://cms.mx.com/admin/content/blog/443",
    name: "Using Data to Drive Consumer Financial Wellness",
    publish_date: "2023-05-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 444,
    url: "https://www.mx.com/blog/evolving-attitudes-on-debt",
    admin_url: "https://cms.mx.com/admin/content/blog/444",
    name: "Will Younger Generations Turn to Loans and Credit Cards Less Often?",
    publish_date: "2023-05-19",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 445,
    url: "https://www.mx.com/blog/whats-appening",
    admin_url: "https://cms.mx.com/admin/content/blog/445",
    name: "What’s App-ening? Mobile Money Experiences  ",
    publish_date: "2023-05-23",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 446,
    url: "https://www.mx.com/blog/consumer-expectations-for-better-money-experiences",
    admin_url: "https://cms.mx.com/admin/content/blog/446",
    name: "Consumer Expectations for a Better Money Experience",
    publish_date: "2023-05-25",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "events",
    id: 16,
    url: "https://www.mx.com/events/cocc-raffle/06162023",
    admin_url: "https://cms.mx.com/admin/content/events/16",
    name: null,
    publish_date: "2023-04-13T15:49:44.896Z",
    last_modified: "2023-05-31T19:53:33.662Z",
    review: true,
  },
  {
    status: "published",
    content_type: "events",
    id: 21,
    url: "https://www.mx.com/events/charlotte-exec-dinner/06212023",
    admin_url: "https://cms.mx.com/admin/content/events/21",
    name: "MX Executive Exchange Dinner | June 2023",
    publish_date: "2023-06-06T16:39:07.574Z",
    last_modified: "2023-06-06T16:42:39.213Z",
    review: true,
  },
  {
    status: "published",
    content_type: "events",
    id: 18,
    url: "https://www.mx.com/events/exec-dinner/06132023",
    admin_url: "https://cms.mx.com/admin/content/events/18",
    name: "Join MX for an Exclusive Executive Dinner",
    publish_date: "2023-05-16T17:23:46.872Z",
    last_modified: "2023-06-08T13:16:06.921Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 210,
    url: "https://www.mx.com/blog/what-is-open-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/210",
    name: "What is Open Banking? What it Means, Examples, Use Cases",
    publish_date: "2023-06-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 332,
    url: "https://www.mx.com/blog/5-reasons-to-choose-ach",
    admin_url: "https://cms.mx.com/admin/content/blog/332",
    name: "5 Reasons to Choose ACH Payments",
    publish_date: "2023-06-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 340,
    url: "https://www.mx.com/blog/what-is-open-finance",
    admin_url: "https://cms.mx.com/admin/content/blog/340",
    name: "What is Open Finance? What it Means, Benefits, and APIs",
    publish_date: "2023-06-09",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 42,
    url: "https://www.mx.com/blog/what-is-instant-account-verification",
    admin_url: "https://cms.mx.com/admin/content/blog/42",
    name: "What is Instant Account Verification? What to Know and Key Benefits",
    publish_date: "2023-06-13",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 447,
    url: "https://www.mx.com/blog/q1-2023-deposits-winners",
    admin_url: "https://cms.mx.com/admin/content/blog/447",
    name: "Who’s Winning on Deposits? Q1 2023 Market Gains and Declines for Banks and Credit Unions",
    publish_date: "2023-06-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 448,
    url: "https://www.mx.com/blog/money-experiences-make-break-consumer-trust",
    admin_url: "https://cms.mx.com/admin/content/blog/448",
    name: "How Money Experiences Make or Break Consumer Trust",
    publish_date: "2023-06-16",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 449,
    url: "https://www.mx.com/blog/what-is-consumer-permissioned-data",
    admin_url: "https://cms.mx.com/admin/content/blog/449",
    name: "What is Consumer-Permissioned Data and Why Does it Matter?",
    publish_date: "2023-06-30",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 450,
    url: "https://www.mx.com/blog/consumer-engagement-key-to-business-growth",
    admin_url: "https://cms.mx.com/admin/content/blog/450",
    name: "Driving Engagement When Consumers Have Multiple Banking Relationships",
    publish_date: "2023-07-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 451,
    url: "https://www.mx.com/blog/q2-mx-partnership-digital-consumers-want-personalized-insights",
    admin_url: "https://cms.mx.com/admin/content/blog/451",
    name: "Digital Consumers Want Personalized Insights | MX + Q2 Partnership",
    publish_date: "2023-07-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 17,
    url: "https://www.mx.com/products/spec-sheets/mobile",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/17",
    name: "Mobile Banking",
    publish_date: "2023-02-07T16:56:02.579Z",
    last_modified: "2023-07-24T18:56:08.710Z",
    review: true,
  },
  {
    id: 471,
    status: "published",
    date_created: "2025-02-28T22:46:04.470Z",
    path: "analysts/faq/index.html",
    url: "https://www.mx.com/analysts/faq/",
    last_modified: "2023-07-26",
    publish_date: "2022-05-06",
    review: true,
  },
  {
    id: 472,
    status: "published",
    date_created: "2025-02-28T22:46:04.577Z",
    path: "analysts/h12023update/index.html",
    url: "https://www.mx.com/analysts/h12023update/",
    last_modified: "2023-07-26",
    publish_date: "2023-07-26",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 452,
    url: "https://www.mx.com/blog/banking-on-trust",
    admin_url: "https://cms.mx.com/admin/content/blog/452",
    name: "Banking on Trust: Consumer Expectations for Financial Providers",
    publish_date: "2023-07-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 16,
    url: "https://www.mx.com/products/spec-sheets/insights",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/16",
    name: "Financial Insights",
    publish_date: "2023-02-07T16:55:40.235Z",
    last_modified: "2023-07-26T22:38:02.026Z",
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 13,
    url: "https://www.mx.com/products/spec-sheets/data-enhancement-off-platform",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/13",
    name: "Data Enhancement Off Platform ",
    publish_date: "2023-02-07T16:52:27.391Z",
    last_modified: "2023-07-27T13:29:30.780Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 231,
    url: "https://www.mx.com/news/mx-named-to-top-100-financial-technology-companies-of-2022",
    admin_url: "https://cms.mx.com/admin/content/mx_news/231",
    name: "MX named to Top 100 Financial Technology Companies of 2022",
    publish_date: "2022-08-17",
    last_modified: "2023-07-27T22:15:00.999Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 213,
    url: "https://www.mx.com/news/utah-business-2022-innovation-awards-honors-m-xaccess",
    admin_url: "https://cms.mx.com/admin/content/mx_news/213",
    name: "2022 Innovation Awards honors MXaccess",
    publish_date: "2022-06-30",
    last_modified: "2023-07-27T22:15:57.845Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 189,
    url: "https://www.mx.com/news/mx-recognized-on-2022-shatter-list-for-programs-to-break-the-glass-ceiling-for-women-in-tech",
    admin_url: "https://cms.mx.com/admin/content/mx_news/189",
    name: "MX Recognized on 2022 Shatter List for Programs to Break the Glass Ceiling for Women in Tech",
    publish_date: "2022-03-31",
    last_modified: "2023-07-27T22:17:26.014Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 164,
    url: "https://www.mx.com/news/mx-ranked-7-on-battery-ventures-list-of-highest-rated-private-cloud-computing-companies-to-work-for",
    admin_url: "https://cms.mx.com/admin/content/mx_news/164",
    name: "MX Ranked #7 on Battery Ventures’ List of Highest-rated Private Cloud Computing Companies to Work for",
    publish_date: "2021-12-14",
    last_modified: "2023-07-27T22:18:42.052Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 160,
    url: "https://www.mx.com/news/mx-named-to-the-salt-lake-tribune-2021-top-workplaces",
    admin_url: "https://cms.mx.com/admin/content/mx_news/160",
    name: "MX named to The Salt Lake Tribune 2021 Top Workplaces",
    publish_date: "2021-11-01",
    last_modified: "2023-07-27T22:18:55.241Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 130,
    url: "https://www.mx.com/news/mx-recognized-in-gartner-report-hype-cycle-for-digital-banking-transformation-in-2021",
    admin_url: "https://cms.mx.com/admin/content/mx_news/130",
    name: 'MX Recognized in Gartner® Report "Hype Cycle"™ for Digital Banking Transformation in 2021',
    publish_date: "2021-09-02",
    last_modified: "2023-07-27T22:19:45.019Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 127,
    url: "https://www.mx.com/news/mx-wins-2-gold-2-bronze-in-the-2021-brandon-hall-group-human-capital-management-awards",
    admin_url: "https://cms.mx.com/admin/content/mx_news/127",
    name: "MX Wins 2 Gold, 2 Bronze in the 2021 Brandon Hall Group Human Capital Management Awards",
    publish_date: "2021-08-23",
    last_modified: "2023-07-27T22:19:56.957Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 116,
    url: "https://www.mx.com/news/business-insider-57-promising-fintechs-building-the-behind-the-scenes-tech-revolutionizing-wall-street",
    admin_url: "https://cms.mx.com/admin/content/mx_news/116",
    name: "Business Insider: 57 promising fintechs building the behind-the-scenes tech revolutionizing Wall Street",
    publish_date: "2021-07-21",
    last_modified: "2023-07-27T22:21:09.326Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 100,
    url: "https://www.mx.com/news/tearheet-best-data-aggregator-fintech-partnership-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/100",
    name: "Tearsheet: Best Data Aggregator/Fintech Partnership: MX",
    publish_date: "2021-06-02",
    last_modified: "2023-07-27T22:21:20.134Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 92,
    url: "https://www.mx.com/news/mx-named-one-of-inc-magazine-s-best-workplaces-for-2021",
    admin_url: "https://cms.mx.com/admin/content/mx_news/92",
    name: "MX named one of Inc. Magazine’s Best Workplaces for 2021",
    publish_date: "2021-05-12",
    last_modified: "2023-07-27T22:21:26.107Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 45,
    url: "https://www.mx.com/news/mx-named-to-women-tech-council-s-2021-shatter-list",
    admin_url: "https://cms.mx.com/admin/content/mx_news/45",
    name: "MX Named to Women Tech Council's 2021 Shatter List",
    publish_date: "2021-03-15",
    last_modified: "2023-07-27T22:24:33.039Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 453,
    url: "https://www.mx.com/blog/products-that-scale-and-deliver-better-experiences",
    admin_url: "https://cms.mx.com/admin/content/blog/453",
    name: "Building Products That Scale and Deliver Better Money Experiences",
    publish_date: "2023-07-28",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 233,
    url: "https://www.mx.com/news/mx-named-one-of-the-fastest-growing-companies-in-utah-for-6th-year",
    admin_url: "https://cms.mx.com/admin/content/mx_news/233",
    name: "MX named one of the fastest-growing companies in Utah for 6th year",
    publish_date: "2022-08-25",
    last_modified: "2023-07-28T12:15:19.650Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 228,
    url: "https://www.mx.com/news/mx-wins-a-gold-and-bronze-in-the-2022-brandon-hall-group-human-capital-management-awards",
    admin_url: "https://cms.mx.com/admin/content/mx_news/228",
    name: "MX Wins a Gold and Silver in the 2022 Brandon Hall Group Human Capital Management Awards",
    publish_date: "2022-08-18",
    last_modified: "2023-07-28T12:15:32.911Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 219,
    url: "https://www.mx.com/news/fiserv-and-mx-win-tearsheet-data-innovation-award-for-enabling-secure-financial-data-sharing",
    admin_url: "https://cms.mx.com/admin/content/mx_news/219",
    name: "Fiserv and MX Win Tearsheet Data Innovation Award for Enabling Secure Financial Data Sharing",
    publish_date: "2022-07-15",
    last_modified: "2023-07-28T12:16:20.714Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 3,
    url: "https://www.mx.com/news/utah-business-ryan-nelsen-named-to-2021-sales-and-marketers-of-the-year",
    admin_url: "https://cms.mx.com/admin/content/mx_news/3",
    name: "Utah Business: Ryan Nelsen named to 2021 Sales & Marketers of the Year",
    publish_date: "2021-01-22",
    last_modified: "2023-07-28T12:18:40.164Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 60,
    url: "https://www.mx.com/news/salt-lake-tribune-recognizes-mx-as-top-workplace-ceo-ryan-caldwell-as-top-leader-in-utah",
    admin_url: "https://cms.mx.com/admin/content/mx_news/60",
    name: "Salt Lake Tribune Recognizes MX as Top Workplace; CEO Ryan Caldwell as Top Leader in Utah",
    publish_date: "2020-12-15",
    last_modified: "2023-07-28T12:18:53.491Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 38,
    url: "https://www.mx.com/news/mx-named-a-best-company-to-work-for-by-utah-business",
    admin_url: "https://cms.mx.com/admin/content/mx_news/38",
    name: "MX Named a Best Company to Work For by Utah Business",
    publish_date: "2020-12-08",
    last_modified: "2023-07-28T12:19:01.969Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 476,
    url: "https://www.mx.com/news/tearsheet-mx-named-digital-transformation-leader-of-the-year",
    admin_url: "https://cms.mx.com/admin/content/mx_news/476",
    name: "Tearsheet: MX named Digital Transformation Leader of the Year 2020",
    publish_date: "2020-06-10",
    last_modified: "2023-07-28T12:19:31.566Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 396,
    url: "https://www.mx.com/news/mx-wins-best-of-show-at-finovatefall-2019-for-new-mx-enabled-partner-program",
    admin_url: "https://cms.mx.com/admin/content/mx_news/396",
    name: "MX Wins Best of Show at FinovateFall 2019 for New MX Enabled Partner Program",
    publish_date: "2019-09-26",
    last_modified: "2023-07-28T12:21:14.115Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 410,
    url: "https://www.mx.com/news/mx-named-a-best-company-to-work-for-by-utah-business-magazine",
    admin_url: "https://cms.mx.com/admin/content/mx_news/410",
    name: "MX Named a Best Company to Work For by Utah Business Magazine",
    publish_date: "2019-12-11",
    last_modified: "2023-07-28T12:21:14.115Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 429,
    url: "https://www.mx.com/news/mx-named-a-best-fintech-to-work-for-2020",
    admin_url: "https://cms.mx.com/admin/content/mx_news/429",
    name: "MX Named a 2020 Best Fintech to Work For",
    publish_date: "2020-02-06",
    last_modified: "2023-07-28T12:21:14.115Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 473,
    url: "https://www.mx.com/news/mx-named-one-of-inc-magazines-best-workplaces",
    admin_url: "https://cms.mx.com/admin/content/mx_news/473",
    name: "MX named one of Inc. Magazine’s Best Workplaces 2020",
    publish_date: "2020-05-06",
    last_modified: "2023-07-28T12:21:14.115Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 345,
    url: "https://www.mx.com/news/mx-named-on-2019-shatter-list-for-helping-to-break-technologys-glass-ceiling",
    admin_url: "https://cms.mx.com/admin/content/mx_news/345",
    name: "MX Named on 2019 Shatter List for Helping to Break Technology’s Glass Ceiling",
    publish_date: "2019-04-24",
    last_modified: "2023-07-28T12:21:44.098Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 346,
    url: "https://www.mx.com/news/mx-a-winner-in-2019-utah-genius-awards",
    admin_url: "https://cms.mx.com/admin/content/mx_news/346",
    name: "MX wins a 2019 Utah Genius award",
    publish_date: "2019-04-29",
    last_modified: "2023-07-28T12:21:44.098Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 351,
    url: "https://www.mx.com/news/mx-wins-2019-fintech-breakthrough-award-as-best-financial-research-and-data-company",
    admin_url: "https://cms.mx.com/admin/content/mx_news/351",
    name: "MX wins 2019 Fintech Breakthrough award as Best Financial Research and Data Company",
    publish_date: "2019-04-30",
    last_modified: "2023-07-28T12:21:44.098Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 368,
    url: "https://www.mx.com/news/mx-wins-4-telly-awards",
    admin_url: "https://cms.mx.com/admin/content/mx_news/368",
    name: "MX Wins 4 Telly Awards",
    publish_date: "2019-06-19",
    last_modified: "2023-07-28T12:21:44.098Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 371,
    url: "https://www.mx.com/news/mx-receives-impact-award-from-utah-workforce-services",
    admin_url: "https://cms.mx.com/admin/content/mx_news/371",
    name: "MX Receives IMPACT Award from Utah Workforce Services",
    publish_date: "2019-06-04",
    last_modified: "2023-07-28T12:21:44.098Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 375,
    url: "https://www.mx.com/news/mx-named-one-of-20-fastest-growing-companies-in-utah",
    admin_url: "https://cms.mx.com/admin/content/mx_news/375",
    name: "MX named one of 20 fastest-growing companies in Utah",
    publish_date: "2019-08-16",
    last_modified: "2023-07-28T12:21:44.098Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 266,
    url: "https://www.mx.com/news/mx-wins-seven-telly-awards",
    admin_url: "https://cms.mx.com/admin/content/mx_news/266",
    name: "MX Wins Seven Telly Awards",
    publish_date: "2015-08-25",
    last_modified: "2023-07-28T12:22:22.078Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 267,
    url: "https://www.mx.com/news/the-q-awards-businessq-presents-utah-valleys-top-movers-and-makers-of-2014",
    admin_url: "https://cms.mx.com/admin/content/mx_news/267",
    name: "The Q Awards: BusinessQ presents Utah Valley’s top movers and makers of 2014",
    publish_date: "2015-03-25",
    last_modified: "2023-07-28T12:22:22.078Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 268,
    url: "https://www.mx.com/news/mx-wins-bai-innovation-showcase-award",
    admin_url: "https://cms.mx.com/admin/content/mx_news/268",
    name: "MX Wins BAI Innovation Showcase Award",
    publish_date: "2015-10-15",
    last_modified: "2023-07-28T12:22:22.078Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 298,
    url: "https://www.mx.com/news/mx-named-one-of-fastest-growing-companies-in-utah-2018",
    admin_url: "https://cms.mx.com/admin/content/mx_news/298",
    name: "MX named one of fastest-growing companies in Utah",
    publish_date: "2018-08-20",
    last_modified: "2023-07-28T12:22:22.078Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 317,
    url: "https://www.mx.com/news/mx-named-most-meaningful-company-in-the-salt-lake-tribune-top-workplaces-2018-awards",
    admin_url: "https://cms.mx.com/admin/content/mx_news/317",
    name: "MX Named Most Meaningful Company in The Salt Lake Tribune Top Workplaces 2018 Awards",
    publish_date: "2018-11-05",
    last_modified: "2023-07-28T12:22:22.078Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 339,
    url: "https://www.mx.com/news/mx-recognized-in-list-of-2019-best-fintechs-to-work-for",
    admin_url: "https://cms.mx.com/admin/content/mx_news/339",
    name: 'MX recognized in list of 2019 “Best Fintechs to Work For"',
    publish_date: "2019-03-27",
    last_modified: "2023-07-28T12:22:22.078Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 342,
    url: "https://www.mx.com/news/mx-recent-award-featured-in-the-daily-herald",
    admin_url: "https://cms.mx.com/admin/content/mx_news/342",
    name: "MX recent award featured in The Daily Herald",
    publish_date: "2019-04-08",
    last_modified: "2023-07-28T12:22:22.078Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 355,
    url: "https://www.mx.com/news/mx-awarded-best-of-show-at-finovatefall-cuna-tech",
    admin_url: "https://cms.mx.com/admin/content/mx_news/355",
    name: "MX Awarded Best of Show at FinovateFall & CUNA Tech",
    publish_date: "2014-09-25",
    last_modified: "2023-07-28T12:22:22.078Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 79,
    url: "https://www.mx.com/news/mx-named-a-best-place-to-work-in-fintech-for-third-straight-year",
    admin_url: "https://cms.mx.com/admin/content/mx_news/79",
    name: "MX Named a Best Place to Work in Fintech for Third Straight Year",
    publish_date: "2021-03-31",
    last_modified: "2023-08-01T16:50:40.337Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 454,
    url: "https://www.mx.com/blog/five-ways-to-meet-digital-banking-consumer-expectations",
    admin_url: "https://cms.mx.com/admin/content/blog/454",
    name: "Five Ways to Meet Digital Banking Consumer Expectations ",
    publish_date: "2023-08-02",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 359,
    url: "https://www.mx.com/blog/why-fdx-matters",
    admin_url: "https://cms.mx.com/admin/content/blog/359",
    name: "Why FDX Matters — Promoting a Standard of Excellence in Financial Services",
    publish_date: "2023-08-07",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 455,
    url: "https://www.mx.com/blog/personal-data-in-exchange-for-personalized-experiences",
    admin_url: "https://cms.mx.com/admin/content/blog/455",
    name: "Financial Data Fuels Personalized Experiences — And Consumers Are Willing To Share It",
    publish_date: "2023-08-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "spec_sheet_library",
    id: 7,
    url: "https://www.mx.com/products/spec-sheets/instant-account-verification",
    admin_url: "https://cms.mx.com/admin/content/spec_sheets_library/7",
    name: "Instant Account Verification",
    publish_date: "2023-02-07T16:30:31.642Z",
    last_modified: "2023-08-15T01:42:40.405Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 137,
    url: "https://www.mx.com/news/american-banker-the-race-to-build-data-sharing-hubs-for-banks-and-end-screen-scraping",
    admin_url: "https://cms.mx.com/admin/content/mx_news/137",
    name: "The race to build data-sharing hubs for banks — and end screen scraping",
    publish_date: "2021-09-20",
    last_modified: "2023-08-18T20:49:25.275Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 145,
    url: "https://www.mx.com/news/utah-valley-business-q-no-8-economic-engines-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/145",
    name: "No. 8 Economic Engines: MX",
    publish_date: "2021-09-29",
    last_modified: "2023-08-18T20:53:36.886Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 227,
    url: "https://www.mx.com/news/bank-automation-news-from-trusted-steward-of-money-to-data",
    admin_url: "https://cms.mx.com/admin/content/mx_news/227",
    name: "From trusted steward of money to data",
    publish_date: "2022-08-09",
    last_modified: "2023-08-18T21:02:11.568Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 221,
    url: "https://www.mx.com/news/the-financial-brand-how-open-banking-increases-data-value-for-banks-and-consumers",
    admin_url: "https://cms.mx.com/admin/content/mx_news/221",
    name: "How Open Banking Increases Data Value for Banks and Consumers",
    publish_date: "2022-07-15",
    last_modified: "2023-08-18T21:07:57.394Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 215,
    url: "https://www.mx.com/news/forbes-the-napster-age-of-finance-four-reasons-why-every-finance-company-should-embrace-open-finance",
    admin_url: "https://cms.mx.com/admin/content/mx_news/215",
    name: "The Napster Age Of Finance: Four Reasons Why Every Finance Company Should Embrace Open Finance",
    publish_date: "2022-07-06",
    last_modified: "2023-08-18T21:11:31.058Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 216,
    url: "https://www.mx.com/news/silicon-slopes-tv-meat-and-potatoes-podcast-mx-creating-a-greater-money-experience",
    admin_url: "https://cms.mx.com/admin/content/mx_news/216",
    name: "Creating a Greater Money Experience - with Kimberly Cassady",
    publish_date: "2022-06-24",
    last_modified: "2023-08-18T21:12:06.063Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 212,
    url: "https://www.mx.com/news/tearsheet-a-day-in-the-life-of-mx-s-vp-of-product-crystal-anderson",
    admin_url: "https://cms.mx.com/admin/content/mx_news/212",
    name: "A day in the life of MX’s VP of Product Crystal Anderson",
    publish_date: "2022-06-23",
    last_modified: "2023-08-18T21:16:23.729Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 208,
    url: "https://www.mx.com/news/digital-transactions-mx-technologies-formalizes-a-partner-network-and-rolls-out-a-partner-portal",
    admin_url: "https://cms.mx.com/admin/content/mx_news/208",
    name: "MX Technologies Formalizes a Partner Network and Rolls Out a Partner Portal",
    publish_date: "2022-06-08",
    last_modified: "2023-08-18T21:19:47.791Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 207,
    url: "https://www.mx.com/news/aba-banking-journal-banking-for-z-next-generation",
    admin_url: "https://cms.mx.com/admin/content/mx_news/207",
    name: "Banking for Z next generation",
    publish_date: "2022-06-07",
    last_modified: "2023-08-18T21:23:10.644Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 201,
    url: "https://www.mx.com/news/american-banker-open-finance-is-the-future-of-banking",
    admin_url: "https://cms.mx.com/admin/content/mx_news/201",
    name: "Open finance is the future of banking",
    publish_date: "2022-05-16",
    last_modified: "2023-08-18T21:27:14.351Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 196,
    url: "https://www.mx.com/news/digital-transactions-half-of-consumers-consolidate-their-financial-accounts-into-a-single-view",
    admin_url: "https://cms.mx.com/admin/content/mx_news/196",
    name: "Half of Consumers Consolidate Their Financial Accounts into a Single View",
    publish_date: "2022-04-21",
    last_modified: "2023-08-18T21:40:05.310Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 456,
    url: "https://www.mx.com/blog/four-ways-open-finance-uplevels-security",
    admin_url: "https://cms.mx.com/admin/content/blog/456",
    name: "4 Ways Open Finance Uplevels Security",
    publish_date: "2023-08-28",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 457,
    url: "https://www.mx.com/blog/four-personal-financial-management-imperatives",
    admin_url: "https://cms.mx.com/admin/content/blog/457",
    name: "4 Personal Financial Management Imperatives",
    publish_date: "2023-08-31",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 458,
    url: "https://www.mx.com/blog/open-banking-fednow-where-to-start",
    admin_url: "https://cms.mx.com/admin/content/blog/458",
    name: "Open Banking or FedNow: Where to Start? ",
    publish_date: "2023-09-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 293,
    url: "https://www.mx.com/news/ryan-caldwell-interviewed-by-informationweek",
    admin_url: "https://cms.mx.com/admin/content/mx_news/293",
    name: "Ryan Caldwell interview in InformationWeek",
    publish_date: "2018-07-16",
    last_modified: "2023-09-15T18:36:06.680Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 413,
    url: "https://www.mx.com/news/mx-named-a-salt-lake-tribune-top-workplaces-winner-for-fourth-time",
    admin_url: "https://cms.mx.com/admin/content/mx_news/413",
    name: "MX Named a Salt Lake Tribune Top Workplaces Winner for Fourth Time",
    publish_date: "2019-11-11",
    last_modified: "2023-09-15T18:37:23.789Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 384,
    url: "https://www.mx.com/news/utah-valley-university-ux-club-collaborates-with-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/384",
    name: "Utah Valley University UX Club collaborates with MX",
    publish_date: "2019-09-03",
    last_modified: "2023-09-15T18:40:08.384Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 370,
    url: "https://www.mx.com/news/check-out-mxs-expanded-office-space",
    admin_url: "https://cms.mx.com/admin/content/mx_news/370",
    name: "Check out MX's expanded office space!",
    publish_date: "2019-05-22",
    last_modified: "2023-09-15T18:41:09.696Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 333,
    url: "https://www.mx.com/news/mx-named-one-of-2019s-top-fintech-companies-to-work-for",
    admin_url: "https://cms.mx.com/admin/content/mx_news/333",
    name: "MX named a 2019 Top Fintech Company to Work For by American Banker",
    publish_date: "2019-02-25",
    last_modified: "2023-09-15T18:46:16.497Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 334,
    url: "https://www.mx.com/news/fintech-data-festival-comes-to-a-close",
    admin_url: "https://cms.mx.com/admin/content/mx_news/334",
    name: "Fintech Data Festival - Winter 2019 a big success",
    publish_date: "2019-02-28",
    last_modified: "2023-09-15T18:47:34.888Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 404,
    url: "https://www.mx.com/news/mx-creates-buzz-at-money2020",
    admin_url: "https://cms.mx.com/admin/content/mx_news/404",
    name: "MX Creates Buzz at Money20/20",
    publish_date: "2019-10-30",
    last_modified: "2023-09-15T18:52:18.536Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 372,
    url: "https://www.mx.com/news/mx-sponsors-national-bank-of-canada-grand-tour-that-raises-175000-to-prevent-troubled-youth",
    admin_url: "https://cms.mx.com/admin/content/mx_news/372",
    name: "MX sponsors National Bank of Canada 'Grand Tour' that raises $175,000 to prevent troubled youth ",
    publish_date: "2019-07-11",
    last_modified: "2023-09-15T18:53:44.140Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 358,
    url: "https://www.mx.com/news/how-mx-is-making-secure-coding-fun",
    admin_url: "https://cms.mx.com/admin/content/mx_news/358",
    name: "How MX is making 'secure coding' fun",
    publish_date: "2019-05-30",
    last_modified: "2023-09-15T19:13:22.988Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 217,
    url: "https://www.mx.com/news/kinly-selects-mx-to-power-data-platform-focused-on-financial-livelihood-for-black-america",
    admin_url: "https://cms.mx.com/admin/content/mx_news/217",
    name: "Kinly Selects MX to Power Data Platform Focused on Financial Livelihood for Black America",
    publish_date: "2022-07-11",
    last_modified: "2023-09-15T19:14:00.926Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 162,
    url: "https://www.mx.com/news/suncoast-credit-union-teams-with-mx-to-provide-clearer-financial-insights-and-enhanced-user-experience-to-its-members",
    admin_url: "https://cms.mx.com/admin/content/mx_news/162",
    name: "Suncoast Credit Union Teams with MX to Provide Clearer Financial Insights and Enhanced User Experience to Its Members",
    publish_date: "2021-12-02",
    last_modified: "2023-09-15T19:15:15.165Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 275,
    url: "https://www.mx.com/news/cocc-announces-mx-as-a-preferred-partner",
    admin_url: "https://cms.mx.com/admin/content/mx_news/275",
    name: "COCC Announces MX as a Preferred Partner",
    publish_date: "2017-06-06",
    last_modified: "2023-09-15T19:21:59.400Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 381,
    url: "https://www.mx.com/news/highlight-video-from-patriot-boot-camp-2019-at-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/381",
    name: "Highlight video from Patriot Boot Camp 2019 at MX",
    publish_date: "2019-09-03",
    last_modified: "2023-09-15T19:22:37.027Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 79,
    url: "https://www.mx.com/summit/2023/",
    admin_url: "https://cms.mx.com/admin/content/webinars/79",
    name: "MXS Rewind 2023",
    publish_date: "2023-09-22",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "events",
    id: 22,
    url: "https://www.mx.com/events/vegas-foodie-tour/10232023",
    admin_url: "https://cms.mx.com/admin/content/events/22",
    name: "Las Vegas Foodie Tour | MX at Money20/20 USA 2023",
    publish_date: "2023-09-28T22:20:33.732Z",
    last_modified: "2023-09-29T20:00:42.211Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 459,
    url: "https://www.mx.com/blog/keep-it-human",
    admin_url: "https://cms.mx.com/admin/content/blog/459",
    name: "Keep it Human: Engaging Consumers Where They Are",
    publish_date: "2023-10-03",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 460,
    url: "https://www.mx.com/blog/future-of-ai-requires-proactivity-and-diversity",
    admin_url: "https://cms.mx.com/admin/content/blog/460",
    name: "The Future of Artificial Intelligence Requires Proactivity and Diversity",
    publish_date: "2023-10-05",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "events",
    id: 23,
    url: "https://www.mx.com/events/the-sphere-experience/10242023",
    admin_url: "https://cms.mx.com/admin/content/events/23",
    name: "The Sphere Experience with MX",
    publish_date: "2023-10-04T14:38:35.800Z",
    last_modified: "2023-10-06T16:08:35.539Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 461,
    url: "https://www.mx.com/blog/present-and-future-of-open-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/461",
    name: "Charting the Course: Open Banking’s Present and Future",
    publish_date: "2023-10-10",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 462,
    url: "https://www.mx.com/blog/why-basics-matter-financial-services",
    admin_url: "https://cms.mx.com/admin/content/blog/462",
    name: "Why the Basics Matter for Financial Services",
    publish_date: "2023-10-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 463,
    url: "https://www.mx.com/blog/how-financial-literacy-drives-engagement",
    admin_url: "https://cms.mx.com/admin/content/blog/463",
    name: "How Financial Literacy — or Perceived Literacy — Drives Engagement",
    publish_date: "2023-10-17",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 42,
    url: "https://www.mx.com/webinars/top-5-challenges-for-fintechs",
    admin_url: "https://cms.mx.com/admin/content/webinars/42",
    name: "Top 5 Challenges for Fintechs",
    publish_date: "2021-04-12",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 43,
    url: "https://www.mx.com/webinars/off-script-with-shawn-achor",
    admin_url: "https://cms.mx.com/admin/content/webinars/43",
    name: "Off Script with Shawn Achor",
    publish_date: "2021-01-21",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 48,
    url: "https://www.mx.com/webinars/digital-ceo-roundtable-discussion",
    admin_url: "https://cms.mx.com/admin/content/webinars/48",
    name: "Digital CEO Roundtable Discussion",
    publish_date: "2020-07-28",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 52,
    url: "https://www.mx.com/webinars/rise-and-shine-breakfast-and-building-products",
    admin_url: "https://cms.mx.com/admin/content/webinars/52",
    name: "Rise & Shine, Breakfast & Building Products",
    publish_date: "2020-06-18",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 39,
    url: "https://www.mx.com/webinars/you-cant-fake-digital-transformation",
    admin_url: "https://cms.mx.com/admin/content/webinars/39",
    name: "You Can’t Fake Digital Transformation",
    publish_date: "2021-02-02",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 56,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-open-finance-sep-29",
    admin_url: "https://cms.mx.com/admin/content/webinars/56",
    name: "Rise & Shine, Breakfast & Open Finance",
    publish_date: "2020-09-29",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 40,
    url: "https://www.mx.com/webinars/coffee-break-with-jon-stein",
    admin_url: "https://cms.mx.com/admin/content/webinars/40",
    name: "Coffee Break with Jon Stein",
    publish_date: "2021-03-04",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 37,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-advocacy-04-23",
    admin_url: "https://cms.mx.com/admin/content/webinars/37",
    name: "Rise & Shine, Breakfast & Advocacy",
    publish_date: "2020-04-23",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 47,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-strategic-alignment-july-23",
    admin_url: "https://cms.mx.com/admin/content/webinars/47",
    name: "Rise & Shine, Breakfast & Strategic Alignment",
    publish_date: "2020-07-23",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 51,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-building-products",
    admin_url: "https://cms.mx.com/admin/content/webinars/51",
    name: "Rise & Shine, Breakfast & Building Products",
    publish_date: "2020-06-16",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 41,
    url: "https://www.mx.com/webinars/top-5-challenges-for-financial-institutions",
    admin_url: "https://cms.mx.com/admin/content/webinars/41",
    name: "Top 5 Challenges for Financial Institutions",
    publish_date: "2021-04-12",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 46,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-digital-transformation",
    admin_url: "https://cms.mx.com/admin/content/webinars/46",
    name: "Rise & Shine, Breakfast & Digital Transformation",
    publish_date: "2020-07-15",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 57,
    url: "https://www.mx.com/webinars/experience-product-forum-2021",
    admin_url: "https://cms.mx.com/admin/content/webinars/57",
    name: "MX Product Forum: Create Loyalty Through Financial Strength",
    publish_date: "2021-11-10",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 44,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-strategic-alignment",
    admin_url: "https://cms.mx.com/admin/content/webinars/44",
    name: "Rise & Shine, Breakfast & Strategic Alignment",
    publish_date: "2020-08-11",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 49,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-marketing",
    admin_url: "https://cms.mx.com/admin/content/webinars/49",
    name: "Rise & Shine, Breakfast & Marketing",
    publish_date: "2020-06-10",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 53,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-helping-small-businesses-survive",
    admin_url: "https://cms.mx.com/admin/content/webinars/53",
    name: "Rise & Shine, Breakfast & Helping Small Businesses Survive",
    publish_date: "2020-05-12",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 35,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-advocacy",
    admin_url: "https://cms.mx.com/admin/content/webinars/35",
    name: "Rise & Shine, Breakfast & Advocacy",
    publish_date: "2020-04-21",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 45,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-strategic-alignment-aug-6",
    admin_url: "https://cms.mx.com/admin/content/webinars/45",
    name: "Rise & Shine, Breakfast & Strategic Alignment",
    publish_date: "2020-08-06",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 54,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-helping-small-businesses-survive-may-19",
    admin_url: "https://cms.mx.com/admin/content/webinars/54",
    name: "Rise & Shine, Breakfast & Helping Small Businesses Survive",
    publish_date: "2020-05-19",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 55,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-open-finance",
    admin_url: "https://cms.mx.com/admin/content/webinars/55",
    name: "Rise & Shine, Breakfast & Open Finance",
    publish_date: "2020-10-01",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 50,
    url: "https://www.mx.com/webinars/rise-shine-breakfast-marketing-june-11",
    admin_url: "https://cms.mx.com/admin/content/webinars/50",
    name: "Rise & Shine, Breakfast & Marketing",
    publish_date: "2020-06-11",
    last_modified: "2023-10-19T00:29:30.627Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 38,
    url: "https://www.mx.com/webinars/user-experience-is-the-new-customer-service",
    admin_url: "https://cms.mx.com/admin/content/webinars/38",
    name: "User Experience is the New Customer Service",
    publish_date: "2015-09-16",
    last_modified: "2023-10-19T00:29:44.609Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 464,
    url: "https://www.mx.com/blog/keeping-the-nobility-in-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/464",
    name: "Keeping the Nobility in Banking: Putting Promises Into Action",
    publish_date: "2023-10-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 531,
    url: "https://www.mx.com/news/bai-rising-star-award",
    admin_url: "https://cms.mx.com/admin/content/mx_news/531",
    name: "BAI Recognizes Three MX Leaders with 2023 Rising Star Award",
    publish_date: "2023-10-23",
    last_modified: "2023-10-27T16:55:01.386Z",
    review: true,
  },
  {
    status: "published",
    content_type: "events",
    id: 24,
    url: "https://www.mx.com/events/cfpb-rulemaking-roundtable/11062023",
    admin_url: "https://cms.mx.com/admin/content/events/24",
    name: "CFPB Rulemaking Virtual Roundtable Discussion | MX + FS Vector",
    publish_date: "2023-10-30T16:02:30.082Z",
    last_modified: "2023-10-30T20:10:06.068Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 529,
    url: "https://www.mx.com/news/global-brands-magazine-excellence-in-open-finance",
    admin_url: "https://cms.mx.com/admin/content/mx_news/529",
    name: 'MX Awarded for "Excellence in Open Finance Innovation" by Global Brands Magazine',
    publish_date: "2023-10-20",
    last_modified: "2023-10-31T19:46:25.949Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 515,
    url: "https://www.mx.com/news/best-egg-financial-data-aggregation",
    admin_url: "https://cms.mx.com/admin/content/mx_news/515",
    name: "Best Egg Partners with MX Technologies to Deliver Financial Insights and Expanded Financial Data Aggregation Capabilities to Consumers",
    publish_date: "2023-03-09",
    last_modified: "2023-10-31T19:50:03.970Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 514,
    url: "https://www.mx.com/news/zum-rails-payment-processing-and-verifications",
    admin_url: "https://cms.mx.com/admin/content/mx_news/514",
    name: "Zūm Rails Partners with MX to Speed Up Payment Processing and Verifications with Secure Processor Tokens ",
    publish_date: "2023-03-07",
    last_modified: "2023-10-31T19:50:28.465Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 263,
    url: "https://www.mx.com/news/celero-partners-for-personal-financial-management-tools",
    admin_url: "https://cms.mx.com/admin/content/mx_news/263",
    name: "MX Partners with Celero to Provide Personal Financial Management (PFM) Tools to Credit Unions in Canada",
    publish_date: "2022-10-19",
    last_modified: "2023-10-31T19:55:02.264Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 88,
    url: "https://www.mx.com/news/tech-buzz-mx-raises-300-million-nate-gardner-discusses-funding-round",
    admin_url: "https://cms.mx.com/admin/content/mx_news/88",
    name: "MX Raises $300 Million; Nate Gardner Discusses Funding Round",
    publish_date: "2021-04-22",
    last_modified: "2023-11-03T17:33:10.251Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 504,
    url: "https://www.mx.com/news/orum-partners-with-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/504",
    name: "Orum and MX Enable Real-Time Money Movement and End-to-End Payment Solutions for Fintechs",
    publish_date: "2022-11-02",
    last_modified: "2023-11-03T17:36:37.683Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 464,
    url: "https://www.mx.com/news/mx-launches-easy-to-use-sba-loan-application-portal-for-banks-and-credit-unions",
    admin_url: "https://cms.mx.com/admin/content/mx_news/464",
    name: "MX Launches Easy-to-use SBA Loan Application Portal for Banks and Credit Unions",
    publish_date: "2020-04-07",
    last_modified: "2023-11-03T17:42:56.913Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 225,
    url: "https://www.mx.com/news/cibc-to-enhance-secure-and-seamless-access-to-financial-tools-and-apps-for-clients-through-agreement-with-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/225",
    name: "CIBC to Enhance Secure and Seamless Access to Financial Tools and Apps for Clients Through Agreement with MX",
    publish_date: "2022-08-08",
    last_modified: "2023-11-03T17:48:42.734Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 513,
    url: "https://www.mx.com/news/what-consumers-want-from-financial-providers-research",
    admin_url: "https://cms.mx.com/admin/content/mx_news/513",
    name: "New MX Research Shows 1 in 4 Consumers Want Financial Providers to Do More to Support Financial Needs",
    publish_date: "2023-01-18",
    last_modified: "2023-11-03T17:50:34.651Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 405,
    url: "https://www.mx.com/news/mx-security-teams-dedication-to-secure-coding-pays-off",
    admin_url: "https://cms.mx.com/admin/content/mx_news/405",
    name: "MX Security Team's Dedication to Secure Coding Pays Off",
    publish_date: "2019-11-08",
    last_modified: "2023-11-03T17:58:18.606Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 85,
    url: "https://www.mx.com/news/mx-and-moov-bring-faster-account-verification-money-movement-to-fintechs",
    admin_url: "https://cms.mx.com/admin/content/mx_news/85",
    name: "MX and Moov Bring Faster Account Verification, Money Movement to Fintechs",
    publish_date: "2021-04-20",
    last_modified: "2023-11-03T18:02:24.749Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 466,
    url: "https://www.mx.com/blog/what-mint-shutdown-means-for-financial-institutions",
    admin_url: "https://cms.mx.com/admin/content/blog/466",
    name: "The Future of Financial Empower(mint)",
    publish_date: "2023-11-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 234,
    url: "https://www.mx.com/news/stackin-partners-with-mx-to-scale-financial-wellness",
    admin_url: "https://cms.mx.com/admin/content/mx_news/234",
    name: "Stackin’ Partners with MX to Scale Financial Wellness",
    publish_date: "2022-08-25",
    last_modified: "2023-11-06T22:18:47.216Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 363,
    url: "https://www.mx.com/news/mx-featured-on-cover-of-utah-business-magazine",
    admin_url: "https://cms.mx.com/admin/content/mx_news/363",
    name: "MX Featured on Cover of Utah Business Magazine",
    publish_date: "2019-06-17",
    last_modified: "2023-11-06T22:27:45.521Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 344,
    url: "https://www.mx.com/news/mx-announces-moneymap-with-pulse-ushering-a-new-age-of-ai-driven-financial-guidance",
    admin_url: "https://cms.mx.com/admin/content/mx_news/344",
    name: "MX Announces MoneyMap with Pulse: Ushering a New Age of AI-driven Financial Guidance",
    publish_date: "2019-04-15",
    last_modified: "2023-11-06T22:33:30.059Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 224,
    url: "https://www.mx.com/news/mx-names-jim-magats-ceo",
    admin_url: "https://cms.mx.com/admin/content/mx_news/224",
    name: "MX Names Jim Magats Chief Executive Officer",
    publish_date: "2022-07-28",
    last_modified: "2023-11-06T22:34:38.508Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 525,
    url: "https://www.mx.com/news/whitacre-named-chief-commercial-officer",
    admin_url: "https://cms.mx.com/admin/content/mx_news/525",
    name: "MX Names Michael Whitacre Chief Commercial Officer ",
    publish_date: "2023-09-14",
    last_modified: "2023-11-06T22:34:55.394Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 518,
    url: "https://www.mx.com/news/finastra-partnership-personalized-insights",
    admin_url: "https://cms.mx.com/admin/content/mx_news/518",
    name: "Finastra Partners with MX to Deliver Personalized Financial Management Tools and Insights for Consumers",
    publish_date: "2023-07-27",
    last_modified: "2023-11-06T22:37:11.394Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 419,
    url: "https://www.mx.com/news/american-banker-visa-plaid-merger-means-for-banks",
    admin_url: "https://cms.mx.com/admin/content/mx_news/419",
    name: "What the Visa-Plaid Merger Means for Banks, Fintechs",
    publish_date: "2020-01-17",
    last_modified: "2023-11-06T22:49:00.526Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 84,
    url: "https://www.mx.com/news/mx-helps-form-free-speed-up-loan-approval-process-and-close-more-than-1-million-loans-",
    admin_url: "https://cms.mx.com/admin/content/mx_news/84",
    name: "MX Helps FormFree Speed Up Loan Approval Process and Close More Than 1 Million Loans",
    publish_date: "2021-04-13",
    last_modified: "2023-11-06T22:51:44.284Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 517,
    url: "https://www.mx.com/news/moneyhub-partnership-to-drive-open-finance-adoption",
    admin_url: "https://cms.mx.com/admin/content/mx_news/517",
    name: "Moneyhub and MX Partner to Drive Global Adoption of Open Finance",
    publish_date: "2023-07-06",
    last_modified: "2023-11-06T22:52:45.348Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 43,
    url: "https://www.mx.com/news/mx-welcomes-brian-kinion-as-chief-financial-officer",
    admin_url: "https://cms.mx.com/admin/content/mx_news/43",
    name: "MX Welcomes Brian Kinion As Chief Financial Officer",
    publish_date: "2021-03-05",
    last_modified: "2023-11-06T22:55:06.168Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 114,
    url: "https://www.mx.com/news/cb-insights-the-complete-list-of-unicorn-companies",
    admin_url: "https://cms.mx.com/admin/content/mx_news/114",
    name: "The Complete List of Unicorn Companies",
    publish_date: "2021-06-29",
    last_modified: "2023-11-06T22:59:36.736Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 257,
    url: "https://www.mx.com/news/banking-transformed-podcast-jim-magats-at-mx-the-next-frontier-of-money-experiences",
    admin_url: "https://cms.mx.com/admin/content/mx_news/257",
    name: "Jim Magats at MX: The Next Frontier of Money Experiences",
    publish_date: "2022-09-27",
    last_modified: "2023-11-06T23:03:10.222Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 522,
    url: "https://www.mx.com/news/worlds-top-fintech-companies-2023",
    admin_url: "https://cms.mx.com/admin/content/mx_news/522",
    name: "MX Named to CNBC's Inaugural World's Top Fintech Companies List 2023",
    publish_date: "2023-08-02",
    last_modified: "2023-11-06T23:03:37.442Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 122,
    url: "https://www.mx.com/news/mx-named-to-forbes-cloud-100",
    admin_url: "https://cms.mx.com/admin/content/mx_news/122",
    name: "MX Named to Forbes Cloud 100",
    publish_date: "2021-08-10",
    last_modified: "2023-11-06T23:05:17.033Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 169,
    url: "https://www.mx.com/news/mx-names-shane-evans-interim-chief-executive-officer-and-member-of-the-board-of-directors",
    admin_url: "https://cms.mx.com/admin/content/mx_news/169",
    name: "MX Names Shane Evans Interim Chief Executive Officer and Member of the Board of Directors",
    publish_date: "2022-01-11",
    last_modified: "2023-11-06T23:06:56.370Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 117,
    url: "https://www.mx.com/news/mx-partners-with-dwolla-to-improve-account-verification-experience-increase-coverage-to-more-than-99-of-u-s-financial-institutions",
    admin_url: "https://cms.mx.com/admin/content/mx_news/117",
    name: "MX Partners with Dwolla to Improve Account Verification Experience, Increase Coverage to more than 99% of U.S. Financial Institutions",
    publish_date: "2021-07-27",
    last_modified: "2023-11-06T23:13:08.236Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 148,
    url: "https://www.mx.com/news/white-paper-gen-z-and-millennial-perspectives-on-emerging-trends-in-banking-and-finance",
    admin_url: "https://cms.mx.com/admin/content/mx_news/148",
    name: "Gen Z and Millennial Perspectives on Emerging Trends in Banking and Finance",
    publish_date: "2021-10-13",
    last_modified: "2023-11-06T23:17:39.755Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 54,
    url: "https://www.mx.com/news/tpg-and-alphabets-capitalg-join-the-mx-board-of-directors-to-accelerate-the-future-of-money-experience",
    admin_url: "https://cms.mx.com/admin/content/mx_news/54",
    name: "TPG and Alphabet’s CapitalG Join the MX Board of Directors to Accelerate the Future of Money Experience",
    publish_date: "2021-02-10",
    last_modified: "2023-11-06T23:19:43.506Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 155,
    url: "https://www.mx.com/news/mx-announces-integration-with-q2-s-digital-banking-platform",
    admin_url: "https://cms.mx.com/admin/content/mx_news/155",
    name: "MX Announces Integration with Q2's Digital Banking Platform",
    publish_date: "2021-11-08",
    last_modified: "2023-11-06T23:21:31.674Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 524,
    url: "https://www.mx.com/news/mx-named-top-growing-company-utah-business",
    admin_url: "https://cms.mx.com/admin/content/mx_news/524",
    name: "Utah Business Names MX on the State’s Fastest-Growing Company List for 7th Year",
    publish_date: "2023-08-29",
    last_modified: "2023-11-06T23:22:24.420Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 139,
    url: "https://www.mx.com/news/mx-and-pinwheel-partner-for-faster-payroll-connectivity-and-income-verification",
    admin_url: "https://cms.mx.com/admin/content/mx_news/139",
    name: "MX and Pinwheel Partner for Faster Payroll Connectivity and Income Verification",
    publish_date: "2021-09-24",
    last_modified: "2023-11-06T23:25:39.222Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 506,
    url: "https://www.mx.com/news/banki-fi-and-mx-partner-to-enable-data-sharing-and-account-connectivity-financial-institutions-serving-small-and-medium-sized-business-customers",
    admin_url: "https://cms.mx.com/admin/content/mx_news/506",
    name: "BankiFi and MX Partner to Enable Data Sharing and Account Connectivity for Financial Institutions Serving Small- and Medium-Sized Business Customers",
    publish_date: "2022-11-08",
    last_modified: "2023-11-06T23:26:48.398Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 133,
    url: "https://www.mx.com/news/mx-named-to-2021-idc-fin-tech-rankings",
    admin_url: "https://cms.mx.com/admin/content/mx_news/133",
    name: "MX Named to 2021 IDC FinTech Rankings",
    publish_date: "2021-09-13",
    last_modified: "2023-11-07T23:15:14.047Z",
    review: true,
  },
  {
    id: 503,
    status: "published",
    date_created: "2025-02-28T22:46:07.834Z",
    path: "case-studies/hughes-federal-credit-union/index.html",
    url: "https://www.mx.com/case-studies/hughes-federal-credit-union/",
    last_modified: "2023-11-08",
    publish_date: "2023-10-28",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 467,
    url: "https://www.mx.com/blog/future-of-fraud",
    admin_url: "https://cms.mx.com/admin/content/blog/467",
    name: "The Future of Fraud: Real-Time Payments and Artificial Intelligence",
    publish_date: "2023-11-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 487,
    url: "https://www.mx.com/news/utah-valley-businessq-40-under-40",
    admin_url: "https://cms.mx.com/admin/content/mx_news/487",
    name: "40 Under 40 Faces of the Future",
    publish_date: "2020-07-16",
    last_modified: "2023-11-08T18:16:10.228Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 165,
    url: "https://www.mx.com/news/mx-named-a-best-company-to-work-for-by-utah-business-magazine-for-third-straight-year",
    admin_url: "https://cms.mx.com/admin/content/mx_news/165",
    name: "MX Named a Best Company to Work For by Utah Business Magazine for Third Straight Year",
    publish_date: "2021-12-15",
    last_modified: "2023-11-08T19:49:57.471Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 526,
    url: "https://www.mx.com/news/mx-listed-idc-top-fintech-rankings-2023",
    admin_url: "https://cms.mx.com/admin/content/mx_news/526",
    name: "MX Listed in IDC's 2023 Top 100 FinTech Rankings",
    publish_date: "2023-09-11",
    last_modified: "2023-11-08T19:58:33.106Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 200,
    url: "https://www.mx.com/news/2022-utah-women-in-sales-awards",
    admin_url: "https://cms.mx.com/admin/content/mx_news/200",
    name: "2022 Utah Women in Sales Awards",
    publish_date: "2022-05-06",
    last_modified: "2023-11-08T23:20:38.269Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 192,
    url: "https://www.mx.com/news/kimberly-cassady-named-chief-people-officer-at-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/192",
    name: "Kimberly Cassady Named Chief People Officer at MX",
    publish_date: "2022-04-05",
    last_modified: "2023-11-08T23:24:15.843Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 520,
    url: "https://www.mx.com/news/what-influences-where-consumers-bank-research",
    admin_url: "https://cms.mx.com/admin/content/mx_news/520",
    name: "New MX Research Reveals Half of U.S. Consumers Making or Considering a Bank Switch",
    publish_date: "2023-07-25",
    last_modified: "2023-11-08T23:26:00.408Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 161,
    url: "https://www.mx.com/news/mx-a-winner-in-the-fdata-global-open-finance-awards-2021",
    admin_url: "https://cms.mx.com/admin/content/mx_news/161",
    name: "MX Named a Winner in the 2021 FDATA Global Open Finance Awards",
    publish_date: "2021-11-30",
    last_modified: "2023-11-08T23:28:54.085Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 198,
    url: "https://www.mx.com/news/everyware-partners-with-mx-to-deliver-real-time-payments-and-open-banking-with-pay-by-text",
    admin_url: "https://cms.mx.com/admin/content/mx_news/198",
    name: "Everyware® Partners with MX to Deliver Real-Time Payments and Open Banking with Pay By Text",
    publish_date: "2022-05-02",
    last_modified: "2023-11-08T23:32:24.303Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 262,
    url: "https://www.mx.com/news/broadridge-teams-with-mx-to-power-data-aggregation-and-financial-wellness-solution",
    admin_url: "https://cms.mx.com/admin/content/mx_news/262",
    name: "Broadridge Teams with MX to Power Data Aggregation and Financial Wellness Solution",
    publish_date: "2022-10-11",
    last_modified: "2023-11-08T23:33:34.130Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 252,
    url: "https://www.mx.com/news/mx-announces-new-product-innovation-to-power-the-open-finance-economy",
    admin_url: "https://cms.mx.com/admin/content/mx_news/252",
    name: "MX Announces New Product Innovation to Power the Open Finance Economy",
    publish_date: "2022-09-21",
    last_modified: "2023-11-08T23:35:22.982Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 265,
    url: "https://www.mx.com/news/moneydesktop-changes-name-to-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/265",
    name: "MoneyDesktop Changes Name to MX",
    publish_date: "2014-09-25",
    last_modified: "2023-11-08T23:38:56.228Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 361,
    url: "https://www.mx.com/news/ryan-caldwell-featured-in-forbes",
    admin_url: "https://cms.mx.com/admin/content/mx_news/361",
    name: "Ryan Caldwell featured in Forbes",
    publish_date: "2019-05-31",
    last_modified: "2023-11-08T23:43:14.952Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 253,
    url: "https://www.mx.com/news/mx-announces-winners-of-2022-innovator-awards",
    admin_url: "https://cms.mx.com/admin/content/mx_news/253",
    name: "MX Announces Winners of 2022 Innovator Awards",
    publish_date: "2022-09-22",
    last_modified: "2023-11-08T23:44:13.898Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 171,
    url: "https://www.mx.com/news/mx-named-to-glassdoor-best-places-to-work-2022",
    admin_url: "https://cms.mx.com/admin/content/mx_news/171",
    name: "MX Named to Glassdoor Best Places to Work 2022",
    publish_date: "2022-01-14",
    last_modified: "2023-11-08T23:50:43.274Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 366,
    url: "https://www.mx.com/news/mx-raises-100-million-in-series-b-financing-signs-2000th-customer",
    admin_url: "https://cms.mx.com/admin/content/mx_news/366",
    name: "MX Raises $100 Million in Series B Financing, Signs 2,000th Customer",
    publish_date: "2019-06-25",
    last_modified: "2023-11-08T23:52:35.044Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 98,
    url: "https://www.mx.com/news/first-hawaiian-bank-goes-live-with-mx-helios-for-mobile-banking-empowering-customers-with-financial-insights",
    admin_url: "https://cms.mx.com/admin/content/mx_news/98",
    name: "First Hawaiian Bank Goes Live with MX Helios for Mobile Banking, Empowering Customers with Financial Insights",
    publish_date: "2021-05-27",
    last_modified: "2023-11-08T23:55:42.880Z",
    review: true,
  },
  {
    id: 481,
    status: "published",
    date_created: "2025-02-28T22:46:05.568Z",
    path: "case-studies/abby-bank/index.html",
    url: "https://www.mx.com/case-studies/abby-bank/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 490,
    status: "published",
    date_created: "2025-02-28T22:46:06.506Z",
    path: "case-studies/borrowell/index.html",
    url: "https://www.mx.com/case-studies/borrowell/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 491,
    status: "published",
    date_created: "2025-02-28T22:46:06.602Z",
    path: "case-studies/cambridge-savings-bank/index.html",
    url: "https://www.mx.com/case-studies/cambridge-savings-bank/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 492,
    status: "published",
    date_created: "2025-02-28T22:46:06.708Z",
    path: "case-studies/central-pacific-bank/index.html",
    url: "https://www.mx.com/case-studies/central-pacific-bank/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 495,
    status: "published",
    date_created: "2025-02-28T22:46:07.009Z",
    path: "case-studies/ent-credit-union/index.html",
    url: "https://www.mx.com/case-studies/ent-credit-union/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-28",
    review: true,
  },
  {
    id: 498,
    status: "published",
    date_created: "2025-02-28T22:46:07.329Z",
    path: "case-studies/first-hawaiian-bank/index.html",
    url: "https://www.mx.com/case-studies/first-hawaiian-bank/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 500,
    status: "published",
    date_created: "2025-02-28T22:46:07.531Z",
    path: "case-studies/global-credit-union/index.html",
    url: "https://www.mx.com/case-studies/global-credit-union/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-28",
    review: true,
  },
  {
    id: 502,
    status: "published",
    date_created: "2025-02-28T22:46:07.735Z",
    path: "case-studies/hr-block/index.html",
    url: "https://www.mx.com/case-studies/hr-block/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 504,
    status: "published",
    date_created: "2025-02-28T22:46:07.933Z",
    path: "case-studies/i-hate-receipts/index.html",
    url: "https://www.mx.com/case-studies/i-hate-receipts/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-28",
    review: true,
  },
  {
    id: 508,
    status: "published",
    date_created: "2025-02-28T22:46:08.332Z",
    path: "case-studies/libro-credit-union/index.html",
    url: "https://www.mx.com/case-studies/libro-credit-union/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 509,
    status: "published",
    date_created: "2025-02-28T22:46:08.435Z",
    path: "case-studies/lokyata/index.html",
    url: "https://www.mx.com/case-studies/lokyata/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 510,
    status: "published",
    date_created: "2025-02-28T22:46:08.534Z",
    path: "case-studies/maps-credit-union/index.html",
    url: "https://www.mx.com/case-studies/maps-credit-union/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 511,
    status: "published",
    date_created: "2025-02-28T22:46:08.634Z",
    path: "case-studies/matrix-rental-solutions/index.html",
    url: "https://www.mx.com/case-studies/matrix-rental-solutions/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 512,
    status: "published",
    date_created: "2025-02-28T22:46:08.735Z",
    path: "case-studies/mercantile-bank/index.html",
    url: "https://www.mx.com/case-studies/mercantile-bank/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-18",
    review: true,
  },
  {
    id: 514,
    status: "published",
    date_created: "2025-02-28T22:46:08.971Z",
    path: "case-studies/moov/index.html",
    url: "https://www.mx.com/case-studies/moov/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 516,
    status: "published",
    date_created: "2025-02-28T22:46:09.168Z",
    path: "case-studies/naviplan/index.html",
    url: "https://www.mx.com/case-studies/naviplan/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-28",
    review: true,
  },
  {
    id: 518,
    status: "published",
    date_created: "2025-02-28T22:46:09.390Z",
    path: "case-studies/orbispay/index.html",
    url: "https://www.mx.com/case-studies/orbispay/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 519,
    status: "published",
    date_created: "2025-02-28T22:46:09.503Z",
    path: "case-studies/ourbanc/index.html",
    url: "https://www.mx.com/case-studies/ourbanc/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 526,
    status: "published",
    date_created: "2025-02-28T22:46:10.297Z",
    path: "case-studies/the-points-guy/index.html",
    url: "https://www.mx.com/case-studies/the-points-guy/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 527,
    status: "published",
    date_created: "2025-02-28T22:46:10.393Z",
    path: "case-studies/tinker-federal-credit-union/index.html",
    url: "https://www.mx.com/case-studies/tinker-federal-credit-union/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-28",
    review: true,
  },
  {
    id: 530,
    status: "published",
    date_created: "2025-02-28T22:46:10.698Z",
    path: "case-studies/us-bank/index.html",
    url: "https://www.mx.com/case-studies/us-bank/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-28",
    review: true,
  },
  {
    id: 531,
    status: "published",
    date_created: "2025-02-28T22:46:10.799Z",
    path: "case-studies/viva-first/index.html",
    url: "https://www.mx.com/case-studies/viva-first/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    id: 533,
    status: "published",
    date_created: "2025-02-28T22:46:11.011Z",
    path: "case-studies/vystar-credit-union/index.html",
    url: "https://www.mx.com/case-studies/vystar-credit-union/",
    last_modified: "2023-11-09",
    publish_date: "2023-10-28",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 376,
    url: "https://www.mx.com/news/dave-selects-mx-data-to-help-provide-financial-clarity-for-millions",
    admin_url: "https://cms.mx.com/admin/content/mx_news/376",
    name: "Dave Selects MX Data To Help Provide Financial Clarity for Millions",
    publish_date: "2019-08-14",
    last_modified: "2023-11-09T21:57:15.744Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 106,
    url: "https://www.mx.com/news/our-banc-goes-live-with-mx-for-mobile-banking-and-actionable-financial-health-insights",
    admin_url: "https://cms.mx.com/admin/content/mx_news/106",
    name: "OurBanc Goes Live with MX for Mobile Banking and Actionable Financial Health Insights",
    publish_date: "2021-07-01",
    last_modified: "2023-11-09T22:01:01.519Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 400,
    url: "https://www.mx.com/news/mx-launches-subscription-tracker-to-help-consumers-take-control-of-their-finances",
    admin_url: "https://cms.mx.com/admin/content/mx_news/400",
    name: "MX Launches Subscription Tracker to Help Consumers Take Control of their Finances",
    publish_date: "2019-10-28",
    last_modified: "2023-11-09T22:04:36.237Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 242,
    url: "https://www.mx.com/news/mx-names-nandita-gupta-chief-product-officer",
    admin_url: "https://cms.mx.com/admin/content/mx_news/242",
    name: "MX Names Nandita Gupta Chief Product Officer",
    publish_date: "2022-09-08",
    last_modified: "2023-11-09T22:06:04.274Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 128,
    url: "https://www.mx.com/news/mx-named-one-of-the-fastest-growing-companies-in-utah-for-5th-year",
    admin_url: "https://cms.mx.com/admin/content/mx_news/128",
    name: "MX Named One of the Fastest-Growing Companies in Utah for 5th Year",
    publish_date: "2021-08-26",
    last_modified: "2023-11-09T22:09:05.721Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 102,
    url: "https://www.mx.com/news/jane-barratt-named-a-utah-business-2021-cxo-of-the-year",
    admin_url: "https://cms.mx.com/admin/content/mx_news/102",
    name: "Jane Barratt Named a Utah Business 2021 CXO of the Year",
    publish_date: "2021-06-08",
    last_modified: "2023-11-09T22:14:19.460Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 494,
    url: "https://www.mx.com/news/mx-launches-open-finance-platform-to-power-the-future-of-money-experience",
    admin_url: "https://cms.mx.com/admin/content/mx_news/494",
    name: "MX Launches Open Finance Platform to Power the Future of Money Experience",
    publish_date: "2020-09-02",
    last_modified: "2023-11-09T22:18:00.939Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 269,
    url: "https://www.mx.com/news/mx-raises-30-million-series-a-financing-from-usaa-and-digital-garage",
    admin_url: "https://cms.mx.com/admin/content/mx_news/269",
    name: "MX Raises $30 Million Series A Financing from USAA and Digital Garage",
    publish_date: "2015-04-30",
    last_modified: "2023-11-09T22:19:32.287Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 179,
    url: "https://www.mx.com/news/meet-the-2022-40-under-40-honorees",
    admin_url: "https://cms.mx.com/admin/content/mx_news/179",
    name: "Meet the 2022 40 Under 40 honorees: Dan Merrill, MX",
    publish_date: "2022-02-09",
    last_modified: "2023-11-09T22:23:15.334Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 521,
    url: "https://www.mx.com/news/fintech-50-2023",
    admin_url: "https://cms.mx.com/admin/content/mx_news/521",
    name: "MX Recognized on Forbes Fintech 50 List for 2023",
    publish_date: "2023-06-06",
    last_modified: "2023-11-09T22:24:21.235Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 439,
    url: "https://www.mx.com/news/2020-03-16-utah-business-ryan-caldwell-named-to-ceo-of-the-year-list",
    admin_url: "https://cms.mx.com/admin/content/mx_news/439",
    name: "Utah Business Names Ryan Caldwell to CEO of the Year List",
    publish_date: "2020-03-16",
    last_modified: "2023-11-09T22:27:54.813Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 230,
    url: "https://www.mx.com/news/mx-names-wes-hummel-chief-technology-officer",
    admin_url: "https://cms.mx.com/admin/content/mx_news/230",
    name: "MX Names Wes Hummel Chief Technology Officer",
    publish_date: "2022-08-24",
    last_modified: "2023-11-09T22:30:15.979Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 143,
    url: "https://www.mx.com/news/mx-named-to-the-2021-cb-insights-fintech-250-list-of-fastest-growing-fintech-startups",
    admin_url: "https://cms.mx.com/admin/content/mx_news/143",
    name: "MX Named to the 2021 CB Insights Fintech 250 List of Fastest-Growing Fintech Startups",
    publish_date: "2021-10-05",
    last_modified: "2023-11-13T20:26:43.311Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 80,
    url: "https://www.mx.com/news/utah-vally-business-q-entrepreneur-of-the-year-ryan-caldwell-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/80",
    name: "Utah Vally BusinessQ: Entrepreneur of the Year: Ryan Caldwell, MX",
    publish_date: "2021-04-02",
    last_modified: "2023-11-13T20:33:01.256Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 91,
    url: "https://www.mx.com/news/mx-s-shayli-lones-named-one-of-utah-business-30-women-to-watch-for-2021",
    admin_url: "https://cms.mx.com/admin/content/mx_news/91",
    name: "Shayli Lones named one of Utah Business's 2021 30 Women to Watch",
    publish_date: "2021-05-10",
    last_modified: "2023-11-13T20:41:47.199Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 312,
    url: "https://www.mx.com/news/jane-barratt-named-chief-advocacy-officer-at-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/312",
    name: "Jane Barratt named Chief Advocacy Officer at MX",
    publish_date: "2018-10-22",
    last_modified: "2023-11-13T20:48:26.782Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 124,
    url: "https://www.mx.com/news/becu-helps-low-balance-savers-increase-savings-by-more-than-2-million-with-quick-save-powered-by-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/124",
    name: "BECU Helps Low-Balance Savers Increase Savings by More Than $2 Million with Quick Save, Powered by MX",
    publish_date: "2021-08-18",
    last_modified: "2023-11-13T20:56:44.344Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 62,
    url: "https://www.mx.com/news/utah-business-nate-gardner-named-a-cxo-of-the-year",
    admin_url: "https://cms.mx.com/admin/content/mx_news/62",
    name: "Nate Gardner named a CXO of the Year",
    publish_date: "2020-09-25",
    last_modified: "2023-11-13T21:00:45.896Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 324,
    url: "https://www.mx.com/news/mx-data-empowers-lake-michigan-credit-union-members-to-improve-their-financial-lives",
    admin_url: "https://cms.mx.com/admin/content/mx_news/324",
    name: "MX Data Empowers Lake Michigan Credit Union Members to Improve their Financial Lives",
    publish_date: "2018-12-20",
    last_modified: "2023-11-13T21:08:30.124Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 57,
    url: "https://www.mx.com/news/utah-business-bryce-nobles-named-to-2020-20-in-their-20s",
    admin_url: "https://cms.mx.com/admin/content/mx_news/57",
    name: 'Bryce Nobles named to 2020 "20 in their 20s"',
    publish_date: "2020-11-19",
    last_modified: "2023-11-13T21:10:28.826Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 406,
    url: "https://www.mx.com/news/tinker-federal-credit-union-partners-with-mx-to-empower-members-to-take-control-of-their-financial-future",
    admin_url: "https://cms.mx.com/admin/content/mx_news/406",
    name: "Tinker Federal Credit Union Partners with MX to Empower Members to Take Control of their Financial Future",
    publish_date: "2019-10-29",
    last_modified: "2023-11-13T21:14:36.142Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 56,
    url: "https://www.mx.com/news/borrowell-selects-mx-to-help-provide-financial-clarity-for-over-one-million-canadians",
    admin_url: "https://cms.mx.com/admin/content/mx_news/56",
    name: "Borrowell Selects MX To Help Provide Financial Clarity For Over One Million Canadians",
    publish_date: "2020-10-22",
    last_modified: "2023-11-13T21:16:10.200Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 146,
    url: "https://www.mx.com/news/cambridge-savings-bank-introduces-money-management-a-tool-for-consumers-to-improve-their-financial-well-being",
    admin_url: "https://cms.mx.com/admin/content/mx_news/146",
    name: "Cambridge Savings Bank Introduces Money Management - A Tool for Consumers to Improve Their Financial Well-Being",
    publish_date: "2021-10-06",
    last_modified: "2023-11-13T21:20:47.727Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 274,
    url: "https://www.mx.com/news/ncr-partners-with-mx-to-launch-data-driven-money-management-solution-for-financial-institutions",
    admin_url: "https://cms.mx.com/admin/content/mx_news/274",
    name: "NCR Partners with MX to Launch Data-Driven Money Management Solution for Financial Institutions",
    publish_date: "2016-09-07",
    last_modified: "2023-11-13T21:25:47.892Z",
    review: true,
  },
  {
    status: "published",
    content_type: "demo_pages",
    id: 9,
    url: "https://www.mx.com/demo/acquire-and-grow-financial-relationships",
    admin_url: "https://cms.mx.com/admin/content/demo_pages/9",
    name: "Acquire and Grow Financial Relationships",
    publish_date: "2023-10-30T17:06:25.784Z",
    last_modified: "2023-11-13T21:30:14.697Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 209,
    url: "https://www.mx.com/news/black-eyed-peas-will-i-am-and-apl-de-ap-to-perform-at-mxs-2022",
    admin_url: "https://cms.mx.com/admin/content/mx_news/209",
    name: "Black Eyed Peas will.i.am and Apl.de.Ap to Perform at MXS 2022",
    publish_date: "2022-06-14",
    last_modified: "2023-11-13T21:32:53.747Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 390,
    url: "https://www.mx.com/news/mx-launches-partner-program-to-connect-banks-and-credit-unions-to-leading-fintech-companies",
    admin_url: "https://cms.mx.com/admin/content/mx_news/390",
    name: "MX Launches Partner Program to Connect Banks and Credit Unions to Leading Fintech Companies",
    publish_date: "2019-09-24",
    last_modified: "2023-11-13T21:48:00.203Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 468,
    url: "https://www.mx.com/blog/fintech-on-fire",
    admin_url: "https://cms.mx.com/admin/content/blog/468",
    name: "What’s Your Spiciest Fintech Take? How 4 Industry Leaders Answered",
    publish_date: "2023-11-14",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 256,
    url: "https://www.mx.com/blog/a-list-of-financial-data-aggregators-in-the-united-states",
    admin_url: "https://cms.mx.com/admin/content/blog/256",
    name: "A List of Financial Data Aggregators in the United States",
    publish_date: "2023-11-18",
    last_modified: null,
    review: true,
  },
  {
    id: 501,
    status: "published",
    date_created: "2025-02-28T22:46:07.630Z",
    path: "case-studies/goalry/index.html",
    url: "https://www.mx.com/case-studies/goalry/",
    last_modified: "2023-11-19",
    publish_date: "2023-10-27",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 469,
    url: "https://www.mx.com/blog/financial-guidance-for-many",
    admin_url: "https://cms.mx.com/admin/content/blog/469",
    name: "Financial Guidance for the Many, Not the Few",
    publish_date: "2023-11-20",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 470,
    url: "https://www.mx.com/blog/leadership-lessons-from-battlefield-to-boardroom",
    admin_url: "https://cms.mx.com/admin/content/blog/470",
    name: "Leadership Lessons from the Battlefield to the Boardroom",
    publish_date: "2023-11-27",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 194,
    url: "https://www.mx.com/news/utah-business-shawn-lindquist-named-a-2022-corporate-counsel-honoree-",
    admin_url: "https://cms.mx.com/admin/content/mx_news/194",
    name: "Shawn Lindquist Named a 2022 Corporate Counsel Honoree",
    publish_date: "2022-04-13",
    last_modified: "2023-11-27T20:58:30.665Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 523,
    url: "https://www.mx.com/news/financial-health-network-becu-study",
    admin_url: "https://cms.mx.com/admin/content/mx_news/523",
    name: "Financial Health Network and BECU Lead Study Powered by MX",
    publish_date: "2023-08-02",
    last_modified: "2023-11-27T21:00:02.222Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 401,
    url: "https://www.mx.com/news/u-s-bank-signs-agreement-with-mx-to-bolster-api-efforts",
    admin_url: "https://cms.mx.com/admin/content/mx_news/401",
    name: "U.S. Bank Signs Agreement with MX to Bolster API Efforts ",
    publish_date: "2019-09-23",
    last_modified: "2023-11-27T21:03:11.381Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 236,
    url: "https://www.mx.com/news/tech-crunch-wes-hummel-has-been-named-chief-technology-officer-cto-of-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/236",
    name: "Wes Hummel Has Been Named Chief Technology Officer (CTO) of MX",
    publish_date: "2022-08-28",
    last_modified: "2023-11-27T21:05:26.924Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 188,
    url: "https://www.mx.com/news/fiserv-and-mx-enable-secure-consumer-financial-data-access-to-accelerate-future-of-open-finance",
    admin_url: "https://cms.mx.com/admin/content/mx_news/188",
    name: "Fiserv and MX Enable Secure Consumer Financial Data Access to Accelerate Future of Open Finance",
    publish_date: "2022-03-21",
    last_modified: "2023-11-27T21:08:35.900Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 254,
    url: "https://www.mx.com/news/american-banker-dwolla-adds-data-partnerships-to-boost-security-for-account-transfers",
    admin_url: "https://cms.mx.com/admin/content/mx_news/254",
    name: "Dwolla Adds Data Partnerships to Boost Security for Account Transfers",
    publish_date: "2022-09-23",
    last_modified: "2023-11-27T21:21:21.449Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 255,
    url: "https://www.mx.com/news/fintech-impact-podcast-mx-with-david-whitcomb",
    admin_url: "https://cms.mx.com/admin/content/mx_news/255",
    name: "MX with David Whitcomb",
    publish_date: "2022-09-20",
    last_modified: "2023-11-27T21:23:48.816Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 259,
    url: "https://www.mx.com/news/digital-growth-institute-podcast-viewing-open-banking-through-a-lens-of-abundance-with-anne-legg-and-jane-barratt",
    admin_url: "https://cms.mx.com/admin/content/mx_news/259",
    name: "Viewing Open Banking Through a Lens of Abundance",
    publish_date: "2022-09-19",
    last_modified: "2023-11-27T21:24:55.356Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 256,
    url: "https://www.mx.com/news/finovate-podcast-scratching-the-surface-of-open-finance-with-nicky-klein",
    admin_url: "https://cms.mx.com/admin/content/mx_news/256",
    name: "Scratching the Surface of Open Finance",
    publish_date: "2022-09-18",
    last_modified: "2023-11-27T21:25:46.835Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 251,
    url: "https://www.mx.com/news/dwolla-and-mx-expand-partnership-with-token-offering",
    admin_url: "https://cms.mx.com/admin/content/mx_news/251",
    name: "Dwolla and MX Expand Partnership with Token Offering",
    publish_date: "2022-09-14",
    last_modified: "2023-11-30T17:43:55.473Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 244,
    url: "https://www.mx.com/news/mx-announces-finalists-for-money-experience-innovator-awards",
    admin_url: "https://cms.mx.com/admin/content/mx_news/244",
    name: "MX Announces Finalists for Money Experience Innovator Awards",
    publish_date: "2022-09-13",
    last_modified: "2023-11-30T17:44:48.926Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 239,
    url: "https://www.mx.com/news/fintech-confidential-podcast-experience-driven-growth-with-ryan-nelsen-evp-of-marketing-at-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/239",
    name: "Experience-driven Growth with Ryan Nelsen, EVP of Marketing at MX",
    publish_date: "2022-09-06",
    last_modified: "2023-11-30T17:45:46.729Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 258,
    url: "https://www.mx.com/news/the-fintech-factor-podcast-pay-pal-to-mx-how-jim-magats-is-building-trust-in-open-finance",
    admin_url: "https://cms.mx.com/admin/content/mx_news/258",
    name: "PayPal to MX: How Jim Magats is Building Trust in Open Finance",
    publish_date: "2022-09-28",
    last_modified: "2023-11-30T17:49:03.481Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 260,
    url: "https://www.mx.com/news/mx-named-to-the-2022-cb-insights-fintech-250",
    admin_url: "https://cms.mx.com/admin/content/mx_news/260",
    name: "MX Named to the 2022 CB Insights' Fintech 250",
    publish_date: "2022-10-05",
    last_modified: "2023-11-30T17:49:24.811Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 232,
    url: "https://www.mx.com/news/open-banking-podcast-jane-barratt-chief-advocacy-officer-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/232",
    name: 'Episode 5: "Made in America" with Jane Barratt, Chief Advocacy Officer at MX',
    publish_date: "2022-09-12",
    last_modified: "2023-11-30T17:50:05.954Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 238,
    url: "https://www.mx.com/news/mx-partners-with-meridian-link-to-deliver-better-account-opening-experiences-with-instant-account-verification",
    admin_url: "https://cms.mx.com/admin/content/mx_news/238",
    name: "MX Partners with MeridianLink® to Deliver Better Account Opening Experiences with Instant Account Verification",
    publish_date: "2022-09-01",
    last_modified: "2023-11-30T17:50:55.063Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 241,
    url: "https://www.mx.com/news/state-of-identity-podcast-talking-open-banking-with-bose-chan-from-mx",
    admin_url: "https://cms.mx.com/admin/content/mx_news/241",
    name: "Open Banking with Bose Chan from MX",
    publish_date: "2022-09-01",
    last_modified: "2023-11-30T17:51:29.333Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 261,
    url: "https://www.mx.com/news/protocol-open-finance-technology-startup-mx-has-landed-another-paypal-veteran",
    admin_url: "https://cms.mx.com/admin/content/mx_news/261",
    name: "Open-Finance Technology Startup MX Has Landed Another PayPal Veteran",
    publish_date: "2022-09-01",
    last_modified: "2023-11-30T17:52:49.615Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 237,
    url: "https://www.mx.com/news/nacha-announces-mx-as-a-preferred-partner-for-risk-management-and-fraud-prevention",
    admin_url: "https://cms.mx.com/admin/content/mx_news/237",
    name: "NACHA Announces MX as a Preferred Partner for Risk Management and Fraud Prevention",
    publish_date: "2022-08-30",
    last_modified: "2023-11-30T17:54:30.879Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 472,
    url: "https://www.mx.com/blog/winning-todays-battle-for-deposits",
    admin_url: "https://cms.mx.com/admin/content/blog/472",
    name: "How to Win in Today’s Battle for Deposits",
    publish_date: "2023-12-01",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 473,
    url: "https://www.mx.com/blog/what-financial-institutions-can-learn-from-mms-and-oreos",
    admin_url: "https://cms.mx.com/admin/content/blog/473",
    name: "What Financial Institutions Can Learn from M&Ms and OREOs",
    publish_date: "2023-12-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 474,
    url: "https://www.mx.com/blog/combating-money-shame",
    admin_url: "https://cms.mx.com/admin/content/blog/474",
    name: "Combating Money Shame by Building Better Financial Relationships",
    publish_date: "2023-12-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 122,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/122",
    url: "https://www.mx.com/guides/trust-and-the-consumer-money-experience",
    name: "A Time for Trust: The Consumer Money Experience ",
    publish_date: "2023-03-29T13:42:59.639Z",
    last_modified: "2023-12-08T20:50:49.178Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 72,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/72",
    url: "https://www.mx.com/whitepapers/modern-connectivity-shaping-open-finance",
    name: "How Modern Connectivity is Shaping Open Finance",
    publish_date: "2023-01-25T02:06:54.822Z",
    last_modified: "2023-12-08T20:50:49.178Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 104,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/104",
    url: "https://www.mx.com/guides/open-banking",
    name: "How Open Banking Works",
    publish_date: "2023-01-25T02:12:01.129Z",
    last_modified: "2023-12-08T20:50:49.178Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 129,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/129",
    url: "https://www.mx.com/whitepapers/how-open-finance-improves-security",
    name: "From Money to Data to Trust: How Open Finance Improves Security",
    publish_date: "2023-08-16T17:57:44.941Z",
    last_modified: "2023-12-08T20:55:55.969Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 128,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/128",
    url: "https://www.mx.com/research/what-influences-where-consumers-choose-to-bank",
    name: "What Influences Where Consumers Choose to Bank",
    publish_date: "2023-07-24T17:04:25.872Z",
    last_modified: "2023-12-08T20:56:41.367Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 127,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/127",
    url: "https://www.mx.com/guides/grow-retain-deposits-with-mx",
    name: "Grow and Retain Deposits with MX",
    publish_date: "2023-06-28T19:32:02.358Z",
    last_modified: "2023-12-08T20:57:13.287Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 126,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/126",
    url: "https://www.mx.com/whitepapers/fintech-trends-to-watch",
    name: "Q2 2023 Fintech Trends to Watch",
    publish_date: "2023-05-11T17:27:55.456Z",
    last_modified: "2023-12-08T20:57:36.492Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 125,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/125",
    url: "https://www.mx.com/whitepapers/open-finance-creates-a-better-money-experience",
    name: "How Open Finance Creates a Better Money Experience",
    publish_date: "2023-05-09T13:43:59.689Z",
    last_modified: "2023-12-08T20:58:24.624Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 123,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/123",
    url: "https://www.mx.com/whitepapers/financial-institution-trends-to-watch",
    name: "Q2 2023 Financial Institution Trends to Watch",
    publish_date: "2023-05-04T21:32:24.623Z",
    last_modified: "2023-12-08T20:58:36.116Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 121,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/121",
    url: "https://www.mx.com/research/insurance-macro-trends",
    name: "2023 Macroeconomic Trends Impacting Insurance Companies",
    publish_date: "2023-02-27T20:39:46.206Z",
    last_modified: "2023-12-08T20:58:49.748Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 120,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/120",
    url: "https://www.mx.com/research/macroeconomic-banking-trends",
    name: "2023 Macroeconomic Trends Impacting Banks",
    publish_date: "2023-02-09T14:10:32.911Z",
    last_modified: "2023-12-08T20:59:04.811Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 114,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/114",
    url: "https://www.mx.com/research/what-consumers-want-from-financial-providers",
    name: "What Consumers Really Want from their Financial Providers: A 2023 Roadmap",
    publish_date: "2023-01-25T02:21:19.712Z",
    last_modified: "2023-12-08T20:59:18.900Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 79,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/79",
    url: "https://www.mx.com/whitepapers/mxs-2022-key-insights-future-finance-and-fintech",
    name: "What Does the Future Hold for Finance and Fintech?",
    publish_date: "2023-01-25T02:06:54.946Z",
    last_modified: "2023-12-08T21:00:04.931Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 117,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/117",
    url: "https://www.mx.com/research/banking-experience-gap-report",
    name: "The Banking Experience Gap: 4 Disconnects Between Financial Institutions and Customer Expectations",
    publish_date: "2023-01-25T02:21:19.731Z",
    last_modified: "2023-12-08T21:02:48.698Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 78,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/78",
    url: "https://www.mx.com/whitepapers/gaps-in-financial-data",
    name: "The Gaps in Financial Data",
    publish_date: "2023-01-25T02:06:54.936Z",
    last_modified: "2023-12-08T21:04:01.620Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 75,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/75",
    url: "https://www.mx.com/whitepapers/open-banking-data-partner-rfi-starter-kit",
    name: "Open Banking Data Partner Request for Information Starter Kit",
    publish_date: "2023-01-25T02:06:54.899Z",
    last_modified: "2023-12-08T21:04:20.603Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 76,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/76",
    url: "https://www.mx.com/whitepapers/cornerstone-research-roadmap-for-fintechs-and-fis",
    name: "Selecting an Open Banking Data Aggregation Vendor",
    publish_date: "2023-01-25T02:06:54.927Z",
    last_modified: "2023-12-08T21:04:38.854Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 81,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/81",
    url: "https://www.mx.com/whitepapers/pains-and-gains-of-crypto",
    name: "Pains and Gains of Crypto",
    publish_date: "2023-01-25T02:06:55.056Z",
    last_modified: "2023-12-08T21:05:14.249Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 74,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/74",
    url: "https://www.mx.com/whitepapers/harnessing-the-power-of-financial-data",
    name: "Harnessing the Power of Financial Data",
    publish_date: "2023-01-25T02:06:54.842Z",
    last_modified: "2023-12-08T21:05:35.867Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 97,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/97",
    url: "https://www.mx.com/guides/open-finance",
    name: "The Ultimate Guide to Open Finance",
    publish_date: "2023-01-25T02:12:00.971Z",
    last_modified: "2023-12-08T21:06:24.678Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 85,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/85",
    url: "https://www.mx.com/whitepapers/real-world-data-enhancement-scenarios",
    name: "Real-World Data Enhancement Scenarios",
    publish_date: "2023-01-25T02:06:55.198Z",
    last_modified: "2023-12-08T21:06:37.972Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 70,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/70",
    url: "https://www.mx.com/whitepapers/the-state-of-2022-bank-budget-planning",
    name: "The State of 2022 Bank Budget Planning",
    publish_date: "2023-01-25T02:06:54.790Z",
    last_modified: "2023-12-08T21:06:54.243Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 69,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/69",
    url: "https://www.mx.com/whitepapers/build-vs-buy-vs-partner-in-banking",
    name: "Build vs. Buy vs. Partner in Banking How to Make the Right Choice",
    publish_date: "2023-01-25T02:06:54.757Z",
    last_modified: "2023-12-08T21:07:06.363Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 84,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/84",
    url: "https://www.mx.com/whitepapers/modern-connectivity-future-of-fintech",
    name: "Modern Connectivity is the Future of Fintech",
    publish_date: "2023-01-25T02:06:55.164Z",
    last_modified: "2023-12-08T21:07:23.156Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 71,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/71",
    url: "https://www.mx.com/whitepapers/new-approach-to-financial-account-aggregation",
    name: "A New Approach to Financial Account Aggregation: Modern Connectivity.",
    publish_date: "2023-01-25T02:06:54.795Z",
    last_modified: "2023-12-08T21:07:33.943Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 82,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/82",
    url: "https://www.mx.com/whitepapers/connected-financial-wellness",
    name: "Connected Financial Wellness: Automate financial wellness to improve customers' health scores",
    publish_date: "2023-01-25T02:06:55.067Z",
    last_modified: "2023-12-08T21:07:49.859Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 101,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/101",
    url: "https://www.mx.com/research/financial-advocacy",
    name: "The Ultimate Guide to Financial Advocacy",
    publish_date: "2023-01-25T02:12:01.060Z",
    last_modified: "2023-12-08T21:08:19.469Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 102,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/102",
    url: "https://www.mx.com/research/banking-user-experience",
    name: "The Ultimate Banker’s Guide to User Experience",
    publish_date: "2023-01-25T02:12:01.077Z",
    last_modified: "2023-12-08T21:08:37.133Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 100,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/100",
    url: "https://www.mx.com/research/banking-digital-transformation",
    name: "The Ultimate Guide to Digital Transformation in Banking",
    publish_date: "2023-01-25T02:12:01.055Z",
    last_modified: "2023-12-08T21:08:53.817Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 103,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/103",
    url: "https://www.mx.com/guides/fintech-data",
    name: "The Ultimate Guide to Fintech Data",
    publish_date: "2023-01-25T02:12:01.094Z",
    last_modified: "2023-12-08T21:09:21.525Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 83,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/83",
    url: "https://www.mx.com/whitepapers/connectivity-for-payments",
    name: "Connect Data for Payments: Give Customers a Quick and Easy Way to Make Payments",
    publish_date: "2023-01-25T02:06:55.072Z",
    last_modified: "2023-12-08T21:10:11.218Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 96,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/96",
    url: "https://www.mx.com/whitepapers/connect-financial-data",
    name: "Connect Financial Data: Make Better Lending Decisions and Simplify the Loan Process",
    publish_date: "2023-01-25T02:06:55.660Z",
    last_modified: "2023-12-08T21:10:58.551Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 108,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/108",
    url: "https://www.mx.com/guides/top-2021-bank-challenges",
    name: "The Ultimate Guide to the Top 2021 Bank Challenges",
    publish_date: "2023-01-25T02:12:01.345Z",
    last_modified: "2023-12-08T21:12:47.964Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 90,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/90",
    url: "https://www.mx.com/whitepapers/5-advocacy-strategies-to-become-the-primary-financial-institution",
    name: "5 Advocacy Strategies to Become the Primary Financial Institution",
    publish_date: "2023-01-25T02:06:55.322Z",
    last_modified: "2023-12-08T21:13:33.672Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 107,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/107",
    url: "https://www.mx.com/research/2021-banking",
    name: "The Ultimate Guide to 2021 Banking",
    publish_date: "2023-01-25T02:12:01.171Z",
    last_modified: "2023-12-08T21:14:14.612Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 106,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/106",
    url: "https://www.mx.com/research/money-experience",
    name: "Ultimate Guide to Money Experience",
    publish_date: "2023-01-25T02:12:01.165Z",
    last_modified: "2023-12-08T21:15:06.546Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 94,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/94",
    url: "https://www.mx.com/whitepapers/banking-transformation-2020-finding-your-force-multipliers",
    name: "Banking Transformation in 2020: Find your Hidden Force Multipliers",
    publish_date: "2023-01-25T02:06:55.537Z",
    last_modified: "2023-12-08T21:15:41.175Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 80,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/80",
    url: "https://www.mx.com/whitepapers/5-top-digital-retail-banking-initiatives-2020",
    name: "5 Top Digital Retail Banking Initiatives in 2020 and How to Align Them Pending an Economic Downturn",
    publish_date: "2023-01-25T02:06:54.999Z",
    last_modified: "2023-12-08T21:16:02.653Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 88,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/88",
    url: "https://www.mx.com/whitepapers/3-ways-to-use-data",
    name: "3 Ways Fintechs Can Use Data to Drive Innovation and Engagement",
    publish_date: "2023-01-25T02:06:55.269Z",
    last_modified: "2023-12-08T21:16:19.795Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 86,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/86",
    url: "https://www.mx.com/whitepapers/5-essential-steps-robust-data-strategy",
    name: "5 Essential Steps to a Robust Data Strategy for Financial Institutions",
    publish_date: "2023-01-25T02:06:55.243Z",
    last_modified: "2023-12-08T21:16:44.434Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 95,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/95",
    url: "https://www.mx.com/whitepapers/personalized-marketing-financial-industry",
    name: "Personalized Marketing for the Financial Industry",
    publish_date: "2023-01-25T02:06:55.591Z",
    last_modified: "2023-12-08T21:17:03.782Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 91,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/91",
    url: "https://www.mx.com/whitepapers/mobile-is-changing-how-people-interact-with-the-world",
    name: "Mobile is Changing How People Interact with the World",
    publish_date: "2023-01-25T02:06:55.370Z",
    last_modified: "2023-12-08T21:17:15.692Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 92,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/92",
    url: "https://www.mx.com/whitepapers/mortality-of-bank-branches",
    name: "The Mortality of Bank Branches",
    publish_date: "2023-01-25T02:06:55.432Z",
    last_modified: "2023-12-08T21:17:28.527Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 89,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/89",
    url: "https://www.mx.com/whitepapers/ada-digital-banking-for-all",
    name: "Digital Banking for All",
    publish_date: "2023-01-25T02:06:55.312Z",
    last_modified: "2023-12-08T21:17:42.324Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 119,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/119",
    url: "https://www.mx.com/whitepapers/the-future-of-financial-services",
    name: "The Future of Financial Services",
    publish_date: "2023-01-30T01:54:12.126Z",
    last_modified: "2023-12-08T21:18:18.897Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 133,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/133",
    url: "https://www.mx.com/guides/acquire-grow-financial-relationships-mx-insights",
    name: "Acquire and Grow Financial Relationships",
    publish_date: "2023-10-30T16:53:41.038Z",
    last_modified: "2023-12-14T23:02:43.421Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 475,
    url: "https://www.mx.com/blog/keeping-consumers-core-of-technology-transformations",
    admin_url: "https://cms.mx.com/admin/content/blog/475",
    name: "Keeping Consumers at the Core of Technology Transformations",
    publish_date: "2023-12-15",
    last_modified: null,
    review: true,
  },
  {
    id: 513,
    status: "published",
    date_created: "2025-02-28T22:46:08.870Z",
    path: "case-studies/mobile-first-initiative/index.html",
    url: "https://www.mx.com/case-studies/mobile-first-initiative/",
    last_modified: "2023-12-18",
    publish_date: "2022-12-19",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 283,
    url: "https://www.mx.com/blog/what-is-account-aggregation",
    admin_url: "https://cms.mx.com/admin/content/blog/283",
    name: "What is Account Aggregation? How it Works, Benefits, and Risks",
    publish_date: "2023-12-21",
    last_modified: null,
    review: true,
  },
  {
    id: 469,
    status: "published",
    date_created: "2025-02-28T22:46:04.235Z",
    path: "adoption-and-engagement-toolkit/fis/index.html",
    url: "https://www.mx.com/adoption-and-engagement-toolkit/fis/",
    last_modified: "2024-01-03",
    publish_date: "2023-11-29",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 98,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/98",
    url: "https://www.mx.com/research/data-ai-automation",
    name: "The Ultimate Guide to Data, AI, and Personalized Financial Automation",
    publish_date: "2023-01-25T02:12:00.989Z",
    last_modified: "2024-01-05T21:49:49.170Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 476,
    url: "https://www.mx.com/blog/financial-resolutions-in-2024",
    admin_url: "https://cms.mx.com/admin/content/blog/476",
    name: "2024: A New Chapter in Financial Resolutions",
    publish_date: "2024-01-09",
    last_modified: null,
    review: true,
  },
  {
    id: 555,
    status: "published",
    date_created: "2025-02-28T22:46:13.226Z",
    path: "mobile/index.html",
    url: "https://www.mx.com/mobile/",
    last_modified: "2024-01-16",
    publish_date: "2024-01-16",
    review: true,
  },
  {
    id: 604,
    status: "published",
    date_created: "2025-02-28T22:46:18.515Z",
    path: "request-demo/mx-mobile/thank-you/index.html",
    url: "https://www.mx.com/request-demo/mx-mobile/thank-you/",
    last_modified: "2024-01-16",
    publish_date: "2024-01-16",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 477,
    url: "https://www.mx.com/blog/2024-predictions-summary",
    admin_url: "https://cms.mx.com/admin/content/blog/477",
    name: "2024 is the Year of Financial Data Intelligence",
    publish_date: "2024-01-18",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 478,
    url: "https://www.mx.com/blog/what-is-financial-data-intelligence",
    admin_url: "https://cms.mx.com/admin/content/blog/478",
    name: "What is Financial Data Intelligence? How it Works, Key Definitions & Benefits",
    publish_date: "2024-01-22",
    last_modified: null,
    review: true,
  },
  {
    id: 477,
    status: "published",
    date_created: "2025-02-28T22:46:05.152Z",
    path: "analysts/thank-you/index.html",
    url: "https://www.mx.com/analysts/thank-you/",
    last_modified: "2024-01-23",
    publish_date: "2022-05-06",
    review: true,
  },
  {
    id: 544,
    status: "published",
    date_created: "2025-02-28T22:46:12.112Z",
    path: "general-waiver/thank-you/index.html",
    url: "https://www.mx.com/general-waiver/thank-you/",
    last_modified: "2024-01-23",
    publish_date: "2022-12-05",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 199,
    url: "https://www.mx.com/news/mx-named-one-of-inc-magazines-best-workplaces-of-2022",
    admin_url: "https://cms.mx.com/admin/content/mx_news/199",
    name: "MX Named One of Inc. Magazine’s Best Workplaces of 2022",
    publish_date: "2022-05-11",
    last_modified: "2024-01-24T16:27:12.918Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 203,
    url: "https://www.mx.com/news/mx-named-to-the-2022-best-places-to-work-in-fintech",
    admin_url: "https://cms.mx.com/admin/content/mx_news/203",
    name: "MX Named to the 2022 Best Places to Work in Fintech",
    publish_date: "2022-06-01",
    last_modified: "2024-01-24T16:27:28.051Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 130,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/130",
    url: "https://www.mx.com/whitepapers/financial-wellness-for-the-masses",
    name: "Financial Wellness for the Masses: Getting Personal Financial Management Right",
    publish_date: "2023-08-29T16:47:18.942Z",
    last_modified: "2024-01-24T16:29:09.914Z",
    review: true,
  },
  {
    id: 607,
    status: "published",
    date_created: "2025-02-28T22:46:18.819Z",
    path: "request-demo/thank-you/index.html",
    url: "https://www.mx.com/request-demo/thank-you/",
    last_modified: "2024-01-25",
    publish_date: "2023-01-09",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 479,
    url: "https://www.mx.com/blog/loud-budgeting-powered-by-data-insights",
    admin_url: "https://cms.mx.com/admin/content/blog/479",
    name: "Powering Loud Budgeting with Data Insights and Tools",
    publish_date: "2024-01-26",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 480,
    url: "https://www.mx.com/blog/is-financial-primacy-possible",
    admin_url: "https://cms.mx.com/admin/content/blog/480",
    name: "Is Financial Primacy Possible with Consumers Today?",
    publish_date: "2024-01-31",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 528,
    url: "https://www.mx.com/news/barratt-named-chief-advocacy-offficer-head-of-global-public-policy",
    admin_url: "https://cms.mx.com/admin/content/mx_news/528",
    name: "MX Names Jane Barratt Chief Advocacy Officer and Head of Global Public Policy ",
    publish_date: "2023-10-18",
    last_modified: "2024-02-01T13:08:47.323Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 481,
    url: "https://www.mx.com/blog/top-factors-for-selecting-financial-provider",
    admin_url: "https://cms.mx.com/admin/content/blog/481",
    name: "Top Factors for Selecting a Financial Provider — And It’s Not What You Expect",
    publish_date: "2024-02-06",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 482,
    url: "https://www.mx.com/blog/rising-to-consumer-expectations",
    admin_url: "https://cms.mx.com/admin/content/blog/482",
    name: "Deliver Money Experiences That Rise to Consumer Expectations",
    publish_date: "2024-02-08",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 483,
    url: "https://www.mx.com/blog/how-consumers-feel-about-finances-in-2024",
    admin_url: "https://cms.mx.com/admin/content/blog/483",
    name: "How Consumers Feel About Finances in 2024",
    publish_date: "2024-02-12",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 137,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/137",
    url: "https://www.mx.com/whitepapers/2023-year-in-review",
    name: "MX 2023 Year in Review",
    publish_date: "2024-02-12T16:31:21.826Z",
    last_modified: "2024-02-12T16:32:22.686Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 168,
    url: "https://www.mx.com/news/american-banker-bank-trends-to-watch-in-2022",
    admin_url: "https://cms.mx.com/admin/content/mx_news/168",
    name: "Bank Tech Trends to Watch in 2022",
    publish_date: "2022-01-04",
    last_modified: "2024-02-15T16:18:17.635Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 536,
    url: "https://www.mx.com/news/fintech-50-2024",
    admin_url: "https://cms.mx.com/admin/content/mx_news/536",
    name: "MX Named to 2024 Forbes Fintech 50 List",
    publish_date: "2024-02-13",
    last_modified: "2024-02-15T23:24:19.819Z",
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 484,
    url: "https://www.mx.com/blog/common-comment-themes-on-cfpb-1033",
    admin_url: "https://cms.mx.com/admin/content/blog/484",
    name: "CFPB Rulemaking on Personal Financial Data Rights: 11K+ Industry Comments, 10 Common Themes ",
    publish_date: "2024-02-21",
    last_modified: null,
    review: true,
  },
  {
    status: "published",
    content_type: "blog",
    id: 485,
    url: "https://www.mx.com/blog/what-is-pfm",
    admin_url: "https://cms.mx.com/admin/content/blog/485",
    name: "What is Personal Financial Management (PFM)? What It Means and Why It Matters",
    publish_date: "2024-02-23",
    last_modified: null,
    review: true,
  },
  {
    id: 473,
    status: "published",
    date_created: "2025-02-28T22:46:04.682Z",
    path: "analysts/h2-2023-update/index.html",
    url: "https://www.mx.com/analysts/h2-2023-update/",
    last_modified: "2024-02-26",
    publish_date: "2024-02-22",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 537,
    url: "https://www.mx.com/news/utah-business-40-under-40-garry-hrechkosy",
    admin_url: "https://cms.mx.com/admin/content/mx_news/537",
    name: "Utah Business Names MX's Garry Hrechkosy to 2024 Forty Under 40 List",
    publish_date: "2024-02-28",
    last_modified: "2024-02-28T17:43:54.333Z",
    review: true,
  },
  {
    status: "published",
    content_type: "events",
    id: 25,
    url: "https://www.mx.com/events/zaytinya-dinner/03132024",
    admin_url: "https://cms.mx.com/admin/content/events/25",
    name: "Exclusive Invite-Only Dinner with MX",
    publish_date: "2024-01-30T22:09:06.754Z",
    last_modified: "2024-03-06T21:16:09.492Z",
    review: true,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 183,
    url: "https://www.mx.com/news/cibc-first-caribbean-launches-online-financial-coach",
    admin_url: "https://cms.mx.com/admin/content/mx_news/183",
    name: "CIBC FirstCaribbean launches online financial ‘coach’",
    publish_date: "2022-02-28",
    last_modified: "2024-03-07T22:27:24.520Z",
    review: true,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 61,
    url: "https://www.mx.com/webinars/putting-the-personal-in-personalized-digital-banking",
    admin_url: "https://cms.mx.com/admin/content/webinars/61",
    name: "Putting the Personal in Personalized Digital Banking: How to Build Engagement and Lasting Relationships",
    publish_date: "2023-11-08",
    last_modified: "2024-03-08T20:42:49.080Z",
    review: true,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 135,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/135",
    url: "https://www.mx.com/whitepapers/the-year-of-financial-data-intelligence",
    name: "4 Predictions for 2024: The Year of Financial Data Intelligence",
    publish_date: "2024-01-17T14:58:44.767Z",
    last_modified: "2024-03-19T20:06:47.393Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 132,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/132",
    url: "https://www.mx.com/whitepapers/mobile-banking-acquire-grow",
    name: "Acquire and Grow Financial Relationships with MX Mobile Banking",
    publish_date: "2023-10-30T16:38:06.131Z",
    last_modified: "2024-03-19T20:07:33.586Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 96,
    url: "https://www.mx.com/blog/how-banks-generate-income-revenue",
    admin_url: "https://cms.mx.com/admin/content/blog/96",
    name: "How the Largest Banks Generate Income and Revenue",
    publish_date: "2024-03-20",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 486,
    url: "https://www.mx.com/blog/what-is-customer-analytics",
    admin_url: "https://cms.mx.com/admin/content/blog/486",
    name: "What is Customer Analytics? How Financial Services Use It and Why It Matters",
    publish_date: "2024-03-22",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 487,
    url: "https://www.mx.com/blog/mobile-banking-consumer-reports-research",
    admin_url: "https://cms.mx.com/admin/content/blog/487",
    name: "How Do Mobile Banking Apps Stack Up? Meeting Consumer Needs",
    publish_date: "2024-03-25",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 124,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/124",
    url: "https://www.mx.com/research/financial-wellness-and-todays-money-experience",
    name: "What is Financial Wellness? It’s Not Today’s Consumer Money Experience",
    publish_date: "2023-05-09T13:35:02.047Z",
    last_modified: "2024-03-26T21:56:40.718Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 141,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/141",
    url: "https://www.mx.com/research/infographic-financial-data-intelligence-current-and-future",
    name: "Infographic: The Current State and Opportunity with Financial Data Intelligence",
    publish_date: "2024-03-26T21:29:32.340Z",
    last_modified: "2024-03-27T14:27:43.479Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 115,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/115",
    url: "https://www.mx.com/research/consumer-money-matters-debt-on-the-rise-savings-on-the-decline",
    name: "Consumer Money Matters: Debt on the Rise, Savings on the Decline",
    publish_date: "2023-01-25T02:21:19.719Z",
    last_modified: "2024-03-27T16:41:01.794Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 1,
    url: "https://www.mx.com/webinars/accelerate-digital-innovation-with-path-by-mx",
    admin_url: "https://cms.mx.com/admin/content/webinars/1",
    name: "Accelerate Digital Innovation with Path by MX",
    publish_date: "2020-07-22",
    last_modified: "2024-03-27T23:49:53.247Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 3,
    url: "https://www.mx.com/webinars/before-and-after-modern-connectivity",
    admin_url: "https://cms.mx.com/admin/content/webinars/3",
    name: "Growth Insights: Before & After Modern Connectivity",
    publish_date: "2021-06-08",
    last_modified: "2024-03-27T23:50:06.821Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 4,
    url: "https://www.mx.com/webinars/gen-z-and-millennial-banking-perspectives-and-2022-predictions",
    admin_url: "https://cms.mx.com/admin/content/webinars/4",
    name: "Gen Z & Millennial Banking Perspectives and 2022 Predictions",
    publish_date: "2021-12-15",
    last_modified: "2024-03-27T23:51:57.697Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 10,
    url: "https://www.mx.com/webinars/the-difference-between-vision-and-delusion",
    admin_url: "https://cms.mx.com/admin/content/webinars/10",
    name: "The Difference Between Vision and Delusion",
    publish_date: "2018-04-24",
    last_modified: "2024-03-27T23:52:23.809Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 9,
    url: "https://www.mx.com/webinars/jim-marous-speaks-june-26-on-mx-webinar",
    admin_url: "https://cms.mx.com/admin/content/webinars/9",
    name: "Jim Marous speaks June 26 on MX webinar",
    publish_date: "2018-05-13",
    last_modified: "2024-03-27T23:52:29.485Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 8,
    url: "https://www.mx.com/webinars/using-data-to-empower-true-financial-strength",
    admin_url: "https://cms.mx.com/admin/content/webinars/8",
    name: "Using Data to Empower True Financial Strength",
    publish_date: "2018-02-27",
    last_modified: "2024-03-27T23:52:33.098Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 5,
    url: "https://www.mx.com/webinars/2022-digital-banking-trends-and-predictions",
    admin_url: "https://cms.mx.com/admin/content/webinars/5",
    name: "2022 Digital Banking Trends and Predictions",
    publish_date: "2022-02-10",
    last_modified: "2024-03-27T23:52:41.294Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 11,
    url: "https://www.mx.com/webinars/how-data-helps-online-banking-providers",
    admin_url: "https://cms.mx.com/admin/content/webinars/11",
    name: "How Data Helps Online Banking Providers",
    publish_date: "2018-05-08",
    last_modified: "2024-03-27T23:53:57.746Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 12,
    url: "https://www.mx.com/webinars/preparing-for-the-5th-age-of-banking-advanced-analytics",
    admin_url: "https://cms.mx.com/admin/content/webinars/12",
    name: "Preparing for the 5th Age of Banking: Advanced Analytics",
    publish_date: "2018-03-13",
    last_modified: "2024-03-27T23:54:03.599Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 13,
    url: "https://www.mx.com/webinars/garbage-to-gold-enhance-your-transaction-data-delight-your-users",
    admin_url: "https://cms.mx.com/admin/content/webinars/13",
    name: "Garbage to Gold: Enhance Your Transaction Data, Delight Your Users",
    publish_date: "2018-02-13",
    last_modified: "2024-03-27T23:54:09.795Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 14,
    url: "https://www.mx.com/webinars/data-the-key-to-delighting-financial-customers",
    admin_url: "https://cms.mx.com/admin/content/webinars/14",
    name: "Data: The Key to Delighting Financial Customers",
    publish_date: "2018-06-26",
    last_modified: "2024-03-27T23:54:16.107Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 15,
    url: "https://www.mx.com/webinars/realizing-the-roi-of-big-data-and-ai-in-banking-the-foundational-step",
    admin_url: "https://cms.mx.com/admin/content/webinars/15",
    name: "Realizing the ROI of Big Data and AI in Banking: The Foundational Steps",
    publish_date: "2018-07-19",
    last_modified: "2024-03-27T23:54:22.381Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 16,
    url: "https://www.mx.com/webinars/utilizing-ai-to-grow-smart-banking-starts-with-clean-data",
    admin_url: "https://cms.mx.com/admin/content/webinars/16",
    name: "Utilizing AI to Grow: Smart Banking Starts with Clean Data",
    publish_date: "2018-08-14",
    last_modified: "2024-03-27T23:54:28.573Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 17,
    url: "https://www.mx.com/webinars/data-is-the-answer-will-you-be-the-disruptor-or-disrupted-in-2019",
    admin_url: "https://cms.mx.com/admin/content/webinars/17",
    name: "Data Is the Answer: Will You Be the Disruptor or Disrupted in 2019?",
    publish_date: "2018-10-30",
    last_modified: "2024-03-27T23:54:34.882Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 18,
    url: "https://www.mx.com/webinars/how-to-power-your-digital-strategy-with-new-technologie",
    admin_url: "https://cms.mx.com/admin/content/webinars/18",
    name: "How to Power Your Digital Strategy with New Technologies",
    publish_date: "2019-04-04",
    last_modified: "2024-03-27T23:54:40.257Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 19,
    url: "https://www.mx.com/webinars/the-importance-of-data-quality-in-the-digital-age",
    admin_url: "https://cms.mx.com/admin/content/webinars/19",
    name: "The Importance of Data Quality in the Digital Age",
    publish_date: "2018-12-12",
    last_modified: "2024-03-27T23:54:49.448Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 20,
    url: "https://www.mx.com/webinars/realizing-the-power-and-roi-of-big-data",
    admin_url: "https://cms.mx.com/admin/content/webinars/20",
    name: "Realizing the Power and ROI of Big Data",
    publish_date: "2018-04-10",
    last_modified: "2024-03-27T23:54:55.600Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 21,
    url: "https://www.mx.com/webinars/dont-let-apple-take-a-bite-out-of-you-compete-against-big-tech",
    admin_url: "https://cms.mx.com/admin/content/webinars/21",
    name: "Don't Let Apple Take a Bite Out of You: Compete Against Big Tech",
    publish_date: "2019-03-29",
    last_modified: "2024-03-27T23:55:03.047Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 22,
    url: "https://www.mx.com/webinars/a-data-driven-approach-to-really-knowing-your-customer",
    admin_url: "https://cms.mx.com/admin/content/webinars/22",
    name: "A Data-Driven Approach to Really Knowing Your Customers",
    publish_date: "2019-03-07",
    last_modified: "2024-03-27T23:55:08.484Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 23,
    url: "https://www.mx.com/webinars/financial-industry-trends-the-impact-of-data-in-the-digital-age",
    admin_url: "https://cms.mx.com/admin/content/webinars/23",
    name: "Financial Industry Trends: The Impact of Data in the Digital Age",
    publish_date: "2019-07-09",
    last_modified: "2024-03-27T23:55:14.380Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 24,
    url: "https://www.mx.com/webinars/empowering-your-marketing-by-improving-peoples-financial-lives",
    admin_url: "https://cms.mx.com/admin/content/webinars/24",
    name: "Empowering Your Marketing By Improving People’s Financial Lives",
    publish_date: "2019-06-20",
    last_modified: "2024-03-27T23:55:20.902Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 25,
    url: "https://www.mx.com/webinars/how-to-increase-financial-awareness",
    admin_url: "https://cms.mx.com/admin/content/webinars/25",
    name: "How to Increase Financial Awareness",
    publish_date: "2019-01-10",
    last_modified: "2024-03-27T23:55:27.515Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 26,
    url: "https://www.mx.com/webinars/the-state-of-aggregation-an-open-banking-conversation",
    admin_url: "https://cms.mx.com/admin/content/webinars/26",
    name: "The State of Aggregation: An Open Banking Conversation",
    publish_date: "2019-05-30",
    last_modified: "2024-03-27T23:55:34.704Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 27,
    url: "https://www.mx.com/webinars/maslows-future-of-banking",
    admin_url: "https://cms.mx.com/admin/content/webinars/27",
    name: "Maslow’s Future of Banking",
    publish_date: "2019-11-19",
    last_modified: "2024-03-27T23:55:40.851Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 28,
    url: "https://www.mx.com/webinars/how-to-use-design-as-a-competitive-advantage",
    admin_url: "https://cms.mx.com/admin/content/webinars/28",
    name: "How to Use Design as a Competitive Advantage",
    publish_date: "2019-08-29",
    last_modified: "2024-03-27T23:55:47.204Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 29,
    url: "https://www.mx.com/webinars/how-financial-institutions-can-use-data-to-build-trust",
    admin_url: "https://cms.mx.com/admin/content/webinars/29",
    name: "How Financial Institutions Can Use Data to Build Trust",
    publish_date: "2020-02-19",
    last_modified: "2024-03-27T23:55:53.819Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 30,
    url: "https://www.mx.com/webinars/four-fintech-data-faux-pas-and-how-to-avoid-them",
    admin_url: "https://cms.mx.com/admin/content/webinars/30",
    name: "Four Fintech Data Faux Pas and How to Avoid Them",
    publish_date: "2020-02-19",
    last_modified: "2024-03-27T23:55:59.540Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 31,
    url: "https://www.mx.com/webinars/crucial-insights-to-continue-accessing-account-data",
    admin_url: "https://cms.mx.com/admin/content/webinars/31",
    name: "3 Crucial Insights to Continue Accessing Account Data Beyond 2020",
    publish_date: "2020-04-22",
    last_modified: "2024-03-27T23:56:07.897Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 32,
    url: "https://www.mx.com/webinars/proactively-using-customer-data-for-good",
    admin_url: "https://cms.mx.com/admin/content/webinars/32",
    name: "Live Q&A: Proactively Using Customer Data for Good",
    publish_date: "2020-04-22",
    last_modified: "2024-03-27T23:56:54.146Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 33,
    url: "https://www.mx.com/webinars/what-consumers-really-want-from-financial-institutions",
    admin_url: "https://cms.mx.com/admin/content/webinars/33",
    name: "What Consumers Really Want from Financial Institutions: Your 2023 Roadmap to Success",
    publish_date: "2023-01-17",
    last_modified: "2024-03-27T23:57:05.125Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 58,
    url: "https://www.mx.com/webinars/how-money-experiences-make-break-consumer-trust",
    admin_url: "https://cms.mx.com/admin/content/webinars/58",
    name: "The Good, the Bad, and the Broken: How Money Experiences Make or Break Consumer Trust",
    publish_date: "2023-06-07",
    last_modified: "2024-03-27T23:58:38.988Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 59,
    url: "https://www.mx.com/product-forums/open-finance-financial-wellness/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/59",
    name: "Drive Engagement & Retention with Financial Wellness | MX Product Forum",
    publish_date: "2023-05-09",
    last_modified: "2024-03-27T23:58:44.201Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 60,
    url: "https://www.mx.com/product-forums/driving-adoption-and-engagement/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/60",
    name: "Winning the Fight for Customer Loyalty and Business Growth | MX Innovation Forum",
    publish_date: "2023-07-26",
    last_modified: "2024-03-27T23:58:49.076Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 62,
    url: "https://www.mx.com/product-forums/how-to-win-retain-customers-2024/watch",
    admin_url: "https://cms.mx.com/admin/content/webinars/62",
    name: "The Year of Financial Data Intelligence — How to Win and Retain Customers in 2024",
    publish_date: "2024-02-09",
    last_modified: "2024-03-27T23:58:59.688Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 65,
    url: "https://www.mx.com/riff-on-a-lift/july-2022/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/65",
    name: "Riff on a Lift | Fintech Takes, Moov, She Banks, Bankrate, MX",
    publish_date: "2022-07-13",
    last_modified: "2024-03-27T23:59:14.872Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 66,
    url: "https://www.mx.com/riff-on-a-lift/march-2023/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/66",
    name: "Riff on a Lift | Finance & Fintech Industry Discussions",
    publish_date: "2023-03-28",
    last_modified: "2024-03-27T23:59:19.352Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 67,
    url: "https://www.mx.com/digital-banking-week/",
    admin_url: "https://cms.mx.com/admin/content/webinars/67",
    name: "Digital Banking Week",
    publish_date: "2019-09-19",
    last_modified: "2024-03-27T23:59:24.305Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 68,
    url: "https://www.mx.com/product-forum-2021/on-demand/",
    admin_url: "https://cms.mx.com/admin/content/webinars/68",
    name: "2021 MX Product Forum",
    publish_date: "2021-05-06",
    last_modified: "2024-03-27T23:59:29.166Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 69,
    url: "https://www.mx.com/product-forums/open-finance-data-innovation/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/69",
    name: "Open Finance: Unlock Data and Limitless Innovation",
    publish_date: "2022-04-14",
    last_modified: "2024-03-27T23:59:33.954Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 70,
    url: "https://www.mx.com/webinars/evaluating-open-banking-data-partners/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/70",
    name: "The Value of Open Banking and Choosing the Right Data Partner",
    publish_date: "2022-06-08",
    last_modified: "2024-03-27T23:59:38.672Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 488,
    url: "https://www.mx.com/blog/good-bad-and-opportunities-to-leverage-consumer-financial-data",
    admin_url: "https://cms.mx.com/admin/content/blog/488",
    name: "The Good, Bad, and Opportunities in How Financial Institutions Leverage Consumer Financial Data",
    publish_date: "2024-03-28",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 139,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/139",
    url: "https://www.mx.com/research/opportunity-growth-financial-services",
    name: "Opportunity And Growth In Financial Services: Turning Data Insights into Actionable Intelligence",
    publish_date: "2024-03-26T21:13:00.469Z",
    last_modified: "2024-03-28T17:11:28.639Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 489,
    url: "https://www.mx.com/blog/how-financial-data-intelligence-improves-outcomes",
    admin_url: "https://cms.mx.com/admin/content/blog/489",
    name: "How Financial Data Intelligence Improves Consumer and Business Outcomes",
    publish_date: "2024-04-02",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 74,
    url: "https://www.mx.com/product-forums/driving-adoption-and-engagement/insights-demo/",
    admin_url: "https://cms.mx.com/admin/content/webinars/74",
    name: "MX Insights Demo - Erin Caldwell",
    publish_date: "2023-07-26",
    last_modified: "2024-04-02T18:06:43.469Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 116,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/116",
    url: "https://www.mx.com/research/consumer-research-digital-and-mobile-banking",
    name: "Consumer Research on Digital and Mobile Banking",
    publish_date: "2023-01-25T02:21:19.725Z",
    last_modified: "2024-04-04T14:27:49.945Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 490,
    url: "https://www.mx.com/blog/mobile-banking-stats",
    admin_url: "https://cms.mx.com/admin/content/blog/490",
    name: "The Ultimate List of Mobile Banking Trends and Statistics (2024)",
    publish_date: "2024-04-05",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "services_descriptions",
    id: 1,
    url: "https://www.mx.com/services-descriptions/client-snapshots",
    admin_url: "https://cms.mx.com/admin/content/services_descriptions/1",
    name: "Client Snapshots",
    publish_date: "2024-04-09",
    last_modified: "2024-04-09T17:02:38.552Z",
    review: false,
  },
  {
    id: 540,
    status: "published",
    date_created: "2025-02-28T22:46:11.706Z",
    path: "data-services/index.html",
    url: "https://www.mx.com/data-services/",
    last_modified: "2024-04-10",
    publish_date: "2024-04-10",
    review: false,
  },
  {
    id: 595,
    status: "published",
    date_created: "2025-02-28T22:46:17.595Z",
    path: "recruitment-privacy/index.html",
    url: "https://www.mx.com/recruitment-privacy/",
    last_modified: "2024-04-10",
    publish_date: "2024-04-10",
    review: false,
  },
  {
    id: 616,
    status: "published",
    date_created: "2025-02-28T22:46:19.796Z",
    path: "terms/index.html",
    url: "https://www.mx.com/terms/",
    last_modified: "2024-04-10",
    publish_date: "2024-04-10",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 491,
    url: "https://www.mx.com/blog/financial-wellness-stats",
    admin_url: "https://cms.mx.com/admin/content/blog/491",
    name: "Statistics on Consumer Financial Wellness and Personal Financial Management",
    publish_date: "2024-04-10",
    last_modified: null,
    review: false,
  },
  {
    id: 543,
    status: "published",
    date_created: "2025-02-28T22:46:12.008Z",
    path: "general-waiver/index.html",
    url: "https://www.mx.com/general-waiver/",
    last_modified: "2024-04-12",
    publish_date: "2022-12-05",
    review: false,
  },
  {
    id: 618,
    status: "published",
    date_created: "2025-02-28T22:46:20.005Z",
    path: "transparency-in-coverage/index.html",
    url: "https://www.mx.com/transparency-in-coverage/",
    last_modified: "2024-04-15",
    publish_date: "2022-07-28",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 492,
    url: "https://www.mx.com/blog/consumer-engagement-stats",
    admin_url: "https://cms.mx.com/admin/content/blog/492",
    name: "Increasing Consumer Engagement: Data and Statistics",
    publish_date: "2024-04-16",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 1,
    url: "https://www.mx.com/work-with-mx/axos-bank",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/1",
    name: "Axos Bank",
    publish_date: "2024-04-16T22:22:16.471Z",
    last_modified: "2024-04-22T20:39:46.297Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 493,
    url: "https://www.mx.com/blog/canada-consumer-driven-banking-framework",
    admin_url: "https://cms.mx.com/admin/content/blog/493",
    name: "Canada’s Consumer-Driven Banking Framework Takes a Step Forward",
    publish_date: "2024-04-24",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 5,
    url: "https://www.mx.com/work-with-mx/pacific-premier-bank",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/5",
    name: "Pacific Premier Bank",
    publish_date: "2024-04-24T16:22:24.876Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 6,
    url: "https://www.mx.com/work-with-mx/bremer-bank",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/6",
    name: "Bremer Bank",
    publish_date: "2024-04-24T16:23:53.413Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 7,
    url: "https://www.mx.com/work-with-mx/popular-bank",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/7",
    name: "Popular Bank",
    publish_date: "2024-04-24T16:25:06.936Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 8,
    url: "https://www.mx.com/work-with-mx/baxter-credit-union",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/8",
    name: "Baxter Credit Union",
    publish_date: "2024-04-24T19:16:06.704Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 2,
    url: "https://www.mx.com/work-with-mx/wells-fargo-bank",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/2",
    name: "Wells Fargo Bank",
    publish_date: "2024-04-18T23:27:30.694Z",
    last_modified: "2024-04-24T19:16:11.074Z",
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 9,
    url: "https://www.mx.com/work-with-mx/citizens-bank",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/9",
    name: "Citizens Bank",
    publish_date: "2024-04-24T19:17:47.534Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 10,
    url: "https://www.mx.com/work-with-mx/digital-federal-credit-union",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/10",
    name: "Digital Federal Credit Union",
    publish_date: "2024-04-24T19:18:57.720Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 11,
    url: "https://www.mx.com/work-with-mx/everbank",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/11",
    name: "EverBank",
    publish_date: "2024-04-24T19:20:21.767Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 12,
    url: "https://www.mx.com/work-with-mx/fifth-third-bank",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/12",
    name: "Fifth Third Bank",
    publish_date: "2024-04-24T19:21:30.016Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 13,
    url: "https://www.mx.com/work-with-mx/keybank",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/13",
    name: "KeyBank",
    publish_date: "2024-04-24T19:22:42.030Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 14,
    url: "https://www.mx.com/work-with-mx/randolph-brooks-federal-credit-union",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/14",
    name: "Randolph-Brooks Federal Credit Union",
    publish_date: "2024-04-24T19:23:54.338Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 3,
    url: "https://www.mx.com/work-with-mx/security-service-federal-credit-union",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/3",
    name: "Security Service Federal Credit Union",
    publish_date: "2024-04-18T23:33:17.759Z",
    last_modified: "2024-04-24T19:24:39.147Z",
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 15,
    url: "https://www.mx.com/work-with-mx/travis-credit-union",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/15",
    name: "Travis Credit Union",
    publish_date: "2024-04-24T19:25:42.231Z",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "Value_Engineering_ABM_Campaign",
    id: 4,
    url: "https://www.mx.com/work-with-mx/midfirst-bank",
    admin_url:
      "https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/4",
    name: "MidFirst Bank",
    publish_date: "2024-04-24T16:20:46.608Z",
    last_modified: "2024-04-25T15:56:23.027Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 494,
    url: "https://www.mx.com/blog/account-opening-stats",
    admin_url: "https://cms.mx.com/admin/content/blog/494",
    name: "What Impacts the Account Opening Process and Engagement for Consumers? Trends and Statistics",
    publish_date: "2024-04-26",
    last_modified: null,
    review: false,
  },
  {
    id: 467,
    status: "published",
    date_created: "2025-02-28T22:46:04.013Z",
    path: "1033/index.html",
    url: "https://www.mx.com/1033/",
    last_modified: "2024-04-30",
    publish_date: "2024-04-30",
    review: false,
  },
  {
    status: "published",
    content_type: "services_descriptions",
    id: 2,
    url: "https://www.mx.com/services-descriptions/professional-services-terms-conditions",
    admin_url: "https://cms.mx.com/admin/content/services_descriptions/2",
    name: "Professional Services Terms and Conditions",
    publish_date: "2024-05-01",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "services_descriptions",
    id: 3,
    url: "https://www.mx.com/services-descriptions/record-deletion",
    admin_url: "https://cms.mx.com/admin/content/services_descriptions/3",
    name: "Record Deletion",
    publish_date: "2024-05-01",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "services_descriptions",
    id: 4,
    url: "https://www.mx.com/services-descriptions/technical-consulting",
    admin_url: "https://cms.mx.com/admin/content/services_descriptions/4",
    name: "Technical Consulting",
    publish_date: "2024-05-01",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "services_descriptions",
    id: 5,
    url: "https://www.mx.com/services-descriptions/base-product-configuration",
    admin_url: "https://cms.mx.com/admin/content/services_descriptions/5",
    name: "Base Product Configuration",
    publish_date: "2024-05-01",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "events",
    id: 27,
    url: "https://www.mx.com/events/financial-brand-marketing-lunch/05212024",
    admin_url: "https://cms.mx.com/admin/content/events/27",
    name: "Marketing Executives Networking Lunch | Financial Brand Forum 2024",
    publish_date: "2024-05-01T15:15:09.771Z",
    last_modified: "2024-05-01T15:16:55.007Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 495,
    url: "https://www.mx.com/blog/consumer-spending-habits",
    admin_url: "https://cms.mx.com/admin/content/blog/495",
    name: "Data and Statistics on Consumer Spending Habits",
    publish_date: "2024-05-03",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 496,
    url: "https://www.mx.com/blog/what-financial-services-can-learn-from-telecom",
    admin_url: "https://cms.mx.com/admin/content/blog/496",
    name: "What the Financial Services Industry Can Learn from Telecom ",
    publish_date: "2024-05-06",
    last_modified: null,
    review: false,
  },
  {
    id: 606,
    status: "published",
    date_created: "2025-02-28T22:46:18.719Z",
    path: "request-demo/product-updates/thank-you/index.html",
    url: "https://www.mx.com/request-demo/product-updates/thank-you/",
    last_modified: "2024-05-09",
    publish_date: "2024-05-09",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 497,
    url: "https://www.mx.com/blog/section-1033-obligations",
    admin_url: "https://cms.mx.com/admin/content/blog/497",
    name: "Where Financial Providers May Fall Short on Section 1033 Obligations",
    publish_date: "2024-05-09",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "events",
    id: 17,
    url: "https://www.mx.com/events/mx-networking-dinner/05042023",
    admin_url: "https://cms.mx.com/admin/content/events/17",
    name: "Networking Dinner and Whiskey Tasting",
    publish_date: "2023-04-19T15:31:14.958Z",
    last_modified: "2024-05-16T16:58:11.069Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 235,
    url: "https://www.mx.com/blog/a-list-of-personal-financial-management-pfm-providers-in-the-united-states",
    admin_url: "https://cms.mx.com/admin/content/blog/235",
    name: "A List of Personal Financial Management (PFM) Providers in the United States",
    publish_date: "2024-05-17",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 498,
    url: "https://www.mx.com/blog/open-finance-supporting-stats",
    admin_url: "https://cms.mx.com/admin/content/blog/498",
    name: "Making the Case for Open Finance: Consumer Statistics ",
    publish_date: "2024-05-17",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 499,
    url: "https://www.mx.com/blog/fraud-in-financial-services",
    admin_url: "https://cms.mx.com/admin/content/blog/499",
    name: "Fraud in Financial Services: Consumer Data and Statistics",
    publish_date: "2024-05-22",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "services_descriptions",
    id: 6,
    url: "https://www.mx.com/services-descriptions/customer-analytics",
    admin_url: "https://cms.mx.com/admin/content/services_descriptions/6",
    name: "Customer Analytics",
    publish_date: "2024-05-21",
    last_modified: "2024-05-22T01:32:47.108Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 136,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/136",
    url: "https://www.mx.com/research/how-to-keep-consumers-from-breaking-up-with-banks",
    name: "How to Keep Consumers from Breaking Up with Banks",
    publish_date: "2024-01-29T23:14:49.100Z",
    last_modified: "2024-05-23T17:57:41.416Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 140,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/140",
    url: "https://www.mx.com/whitepapers/key-takeaways-forrester-opportunity-snapshot",
    name: "Unlocking Actionable Intelligence: Key Takeaways from Forrester Opportunity Snapshot",
    publish_date: "2024-03-26T21:24:29.811Z",
    last_modified: "2024-05-23T21:59:03.674Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 72,
    url: "https://www.mx.com/product-forums/unlocking-actionable-intelligence/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/72",
    name: "Unlocking Actionable Intelligence | MX Innovation Forum",
    publish_date: "2024-03-28",
    last_modified: "2024-05-28T19:40:32.666Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 63,
    url: "https://www.mx.com/webinars/taking-personalized-digital-banking-next-level",
    admin_url: "https://cms.mx.com/admin/content/webinars/63",
    name: "Taking Personalized Digital Banking to the Next Level: How to Drive Engagement and Value",
    publish_date: "2024-02-27",
    last_modified: "2024-05-28T19:40:46.530Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 500,
    url: "https://www.mx.com/blog/demand-for-personalized-money-experiences",
    admin_url: "https://cms.mx.com/admin/content/blog/500",
    name: "The Demand for Personalized Money Experiences: Stats and Graphs",
    publish_date: "2024-05-29",
    last_modified: null,
    review: false,
  },
  {
    id: 468,
    status: "published",
    date_created: "2025-02-28T22:46:04.122Z",
    path: "404/index.html",
    url: "https://www.mx.com/404/",
    last_modified: "2024-05-30",
    publish_date: "2022-06-09",
    review: false,
  },
  {
    id: 482,
    status: "published",
    date_created: "2025-02-28T22:46:05.667Z",
    path: "case-studies/afcu/index.html",
    url: "https://www.mx.com/case-studies/afcu/",
    last_modified: "2024-05-30",
    publish_date: "2023-12-18",
    review: false,
  },
  {
    id: 483,
    status: "published",
    date_created: "2025-02-28T22:46:05.770Z",
    path: "case-studies/affinity-based-marketing/index.html",
    url: "https://www.mx.com/case-studies/affinity-based-marketing/",
    last_modified: "2024-05-30",
    publish_date: "2022-12-19",
    review: false,
  },
  {
    id: 484,
    status: "published",
    date_created: "2025-02-28T22:46:05.893Z",
    path: "case-studies/affinity-federal-credit-union/index.html",
    url: "https://www.mx.com/case-studies/affinity-federal-credit-union/",
    last_modified: "2024-05-30",
    publish_date: "2023-11-06",
    review: false,
  },
  {
    id: 486,
    status: "published",
    date_created: "2025-02-28T22:46:06.103Z",
    path: "case-studies/atb-financial/index.html",
    url: "https://www.mx.com/case-studies/atb-financial/",
    last_modified: "2024-05-30",
    publish_date: "2023-12-19",
    review: false,
  },
  {
    id: 493,
    status: "published",
    date_created: "2025-02-28T22:46:06.807Z",
    path: "case-studies/cure-for-lost-deposits/index.html",
    url: "https://www.mx.com/case-studies/cure-for-lost-deposits/",
    last_modified: "2024-05-30",
    publish_date: "2023-12-13",
    review: false,
  },
  {
    id: 496,
    status: "published",
    date_created: "2025-02-28T22:46:07.109Z",
    path: "case-studies/etfcu/index.html",
    url: "https://www.mx.com/case-studies/etfcu/",
    last_modified: "2024-05-30",
    publish_date: "2023-12-14",
    review: false,
  },
  {
    id: 497,
    status: "published",
    date_created: "2025-02-28T22:46:07.228Z",
    path: "case-studies/first-citizens-bank/index.html",
    url: "https://www.mx.com/case-studies/first-citizens-bank/",
    last_modified: "2024-05-30",
    publish_date: "2023-12-08",
    review: false,
  },
  {
    id: 499,
    status: "published",
    date_created: "2025-02-28T22:46:07.428Z",
    path: "case-studies/formfree/index.html",
    url: "https://www.mx.com/case-studies/formfree/",
    last_modified: "2024-05-30",
    publish_date: "2023-10-27",
    review: false,
  },
  {
    id: 506,
    status: "published",
    date_created: "2025-02-28T22:46:08.128Z",
    path: "case-studies/informative-research/index.html",
    url: "https://www.mx.com/case-studies/informative-research/",
    last_modified: "2024-05-30",
    publish_date: "2024-03-20",
    review: false,
  },
  {
    id: 507,
    status: "published",
    date_created: "2025-02-28T22:46:08.230Z",
    path: "case-studies/lgfcu/index.html",
    url: "https://www.mx.com/case-studies/lgfcu/",
    last_modified: "2024-05-30",
    publish_date: "2024-01-02",
    review: false,
  },
  {
    id: 515,
    status: "published",
    date_created: "2025-02-28T22:46:09.067Z",
    path: "case-studies/mt-bank/index.html",
    url: "https://www.mx.com/case-studies/mt-bank/",
    last_modified: "2024-05-30",
    publish_date: "2024-01-02",
    review: false,
  },
  {
    id: 517,
    status: "published",
    date_created: "2025-02-28T22:46:09.273Z",
    path: "case-studies/notre-dame-fcu/index.html",
    url: "https://www.mx.com/case-studies/notre-dame-fcu/",
    last_modified: "2024-05-30",
    publish_date: "2024-01-09",
    review: false,
  },
  {
    id: 520,
    status: "published",
    date_created: "2025-02-28T22:46:09.605Z",
    path: "case-studies/pioneer-bank/index.html",
    url: "https://www.mx.com/case-studies/pioneer-bank/",
    last_modified: "2024-05-30",
    publish_date: "2024-01-19",
    review: false,
  },
  {
    id: 521,
    status: "published",
    date_created: "2025-02-28T22:46:09.707Z",
    path: "case-studies/regional-bank-utilizes-mx-data/index.html",
    url: "https://www.mx.com/case-studies/regional-bank-utilizes-mx-data/",
    last_modified: "2024-05-30",
    publish_date: "2023-12-18",
    review: false,
  },
  {
    id: 525,
    status: "published",
    date_created: "2025-02-28T22:46:10.195Z",
    path: "case-studies/synergy-credit-union/index.html",
    url: "https://www.mx.com/case-studies/synergy-credit-union/",
    last_modified: "2024-05-30",
    publish_date: "2024-01-08",
    review: false,
  },
  {
    id: 528,
    status: "published",
    date_created: "2025-02-28T22:46:10.496Z",
    path: "case-studies/travis-credit-union/index.html",
    url: "https://www.mx.com/case-studies/travis-credit-union/",
    last_modified: "2024-05-30",
    publish_date: "2024-01-17",
    review: false,
  },
  {
    id: 529,
    status: "published",
    date_created: "2025-02-28T22:46:10.598Z",
    path: "case-studies/uccu/index.html",
    url: "https://www.mx.com/case-studies/uccu/",
    last_modified: "2024-05-30",
    publish_date: "2024-01-18",
    review: false,
  },
  {
    id: 542,
    status: "published",
    date_created: "2025-02-28T22:46:11.905Z",
    path: "driving-actionable-financial-data-intelligence/index.html",
    url: "https://www.mx.com/driving-actionable-financial-data-intelligence/",
    last_modified: "2024-05-30",
    publish_date: "2024-03-21",
    review: false,
  },
  {
    id: 548,
    status: "published",
    date_created: "2025-02-28T22:46:12.512Z",
    path: "innovator-awards/index.html",
    url: "https://www.mx.com/innovator-awards/",
    last_modified: "2024-05-30",
    publish_date: "2023-09-13",
    review: false,
  },
  {
    id: 615,
    status: "published",
    date_created: "2025-02-28T22:46:19.692Z",
    path: "subscription-center/index.html",
    url: "https://www.mx.com/subscription-center/",
    last_modified: "2024-05-30",
    publish_date: "2024-01-22",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 541,
    url: "https://www.mx.com/news/utah-business-30-women-to-watch-crystal-anderson",
    admin_url: "https://cms.mx.com/admin/content/mx_news/541",
    name: "Utah Business Names MX's Crystal Anderson to its 2024 30 Women to Watch List",
    publish_date: "2024-05-30",
    last_modified: "2024-05-30T22:42:52.325Z",
    review: false,
  },
  {
    id: 487,
    status: "published",
    date_created: "2025-02-28T22:46:06.204Z",
    path: "case-studies/becu/call-center/index.html",
    url: "https://www.mx.com/case-studies/becu/call-center/",
    last_modified: "2024-05-31",
    publish_date: "2023-12-18",
    review: false,
  },
  {
    id: 488,
    status: "published",
    date_created: "2025-02-28T22:46:06.305Z",
    path: "case-studies/becu/marketing/index.html",
    url: "https://www.mx.com/case-studies/becu/marketing/",
    last_modified: "2024-05-31",
    publish_date: "2024-01-04",
    review: false,
  },
  {
    id: 489,
    status: "published",
    date_created: "2025-02-28T22:46:06.405Z",
    path: "case-studies/becu/mobile-banking/index.html",
    url: "https://www.mx.com/case-studies/becu/mobile-banking/",
    last_modified: "2024-05-31",
    publish_date: "2023-12-15",
    review: false,
  },
  {
    id: 522,
    status: "published",
    date_created: "2025-02-28T22:46:09.812Z",
    path: "case-studies/suma-hello-iconic/index.html",
    url: "https://www.mx.com/case-studies/suma-hello-iconic/",
    last_modified: "2024-05-31",
    publish_date: "2024-01-19",
    review: false,
  },
  {
    id: 524,
    status: "published",
    date_created: "2025-02-28T22:46:10.087Z",
    path: "case-studies/suncoast-credit-union/index.html",
    url: "https://www.mx.com/case-studies/suncoast-credit-union/",
    last_modified: "2024-05-31",
    publish_date: "2024-02-13",
    review: false,
  },
  {
    id: 580,
    status: "published",
    date_created: "2025-02-28T22:46:16.051Z",
    path: "product-forums/unlocking-actionable-intelligence/watch-staged/index.html",
    url: "https://www.mx.com/product-forums/unlocking-actionable-intelligence/watch-staged/",
    last_modified: "2024-05-31",
    publish_date: "2024-03-13",
    review: false,
  },
  {
    id: 534,
    status: "published",
    date_created: "2025-02-28T22:46:11.114Z",
    path: "case-studies/ynab/index.html",
    url: "https://www.mx.com/case-studies/ynab/",
    last_modified: "2024-06-03",
    publish_date: "2024-02-26",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 501,
    url: "https://www.mx.com/blog/encouraging-new-money-management-approaches",
    admin_url: "https://cms.mx.com/admin/content/blog/501",
    name: "How to Encourage Consumers to Use New Money Management Approaches ",
    publish_date: "2024-06-04",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 76,
    url: "https://www.mx.com/summit/2020/",
    admin_url: "https://cms.mx.com/admin/content/webinars/76",
    name: "MXS On-demand 2020",
    publish_date: "2020-09-02",
    last_modified: "2024-06-04T17:25:07.264Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 77,
    url: "https://www.mx.com/summit/2021/",
    admin_url: "https://cms.mx.com/admin/content/webinars/77",
    name: "MXS On-demand 2021",
    publish_date: "2021-10-05",
    last_modified: "2024-06-04T17:26:07.140Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 159,
    url: "https://www.mx.com/news/caden-mc-bride-named-one-of-utah-business-s-2021-20-in-their-20s-honorees",
    admin_url: "https://cms.mx.com/admin/content/mx_news/159",
    name: "Caden McBride named one of Utah Business's 2021 20 In Their 20s honorees",
    publish_date: "2021-11-17",
    last_modified: "2024-06-04T18:19:51.427Z",
    review: false,
  },
  {
    id: 576,
    status: "published",
    date_created: "2025-02-28T22:46:15.644Z",
    path: "product-forums/maximized-intelligence/thank-you/index.html",
    url: "https://www.mx.com/product-forums/maximized-intelligence/thank-you/",
    last_modified: "2024-06-06",
    publish_date: "2024-06-06",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 502,
    url: "https://www.mx.com/blog/defining-living-paycheck-to-paycheck",
    admin_url: "https://cms.mx.com/admin/content/blog/502",
    name: "What Does Living Paycheck to Paycheck Mean for Consumers? ",
    publish_date: "2024-06-06",
    last_modified: null,
    review: false,
  },
  {
    id: 568,
    status: "published",
    date_created: "2025-02-28T22:46:14.838Z",
    path: "product-forums/data-access/index.html",
    url: "https://www.mx.com/product-forums/data-access/",
    last_modified: "2024-06-10",
    publish_date: "2023-05-09",
    review: false,
  },
  {
    id: 569,
    status: "published",
    date_created: "2025-02-28T22:46:14.938Z",
    path: "product-forums/driving-adoption-and-engagement/insights-demo/index.html",
    url: "https://www.mx.com/product-forums/driving-adoption-and-engagement/insights-demo/",
    last_modified: "2024-06-10",
    publish_date: "2023-08-01",
    review: false,
  },
  {
    id: 570,
    status: "published",
    date_created: "2025-02-28T22:46:15.036Z",
    path: "product-forums/driving-adoption-and-engagement/watch/index.html",
    url: "https://www.mx.com/product-forums/driving-adoption-and-engagement/watch/",
    last_modified: "2024-06-10",
    publish_date: "2023-06-27",
    review: false,
  },
  {
    id: 574,
    status: "published",
    date_created: "2025-02-28T22:46:15.441Z",
    path: "product-forums/how-to-win-retain-customers-2024/watch/index.html",
    url: "https://www.mx.com/product-forums/how-to-win-retain-customers-2024/watch/",
    last_modified: "2024-06-10",
    publish_date: "2023-12-15",
    review: false,
  },
  {
    id: 578,
    status: "published",
    date_created: "2025-02-28T22:46:15.847Z",
    path: "product-forums/open-finance-financial-wellness/watch/index.html",
    url: "https://www.mx.com/product-forums/open-finance-financial-wellness/watch/",
    last_modified: "2024-06-10",
    publish_date: "2023-04-24",
    review: false,
  },
  {
    id: 605,
    status: "published",
    date_created: "2025-02-28T22:46:18.614Z",
    path: "request-demo/product-updates/index.html",
    url: "https://www.mx.com/request-demo/product-updates/",
    last_modified: "2024-06-10",
    publish_date: "2024-05-09",
    review: false,
  },
  {
    status: "published",
    content_type: "events",
    id: 28,
    url: "https://www.mx.com/events/american-banker-digital-banking-dinner/06252024",
    admin_url: "https://cms.mx.com/admin/content/events/28",
    name: "MX & Atomic Executive Exchange Dinner | June 2024",
    publish_date: "2024-06-04T21:38:37.984Z",
    last_modified: "2024-06-10T20:46:55.739Z",
    review: false,
  },
  {
    id: 566,
    status: "published",
    date_created: "2025-02-28T22:46:14.628Z",
    path: "partners/q2-innovation-studio/index.html",
    url: "https://www.mx.com/partners/q2-innovation-studio/",
    last_modified: "2024-06-11",
    publish_date: "2023-07-10",
    review: false,
  },
  {
    id: 610,
    status: "published",
    date_created: "2025-02-28T22:46:19.201Z",
    path: "riff-on-a-lift/july-2022/watch/index.html",
    url: "https://www.mx.com/riff-on-a-lift/july-2022/watch/",
    last_modified: "2024-06-11",
    publish_date: "2022-06-16",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 503,
    url: "https://www.mx.com/blog/millennial-money-management",
    admin_url: "https://cms.mx.com/admin/content/blog/503",
    name: "Millennial Money Management: Data, Stats, and Graphs",
    publish_date: "2024-06-11",
    last_modified: null,
    review: false,
  },
  {
    id: 611,
    status: "published",
    date_created: "2025-02-28T22:46:19.297Z",
    path: "riff-on-a-lift/march-2023/watch/index.html",
    url: "https://www.mx.com/riff-on-a-lift/march-2023/watch/",
    last_modified: "2024-06-14",
    publish_date: "2023-02-28",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 504,
    url: "https://www.mx.com/blog/mx-names-ryan-caldwell-chief-executive-officer",
    admin_url: "https://cms.mx.com/admin/content/blog/504",
    name: "MX Founder Ryan Caldwell Returns as Chief Executive Officer",
    publish_date: "2024-06-18",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 505,
    url: "https://www.mx.com/blog/how-gen-x-manages-money",
    admin_url: "https://cms.mx.com/admin/content/blog/505",
    name: "How Gen X Manages Money: Graphs and Stats",
    publish_date: "2024-06-21",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 80,
    url: "https://www.mx.com/webinars/evaluating-open-banking-data-partners",
    admin_url: "https://cms.mx.com/admin/content/webinars/80",
    name: "A Conversation with Moneytree and Matrix",
    publish_date: "2023-06-06",
    last_modified: "2024-06-24T17:37:03.351Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 506,
    url: "https://www.mx.com/blog/stats-on-gen-z-finances",
    admin_url: "https://cms.mx.com/admin/content/blog/506",
    name: "Understanding Gen Z Finances: A Comprehensive List of Stats and Research",
    publish_date: "2024-06-25",
    last_modified: null,
    review: false,
  },
  {
    id: 579,
    status: "published",
    date_created: "2025-02-28T22:46:15.946Z",
    path: "product-forums/unlocking-actionable-intelligence/watch/index.html",
    url: "https://www.mx.com/product-forums/unlocking-actionable-intelligence/watch/",
    last_modified: "2024-06-27",
    publish_date: "2024-03-05",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 507,
    url: "https://www.mx.com/blog/mx-celebrates-new-office-in-india",
    admin_url: "https://cms.mx.com/admin/content/blog/507",
    name: "MX Celebrates New Office in India ",
    publish_date: "2024-06-27",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 78,
    url: "https://www.mx.com/blog/biggest-us-banks-by-deposits",
    admin_url: "https://cms.mx.com/admin/content/blog/78",
    name: "Largest U.S. Banks by Total Deposits (2024)",
    publish_date: "2024-06-28",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 174,
    url: "https://www.mx.com/blog/biggest-banks-by-asset-size-united-states",
    admin_url: "https://cms.mx.com/admin/content/blog/174",
    name: "Largest U.S. Banks by Asset Size (2024)",
    publish_date: "2024-06-28",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 251,
    url: "https://www.mx.com/blog/biggest-us-credit-unions-by-asset-size",
    admin_url: "https://cms.mx.com/admin/content/blog/251",
    name: "Largest U.S. Credit Unions by Asset Size (2024)",
    publish_date: "2024-06-28",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 508,
    url: "https://www.mx.com/blog/risks-of-ai-in-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/508",
    name: "Top 5 Risks of AI in Banking",
    publish_date: "2024-07-01",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 543,
    url: "https://www.mx.com/news/mx-named-100-companies-championing-women-2024",
    admin_url: "https://cms.mx.com/admin/content/mx_news/543",
    name: "Inspire In Utah Names MX to 100 Companies Championing Women 2024 List",
    publish_date: "2024-07-05",
    last_modified: "2024-07-08T20:12:37.129Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 509,
    url: "https://www.mx.com/blog/opportunities-for-ai-in-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/509",
    name: "Understanding Opportunities for AI in Banking",
    publish_date: "2024-07-09",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 147,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/147",
    url: "https://www.mx.com/research/ai-in-banking",
    name: "Infographic: AI in Banking Risks and Rewards",
    publish_date: "2024-06-26T14:17:18.968Z",
    last_modified: "2024-07-09T18:28:27.661Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 544,
    url: "https://www.mx.com/news/worlds-top-fintech-companies-2024",
    admin_url: "https://cms.mx.com/admin/content/mx_news/544",
    name: "MX Named to CNBC's World's Top Fintech Companies List for Second Year",
    publish_date: "2024-07-10",
    last_modified: "2024-07-11T04:25:40.899Z",
    review: false,
  },
  {
    id: 575,
    status: "published",
    date_created: "2025-02-28T22:46:15.541Z",
    path: "product-forums/maximized-intelligence/index.html",
    url: "https://www.mx.com/product-forums/maximized-intelligence/",
    last_modified: "2024-07-15",
    publish_date: "2024-06-18",
    review: false,
  },
  {
    id: 577,
    status: "published",
    date_created: "2025-02-28T22:46:15.746Z",
    path: "product-forums/maximized-intelligence/watch/index.html",
    url: "https://www.mx.com/product-forums/maximized-intelligence/watch/",
    last_modified: "2024-07-15",
    publish_date: "2024-06-18",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 134,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/134",
    url: "https://www.mx.com/guides/personal-financial-management-done-right",
    name: "Personal Financial Management Done Right",
    publish_date: "2023-12-04T18:29:32.330Z",
    last_modified: "2024-07-16T16:27:30.957Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 87,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/87",
    url: "https://www.mx.com/whitepapers/understanding-journey-stages-open-finance",
    name: "Understanding the Journey Stages of Open Finance",
    publish_date: "2023-01-25T02:06:55.260Z",
    last_modified: "2024-07-16T21:02:06.833Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 510,
    url: "https://www.mx.com/blog/from-wasted-data-to-maximized-intelligence",
    admin_url: "https://cms.mx.com/admin/content/blog/510",
    name: "From Wasted Data to Maximized Intelligence: MX Product Updates",
    publish_date: "2024-07-17",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 511,
    url: "https://www.mx.com/blog/baby-boomer-financial-management-stats",
    admin_url: "https://cms.mx.com/admin/content/blog/511",
    name: "Baby Boomer Behaviors: Stats and Data on How This Generation Manages Money",
    publish_date: "2024-07-19",
    last_modified: null,
    review: false,
  },
  {
    id: 598,
    status: "published",
    date_created: "2025-02-28T22:46:17.891Z",
    path: "request-demo/index.html",
    url: "https://www.mx.com/request-demo/",
    last_modified: "2024-07-22",
    publish_date: "2023-01-13",
    review: false,
  },
  {
    id: 601,
    status: "published",
    date_created: "2025-02-28T22:46:18.201Z",
    path: "request-demo/mx-finastra-integration/index.html",
    url: "https://www.mx.com/request-demo/mx-finastra-integration/",
    last_modified: "2024-07-22",
    publish_date: "2023-11-30",
    review: false,
  },
  {
    id: 603,
    status: "published",
    date_created: "2025-02-28T22:46:18.405Z",
    path: "request-demo/mx-mobile/index.html",
    url: "https://www.mx.com/request-demo/mx-mobile/",
    last_modified: "2024-07-22",
    publish_date: "2024-01-16",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 146,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/146",
    url: "https://www.mx.com/guides/risks-rewards-ai",
    name: "The Risks and Rewards of AI in Banking ",
    publish_date: "2024-06-26T14:13:03.302Z",
    last_modified: "2024-07-25T17:07:35.592Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 142,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/142",
    url: "https://www.mx.com/guides/building-long-term-digital-engagement",
    name: "How to Build Long-Term Digital Engagement",
    publish_date: "2024-04-15T16:42:37.299Z",
    last_modified: "2024-07-26T17:40:01.572Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 145,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/145",
    url: "https://www.mx.com/research/driving-engagement-for-consumers-research",
    name: "How to Drive Engagement When Consumers Refuse to Change",
    publish_date: "2024-05-29T13:56:57.421Z",
    last_modified: "2024-08-08T14:41:40.932Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 535,
    url: "https://www.mx.com/news/trustly-integrates-mx-data-enhancement-to-deliver-better-consumer-experiences",
    admin_url: "https://cms.mx.com/admin/content/mx_news/535",
    name: "Trustly Integrates MX Data Enhancement to Deliver Better Consumer Shopping and Underwriting Experiences",
    publish_date: "2024-02-07",
    last_modified: "2024-08-08T14:57:43.742Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 532,
    url: "https://www.mx.com/news/mx-announces-customer-analytics",
    admin_url: "https://cms.mx.com/admin/content/mx_news/532",
    name: "MX Customer Analytics Delivers Actionable Insights for Financial Providers to Better Understand and Serve Customers",
    publish_date: "2024-01-23",
    last_modified: "2024-08-08T14:59:41.073Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 530,
    url: "https://www.mx.com/news/financial-technology-report-top-100-fintech-2023",
    admin_url: "https://cms.mx.com/admin/content/mx_news/530",
    name: "The Financial Technology Report Names MX in Top 100 Financial Technology Companies of 2023",
    publish_date: "2023-10-24",
    last_modified: "2024-08-08T15:13:52.433Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 539,
    url: "https://www.mx.com/news/dwolla-selects-mx-for-modern-money-movement",
    admin_url: "https://cms.mx.com/admin/content/mx_news/539",
    name: "Dwolla Selects MX to Power Account Verifications and Aggregation for Modern Money Movement",
    publish_date: "2024-03-26",
    last_modified: "2024-08-08T15:23:41.177Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 538,
    url: "https://www.mx.com/news/ahrvo-leverages-mx-to-transform-identity-verification-and-transaction-risk-management",
    admin_url: "https://cms.mx.com/admin/content/mx_news/538",
    name: "Ahrvo Labs Leverages MX to Transform Identity Verification and Transaction Risk Management",
    publish_date: "2024-03-06",
    last_modified: "2024-08-08T15:28:16.976Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 512,
    url: "https://www.mx.com/blog/account-aggregation-stats",
    admin_url: "https://cms.mx.com/admin/content/blog/512",
    name: "The Value of Connectivity: Stats on the Impact of Account Aggregation",
    publish_date: "2024-08-13",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "trust_center",
    id: 12,
    url: "https://www.mx.com/trust/protecting-data-in-the-cloud",
    admin_url: "https://cms.mx.com/admin/content/trust_center/12",
    name: "How MX Protects Your Data in the Cloud",
    publish_date: "2023-10-04",
    last_modified: "2024-08-14T15:46:51.462Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 513,
    url: "https://www.mx.com/blog/connectivity-matters",
    admin_url: "https://cms.mx.com/admin/content/blog/513",
    name: "Winning the Connectivity Game: Why Connectivity Matters",
    publish_date: "2024-08-16",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 514,
    url: "https://www.mx.com/blog/pay-by-bank-stats",
    admin_url: "https://cms.mx.com/admin/content/blog/514",
    name: "Preferred Payment Methods and Consumer Use of Pay by Bank: Data, Stats, Graphs",
    publish_date: "2024-08-20",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 93,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/93",
    url: "https://www.mx.com/whitepapers/55-years-technology-banking",
    name: "55 Years of Technology in Banking",
    publish_date: "2023-01-25T02:06:55.476Z",
    last_modified: "2024-08-20T20:00:32.731Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 149,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/149",
    url: "https://www.mx.com/whitepapers/secondary-use-cases",
    name: "Why Enabling Secondary Use Cases for Consumer Benefit is Critical ",
    publish_date: "2024-08-20T21:54:05.109Z",
    last_modified: "2024-08-20T21:55:13.153Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 515,
    url: "https://www.mx.com/blog/why-secondary-use-cases-matter",
    admin_url: "https://cms.mx.com/admin/content/blog/515",
    name: "Why Secondary Use Cases Matter for Consumer Financial Health",
    publish_date: "2024-08-27",
    last_modified: null,
    review: false,
  },
  {
    id: 474,
    status: "published",
    date_created: "2025-02-28T22:46:04.788Z",
    path: "analysts/index.html",
    url: "https://www.mx.com/analysts/",
    last_modified: "2024-08-28",
    publish_date: "2022-05-06",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 9,
    url: "https://www.mx.com/news/bank-innovation-data-aggregators-push-to-meet-banks-pfm-needs",
    admin_url: "https://cms.mx.com/admin/content/mx_news/9",
    name: "Data Aggregators Push to Meet Banks' PFM Needs",
    publish_date: "2020-08-13",
    last_modified: "2024-08-28T15:36:19.480Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 534,
    url: "https://www.mx.com/news/jack-henry-and-mx-enable-secure-data-sharing",
    admin_url: "https://cms.mx.com/admin/content/mx_news/534",
    name: "MX and Jack Henry to Enable a More Secure Data Sharing Experience",
    publish_date: "2024-02-01",
    last_modified: "2024-08-28T15:45:43.042Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 118,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/118",
    url: "https://www.mx.com/research/genz-millennial-banking-perspectives",
    name: "Modern Banking Experiences for Gen Z and Millennials",
    publish_date: "2023-01-25T02:21:19.737Z",
    last_modified: "2024-08-28T16:15:26.116Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 105,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/105",
    url: "https://www.mx.com/guides/bank-apis",
    name: "How Bank APIs Work",
    publish_date: "2023-01-25T02:12:01.135Z",
    last_modified: "2024-08-28T17:46:02.294Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 131,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/131",
    url: "https://www.mx.com/guides/2024-digital-banking-playbook",
    name: "Your 2024 Digital Banking Playbook: How to Improve Consumer Engagement, Increase Deposits, and Drive Meaningful Outcomes",
    publish_date: "2023-10-16T14:02:39.860Z",
    last_modified: "2024-08-28T20:15:16.978Z",
    review: false,
  },
  {
    id: 485,
    status: "published",
    date_created: "2025-02-28T22:46:05.996Z",
    path: "case-studies/apiture/index.html",
    url: "https://www.mx.com/case-studies/apiture/",
    last_modified: "2024-08-29",
    publish_date: "2023-11-28",
    review: false,
  },
  {
    id: 552,
    status: "published",
    date_created: "2025-02-28T22:46:12.923Z",
    path: "marketing-toolkit/pfm/index.html",
    url: "https://www.mx.com/marketing-toolkit/pfm/",
    last_modified: "2024-09-03",
    publish_date: "2024-05-16",
    review: false,
  },
  {
    id: 562,
    status: "published",
    date_created: "2025-02-28T22:46:14.225Z",
    path: "open-finance-assessment/index.html",
    url: "https://www.mx.com/open-finance-assessment/",
    last_modified: "2024-09-05",
    publish_date: "2024-05-24",
    review: false,
  },
  {
    id: 563,
    status: "published",
    date_created: "2025-02-28T22:46:14.326Z",
    path: "open-finance-assessment/results/index.html",
    url: "https://www.mx.com/open-finance-assessment/results/",
    last_modified: "2024-09-05",
    publish_date: "2022-08-19",
    review: false,
  },
  {
    status: "published",
    content_type: "events",
    id: 32,
    url: "https://www.mx.com/events/finovate-fall-dinner/09102024",
    admin_url: "https://cms.mx.com/admin/content/events/32",
    name: "MX Dinner and Networking at Finovate Fall 2024",
    publish_date: "2024-08-28T13:56:39.966Z",
    last_modified: "2024-09-09T17:09:42.589Z",
    review: false,
  },
  {
    id: 551,
    status: "published",
    date_created: "2025-02-28T22:46:12.821Z",
    path: "marketing-toolkit/insights/index.html",
    url: "https://www.mx.com/marketing-toolkit/insights/",
    last_modified: "2024-09-12",
    publish_date: "2024-09-03",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 516,
    url: "https://www.mx.com/blog/how-consumers-spend-on-summer-fun",
    admin_url: "https://cms.mx.com/admin/content/blog/516",
    name: "The Dollar Days of Summer: How Consumers Spend on Summer Fun",
    publish_date: "2024-09-16",
    last_modified: null,
    review: false,
  },
  {
    id: 494,
    status: "published",
    date_created: "2025-02-28T22:46:06.908Z",
    path: "case-studies/emprise-bank/index.html",
    url: "https://www.mx.com/case-studies/emprise-bank/",
    last_modified: "2024-09-17",
    publish_date: "2023-10-27",
    review: false,
  },
  {
    id: 532,
    status: "published",
    date_created: "2025-02-28T22:46:10.915Z",
    path: "case-studies/voya-financial/index.html",
    url: "https://www.mx.com/case-studies/voya-financial/",
    last_modified: "2024-09-17",
    publish_date: "2023-11-19",
    review: false,
  },
  {
    id: 584,
    status: "published",
    date_created: "2025-02-28T22:46:16.466Z",
    path: "products/customer-analytics/index.html",
    url: "https://www.mx.com/products/customer-analytics/",
    last_modified: "2024-09-17",
    publish_date: "2023-09-07",
    review: false,
  },
  {
    id: 587,
    status: "published",
    date_created: "2025-02-28T22:46:16.771Z",
    path: "products/data-enhancement/index.html",
    url: "https://www.mx.com/products/data-enhancement/",
    last_modified: "2024-09-17",
    publish_date: "2023-05-08",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 518,
    url: "https://www.mx.com/blog/fixing-the-connectivity-gap",
    admin_url: "https://cms.mx.com/admin/content/blog/518",
    name: "Are Consumers Missing a Step? Fixing the Connectivity Gap ",
    publish_date: "2024-09-18",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 519,
    url: "https://www.mx.com/blog/data-value-creation",
    admin_url: "https://cms.mx.com/admin/content/blog/519",
    name: "From Value Extraction to Value Creation: The Future of the Financial Data Economy",
    publish_date: "2024-09-19",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 520,
    url: "https://www.mx.com/blog/good-mobile-banking-matters",
    admin_url: "https://cms.mx.com/admin/content/blog/520",
    name: "Mobile Matters: Consumers Expect a Good Mobile Banking Experience ",
    publish_date: "2024-09-24",
    last_modified: null,
    review: false,
  },
  {
    id: 600,
    status: "published",
    date_created: "2025-02-28T22:46:18.094Z",
    path: "request-demo/mx-cgi-integration/thank-you/index.html",
    url: "https://www.mx.com/request-demo/mx-cgi-integration/thank-you/",
    last_modified: "2024-09-25",
    publish_date: "2024-09-25",
    review: false,
  },
  {
    id: 602,
    status: "published",
    date_created: "2025-02-28T22:46:18.302Z",
    path: "request-demo/mx-finastra-integration/thank-you/index.html",
    url: "https://www.mx.com/request-demo/mx-finastra-integration/thank-you/",
    last_modified: "2024-09-25",
    publish_date: "2023-11-30",
    review: false,
  },
  {
    status: "published",
    content_type: "events",
    id: 34,
    url: "https://www.mx.com/events/cu-tech-forum-dinner/09302024",
    admin_url: "https://cms.mx.com/admin/content/events/34",
    name: "MX Networking Dinner: Credit Union Tech Forum",
    publish_date: "2024-09-18T22:21:11.951Z",
    last_modified: "2024-09-25T00:01:22.594Z",
    review: false,
  },
  {
    id: 599,
    status: "published",
    date_created: "2025-02-28T22:46:17.991Z",
    path: "request-demo/mx-cgi-integration/index.html",
    url: "https://www.mx.com/request-demo/mx-cgi-integration/",
    last_modified: "2024-09-26",
    publish_date: "2024-09-25",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 521,
    url: "https://www.mx.com/blog/shifting-trust-in-ai",
    admin_url: "https://cms.mx.com/admin/content/blog/521",
    name: "Are Consumers Ready to Trust AI?",
    publish_date: "2024-09-27",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 522,
    url: "https://www.mx.com/blog/global-financial-inclusion-and-health",
    admin_url: "https://cms.mx.com/admin/content/blog/522",
    name: "Turning Inclusion into Financial Health",
    publish_date: "2024-10-01",
    last_modified: null,
    review: false,
  },
  {
    id: 479,
    status: "published",
    date_created: "2025-02-28T22:46:05.366Z",
    path: "brand-trademark-policy/index.html",
    url: "https://www.mx.com/brand-trademark-policy/",
    last_modified: "2024-10-03",
    publish_date: "2024-03-27",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 138,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/138",
    url: "https://www.mx.com/whitepapers/mx-perspective-on-consumer-data-rights-rulemaking",
    name: "MX Comments on the CFPB’s Required Rulemaking on Personal Financial Data Rights",
    publish_date: "2024-02-21T21:11:19.770Z",
    last_modified: "2024-10-04T14:12:01.757Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 144,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/144",
    url: "https://www.mx.com/guides/where-financial-providers-may-fall-short-on-1033-reqs",
    name: "Getting Ready for Section 1033 Obligations: Where Financial Providers May Fall Short",
    publish_date: "2024-04-26T14:48:26.357Z",
    last_modified: "2024-10-04T15:34:27.641Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 143,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/143",
    url: "https://www.mx.com/whitepapers/how-data-access-supports-1033",
    name: "How MX’s Data Access Can Help Financial Providers Get Ready for Section 1033",
    publish_date: "2024-04-26T14:10:43.857Z",
    last_modified: "2024-10-04T15:51:45.139Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 523,
    url: "https://www.mx.com/blog/road-to-1033",
    admin_url: "https://cms.mx.com/admin/content/blog/523",
    name: "Coming Soon: Section 1033 Obligations and Opportunities",
    publish_date: "2024-10-07",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 548,
    url: "https://www.mx.com/news/mx-announces-investment-data-solution",
    admin_url: "https://cms.mx.com/admin/content/mx_news/548",
    name: "New MX Investment Data Enhancement Solution Improves Data Accuracy, Coverage, and Scalability for Investment Management",
    publish_date: "2024-10-08",
    last_modified: "2024-10-08T15:39:31.113Z",
    review: false,
  },
  {
    id: 572,
    status: "published",
    date_created: "2025-02-28T22:46:15.240Z",
    path: "product-forums/gain-trust-grow-deposits-improve-processes/thank-you/index.html",
    url: "https://www.mx.com/product-forums/gain-trust-grow-deposits-improve-processes/thank-you/",
    last_modified: "2024-10-11",
    publish_date: "2024-10-11",
    review: false,
  },
  {
    id: 558,
    status: "published",
    date_created: "2025-02-28T22:46:13.653Z",
    path: "mxs/index.html",
    url: "https://www.mx.com/mxs/",
    last_modified: "2024-10-14",
    publish_date: "2023-08-18",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 547,
    url: "https://www.mx.com/news/mx-launches-deposit-switch-integrations",
    admin_url: "https://cms.mx.com/admin/content/mx_news/547",
    name: "MX Launches New Integrations to Streamline Deposit and Bill Pay Switching",
    publish_date: "2024-10-08",
    last_modified: "2024-10-18T18:43:11.031Z",
    review: false,
  },
  {
    id: 560,
    status: "published",
    date_created: "2025-02-28T22:46:13.970Z",
    path: "open-banking-made-easier/thank-you/index.html",
    url: "https://www.mx.com/open-banking-made-easier/thank-you/",
    last_modified: "2024-10-21",
    publish_date: "2024-04-26",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 524,
    url: "https://www.mx.com/blog/caution-in-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/524",
    name: "Balancing Caution Across Today’s Banking Trends",
    publish_date: "2024-10-21",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 88,
    url: "https://www.mx.com/summit/2024/",
    admin_url: "https://cms.mx.com/admin/content/webinars/88",
    name: "MXS Rewind 2024 | Video On-Demand",
    publish_date: "2024-10-21",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 465,
    url: "https://www.mx.com/blog/cfpb-dodd-frank-section-1033",
    admin_url: "https://cms.mx.com/admin/content/blog/465",
    name: "Understanding CFPB Section 1033 — Establishing Consumer Financial Data Rights in the United States",
    publish_date: "2024-10-22",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 525,
    url: "https://www.mx.com/blog/final-1033-rule-quick-takes",
    admin_url: "https://cms.mx.com/admin/content/blog/525",
    name: "Section 1033 Final Rule: Quick Takes on Key Changes — Deadlines, Scope, and Secondary Use",
    publish_date: "2024-10-23",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 550,
    url: "https://www.mx.com/news/ebankit-selects-mx-as-open-banking-partner",
    admin_url: "https://cms.mx.com/admin/content/mx_news/550",
    name: "ebankIT Selects MX as Open Banking Partner to Provide Secure Data Access to Financial Institutions in North America",
    publish_date: "2024-10-23",
    last_modified: "2024-10-23T11:51:33.078Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 526,
    url: "https://www.mx.com/blog/new-product-innovations",
    admin_url: "https://cms.mx.com/admin/content/blog/526",
    name: "All About the Data: New Product Innovations Unlock New Insights and Capabilities",
    publish_date: "2024-10-24",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 527,
    url: "https://www.mx.com/blog/utah-becoming-a-fintech-hub",
    admin_url: "https://cms.mx.com/admin/content/blog/527",
    name: "Why Utah is the Place to Be for Fintechs",
    publish_date: "2024-10-29",
    last_modified: null,
    review: false,
  },
  {
    id: 523,
    status: "published",
    date_created: "2025-02-28T22:46:09.912Z",
    path: "case-studies/suma-partners-with-hello-iconic-and-mx/pdf/index.html",
    url: "https://www.mx.com/case-studies/suma-partners-with-hello-iconic-and-mx/pdf/",
    last_modified: "2024-10-31",
    publish_date: "2022-12-19",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 528,
    url: "https://www.mx.com/blog/mxs-ceo-keynote",
    admin_url: "https://cms.mx.com/admin/content/blog/528",
    name: "The Future Is Bright: MX CEO Takeaways from the 2024 Money Experience Summit ",
    publish_date: "2024-11-01",
    last_modified: null,
    review: false,
  },
  {
    id: 549,
    status: "published",
    date_created: "2025-02-28T22:46:12.621Z",
    path: "internal/site-list/index.html",
    url: "https://www.mx.com/internal/site-list/",
    last_modified: "2024-11-04",
    publish_date: "2024-11-04",
    review: false,
  },
  {
    id: 567,
    status: "published",
    date_created: "2025-02-28T22:46:14.737Z",
    path: "privacy/index.html",
    url: "https://www.mx.com/privacy/",
    last_modified: "2024-11-04",
    publish_date: "2024-04-04",
    review: false,
  },
  {
    id: 571,
    status: "published",
    date_created: "2025-02-28T22:46:15.136Z",
    path: "product-forums/gain-trust-grow-deposits-improve-processes/index.html",
    url: "https://www.mx.com/product-forums/gain-trust-grow-deposits-improve-processes/",
    last_modified: "2024-11-04",
    publish_date: "2024-10-11",
    review: false,
  },
  {
    id: 573,
    status: "published",
    date_created: "2025-02-28T22:46:15.340Z",
    path: "product-forums/gain-trust-grow-deposits-improve-processes/watch/index.html",
    url: "https://www.mx.com/product-forums/gain-trust-grow-deposits-improve-processes/watch/",
    last_modified: "2024-11-04",
    publish_date: "2024-10-11",
    review: false,
  },
  {
    id: 538,
    status: "published",
    date_created: "2025-02-28T22:46:11.506Z",
    path: "cookie-policy/index.html",
    url: "https://www.mx.com/cookie-policy/",
    last_modified: "2024-11-05",
    publish_date: "2024-04-04",
    review: false,
  },
  {
    id: 541,
    status: "published",
    date_created: "2025-02-28T22:46:11.804Z",
    path: "dewitt/index.html",
    url: "https://www.mx.com/dewitt/",
    last_modified: "2024-11-05",
    publish_date: "2022-08-11",
    review: false,
  },
  {
    id: 556,
    status: "published",
    date_created: "2025-02-28T22:46:13.327Z",
    path: "money-experience/index.html",
    url: "https://www.mx.com/money-experience/",
    last_modified: "2024-11-05",
    publish_date: "2022-12-20",
    review: false,
  },
  {
    id: 561,
    status: "published",
    date_created: "2025-02-28T22:46:14.083Z",
    path: "open-finance/index.html",
    url: "https://www.mx.com/open-finance/",
    last_modified: "2024-11-05",
    publish_date: "2022-08-09",
    review: false,
  },
  {
    id: 583,
    status: "published",
    date_created: "2025-02-28T22:46:16.362Z",
    path: "products/connectivity/index.html",
    url: "https://www.mx.com/products/connectivity/",
    last_modified: "2024-11-05",
    publish_date: "2022-06-29",
    review: false,
  },
  {
    id: 585,
    status: "published",
    date_created: "2025-02-28T22:46:16.565Z",
    path: "products/data/index.html",
    url: "https://www.mx.com/products/data/",
    last_modified: "2024-11-05",
    publish_date: "2022-06-29",
    review: false,
  },
  {
    id: 588,
    status: "published",
    date_created: "2025-02-28T22:46:16.871Z",
    path: "products/experience/index.html",
    url: "https://www.mx.com/products/experience/",
    last_modified: "2024-11-05",
    publish_date: "2022-06-29",
    review: false,
  },
  {
    id: 622,
    status: "published",
    date_created: "2025-02-28T22:46:20.406Z",
    path: "use-cases/financial-wellness/index.html",
    url: "https://www.mx.com/use-cases/financial-wellness/",
    last_modified: "2024-11-05",
    publish_date: "2023-07-28",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 529,
    url: "https://www.mx.com/blog/ai-in-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/529",
    name: "Driving Value from AI in Banking",
    publish_date: "2024-11-06",
    last_modified: null,
    review: false,
  },
  {
    id: 546,
    status: "published",
    date_created: "2025-02-28T22:46:12.310Z",
    path: "helios-5/index.html",
    url: "https://www.mx.com/helios-5/",
    last_modified: "2024-11-07",
    publish_date: "2024-11-07",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 530,
    url: "https://www.mx.com/blog/key-insights-from-money2020",
    admin_url: "https://cms.mx.com/admin/content/blog/530",
    name: "Changes Made for Changing Times: Key Insights from Money20/20 USA 2024",
    publish_date: "2024-11-07",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 532,
    url: "https://www.mx.com/blog/1033-is-here-whats-next",
    admin_url: "https://cms.mx.com/admin/content/blog/532",
    name: "1033 is Here: Where Do We Go Next? ",
    publish_date: "2024-11-08",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 531,
    url: "https://www.mx.com/blog/mx-welcomes-maisie-bilotti",
    admin_url: "https://cms.mx.com/admin/content/blog/531",
    name: "MX Bolsters Advocacy Efforts; Welcomes Maisie Bilotti",
    publish_date: "2024-11-13",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 148,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/148",
    url: "https://www.mx.com/guides/why-connectivity-is-critical",
    name: "Why Connectivity is Critical ",
    publish_date: "2024-08-12T18:48:07.768Z",
    last_modified: "2024-11-14T17:40:04.079Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 545,
    url: "https://www.mx.com/news/idc-fintech-rankings-2024",
    admin_url: "https://cms.mx.com/admin/content/mx_news/545",
    name: "MX Recognized in IDC Fintech Rankings Top 100",
    publish_date: "2024-09-09",
    last_modified: "2024-11-14T20:32:18.963Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 533,
    url: "https://www.mx.com/blog/regulation-and-innovation",
    admin_url: "https://cms.mx.com/admin/content/blog/533",
    name: "Balancing Regulation and Innovation",
    publish_date: "2024-11-15",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 552,
    url: "https://www.mx.com/news/top-100-financial-technology-companies-2024",
    admin_url: "https://cms.mx.com/admin/content/mx_news/552",
    name: "MX Named in Top 100 Financial Technology Companies for Fourth Year",
    publish_date: "2024-11-20",
    last_modified: "2024-11-20T17:32:32.844Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 534,
    url: "https://www.mx.com/blog/meet-the-expanded-mx-executive-team",
    admin_url: "https://cms.mx.com/admin/content/blog/534",
    name: "Meet the Expanded MX Executive Team",
    publish_date: "2024-11-21",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 535,
    url: "https://www.mx.com/blog/financial-health-and-inclusion",
    admin_url: "https://cms.mx.com/admin/content/blog/535",
    name: "Why Financial Health and Inclusion Matters: Lowering the Price of Being Poor",
    publish_date: "2024-11-26",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 554,
    url: "https://www.mx.com/news/fintech-magazine-top-100-companies-fintech-2024",
    admin_url: "https://cms.mx.com/admin/content/mx_news/554",
    name: "FinTech Magazine Names MX in Top 100 Companies in FinTech",
    publish_date: "2024-11-21",
    last_modified: "2024-11-26T22:23:28.564Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 553,
    url: "https://www.mx.com/news/inspiring-fintech-females-2024",
    admin_url: "https://cms.mx.com/admin/content/mx_news/553",
    name: "NYC Fintech Women Names MX's Nicky Klein in 2024 Inspiring Fintech Females",
    publish_date: "2024-12-02",
    last_modified: "2024-12-02T16:58:34.795Z",
    review: false,
  },
  {
    id: 619,
    status: "published",
    date_created: "2025-02-28T22:46:20.107Z",
    path: "use-cases/account-opening/index.html",
    url: "https://www.mx.com/use-cases/account-opening/",
    last_modified: "2024-12-03",
    publish_date: "2023-08-09",
    review: false,
  },
  {
    id: 620,
    status: "published",
    date_created: "2025-02-28T22:46:20.208Z",
    path: "use-cases/customer-intelligence/index.html",
    url: "https://www.mx.com/use-cases/customer-intelligence/",
    last_modified: "2024-12-03",
    publish_date: "2023-11-13",
    review: false,
  },
  {
    id: 621,
    status: "published",
    date_created: "2025-02-28T22:46:20.307Z",
    path: "use-cases/digital-banking/index.html",
    url: "https://www.mx.com/use-cases/digital-banking/",
    last_modified: "2024-12-03",
    publish_date: "2023-07-31",
    review: false,
  },
  {
    id: 623,
    status: "published",
    date_created: "2025-02-28T22:46:20.505Z",
    path: "use-cases/fraud-risk-management/index.html",
    url: "https://www.mx.com/use-cases/fraud-risk-management/",
    last_modified: "2024-12-03",
    publish_date: "2023-08-09",
    review: false,
  },
  {
    id: 624,
    status: "published",
    date_created: "2025-02-28T22:46:20.611Z",
    path: "use-cases/loan-origination-processing/index.html",
    url: "https://www.mx.com/use-cases/loan-origination-processing/",
    last_modified: "2024-12-03",
    publish_date: "2023-07-31",
    review: false,
  },
  {
    id: 626,
    status: "published",
    date_created: "2025-02-28T22:46:20.813Z",
    path: "use-cases/payment-verifications/index.html",
    url: "https://www.mx.com/use-cases/payment-verifications/",
    last_modified: "2024-12-03",
    publish_date: "2023-07-31",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 536,
    url: "https://www.mx.com/blog/finances-fears-feelings",
    admin_url: "https://cms.mx.com/admin/content/blog/536",
    name: "Finances, Fears, and Feelings",
    publish_date: "2024-12-03",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "trust_center",
    id: 8,
    url: "https://www.mx.com/trust/mx-pci-certificate",
    admin_url: "https://cms.mx.com/admin/content/trust_center/8",
    name: "MX Technologies, Inc. PCI Certificate",
    publish_date: "2024-12-03",
    last_modified: "2024-12-03T22:57:34.193Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 73,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/73",
    url: "https://www.mx.com/whitepapers/security-how-mx-protects-your-data",
    name: "How MX Protects Your Data",
    publish_date: "2023-01-25T02:06:54.826Z",
    last_modified: "2024-12-03T23:00:13.020Z",
    review: false,
  },
  {
    id: 581,
    status: "published",
    date_created: "2025-02-28T22:46:16.153Z",
    path: "products/account-aggregation/index.html",
    url: "https://www.mx.com/products/account-aggregation/",
    last_modified: "2024-12-04",
    publish_date: "2022-07-20",
    review: false,
  },
  {
    id: 582,
    status: "published",
    date_created: "2025-02-28T22:46:16.264Z",
    path: "products/balance-checks/index.html",
    url: "https://www.mx.com/products/balance-checks/",
    last_modified: "2024-12-04",
    publish_date: "2022-11-30",
    review: false,
  },
  {
    id: 589,
    status: "published",
    date_created: "2025-02-28T22:46:16.971Z",
    path: "products/financial-insights/index.html",
    url: "https://www.mx.com/products/financial-insights/",
    last_modified: "2024-12-04",
    publish_date: "2022-07-20",
    review: false,
  },
  {
    id: 590,
    status: "published",
    date_created: "2025-02-28T22:46:17.080Z",
    path: "products/instant-account-verifications/index.html",
    url: "https://www.mx.com/products/instant-account-verifications/",
    last_modified: "2024-12-04",
    publish_date: "2022-07-20",
    review: false,
  },
  {
    id: 591,
    status: "published",
    date_created: "2025-02-28T22:46:17.186Z",
    path: "products/investment-data/index.html",
    url: "https://www.mx.com/products/investment-data/",
    last_modified: "2024-12-04",
    publish_date: "2024-10-04",
    review: false,
  },
  {
    id: 592,
    status: "published",
    date_created: "2025-02-28T22:46:17.294Z",
    path: "products/mobile-banking/index.html",
    url: "https://www.mx.com/products/mobile-banking/",
    last_modified: "2024-12-04",
    publish_date: "2022-07-20",
    review: false,
  },
  {
    id: 593,
    status: "published",
    date_created: "2025-02-28T22:46:17.395Z",
    path: "products/personal-financial-management/index.html",
    url: "https://www.mx.com/products/personal-financial-management/",
    last_modified: "2024-12-04",
    publish_date: "2023-05-08",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 537,
    url: "https://www.mx.com/blog/growing-engagement-and-loyalty",
    admin_url: "https://cms.mx.com/admin/content/blog/537",
    name: "Customer-Centric, Personalized, Data-Driven: A Recipe for Engagement and Loyalty",
    publish_date: "2024-12-11",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 538,
    url: "https://www.mx.com/blog/digital-transformation-challenges",
    admin_url: "https://cms.mx.com/admin/content/blog/538",
    name: "Overcoming the Challenges of Digital Transformation",
    publish_date: "2024-12-13",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 539,
    url: "https://www.mx.com/blog/what-should-the-us-banking-system-look-like",
    admin_url: "https://cms.mx.com/admin/content/blog/539",
    name: "The Ghost of Banking Future: What Lies Ahead for the U.S.? ",
    publish_date: "2024-12-17",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 540,
    url: "https://www.mx.com/blog/how-data-providers-can-compete-and-comply",
    admin_url: "https://cms.mx.com/admin/content/blog/540",
    name: "How Data Providers Can Compete and Comply: Achieving 1033 Compliance and Gaining a Competitive Advantage",
    publish_date: "2024-12-18",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 152,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/152",
    url: "https://www.mx.com/guides/1033-data-provider-quick-guide",
    name: "1033 Is Here: Quick Guide for Data Providers",
    publish_date: "2024-12-18T15:02:53.722Z",
    last_modified: "2024-12-18T15:16:20.848Z",
    review: false,
  },
  {
    status: "published",
    content_type: "trust_center",
    id: 9,
    url: "https://www.mx.com/trust/mx-type-2-soc-3-report",
    admin_url: "https://cms.mx.com/admin/content/trust_center/9",
    name: "SOC 3, Type 2 Report for MX Technologies, Inc.",
    publish_date: "2024-12-18",
    last_modified: "2024-12-18T17:29:39.725Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 541,
    url: "https://www.mx.com/blog/canada-open-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/541",
    name: "Canada’s Open Banking Framework Takes Shape",
    publish_date: "2024-12-19",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 542,
    url: "https://www.mx.com/blog/data-lessons",
    admin_url: "https://cms.mx.com/admin/content/blog/542",
    name: "Betting on Data: Lessons From a Blackjack Master",
    publish_date: "2024-12-20",
    last_modified: null,
    review: false,
  },
  {
    id: 480,
    status: "published",
    date_created: "2025-02-28T22:46:05.470Z",
    path: "careers/index.html",
    url: "https://www.mx.com/careers/",
    last_modified: "2025-01-02",
    publish_date: "2023-07-05",
    review: false,
  },
  {
    id: 559,
    status: "published",
    date_created: "2025-02-28T22:46:13.855Z",
    path: "open-banking-made-easier/index.html",
    url: "https://www.mx.com/open-banking-made-easier/",
    last_modified: "2025-01-02",
    publish_date: "2024-04-26",
    review: false,
  },
  {
    id: 564,
    status: "published",
    date_created: "2025-02-28T22:46:14.426Z",
    path: "partners/directory/index.html",
    url: "https://www.mx.com/partners/directory/",
    last_modified: "2025-01-02",
    publish_date: "2022-11-15",
    review: false,
  },
  {
    id: 565,
    status: "published",
    date_created: "2025-02-28T22:46:14.525Z",
    path: "partners/index.html",
    url: "https://www.mx.com/partners/",
    last_modified: "2025-01-02",
    publish_date: "2022-04-26",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 543,
    url: "https://www.mx.com/blog/new-year-2025",
    admin_url: "https://cms.mx.com/admin/content/blog/543",
    name: "3 Cheers for the New Year: Looking Back on a Year of Data-Driven Success",
    publish_date: "2025-01-02",
    last_modified: null,
    review: false,
  },
  {
    id: 478,
    status: "published",
    date_created: "2025-02-28T22:46:05.264Z",
    path: "auth/index.html",
    url: "https://www.mx.com/auth/",
    last_modified: "2025-01-09",
    publish_date: "2024-11-08",
    review: false,
  },
  {
    id: 627,
    status: "published",
    date_created: "2025-02-28T22:46:20.910Z",
    path: "vulnerability-disclosure-program/index.html",
    url: "https://www.mx.com/vulnerability-disclosure-program/",
    last_modified: "2025-01-09",
    publish_date: "2025-01-09",
    review: false,
  },
  {
    id: 553,
    status: "published",
    date_created: "2025-02-28T22:46:13.022Z",
    path: "media-kit/index.html",
    url: "https://www.mx.com/media-kit/",
    last_modified: "2025-01-13",
    publish_date: "2022-12-02",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 545,
    url: "https://www.mx.com/blog/financial-wellness-is-a-win-win",
    admin_url: "https://cms.mx.com/admin/content/blog/545",
    name: "Financial Wellness is a Win-Win for Financial Providers and Consumers",
    publish_date: "2025-01-17",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "events",
    id: 36,
    url: "https://www.mx.com/events/bulls-pistons/02112025",
    admin_url: "https://cms.mx.com/admin/content/events/36",
    name: null,
    publish_date: "2025-01-21T11:57:23.783Z",
    last_modified: "2025-01-23T15:49:32.281Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 154,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/154",
    url: "https://www.mx.com/whitepapers/2025-predictions",
    name: "2025 Financial Services Trends to Watch",
    publish_date: "2025-01-23T15:36:27.757Z",
    last_modified: "2025-01-23T16:08:42.998Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 546,
    url: "https://www.mx.com/blog/competition-open-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/546",
    name: "Gaining a Competitive Advantage with Open Banking",
    publish_date: "2025-01-27",
    last_modified: null,
    review: false,
  },
  {
    id: 547,
    status: "published",
    date_created: "2025-02-28T22:46:12.407Z",
    path: "index.html",
    url: "https://www.mx.com/",
    last_modified: "2025-01-29",
    publish_date: "2022-02-23",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 83,
    url: "https://www.mx.com/webinars/road-to-1033",
    admin_url: "https://cms.mx.com/admin/content/webinars/83",
    name: "On-Demand Replay: The Road to 1033",
    publish_date: "2024-10-02",
    last_modified: "2025-01-29T16:04:54.536Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 86,
    url: "https://www.mx.com/webinars/1033-is-here",
    admin_url: "https://cms.mx.com/admin/content/webinars/86",
    name: "CFPB’s 1033 is Here | What It Means for You",
    publish_date: "2024-11-06",
    last_modified: "2025-01-29T16:07:12.646Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 85,
    url: "https://www.mx.com/product-forums/gain-trust-grow-deposits-improve-processes/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/85",
    name: "MX Product Forum | Sharpening Focus, Enhancing Value",
    publish_date: "2024-10-22",
    last_modified: "2025-01-29T16:09:12.378Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 82,
    url: "https://www.mx.com/product-forums/maximized-intelligence/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/82",
    name: "Move From Wasted Data to Maximized Intelligence | Product Forum",
    publish_date: "2024-07-15",
    last_modified: "2025-01-29T16:09:53.004Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 150,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/150",
    url: "https://www.mx.com/research/the-missing-link",
    name: "The Missing Link: Where Banks May Be Failing Consumers ",
    publish_date: "2024-09-10T23:46:30.018Z",
    last_modified: "2025-01-29T21:01:00.333Z",
    review: false,
  },
  {
    id: 535,
    status: "published",
    date_created: "2025-02-28T22:46:11.211Z",
    path: "company/index.html",
    url: "https://www.mx.com/company/",
    last_modified: "2025-01-30",
    publish_date: "2022-12-14",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 548,
    url: "https://www.mx.com/blog/consumers-entering-era-of-financial-app-consolidation-",
    admin_url: "https://cms.mx.com/admin/content/blog/548",
    name: "A Call to Consolidate: Consumers May Simplify Their Mobile Finances Apps",
    publish_date: "2025-01-30",
    last_modified: null,
    review: false,
  },
  {
    id: 537,
    status: "published",
    date_created: "2025-02-28T22:46:11.407Z",
    path: "contact-us/thank-you/index.html",
    url: "https://www.mx.com/contact-us/thank-you/",
    last_modified: "2025-02-04",
    publish_date: "2022-12-23",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 549,
    url: "https://www.mx.com/blog/fraud-on-the-rise-and-how-to-fight-it",
    admin_url: "https://cms.mx.com/admin/content/blog/549",
    name: "Fraud (and Fighting It) is the Future",
    publish_date: "2025-02-04",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 157,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/157",
    url: "https://www.mx.com/research/financial-apps-fall-short",
    name: "Are Financial Apps Falling Short? Why Consumers Want More from Financial Providers",
    publish_date: "2025-02-05T00:12:14.708Z",
    last_modified: "2025-02-05T00:13:19.247Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 550,
    url: "https://www.mx.com/blog/delivering-value-from-artificial-intelligence",
    admin_url: "https://cms.mx.com/admin/content/blog/550",
    name: "Artificial Intelligence: How to Move from Science Project to Value Add for Banks ",
    publish_date: "2025-02-07",
    last_modified: null,
    review: false,
  },
  {
    id: 545,
    status: "published",
    date_created: "2025-02-28T22:46:12.210Z",
    path: "grow-deposits/index.html",
    url: "https://www.mx.com/grow-deposits/",
    last_modified: "2025-02-10",
    publish_date: "2025-02-10",
    review: false,
  },
  {
    id: 586,
    status: "published",
    date_created: "2025-02-28T22:46:16.670Z",
    path: "products/data-access/index.html",
    url: "https://www.mx.com/products/data-access/",
    last_modified: "2025-02-10",
    publish_date: "2022-07-20",
    review: false,
  },
  {
    id: 596,
    status: "published",
    date_created: "2025-02-28T22:46:17.695Z",
    path: "request-demo/direct-deposits/index.html",
    url: "https://www.mx.com/request-demo/direct-deposits/",
    last_modified: "2025-02-10",
    publish_date: "2025-02-10",
    review: false,
  },
  {
    id: 597,
    status: "published",
    date_created: "2025-02-28T22:46:17.792Z",
    path: "request-demo/direct-deposits/thank-you/index.html",
    url: "https://www.mx.com/request-demo/direct-deposits/thank-you/",
    last_modified: "2025-02-10",
    publish_date: "2025-02-10",
    review: false,
  },
  {
    id: 625,
    status: "published",
    date_created: "2025-02-28T22:46:20.710Z",
    path: "use-cases/open-finance/index.html",
    url: "https://www.mx.com/use-cases/open-finance/",
    last_modified: "2025-02-10",
    publish_date: "2023-08-09",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 552,
    url: "https://www.mx.com/blog/what-cfpb-turbulence-means-for-open-banking",
    admin_url: "https://cms.mx.com/admin/content/blog/552",
    name: "The Promise of Open Banking Persists in the Face of Turbulence at CFPB",
    publish_date: "2025-02-10",
    last_modified: null,
    review: false,
  },
  {
    id: 536,
    status: "published",
    date_created: "2025-02-28T22:46:11.311Z",
    path: "contact-us/index.html",
    url: "https://www.mx.com/contact-us/",
    last_modified: "2025-02-12",
    publish_date: "2022-12-23",
    review: false,
  },
  {
    id: 628,
    status: "published",
    date_created: "2025-03-03T16:59:12.594Z",
    path: "marketing-toolkit/candescent/index.html",
    url: "https://www.mx.com/marketing-toolkit/candescent/",
    last_modified: "2025-02-13",
    publish_date: "2025-02-13",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 551,
    url: "https://www.mx.com/blog/whats-going-on-in-banking-key-takeaways",
    admin_url: "https://cms.mx.com/admin/content/blog/551",
    name: "What’s Going On In Banking? Key Takeaways",
    publish_date: "2025-02-13",
    last_modified: null,
    review: false,
  },
  {
    id: 550,
    status: "published",
    date_created: "2025-02-28T22:46:12.722Z",
    path: "learn/index.html",
    url: "https://www.mx.com/learn/",
    last_modified: "2025-02-14",
    publish_date: "2023-02-27",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 87,
    url: "https://www.mx.com/webinars/top-trends-grow-desposits-engagement-2025/watch/",
    admin_url: "https://cms.mx.com/admin/content/webinars/87",
    name: "MX 2025 Predictions | Grow Deposits and Engagement",
    publish_date: "2025-01-23",
    last_modified: "2025-02-14T19:26:46.377Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 554,
    url: "https://www.mx.com/blog/avoiding-under-dataed-consumers",
    admin_url: "https://cms.mx.com/admin/content/blog/554",
    name: "How Building Trust Can Help Avoid “Under-Data’ed” Consumers",
    publish_date: "2025-02-18",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "events",
    id: 37,
    url: "https://www.mx.com/events/mx-atomic-fintech-meetup/03112025",
    admin_url: "https://cms.mx.com/admin/content/events/37",
    name: null,
    publish_date: "2025-02-05T19:56:27.705Z",
    last_modified: "2025-02-19T18:00:58.618Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 555,
    url: "https://www.mx.com/blog/financial-avoidance",
    admin_url: "https://cms.mx.com/admin/content/blog/555",
    name: "Is Financial Avoidance Becoming a Norm?",
    publish_date: "2025-02-21",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 557,
    url: "https://www.mx.com/news/top-100-fintech-leaders-2025",
    admin_url: "https://cms.mx.com/admin/content/mx_news/557",
    name: "FinTech Magazine Recognizes MX CEO Ryan Caldwell on Top 100 Leaders in Fintech for 2025",
    publish_date: "2025-02-21",
    last_modified: "2025-02-22T00:21:49.899Z",
    review: false,
  },
  {
    id: 505,
    status: "published",
    date_created: "2025-02-28T22:46:08.029Z",
    path: "case-studies/index.html",
    url: "https://www.mx.com/case-studies/",
    last_modified: "2025-02-25",
    publish_date: "2022-12-19",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 556,
    url: "https://www.mx.com/blog/financial-goals-2025",
    admin_url: "https://cms.mx.com/admin/content/blog/556",
    name: "Consumer Financial Attitudes and Goals in 2025",
    publish_date: "2025-02-26",
    last_modified: null,
    review: false,
  },
  {
    id: 470,
    status: "published",
    date_created: "2025-02-28T22:46:04.365Z",
    path: "adoption-and-engagement-toolkit/ncr-voyix/index.html",
    url: "https://www.mx.com/adoption-and-engagement-toolkit/ncr-voyix/",
    last_modified: "2025-02-27",
    publish_date: "2023-11-29",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 557,
    url: "https://www.mx.com/blog/prioritizing-consumer-outcomes",
    admin_url: "https://cms.mx.com/admin/content/blog/557",
    name: "Prioritizing Consumer Outcomes In the Face of Uncertainty ",
    publish_date: "2025-02-27",
    last_modified: null,
    review: false,
  },
  {
    status: "published",
    content_type: "events",
    id: 38,
    url: "https://www.mx.com/events/charlotte-supercar-driving/03202025",
    admin_url: "https://cms.mx.com/admin/content/events/38",
    name: null,
    publish_date: "2025-02-25T17:44:41.895Z",
    last_modified: "2025-02-28T22:15:24.429Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 527,
    url: "https://www.mx.com/news/2023-innovator-awards",
    admin_url: "https://cms.mx.com/admin/content/mx_news/527",
    name: "MX Recognizes 2023 Innovator Award Winners",
    publish_date: "2023-09-21",
    last_modified: "2025-03-03T16:40:18.403Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 546,
    url: "https://www.mx.com/news/mobile-banking-and-connectivity-consumer-research",
    admin_url: "https://cms.mx.com/admin/content/mx_news/546",
    name: "MX Survey Says Bad Mobile Banking Experience Could Make Consumers Look Elsewhere",
    publish_date: "2024-09-12",
    last_modified: "2025-03-03T16:41:19.311Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 516,
    url: "https://www.mx.com/news/mx-delivers-free-data-access-solution",
    admin_url: "https://cms.mx.com/admin/content/mx_news/516",
    name: "MX Delivers Free Data Access Solution to Help Financial Institutions Provide Tokenized API Connections and Unlock Customer Insights",
    publish_date: "2023-05-10",
    last_modified: "2025-03-03T16:42:56.831Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 555,
    url: "https://www.mx.com/news/survey-says-consumers-demand-more",
    admin_url: "https://cms.mx.com/admin/content/mx_news/555",
    name: "MX Survey Shows Consumers Demand More Insightful, Comprehensive Financial Support",
    publish_date: "2025-02-05",
    last_modified: "2025-03-03T16:45:41.691Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 99,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/99",
    url: "https://www.mx.com/research/banking-data-transformation",
    name: "The Ultimate Banker's Guide to Data Transformation",
    publish_date: "2023-01-25T02:12:01.021Z",
    last_modified: "2025-03-03T17:23:39.386Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 155,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/155",
    url: "https://www.mx.com/guides/choosing-a-data-enhancement-partner",
    name: "Choosing a Data Enhancement Partner",
    publish_date: "2025-01-28T15:58:32.146Z",
    last_modified: "2025-03-04T15:59:13.692Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 547,
    url: "https://www.mx.com/blog/what-is-data-enhancement",
    admin_url: "https://cms.mx.com/admin/content/blog/547",
    name: "What is Data Enhancement? How It Works and Key Benefits",
    publish_date: "2025-01-28",
    last_modified: "2025-03-04T15:47:38",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 553,
    url: "https://www.mx.com/blog/data-enhancement-upgrade",
    admin_url: "https://cms.mx.com/admin/content/blog/553",
    name: "Data Enhancement Product Updates: Maximizing Geolocation and Merchant Coverage",
    publish_date: "2025-02-11",
    last_modified: "2025-03-04T15:56:09",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 558,
    url: "https://www.mx.com/blog/where-consumers-trust-and-use-ai",
    admin_url: "https://cms.mx.com/admin/content/blog/558",
    name: "Trust vs. Use: How Consumers Feel About Adopting AI",
    publish_date: "2025-03-03",
    last_modified: "2025-03-04T16:40:51",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 544,
    url: "https://www.mx.com/blog/why-interoperability-matters",
    admin_url: "https://cms.mx.com/admin/content/blog/544",
    name: "What is Interoperability? What It Means and Why It Matters in Open Banking",
    publish_date: "2025-01-09",
    last_modified: "2025-03-05T22:53:46",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 558,
    url: "https://www.mx.com/news/global-credit-union-sees-immediate-results",
    admin_url: "https://cms.mx.com/admin/content/mx_news/558",
    name: "MX Delivers Immediate Results and Deposit Growth for Global Credit Union",
    publish_date: "2025-03-06",
    last_modified: "2025-03-06T14:41:21.277Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 559,
    url: "https://www.mx.com/blog/buying-from-fintech-selling-to-banks",
    admin_url: "https://cms.mx.com/admin/content/blog/559",
    name: "Buying from Fintech (and Selling to Banks): Key Takeaways from TTV Capital’s Bank LP Summit",
    publish_date: "2025-03-06",
    last_modified: "2025-03-06T18:25:29",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 264,
    url: "https://www.mx.com/news/ebankit-integrates-mx-financial-wellness",
    admin_url: "https://cms.mx.com/admin/content/mx_news/264",
    name: "ebankIT and MX Help Financial Institutions Guide and Empower Customers",
    publish_date: "2022-10-21",
    last_modified: "2025-03-07T22:21:27.059Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 540,
    url: "https://www.mx.com/news/manual-methods-consumer-research",
    admin_url: "https://cms.mx.com/admin/content/mx_news/540",
    name: "New MX Study Finds 64% of U.S. Consumers Prefer to Pay Bills Manually Each Month",
    publish_date: "2024-05-30",
    last_modified: "2025-03-07T22:25:17.245Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 533,
    url: "https://www.mx.com/news/primary-financial-relationships-consumer-research",
    admin_url: "https://cms.mx.com/admin/content/mx_news/533",
    name: "MX Survey Shows 23% of U.S. Consumers Open a New Financial Account at Least Once per Year",
    publish_date: "2024-01-30",
    last_modified: "2025-03-07T22:48:14.175Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 507,
    url: "https://www.mx.com/news/sila-and-mx-announce-tokenized-integration-for-bank-account-verification",
    admin_url: "https://cms.mx.com/admin/content/mx_news/507",
    name: "Sila and MX Announce Tokenized Integration For Bank Account Verification",
    publish_date: "2022-11-09",
    last_modified: "2025-03-07T23:03:44.453Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 64,
    url: "https://www.mx.com/webinars/data-access",
    admin_url: "https://cms.mx.com/admin/content/webinars/64",
    name: "MX Product Forum: Data Access",
    publish_date: "2023-05-09",
    last_modified: "2025-03-07T23:07:58.432Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 6,
    url: "https://www.mx.com/webinars/cornerstone-research-open-banking-data-aggregation",
    admin_url: "https://cms.mx.com/admin/content/webinars/6",
    name: "5 Questions in 25 Minutes With Cornerstone Research",
    publish_date: "2022-06-01",
    last_modified: "2025-03-07T23:10:55.231Z",
    review: false,
  },
  {
    status: "published",
    content_type: "webinars",
    id: 34,
    url: "https://www.mx.com/webinars/what-consumers-really-want-from-financial-providers",
    admin_url: "https://cms.mx.com/admin/content/webinars/34",
    name: "Fintech Functionality to Drive Customer Engagement and Loyalty",
    publish_date: "2023-01-18",
    last_modified: "2025-03-10T19:42:02.477Z",
    review: false,
  },
  {
    status: "published",
    content_type: "insights_library_posts",
    id: 151,
    admin_url: "https://cms.mx.com/admin/content/insights_library_posts/151",
    url: "https://www.mx.com/guides/banking-apis-oauth-sceenscraping",
    name: "A Primer on Banking APIs, OAuth, and Screen Scraping",
    publish_date: "2024-11-14T17:35:17.103Z",
    last_modified: "2025-03-10T20:04:38.131Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 551,
    url: "https://www.mx.com/news/american-express-and-mx-announce-customer-permissioned-data-sharing",
    admin_url: "https://cms.mx.com/admin/content/mx_news/551",
    name: "American Express and MX Announce Customer-Permissioned Data Sharing Agreement to Expand Digital Finance Management for Customers",
    publish_date: "2024-11-07",
    last_modified: "2025-03-10T20:18:14.016Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 549,
    url: "https://www.mx.com/news/jane-barratt-named-fdx-board-co-chair",
    admin_url: "https://cms.mx.com/admin/content/mx_news/549",
    name: "MX Chief Advocacy Officer Jane Barratt Named Financial Data Exchange (FDX) Board Co-Chair",
    publish_date: "2024-10-22",
    last_modified: "2025-03-10T20:19:57.187Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 508,
    url: "https://www.mx.com/news/loanpro-enhanced-data-for-loan-management",
    admin_url: "https://cms.mx.com/admin/content/mx_news/508",
    name: "LoanPro and MX Announce Partnership to Deliver Enhanced Financial Data for Loan Management Solutions",
    publish_date: "2022-11-29",
    last_modified: "2025-03-10T20:21:20.528Z",
    review: false,
  },
  {
    status: "published",
    content_type: "mx_news",
    id: 500,
    url: "https://www.mx.com/news/mx-widens-lead-with-more-than-connections-to-the-top-financial-institutions-and-fintechs",
    admin_url: "https://cms.mx.com/admin/content/mx_news/500",
    name: "MX Widens Lead With More Than 50,000 Connections to the Top Financial Institutions and Fintechs",
    publish_date: "2020-08-20",
    last_modified: "2025-03-10T20:28:55.116Z",
    review: false,
  },
  {
    status: "published",
    content_type: "blog",
    id: 560,
    url: "https://www.mx.com/blog/consumers-define-and-measure-financial-wellness",
    admin_url: "https://cms.mx.com/admin/content/blog/560",
    name: "Metrics and Mindsets: How Consumers Define and Measure Financial Wellness",
    publish_date: "2025-03-10",
    last_modified: "2025-03-10T15:52:51",
    review: false,
  },
];

export default testPagesData;
