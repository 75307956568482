import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { LoginCallback } from "@okta/okta-react";
import testPagesData from "../testPagesData.js";

// Styled wrapper with nested styles
const StatusPageWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 20px 100px 20px;
  font-family: Arial, sans-serif;

  h1 {
    text-align: center;
    color: ${({ theme }) => theme.textColor};
    margin: 30px 0 50px 0;
    @media only screen and (max-width: 600px) {
      font-size: 25px;
    }
  }

  p {
    color: ${({ theme }) => theme.textColor};
    margin: 0;
  }

  .filter-btns {
    display: flex;
    justify-content: center;
    p {
      margin: 5px 20px;
      opacity: 0.5;
      &.active {
        opacity: 1;
      }
    }
  }

  .page-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 900px;
    margin: auto;

    .header-description {
      text-align: center;
    }

    .tab-item {
      font-weight: 600;
      cursor: pointer;
      transition: opacity 0.2s ease;

      &:hover {
        opacity: 1;
        color: ${({ theme }) => theme.textColor} !important;
      }
    }

    .page {
      position: relative;
      display: flex;
      align-items: center;
      padding: 15px 15px 5px 15px;
      border-radius: 6px;
      background: ${({ theme }) => theme.sectionColor};
      transition: all 0.2s ease-in-out;
      text-decoration: none;
      /* border-left: 6px solid transparent; */

      .icon-view {
        width: 20px;
        margin-right: 0;
        margin-left: auto;
      }

      &.live {
        border-color: #2ecc71; // Green for published
      }
      &.archived {
        border-color: #e74c3c; // Gray for archived
      }

      p {
        margin: 0;
      }

      .info {
        display: flex;
        flex-direction: column;
        width: 100%;

        & > .icon {
          width: 20px;
          position: absolute;
          right: 15px;
          top: 15px;
          filter: grayscale();
          cursor: pointer;

          &:hover {
            filter: none;
          }
        }
      }

      .options {
        display: flex;
        align-items: center;

        .icon {
          width: 20px;
          margin: 0 10px;
          cursor: pointer;

          &:first-of-type {
            margin-left: 0;
          }
        }
        p {
          cursor: pointer;
          display: inline;
          font-weight: 600;
          font-size: 14px;

          &.overdue {
            cursor: initial;
          }
        }
      }

      .url {
        font-size: 18px;
        font-weight: 300;
        color: ${({ theme }) => theme.textColor};
        text-decoration: none;
        transition: color 0.2s ease-in-out;
      }

      .date {
        font-size: 16px;
        color: #6c757d;
        margin-bottom: 10px;

        &.publish {
          margin: 10px 0;
        }
      }
      .overdue {
        position: absolute;
        right: 20px;
        bottom: 5px;
        color: #e74c3c; // Gray for archived
        font-weight: 600;
        font-size: 14px;

        @media only screen and (max-width: 900px) {
          position: initial;
        }
      }
    }
  }

  .review-page {
    margin-bottom: 20px;
    background: ${({ theme }) => theme.sectionColor};
    border: none !important;

    &:first-of-type {
      margin-top: 20px;
    }
    .url {
      font-weight: 600;
      margin-bottom: 5px;
    }

    .date {
      font-size: 14px;

      &.overdue {
        margin-bottom: 0;
      }
    }
  }
`;

const unPublishPost = (post) => {
  // Set Item to Archive
  // Add a redirect to relevant collection listing page (so blog items redirect to /blog)
};

function transformDataForDisplay(data, collectionName) {
  let urlBase = "https://hub.mx.com/";
  let imageUrlBase = "https://directus.hub.mx.com/assets/";

  if (!data) {
    console.log(
      "error: check directus API endpoint to make sure the collection has read access"
    );
    return [];
  }

  return data.map((item) => {
    const transformed = {
      status: item.status,
      content_type: collectionName,
      id: item.id,
    };

    // STEP 2: Add Collections and field mappings here

    switch (collectionName) {
      case "blog":
        transformed.url = "https://www.mx.com/blog/" + item.post_url;
        transformed.admin_url = `https://cms.mx.com/admin/content/blog/${item.id}`;
        transformed.name = item.title;
        transformed.publish_date = item.publish_date;
        transformed.last_modified = item.updated_at;
        break;
      case "mx_news":
        transformed.url = "https://www.mx.com/news/" + item.post_url;
        transformed.admin_url = `https://cms.mx.com/admin/content/mx_news/${item.id}`;
        transformed.name = item.title;
        transformed.publish_date = item.publish_date;
        transformed.last_modified = item.date_updated;
        break;
      case "trust_center":
        transformed.url = "https://www.mx.com/trust/" + item.url;
        transformed.admin_url = `https://cms.mx.com/admin/content/trust_center/${item.id}`;
        transformed.name = item.title;
        transformed.publish_date = item.publish_date;
        transformed.last_modified = item.date_updated;
        break;
      case "demo_pages":
        transformed.url = "https://www.mx.com/demo/" + item.page_url;
        transformed.admin_url = `https://cms.mx.com/admin/content/demo_pages/${item.id}`;
        transformed.name = item.title;
        transformed.publish_date = item.date_created;
        transformed.last_modified = item.date_updated;
        break;
      case "insights_library_posts":
        let url = "";
        if (item.content_type === "guides") {
          url = "https://www.mx.com/guides/" + item.guide_url;
        }
        if (item.content_type === "research") {
          url = "https://www.mx.com/research/" + item.research_url;
        }
        if (item.content_type === "whitepapers") {
          url = "https://www.mx.com/whitepapers/" + item.whitepaper_url;
        }
        transformed.admin_url = `https://cms.mx.com/admin/content/insights_library_posts/${item.id}`;
        transformed.url = url;
        transformed.name = item.title;
        transformed.publish_date = item.date_created;
        transformed.last_modified = item.date_updated;
        break;
      case "webinars":
        let exceptionUrl = item.exception_url;
        if (exceptionUrl && !exceptionUrl.includes("mx.com")) {
          exceptionUrl = "https://www.mx.com" + exceptionUrl;
        }

        transformed.url = item.webinar_url
          ? "https://www.mx.com/webinars/" + item.webinar_url
          : exceptionUrl;
        transformed.admin_url = `https://cms.mx.com/admin/content/webinars/${item.id}`;
        transformed.name = item.webinar_title;
        transformed.publish_date = item.publish_date;
        transformed.last_modified = item.date_updated;
        break;
      case "events":
        transformed.url = "https://www.mx.com/events/" + item.event_url;
        transformed.admin_url = `https://cms.mx.com/admin/content/events/${item.id}`;
        transformed.name = item.meta_title;
        transformed.publish_date = item.date_created;
        transformed.last_modified = item.date_updated;
        break;
      case "spec_sheet_library":
        transformed.url = "https://www.mx.com/products/spec-sheets/" + item.url;
        transformed.admin_url = `https://cms.mx.com/admin/content/spec_sheets_library/${item.id}`;
        transformed.name = item.title;
        transformed.publish_date = item.date_created;
        transformed.last_modified = item.date_updated;
        break;
      case "services_descriptions":
        transformed.url =
          "https://www.mx.com/services-descriptions/" + item.url;
        transformed.admin_url = `https://cms.mx.com/admin/content/services_descriptions/${item.id}`;
        transformed.name = item.title;
        transformed.publish_date = item.publish_date;
        transformed.last_modified = item.date_updated;
        break;
      case "Value_Engineering_ABM_Campaign":
        transformed.url = "https://www.mx.com/work-with-mx/" + item.url;
        transformed.admin_url = `https://cms.mx.com/admin/content/Value_Engineering_ABM_Campaign/${item.id}`;
        transformed.name = item.account;
        transformed.publish_date = item.date_created;
        transformed.last_modified = item.date_updated;
        break;

      default:
        console.error("Unknown collection: ", collectionName);
        break;
    }
    return transformed;
  });
}

async function fetchAndTransformData(
  baseUrl,
  collectionName,
  additionalParams
) {
  try {
    const response = await fetch(
      `${baseUrl}/api/public/mx-com/directus/collections/${collectionName}/?limit=-1${additionalParams}`
    );

    const theData = await response.json();

    const data = theData.data;
    console.log(`✅ Fetched ${data.length} ${collectionName} posts`);

    return transformDataForDisplay(data, collectionName);
  } catch (error) {
    console.error(
      `Error fetching data for collection ${collectionName}:`,
      error
    );
    return [];
  }
}

const getDirectusPages = async (baseUrl) => {
  // Get from all collections

  const collectionList = [
    {
      name: "blog",
      additionalParams: "&filter[_and][1][status][_eq]=published",
    },
    {
      name: "mx_news",
      additionalParams: "&filter[_and][1][status][_eq]=published",
    },
    {
      name: "trust_center",
      additionalParams: "&filter[_and][1][status][_eq]=published",
    },
    {
      name: "demo_pages",
      additionalParams: "&filter[_and][1][status][_eq]=published",
    },
    {
      name: "insights_library_posts",
      additionalParams: "&filter[_and][1][status][_eq]=published",
    },
    {
      name: "webinars",
      additionalParams: "&filter[_and][1][status][_eq]=published",
    },
    {
      name: "events",
      additionalParams: "&filter[_and][1][status][_eq]=published",
    },
    {
      name: "spec_sheet_library",
      additionalParams: "&filter[_and][1][status][_eq]=published",
    },
    {
      name: "services_descriptions",
      additionalParams: "&filter[_and][1][status][_eq]=published",
    },
    {
      name: "Value_Engineering_ABM_Campaign",
      additionalParams: "&filter[_and][1][status][_eq]=published",
    },
  ];

  let allCollectionData = [];

  for (const { name, additionalParams } of collectionList) {
    console.log(`🔄 Fetching latest ${name} collection from Directus...`);
    const transformedData = await fetchAndTransformData(
      baseUrl,
      name,
      additionalParams
    );
    allCollectionData = allCollectionData.concat(transformedData);
  }

  return allCollectionData;
};

const formatDateTime = (isoString) => {
  if (!isoString) {
    return null;
  }
  const date = new Date(isoString);

  return date.toLocaleString("en-US", {
    year: "numeric",
    month: "short",
    day: "2-digit",
  });
};

function formatRelativeTime(dateString) {
  const givenDate = new Date(dateString);
  const today = new Date();

  const diffTime = today - givenDate; // Difference in milliseconds
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24)); // Convert to days

  if (diffDays < 30) {
    return `${diffDays} day${diffDays !== 1 ? "s" : ""} ago`; // Show in days
  }

  const diffMonths = Math.floor(diffDays / 30); // Approximate months
  if (diffMonths < 12) {
    return `${diffMonths} month${diffMonths !== 1 ? "s" : ""} ago`; // Show in months
  }

  const diffYears = Math.floor(diffMonths / 12); // Convert to years
  const remainingMonths = diffMonths % 12; // Remaining months after full years

  if (remainingMonths === 0) {
    return `${diffYears} year${diffYears !== 1 ? "s" : ""} ago`; // Show only years if no extra months
  }

  return `${diffYears} year${
    diffYears !== 1 ? "s" : ""
  } ${remainingMonths} month${remainingMonths !== 1 ? "s" : ""} ago`;
}

const markAsReviewed = async (pageData) => {
  // GET THIS WORKING
  const result = await axios.patch(
    `${apiBaseUrl}/public/mx-com/directus/collections/${name}/${id}`,
    data,
    {
      headers: {
        authorization: `Bearer ${token}`,
      },
    }
  );
  if (result.status == 200) {
    return "success";
  }
  return "error";
};

// const baseUrl = "https://api.hub.mx.com";
const baseUrl = "http://localhost:3080";

const PageItem = ({ pageData, pageType }) => {
  return (
    <div
      className={`page page-list__item ${
        pageData.status === "published" ? "live" : "archived"
      }`}
      target="_blank"
      key={pageData.id}
    >
      <div className="info">
        <img
          onClick={() => markAsReviewed(pageData)}
          className="icon"
          src="https://assets.mx.com/hub/images/q-a/answered-icon.svg"
        />
        <p className="url">{pageData.url}</p>
        <p className="date publish">
          Published: {formatDateTime(pageData.publish_date)}
        </p>{" "}
        {/* {pageType === "archived" && (
          <p className="date">
            {pageData.archived_date &&
              `Archived: ${formatDateTime(pageData.archived_date)}`}
          </p>
        )} */}
        <p className={`date ${pageType === "review" ? "overdue" : ""}`}>
          {pageData.last_modified &&
            `Last Updated ${formatDateTime(pageData.last_modified)}`}
        </p>
        {/* <p className="date">
          Last Updated {formatDateTime(pageData.last_modified)}
        </p> */}
        {pageData.review && pageType !== "review" && (
          <div className="options">
            <p className="date overdue">Suggested for Review</p>
            {pageData.admin_url && (
              <a href={pageData.admin_url} target="_blank">
                <img
                  className="icon icon-view"
                  src="https://assets.mx.com/hub/images/icon-settings.svg"
                />
              </a>
            )}
            <a href={pageData.url} target="_blank">
              <img
                className="icon icon-view"
                src="https://assets.mx.com/hub/images/icon-open-in.svg"
              />
            </a>
            {/* {pageData.admin_url && (
              <p onClick={() => unPublishPost(pageData)}>Unpublish</p>
            )} */}
          </div>
        )}
      </div>
    </div>
  );
};
const ReviewItem = ({ pageData, pageType, number }) => {
  return (
    <a
      className={`page review-page page-list__item ${
        pageData.status === "published" ? "live" : "archived"
      }`}
      href={pageData.admin_url ? pageData.admin_url : pageData.url}
      target="_blank"
      key={pageData.id}
    >
      <div className="info">
        <p>{number}</p>
        <p className="url">{pageData.url}</p>
        <p className="date publish">
          Published: {formatDateTime(pageData.publish_date)}
        </p>{" "}
        <p className={`date ${pageType === "review" ? "overdue" : ""}`}>
          {pageData.last_modified
            ? `Last Updated: ${formatDateTime(pageData.last_modified)}`
            : "No Update Data"}
        </p>
      </div>
    </a>
  );
};

const suggestedForReview = (page) => {
  const ONE_YEAR_MS = 365 * 24 * 60 * 60 * 1000; // One year in milliseconds
  const today = new Date();

  function isOverOneYearOld(dateString) {
    if (!dateString) return false; // Skip if date is null
    const date = new Date(dateString);
    return today - date > ONE_YEAR_MS; // Compare date difference
  }
  if (!page.last_modified) {
    return isOverOneYearOld(page.publish_date);
  } else if (page.publish_date && page.last_modified) {
    return (
      isOverOneYearOld(page.last_modified) &&
      isOverOneYearOld(page.publish_date)
    );
  }
};

const MXPages = () => {
  const [pages, setPages] = useState([]);
  const [archivedPages, setArchivedPages] = useState([]);
  const [reviewPages, setReviewPages] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [pageList, setPageList] = useState(pages);

  const useTestPages = async (pageData) => {
    let thePages = pageData;

    const publishedPages = thePages.filter(
      (page) => page.status === "published"
    );

    const allPublishedPages = publishedPages
      .sort((a, b) => {
        const dateA = new Date(a.last_modified || a.publish_date);
        const dateB = new Date(b.last_modified || b.publish_date);

        return dateA - dateB; // Sort ascending (oldest first)
      })
      .map((page) => ({
        ...page,
        review: suggestedForReview(page),
      }));

    const toReviewPages = allPublishedPages
      .filter((page) => suggestedForReview(page))
      .sort((a, b) => {
        const dateA = new Date(a.last_modified || a.publish_date);
        const dateB = new Date(b.last_modified || b.publish_date);

        return dateA - dateB; // Sort ascending (oldest first)
      });

    console.log(allPublishedPages);

    setPages(allPublishedPages);

    setReviewPages(toReviewPages);
  };

  const fetchPages = async () => {
    try {
      let filterParams = `limit=-1`;
      filterParams += `&filter[status][_neq]=draft`;
      const response = await fetch(
        `${baseUrl}/api/public/mx-com/directus/collections/pages_test/?${filterParams}`
      ).then((res) => res.json());

      if (response.status < 200 || response.status >= 300) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      thePages = response.data;

      const publishedPages = thePages.filter(
        (page) => page.status === "published"
      );

      const archivedPages = thePages
        .filter((page) => page.status === "archived")
        .sort((a, b) => new Date(b.last_modified) - new Date(a.last_modified));

      const directusData = await getDirectusPages(baseUrl);

      const allPublishedPages = [...publishedPages, ...directusData]
        .sort((a, b) => {
          const dateA = new Date(a.last_modified || a.publish_date);
          const dateB = new Date(b.last_modified || b.publish_date);

          return dateA - dateB; // Sort ascending (oldest first)
        })
        .map((page) => ({
          ...page,
          review: suggestedForReview(page),
        }));

      const toReviewPages = allPublishedPages
        .filter((page) => suggestedForReview(page))
        .sort((a, b) => {
          const dateA = new Date(a.last_modified || a.publish_date);
          const dateB = new Date(b.last_modified || b.publish_date);

          return dateA - dateB; // Sort ascending (oldest first)
        });

      console.log(allPublishedPages);

      setPages(allPublishedPages);

      // setArchivedPages(archivedPages);

      setReviewPages(toReviewPages);

      return response.data;
    } catch (error) {
      console.error("❌ Error fetching pages:", error.message);
      setPages([]);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      await fetchPages();
    };

    if (!Boolean(testPagesData)) {
      fetchData();
    } else {
      useTestPages(testPagesData);
    }
  }, []);

  useEffect(() => {
    if (activeTab === 1) {
      setPageList(pages);
    }
    if (activeTab === 2) {
      setPageList(archivedPages);
    }

    if (activeTab === 3) {
      setPageList([]);
    }
  }, [activeTab, pages]);

  return (
    <StatusPageWrapper>
      <div className="page-list">
        <h1>MX.com Pages</h1>
        <div className="filter-btns">
          <p
            className={`tab-item ${activeTab === 1 ? "active" : "disabled"}`}
            onClick={() => setActiveTab(1)}
          >
            Active {pages.length}
          </p>
          <p
            className={`tab-item ${activeTab === 3 ? "active" : "disabled"}`}
            onClick={() => setActiveTab(3)}
          >
            Suggested For Review {reviewPages.length}
          </p>
        </div>
        {pageList &&
          pageList.map((page) => (
            <PageItem
              pageType={page.status === "published" ? "live" : "archived"}
              pageData={page}
              key={page.id + page.url}
            />
          ))}

        {activeTab === 3 && reviewPages && (
          <>
            <p className="header-description">
              Pages over a year old with no recent updates.
            </p>

            {reviewPages.map((page, index) => (
              <ReviewItem
                pageType="review"
                pageData={page}
                key={page.id + page.url}
              />
            ))}
          </>
        )}
      </div>
    </StatusPageWrapper>
  );
};

export default MXPages;
