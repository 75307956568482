import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styled, { withTheme } from "styled-components";
import parse from "html-react-parser";
import api from "../components/api";
import CloseIconBlack from "../assets/images/icons/close-black.svg";
import OutlineButton from "../components/OutlineButton";
import utils from "../components/utils";
import { useOktaAuth } from "@okta/okta-react";
import Button from "../components/Button";
import Gap from "../components/gap";
import PostRow from "../components/PostRow";

const PageWrapper = styled.div`
  padding-top: 60px;

  @media only screen and (max-width: 900px) {
    padding: 0 20px;
    padding-top: 40px;
  }
  h1 {
    margin-bottom: 40px;
    padding-bottom: 10px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    color: ${({ theme }) => theme.textColor};

    &:after {
      content: " ";
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      /* background: linear-gradient(
        90.72deg,
        #6cdbe6 1.62%,
        #2c64ef 69.66%,
        #9c42f6 99.84%
      ); */
      background: ${({ theme }) => theme.blue};
      max-width: 100%;
      width: 786px;
      bottom: -10px;
    }
  }
`;

function TagResults({ theme }) {
  const [posts, setPosts] = useState([]);
  const [oktaInfo, setOktaInfo] = useState([]);
  const { authState, oktaAuth } = useOktaAuth();

  const { tag } = useParams();

  useEffect(() => {
    // Check if the user is authenticated
    if (authState.isAuthenticated) {
      const accessToken = authState.accessToken.accessToken;
      setOktaInfo(authState.idToken.claims);

      // Now you can use the accessToken as needed
      // For example, you can include it in your API requests
    }
  }, [oktaInfo]);

  useEffect(() => {
    async function fetchTaggedPostsData() {
      // let filterParams = "filter[article_tags][_contains]=" + tag;
      let filterParams = "filter[status][_eq]=published";
      filterParams += "&filter[article_tags][_nnull]=true";
      let articlesData = await api.collection(
        "articles",
        oktaAuth,
        filterParams
      );

      let matchingArticles = [];

      for (let index = 0; index < articlesData.length; index++) {
        const article = articlesData[index];
        const arrayOfTags = article.article_tags;

        const formattedTags = arrayOfTags.map((str) =>
          str.toLowerCase().replaceAll(" ", "-")
        );

        if (formattedTags.includes(tag)) {
          matchingArticles.push(article);
        }
      }

      setPosts(matchingArticles);
    }

    fetchTaggedPostsData();
  }, []);

  return (
    <PageWrapper>
      <h1>{`Tag: ${tag.replaceAll("-", " ")}`}</h1>
      {posts.length > 0
        ? posts.map((article) => {
            return (
              <PostRow
                key={article.id}
                postId={article.id}
                title={article.article_title}
                description={utils.parseHTML(article)}
                link={`/library/${article.article_url}/`}
                image={article.featured_image}
                // tags={article.article_tags}
              />
            );
          })
        : ""}
    </PageWrapper>
  );
}

export default withTheme(TagResults);
