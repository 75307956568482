import { extend } from "@okta/okta-auth-js";
import api from "./api";
import utils from "./utils";

const keySwap = (array, oldKey, newKey) => {
  for (let i = 0; i < array.length; i++) {
    const item = array[i];
    if (item[oldKey]) {
      item[newKey] = item[oldKey];
      delete item[oldKey];
    }
  }
  return array;
};

const slugify = (s) => {
  //make lowercase
  s = s.toLowerCase().trim();

  //replace spaces with hyphens
  s = s.replace(/\s/g, "-");
  s = s.replace("(", "");
  s = s.replace(")", "");

  return s;
};

const getDateXDaysAway = (days) => {
  var date = new Date();
  date.setDate(date.getDate() + days);
  var month = date.getMonth() + 1;
  if (month < 10) month = "0" + month;
  var day = date.getDate();
  if (day < 10) day = "0" + day;
  return `${date.getFullYear()}-${month}-${day}-00:00`;
};

const easyToReadDate = (start, end, type) => {
  if (!start) return "";

  if (type && type == "mm/dd/yyyy") {
    //convert
    start =
      start.split("/")[2] +
      "-" +
      start.split("/")[0] +
      "-" +
      start.split("/")[1];
    if (end) {
      end =
        end.split("/")[2] + "-" + end.split("/")[0] + "-" + end.split("/")[1];
    }
  }

  if (type && type == "date-T-time") {
    //convert
    start = start.split("T")[0];
    if (end) {
      end = end.split("T")[0];
    }
  }

  var months = [
    "JAN",
    "FEB",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];

  const startYear = start.split("-")[0];
  const startMonth = months[start.split("-")[1] - 1];
  const startDay = start.split("-")[2].replace(/^0+/, "");

  let formatted = `${startMonth} ${startDay}, ${startYear}`;

  if (end) {
    const endYear = end.split("-")[0];
    const endMonth = months[end.split("-")[1] - 1];
    const endDay = end.split("-")[2].replace(/^0+/, "");

    if (endYear != startYear) {
      formatted = `${startMonth} ${startDay}, ${startYear} - ${endMonth}. ${endDay}, ${endYear}`;
    } else if (endMonth != startMonth) {
      formatted = `${startMonth} ${startDay}-${endMonth} ${endDay}, ${startYear}`;
    } else if (startDay != endDay) {
      formatted = `${startMonth} ${startDay}-${endDay}, ${startYear}`;
    }
  }

  return formatted;
};

const getCookie = (name) => {
  var value = "; " + document.cookie;
  var parts = value.split("; " + name + "=");
  if (parts.length == 2) return parts.pop().split(";").shift();
};

const getPageParams = () => {
  var params = {};
  var parser = document.createElement("a");
  parser.href = window.location.href;
  var query = parser.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    params[pair[0]] = decodeURIComponent(pair[1]);
  }
  if (!window.location.href.includes("?")) {
    return [];
  }
  return params;
};

const createCookie = (name, value, minutes) => {
  if (minutes) {
    var date = new Date();
    date.setTime(date.getTime() + minutes * 60 * 1000);
    var expires = "; expires=" + date.toGMTString();
  } else {
    var expires = "";
  }
  document.cookie = name + "=" + value + expires + "; path=/";
};

const isExternalLink = (link) => {
  if (link.includes("http")) {
    return true;
  }
  if (link.includes(".com") && !link.includes("hub.mx.com")) {
    return true;
  }

  if (link.includes("www") && !link.includes("hub.mx.com")) {
    return true;
  }

  return false;
};

const reloadIfOnSamePage = (link) => {
  if (window.location.pathname.includes(link)) {
    location.href = link;
  }
};

const parseHTML = (post) => {
  let content = "";

  if (post.searchable_description) {
    content = post.searchable_description;
  } else if (post.article_content) {
    content = post.article_content;
  } else if (post.post_subtitle) {
    content = post.post_subtitle;
  } else if (post.content) {
    content = post.content;
  }

  const parser = new DOMParser();
  let cleanContent;
  if (!content) {
    cleanContent = null;
  } else {
    cleanContent = parser.parseFromString(content, "text/html").body.innerText;
    cleanContent = cleanContent.trim();

    if (
      cleanContent.includes("unable to view the document") ||
      cleanContent.length < 10
    ) {
      cleanContent = null;
    }
  }
  content = cleanContent;
  return content;
};

const adminList = [
  "ali.kirk@mx.com",
  "cameron.bullock@mx.com",
  "hailey.molina@mx.com",
  "emily.simmons@mx.com",
  "carrie.johnson@mx.com",
];

export default {
  keySwap,
  getDateXDaysAway,
  easyToReadDate,
  getCookie,
  getPageParams,
  createCookie,
  slugify,
  isExternalLink,
  reloadIfOnSamePage,
  parseHTML,
  adminList,
};
