import React, { useState, useEffect, useRef } from "react";
import api from "../components/api";
import utils from "../components/utils";
import { useOktaAuth } from "@okta/okta-react";
import styled, { withTheme } from "styled-components";
import CompetencyLibraryPage from "../components/CompetencyLibraryPage";

const PageWrapper = styled.section`
  .glossary-title {
    top: 0;
  }
`;

function Glossary({ theme }) {
  return (
    <PageWrapper>
      <CompetencyLibraryPage />
    </PageWrapper>
  );
}

export default withTheme(Glossary);
