import React, { useState, useEffect } from "react";
import styled from "styled-components";
import api from "../components/api";
import { useOktaAuth } from "@okta/okta-react";
import ManagerTask from "../components/ManagerTask";
import PostRow from "../components/ManagerResourcePost";
import Gap from "../components/gap";
import Button from "../components/Button";
import SearchInput from "../components/SearchInput";
import Pagination from "../components/PaginationB";

const NonManagerMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 70vh;
`;

const PageWrapper = styled.div`
  padding-right: 40px;

  h1 {
    font-weight: 400;
    color: ${({ theme }) => theme.textColor};

    @media only screen and (max-width: 1000px) {
      padding: 20px;
      font-size: 25px;
    }

    span {
      font-size: 48px;
      font-style: normal;
      font-weight: 700;
      line-height: 50px; /* 104.167% */
      text-transform: uppercase;
    }
  }
  h2 {
    color: ${({ theme }) => theme.textColor};
    font-weight: 700;
    font-size: 20px;
    line-height: 1;
    margin-top: 30px;
    align-items: center;
    text-transform: uppercase;
  }

  #resources-listing {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    grid-gap: 20px;
    max-width: 1500px;
    max-width: 100%;

    @media only screen and (max-width: 1600px) {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @media only screen and (max-width: 1000px) {
      grid-template-columns: 1fr;
    }
  }

  #resources {
    @media only screen and (max-width: 800px) {
      padding: 0 30px;
    }
  }
`;

const PostCol = styled.div`
  border-radius: 15px;
  width: 100%;
  background: ${({ theme }) => theme.backgroundColor};
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 1000px) {
    min-height: fit-content;
  }

  @media only screen and (max-width: 800px) {
    padding: 0 30px;
  }

  .snippet {
    background: ${({ theme }) => theme.backgroundColor};
    margin-right: 30px;
  }

  &.white-bg {
    background: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.textColor};

    h2 {
      /* margin-top: 10px; */
    }
  }
`;

const ButtonWrap = styled.div`
  display: flex;
  justify-content: end;
`;

const defaultBackupThumbnail = "107a1ff2-5310-4285-bcf6-0e987add96ab";

const HeaderWrap = styled.div`
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

function paginateItems(items, page, pageLimit) {
  if (items.length == 0) return;

  const startIndex = (page - 1) * pageLimit;
  const endIndex = startIndex + pageLimit;
  return items.slice(startIndex, endIndex);
}

function matchingItems(searchTerm, items, fields) {
  const lowercasedSearchTerm = searchTerm.toLowerCase();

  return items.filter((item) => {
    return fields.some((field) => {
      const fieldValue = item[field]?.toString().toLowerCase() || "";
      return fieldValue.includes(lowercasedSearchTerm);
    });
  });
}

function ManagerPortal({ isManager }) {
  let [tasks, setTasks] = useState([]);
  const [listingItems, setListingItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [page, setPage] = useState(1);
  const pageLimit = 9;
  const [theDate, setTheDate] = useState(new Date());
  const [searchTerm, setSearchTerm] = useState("");
  const { authState, oktaAuth } = useOktaAuth();
  const [oktaInfo, setOktaInfo] = useState([]);
  const parser = new DOMParser();

  useEffect(() => {
    // Check if the user is authenticated
    if (authState.isAuthenticated) {
      const accessToken = authState.accessToken.accessToken;
      setOktaInfo(authState.idToken.claims);

      // Now you can use the accessToken as needed
      // For example, you can include it in your API requests
    }
  }, [oktaInfo]);

  const managerSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    let items = [...listingItems];

    if (searchTerm) {
      const fields = ["title", "type", "description", "link"];
      items = matchingItems(searchTerm, items, fields);
    }

    items = paginateItems(items, page, pageLimit);

    if (items) {
      setFilteredItems(items);
    }
  }, [listingItems, searchTerm, page]);

  useEffect(() => {
    let componentMounted = true;

    function addLeadingZeros(num) {
      return num.toString().padStart(2, "0");
    }

    const getTasks = async () => {
      // Get the current date and time
      const now = new Date();

      // Calculate the date 36 hours in the past
      const date36HoursInPast = new Date(now.getTime() - 36 * 60 * 60 * 1000);

      // Format the date components to ISO 8601 format (UTC)
      const year = date36HoursInPast.getUTCFullYear();
      const month = addLeadingZeros(date36HoursInPast.getUTCMonth() + 1);
      const day = addLeadingZeros(date36HoursInPast.getUTCDate());
      const hour = addLeadingZeros(date36HoursInPast.getUTCHours());
      const minute = addLeadingZeros(date36HoursInPast.getUTCMinutes());
      const second = addLeadingZeros(date36HoursInPast.getUTCSeconds());

      // Construct the filter parameters
      let filterParams = "filter[status][_eq]=published";
      filterParams += `&filter[deadline][_gt]=${year}-${month}-${day}T${hour}:${minute}:${second}Z`;
      filterParams += "&sort=-display_as_banner,deadline";

      // Fetch the filtered tasks
      let tasksData = await api.collection(
        "Manager_Action_Tasks",
        oktaAuth,
        filterParams
      );

      setTasks(tasksData);
    };

    const getListingItems = async () => {
      let filterParams = "filter[status][_eq]=published";
      filterParams += "&filter[manager_only][_eq]=true";
      let resourcesData = await api.collection(
        "resources",
        oktaAuth,
        filterParams
      );

      let announcementsData = await api.collection(
        "announcements",
        oktaAuth,
        filterParams
      );

      let articlesData = await api.collection(
        "articles",
        oktaAuth,
        filterParams
      );

      //update key names
      announcementsData = announcementsData.map((item) => {
        const doc = parser.parseFromString(item.post_content, "text/html");
        const description = doc.body.textContent || "";
        return {
          ...item,
          title: item.post_title,
          type: "comms",
          thumbnail_image: item.featured_image || defaultBackupThumbnail,
          description: description,
          link: "/comms/" + item.slug,
        };
      });

      //update key names
      articlesData = articlesData.map((item) => {
        const doc = parser.parseFromString(item.article_content, "text/html");
        const description = doc.body.textContent || "";
        return {
          ...item,
          title: item.article_title,
          type: "article",
          thumbnail_image: item.featured_image || defaultBackupThumbnail,
          description: description,
          link: "/library/" + item.article_url,
          publish_date: item.update_date,
        };
      });

      let combined = [...resourcesData, ...announcementsData, ...articlesData];

      // Remove empty or null values
      combined = combined.filter((item) => item != null);

      //fallback to date_updated when publish_date is omitted
      for (const comby of combined) {
        if (!comby.publish_date) comby.publish_date = comby.date_created;
      }

      // Sort by publish_date
      combined.sort(
        (a, b) => new Date(b.publish_date) - new Date(a.publish_date)
      );

      if (componentMounted) {
        setListingItems(combined);
      }
    };

    if (isManager) {
      getTasks();
      getListingItems();
    }

    return () => {
      componentMounted = false;
    };
  }, [theDate, isManager, oktaInfo]);

  return (
    <>
      {isManager ? (
        <PageWrapper>
          <HeaderWrap>
            <h1>
              <span>Manager Portal</span>
            </h1>
            <SearchInput value={searchTerm} onChange={managerSearchChange} />
          </HeaderWrap>

          <div id="columns">
            <PostCol>
              {tasks &&
                tasks.map((task, index) => {
                  return <ManagerTask data={task} key={`-${index}`} />;
                })}
            </PostCol>
          </div>
          <Gap size={30} />
          <div id="resources">
            <h2>Leadership Resources</h2>
            <div id="resources-listing">
              {filteredItems &&
                filteredItems.map((item) => {
                  return (
                    <PostRow data={item} key={"post-resource-" + item.id} />
                  );
                })}
            </div>
          </div>
          <Gap size={20} />
          <div>
            {listingItems.length > pageLimit && (
              <Pagination
                setPage={setPage}
                page={page}
                count={listingItems.length}
                limit={pageLimit}
              />
            )}
          </div>
          <ButtonWrap>
            <Button href={`/library/?manager`}>MANAGER LIBRARY</Button>
          </ButtonWrap>
          <Gap size={100} />
        </PageWrapper>
      ) : (
        <NonManagerMessage>
          <h1>Manager Portal</h1>
          <p>Contact Ali Kirk for access</p>
        </NonManagerMessage>
      )}
    </>
  );
}

export default ManagerPortal;
